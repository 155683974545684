/* eslint eqeqeq: 0 */
import React, { Fragment } from 'react';
import { Container, Button, Row, Col } from 'shards-react';
import { reduxForm, Field } from 'redux-form';
import { connect } from 'react-redux';
import Scheduler, { SchedulerData, ViewTypes, DATE_FORMAT, CellUnits } from 'react-big-scheduler';
import 'react-big-scheduler/lib/css/style.css';

import moment from 'moment';
import { getTherapistsByTreatmentId, getLocationsByTherapistId, getSchedule, getAllSchedule, getTherapists, getTreatmentsByTherapistId, getTherapistScheduleSlots, addTherapistScheduleSlots, deleteTherapistScheduleSlots } from '../../redux/therapist.redux';
import { getBookings } from '../../redux/booking.redux';
import { getLocations } from '../../redux/location.redux';
import { getTreatments } from '../../redux/treatment.redux';
import { FormField } from '../../helper/formFields';

import { isEmpty } from 'lodash';

import ConfirmationDialog from '../common/ConfirmationDialog';
import { required } from '../../helper/validate';
import "moment/locale/sv";
import "moment/locale/ru";
import DatePicker from '../common/datePicker';
import { CSVLink } from 'react-csv';
import { SegmentedControl } from 'segmented-control'


import ReactAgenda from '../../thirdparty/agenda/reactAgenda';
// import ReactAgendaCtrl from '../../thirdparty/agenda/reactAgendaCtrlResp.js';
import ReactAgendaCtrl from '../../thirdparty/agenda/reactAgendaCtrl';

import Modal from '../../thirdparty/agenda/MyModal/MyModal.js';
import '../../thirdparty/agenda/myModalstyle.css';
import {
    CollapsibleComponent,
    CollapsibleHead,
    CollapsibleContent
} from "react-collapsible-component";

// import Agenda from '../schedule/Agenda'
require('moment/locale/sv.js');


var schedulerData_;
var resources = [];
var anyUpdate = true
var events = [];
var itemToBeDeleted = [];
var totalItems = [];
var refreshDataG = false


var now = new Date();
var colors = {
    'color-1': "rgba(102, 195, 131 , 1)", // #66c383
    "color-2": "rgba(242, 177, 52, 1)", //#f2b233
    "color-3": "rgba(235, 85, 59, 1)", //#eb553b
    "color-4": "rgba(70, 159, 213, 1)",
    "color-5": "rgba(170, 59, 123, 1)"
}

var items = [];


class Schedule extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            filterState: "schedule",
            test: false,
            refreshData: false,
            data: null,
            bookingData: [],
            showDeleteBookingDialogue: false,
            t: null,
            gettherapistStart: false,
            showTherapistTreatments: false,
            selectedTherapist: '',
            therapistWorkingHours: [],
            currentShowOnCalendar: [{ "start": "2020-01-20 01:00:00", "end": "2020-01-20 06:00:00" }],
            showSchedule: false,
            therapistSearchOptions: [],
            locations: [],
            showLocationScheduleModal: false,
            therapistLocationScheduleStartDate: moment().tz("Europe/Stockholm").format(),
            therapistLocationScheduleEndDate: moment().tz("Europe/Stockholm").format(),
            therapistLocationId: 0
        }

        this.state = {
            items: [],
            mbookings: [],
            backup_items: [],
            selected: [],
            cellHeight: (60 / 4),
            showModal: false,
            openNew: false,
            open: false,
            locale: "en",
            rowsPerHour: 2,
            numberOfDays: 7,
            startDate: new Date()
            // startDate: this.getMonday(new Date()) // Mon Nov 08 2010  new Date()
        }
        this.handleRangeSelection = this.handleRangeSelection.bind(this)
        this.handleItemEdit = this.handleItemEdit.bind(this)
        this.zoomIn = this.zoomIn.bind(this)
        this.zoomOut = this.zoomOut.bind(this)
        this._openModal = this._openModal.bind(this)
        this._closeModal = this._closeModal.bind(this)
        this.addNewEvent = this.addNewEvent.bind(this)
        this.removeEvent = this.removeEvent.bind(this)
        this.editEvent = this.editEvent.bind(this)
        this.changeView = this.changeView.bind(this)
        this.handleCellSelection = this.handleCellSelection.bind(this)


        var current_date = moment().format('YYYY-MM-DD');
        console.log('Current: ' + current_date);


        // schedulerData_ = new SchedulerData(current_date, ViewTypes.Week, false, false, {
        //     startResizable: true,
        //     endResizable: true,
        //     movable: false,
        //     creatable: false,
        //     nonWorkingTimeHeadColor: '#5a6169',
        //     nonWorkingTimeHeadBgColor: 'white',
        //     nonWorkingTimeBodyBgColor: 'white',
        //     resourceName: "Therapists",
        //     displayWeekend: true,
        // });
        schedulerData_ = new SchedulerData(current_date, ViewTypes.Week, false, false, {
            displayWeekend: true,
            // customCellWidth: 30,
            startResizable: false,
            endResizable: false,
            movable: false,
            creatable: false,
            // dayStartFrom: 0,
            // dayStopTo: 23,
            nonWorkingTimeHeadColor: '#5a6169',
            nonWorkingTimeHeadBgColor: 'white',
            nonWorkingTimeBodyBgColor: 'white',
            // weekCellWidth: '12%',
            resourceName: "Therapists",
            views: [
                { viewName: 'Day', viewType: ViewTypes.Day, showAgenda: false, isEventPerspective: false },
                { viewName: 'Week', viewType: ViewTypes.Week, showAgenda: false, isEventPerspective: false },
                { viewName: 'Month', viewType: ViewTypes.Month, showAgenda: false, isEventPerspective: false },
            ],
        });
        schedulerData_.localeMoment.locale('en');


    }



    getMonday(d) {
        d = new Date(d);
        var day = d.getDay(),
            diff = d.getDate() - day + (day == 0 ? -6 : 1); // adjust when day is sunday
        return new Date(d.setDate(diff));
    }

    //UNSAFE_componentWillReceiveProps(next, last) {
    //     if (next.items) {

    //         this.setState({ items: next.items })
    //     }
    // }

    handleItemDelete = (data) => {
        console.log('handleItemDelete: ' + JSON.stringify(data));
    }

    handleItemUpdate = (data) => {
        console.log('handleItemUpdate: ' + JSON.stringify(data));
        this.props.history.push('/editschedule', { action: "edit", data: data })
    }

    editMethod(item) {
        // this.props.history.push('/addschedule');

        ////////debugger;
        var slots = this.props.therapistSlots.therapistSlots;
        let filtered_ = slots.filter(el => {
            return el.id === item.id
        });
        this.props.history.push('/editschedule', { action: "edit", data: JSON.stringify(filtered_[0]), t: this.state.t })

        // this.props.history.push('/addschedule', {action: "edit", data: item, t: this.state.t})
    }

    handleItemEdit(item, openModal) {
        console.log('handleItemEdit');
        this.editMethod(item);
        // this.props.history.push('/addschedule', {action: "edit", data: item})

        // if (item && openModal === true) {
        //     this.setState({ selected: [item] })
        //     return this._openModal();
        // }
    }

    handleCellSelection(item, openModal) {
        console.log('handleCellSelection: ' + item);

        // this.setState({ selected: [item] })
        // console.log('handleCellSelection2: '+ this.state.selected);

        // if (this.state.selected && this.state.selected[0] === item) {
        //     return this._openModal();
        // }
    }

    zoomIn() {
        var num = this.state.cellHeight + 15
        this.setState({ cellHeight: num })
    }

    zoomOut() {
        var num = this.state.cellHeight - 15
        this.setState({ cellHeight: num })
    }

    handleDateRangeChange(startDate, endDate) {
        console.log('handleDateRangeChange: ' + startDate);
        this.setState({ startDate: startDate })
    }

    handleRangeSelection(selected) {


        this.setState({ selected: selected, showCtrl: true })
        this._openModal();

    }

    _openNewModal() {
        this.setState({ openNew: true })
    }

    _closeNewModal() {
        this.setState({ openNew: false })
    }



    _openModal() {
        this.setState({ showModal: true })
    }

    _closeModal(e) {
        console.log('_closeModal:')

        if (e) {
            e.stopPropagation();
            e.preventDefault();
        }
        this.setState({ showModal: false })
    }

    handleItemChange(items, item) {
        console.log('handleItemChange');
        this.setState({ items: items })
    }

    handleItemSize(items, item) {
        console.log('handleItemSize');
        this.setState({ items: items })
    }

    deleteBookingAction(items, item) {
        var items_ = this.state.items;
        this.setState({ showDeleteBookingDialogue: false })

        console.log('items_ Length: ' + JSON.stringify(items_.length));
        // console.log('removeEvent: ' + JSON.stringify(items.length) + " = " + JSON.stringify(item));
        // let filtered_ = items_.filter( el => {
        //     if (el.id === item.id) {
        //         el.id = -10
        //     }
        //     return true
        // });
        // console.log('filtered: ' + JSON.stringify(filtered_.length));

        console.log('ITEM TO BE DELETED: ' + JSON.stringify(item));
        var param = { "slot_id": item.id }
        this.props.deleteTherapistScheduleSlots(param);
    }

    removeEvent(items, item) {
        // totalItems = items;
        // itemToBeDeleted = item;

        this.props.history.push('/addschedule', { action: "delete", data: item, t: this.state.t })


    }

    addNewEvent(items, newItems) {
        console.log('addNewEvent: ' + JSON.stringify(items));
        // console.log('addNewEvent1: ' + JSON.stringify(newItems));

        // this.setState({ showModal: false, selected: [], items: items });
        // this._closeModal();
    }

    editEvent(items, item) {
        console.log('editEvent: ' + JSON.stringify(items));

        // this.setState({ showModal: false, selected: [], items: items });
        // this._closeModal();
    }

    changeView(days, event) {
        this.setState({ numberOfDays: days })
    }

    UNSAFE_componentWillMount() {
        anyUpdate = true
        events = []
        const { user } = this.props;
        console.log('componentwillMount123: ' + user.role_type);

        // //FOR THERAPIST PORTAL
        // if (user.role_type === 3) {
        //     const data = {
        //         therapist_id: user.id
        //     }
        //     //APICALL
        //     console.log('APICALL: getLocations');
        //     this.props.getLocations(data);
        // }
        // else {
        //             //APICALL
        //     // console.log('APICALL: getTherapists');
        //     // this.props.getTherapists();

        // }

        this.setState({ items: items })
    }

    getCustomDate = (schedulerData_, num, date = undefined) => {

        let selectDate = schedulerData_.startDate;
        if (date != undefined)
            selectDate = date;

        let startDate = selectDate,
            endDate = schedulerData_.localeMoment(startDate).add(1, 'days').format(DATE_FORMAT),
            cellUnit = CellUnits.Hour;
        if (num === 1) {
            startDate = schedulerData_.startDate;
            endDate = schedulerData_.localeMoment(schedulerData_.endDate).add(1, 'days').format(DATE_FORMAT);
        } else if (num === -1) {
            startDate = schedulerData_.localeMoment(schedulerData_.startDate).add(-1, 'days').format(DATE_FORMAT);
            endDate = schedulerData_.endDate;
        }

        return {
            startDate,
            endDate,
            cellUnit
        };
    }

    isNonWorkingTime = (schedulerData_, time) => {
        // const { localeMoment } = schedulerData_;
        // if (schedulerData_.cellUnit === CellUnits.Hour) {
        //     let hour = localeMoment(time).hour();
        //     console.log(" Hours: " + hour);
        //     if (hour < 10 || hour > 17)
        //         return true;
        // }
        // else {
        //     let dayOfWeek = localeMoment(time).weekday();
        //     if (dayOfWeek === 0 || dayOfWeek === 6)
        //         return true;
        // }

        return true;
    }

    isObjEmpty(obj) {
        if (obj === undefined || obj === null) {
            return true;
        }
        return Object.keys(obj).length === 0;
    }

    minTwoDigits(n) {
        return (n < 10 ? '0' : '') + n;
    }
    // COmment
    componentDidUpdate(prevProps) {
        if (this.props !== prevProps) {
            // if (this.props.locations !== [] && this.props.locations !== '') {
            //     console.log('locations123: ' + JSON.stringify(this.props.locations));
            //     this.setState({ locations: this.props.locations });
            // }

            if (this.props.therapists !== []) {
                // if (this.state.therapistSearchOptions === [] || this.state.therapistSearchOptions === '') {
                var therapistArray = []
                this.props.therapists.forEach(element => {
                    therapistArray.push({ value: element.id, label: element.first_name })
                });
                this.setState({ therapistSearchOptions: therapistArray })
                // }
                // else {
                //     console.log('therapistSearchOptions Already Filled: '+ this.state.therapistSearchOptions);
                // }
            }

            var currentShowOnCalendar_ = [];
            // Implementation for therapist schedule slots

            if (this.props.therapistSlots !== null && !this.isObjEmpty(this.props.therapistSlots)) {
                // if (this.props.therapistSlots !== [] && this.props.therapistSlots != undefined &&  this.props.therapistSlots !== "" )  {
                var slots = this.props.therapistSlots.therapistSlots;
                // console.log('therapistSlots11: ' + JSON.stringify(this.props.therapistSlots));
                // console.log('therapistSlots22: ' + JSON.stringify(slots));
                var temp_s = [];

                if (slots !== undefined && slots !== null) {
                    // if (this.state.currentShowOnCalendar === [] || this.state.currentShowOnCalendar === '') {

                    // var data = {
                    //     therapist_id: this.props.user.id
                    // }

                    // if (this.props.user.role_type === 3) {
                    //     //APICALL
                    //     console.log('APICALL: refresh getTherapistScheduleSlots');
                    //     this.props.getTherapistScheduleSlots(data);
                    // }

                    slots.forEach(slot => {

                        var startDate = new Date(0); // The 0 there is the key, which sets the date to the epoch
                        startDate.setUTCSeconds(slot.start_time);
                        var offset_ = startDate.getTimezoneOffset() * 60000;
                        var diff_ = startDate.getTime() + offset_;
                        startDate.setTime(diff_);

                        // const start = startDate.toISOString();
                        const start = moment(startDate).tz('Europe/Stockholm').format("YYYY-MM-DD hh:mm a");

                        var endDate = new Date(0); // The 0 there is the key, which sets the date to the epoch
                        endDate.setUTCSeconds(slot.end_time);
                        const end = moment(endDate).tz('Europe/Stockholm').format("YYYY-MM-DD hh:mm a");
                        var diff2_ = endDate.getTime() + offset_;
                        endDate.setTime(diff2_);


                        console.log('currentShowOnCalendar PUSH__: ' + slot.start_time + " = " + slot.end_time);
                        console.log('currentShowOnCalendar PUSH: ' + start + " = " + end);
                        // console.log('currentShowOnCalendar PUSH: ' + start.getFullYear + " = " + end);
                        console.log('currentShowOnCalendar NAME: ' + slot.name);


                        temp_s.push({
                            id: slot.id,
                            name: slot.name,
                            loc: slot.loc_id,
                            loc_name: slot.loc_name,
                            startDateTime: startDate,
                            state: "schedule",
                            endDateTime: endDate, //new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1, 13, 0),
                            classes: slot.name === 'healness_locations' ? 'color-1' : (slot.name === 'private' ? 'color-3' : 'color-2')
                        });

                        currentShowOnCalendar_.push({ start: start, end: end })
                    });

                    console.log('MYITEMS: ' + JSON.stringify(temp_s));
                    this.setState({ items: temp_s });

                    console.log('currentShowOnCalendar ABC: ' + JSON.stringify(this.state.currentShowOnCalendar));

                    this.setState({ currentShowOnCalendar: currentShowOnCalendar_ })
                    this.setState({ showSchedule: true })

                }

                // }
                // else {
                //     console.log('currentShowOnCalendar Already Filled: ' + this.state.currentShowOnCalendar);
                // }
                // }
            }
            else {
                if (this.props.success) {
                    this.setState({ showSchedule: true })
                }
            }
            //end

            if (this.props.bookings !== null && !this.isObjEmpty(this.props.bookings)) {
                let temp_s = [];
                var bookings_ = this.props.bookings;

                if (bookings_ != undefined && bookings_ !== null) {
                    bookings_.forEach(slot => {

                        var hrs_ = this.minTwoDigits(Math.trunc(slot.reserved_slot / 100));
                        var mins_ = this.minTwoDigits(slot.reserved_slot % 100);

                        var start_epoch_ = new Date(0).setUTCSeconds(slot.date)
                        var start_date_ = moment(start_epoch_);
                        start_date_.set({ hour: hrs_, minute: mins_, second: 0, millisecond: 0 })

                        var end_date_ = moment(start_epoch_).set({ hour: hrs_, minute: mins_, second: 0, millisecond: 0 }).add(slot.slot_time, 'minutes');


                        temp_s.push({
                            id: slot.raw,
                            name: slot.customer.first_name + " " + slot.customer.last_name,
                            loc: slot.location.id,
                            loc_name: slot.location.location_type === '1' ? slot.location.address : slot.location.name,
                            startDateTime: start_date_.toDate(), //startDate,
                            reserved_slot: slot.slot_time,
                            status: slot.status,
                            state: "bookings",
                            endDateTime: end_date_.toDate(), // endDate, //new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1, 13, 0),
                            classes: slot.location.location_type == '1' ? 'color-1' : (slot.location.location_type == '4' ? 'color-3' : 'color-2')
                        });
                        // }


                    });

                    // console.log('Booking MYITEMS: ' + JSON.stringify(temp_s));
                    this.setState({ mbookings: temp_s });
                    this.setState({ showSchedule: true })

                }
            }

            if (this.state.showTherapistTreatments) {
                if (!isEmpty(this.props.therapistTreaments)) {

                    // if (this.state.showTherapistTreatments === [] || this.state.showTherapistTreatments === ''){
                    var treatmentsData = this.state.selectedTherapist + "\n"
                    this.props.therapistTreaments.forEach(treatment => (
                        treatmentsData += treatment.name + "\n"
                    ))
                    alert(treatmentsData);
                    this.setState({ showTherapistTreatments: false });
                    // }
                    // else {
                    //     console.log('showTherapistTreatments Already Filled: ' + this.state.showTherapistTreatments);
                    // }
                }
            }


            // debugger;
            //SCHEDULER UPDATED: FOR ALL
            if (this.props.therapists !== null && this.props.therapists != undefined) {
                if (this.state.gettherapistStart === false || this.state.gettherapistStart === undefined) {

                    ////////debugger;
                    //FOR THERAPIST PORTAL
                    // if (this.props.user.role_type == 3) {
                    //APICALL
                    // console.log('APICALL: getSchedule');
                    // this.props.getSchedule();
                    // this.props.getAllSchedule();
                    // }
                    this.setState({ gettherapistStart: true })
                }

                let temp = this.props.therapists;

                console.log('schedulerData_ resources: ' + resources.length);
                if (resources.length == 0) {
                    resources = [];
                    temp.forEach(schedulerData => {
                        console.log("Setting therapist: " + schedulerData.id + " => " + schedulerData.first_name + " " + schedulerData.last_name);

                        resources.push({
                            id: schedulerData.id,
                            name: schedulerData.first_name + " " + schedulerData.last_name
                        })

                    });
                    schedulerData_.setResources(resources);
                }
            }

            //SCHEDULER UPDATED: FOR SPECIFIC
            if (this.props.all_schedule !== null && this.props.all_schedule != undefined) {
                let temp = isEmpty(this.props.all_schedule) ? prevProps.all_schedule : this.props.all_schedule;


                console.log('schedulerData_ events: ' + events.length);
                // console.log('schedulerData_ anyUpdate: ' + anyUpdate);

                if (events.length == 0 && anyUpdate && temp.slots_ != undefined) {
                    anyUpdate = false;
                    events = [];
                    temp.slots_.forEach(scheduleData => {
                        var d = new Date(0); // The 0 there is the key, which sets the date to the epoch
                        d.setUTCSeconds(scheduleData.scheduled_date);

                        var startDate = new Date(0); // The 0 there is the key, which sets the date to the epoch
                        startDate.setUTCSeconds(scheduleData.start_time);
                        var offset_ = startDate.getTimezoneOffset() * 60000;
                        var diff_ = startDate.getTime() + offset_;
                        startDate.setTime(diff_);

                        var endDate = new Date(0); // The 0 there is the key, which sets the date to the epoch
                        endDate.setUTCSeconds(scheduleData.end_time);
                        var diff2_ = endDate.getTime() + offset_;
                        endDate.setTime(diff2_);


                        var sTime = moment(startDate).format('YYYY-MM-DD HH:mm');
                        var eTime = moment(endDate).format('YYYY-MM-DD HH:mm');

                        // var sTime = moment(new Date(0).setUTCSeconds(scheduleData.start_time)).format('YYYY-MM-DD HH:mm');
                        // var eTime = moment(new Date(0).setUTCSeconds(scheduleData.end_time)).format('YYYY-MM-DD HH:mm');

                        console.log("START: " + sTime);
                        console.log("END: " + eTime);

                        var obj = {
                            id: scheduleData.id,
                            start: sTime,
                            end: eTime,
                            resourceId: scheduleData.therapist_id,
                            title: scheduleData.loc_name + "\n (" + scheduleData.therapist_first_name + " " + scheduleData.therapist_last_name + ")",
                            bgColor: scheduleData.name === 'healness_locations' ? '#66c383' : (scheduleData.name === 'private' ? '#eb553b' : '#f2b233')

                        };
                        events.push(obj);

                        console.log("SCHEDLED: " + scheduleData);

                    });

                    console.log('schedulerData_ setEvents: ' + JSON.stringify(events));
                    schedulerData_.setEvents(events);

                    resources = [];
                    temp.therapists_.forEach(schedulerData => {
                        resources.push({
                            id: schedulerData.id,
                            name: schedulerData.first_name + " " + schedulerData.last_name
                        })

                    });
                    schedulerData_.setResources(resources);

                    this.setState({ data: schedulerData_ });
                }
                else if (events.length > 0 && temp.slots_ != undefined) {

                    if (!anyUpdate && refreshDataG) {

                        anyUpdate = true;
                        events = []
                        refreshDataG = false;
                        this.props.getAllSchedule();

                        setTimeout(() => {
                            anyUpdate = true;
                            events = []
                            this.props.getAllSchedule();
                        }, 1000);
                    }
                }

            }

            this.setState({
                t: this.props.t,
                bookingData: events
            })
        }
    }

    componentDidMount() {
        //FOR ADMIN
        //APICALL
        // console.log('APICALL: getTreatments');
        // this.props.getTreatments();
        refreshDataG = true
        var data = {
            therapist_id: this.props.user.id
        }
        if (this.props.user.role_type === 3) {
            //APICALL
            this.props.getBookings(data);

            console.log('APICALL: getTherapistScheduleSlots');
            this.props.getTherapistScheduleSlots(data);
        }
        else {
            console.log('APICALL: getAllSchedule');
            this.props.getAllSchedule();
        }


        this.setState({
            test: true,
            data: schedulerData_,
            t: this.props.t
        });
    }

    treatmentSelect = (value, name) => {
        const data = {
            treatment_id: value
        }
        //APICALL
        console.log('APICALL: getTherapistsByTreatmentId');
        this.props.getTherapistsByTreatmentId(data);
    }

    therapistSelect = (value, name) => {
        const data = {
            therapist_id: value
        }
        //APICALL
        console.log('APICALL: getLocationsByTherapistId');
        this.props.getLocationsByTherapistId(data);
    }

    handleSubmit = (values, dispatch) => {
        console.log('APICALL: getSchedule');
        const { treatment_id, therapist_id, loc_id, slot_id } = values;
        const data = {
            therapist_id,
            treatment_id,
            location_id: loc_id,
            slot_id: slot_id,
        }
        //APICALL
        this.props.getSchedule(data);
    }

    loadMoreEvents = (events) => {
    }

    // addScheduleBackup = () => {
    //     anyUpdate = true;
    //     console.log('APICALL: addTherapistScheduleSlots');
    //     var data = {
    //         therapist_id: this.props.user.id,
    //         therapistSlots: this.state.therapistWorkingHours
    //     }
    //     console.log('APICALL: therapistWorkingHours: ' + JSON.stringify(this.state.therapistWorkingHours));

    //     //APICALL
    //     this.props.addTherapistScheduleSlots(data);
    // }

    addSchedule = () => {
        anyUpdate = true;
        console.log('APICALL: addTherapistScheduleSlots');

        var t = [];
        console.log('APICALL: therapistWorkingHours items: ' + JSON.stringify(this.state.items));

        this.state.items.forEach(i => {
            var m = i.startDateTime instanceof Date;

            console.log('Iteration: ' + i + " = " + m);


            t.push({
                id: (i.id === null || i.id === undefined) ? -1 : i.id,
                name: i.name,
                loc_name: i.loc_name,
                loc_id: i.loc,
                start_time: Math.floor(i.startDateTime.getTime() / 1000),
                end_time: Math.floor(i.endDateTime.getTime() / 1000)
            })
        });


        console.log('APICALL: therapistWorkingHours: ' + JSON.stringify(t));

        var data = {
            therapist_id: this.props.user.id,
            therapistSlots: t
        }

        console.log('APICALL: addTherapistScheduleSlots Params: ' + JSON.stringify(data));

        //APICALL
        this.props.addTherapistScheduleSlots(data);
    }

    handleStartDateChange = date => {
        this.setState({ therapistLocationScheduleStartDate: date, therapistLocationScheduleEndDate: date })
    };

    handleEndDateChange = date => {
        this.setState({ therapistLocationScheduleEndDate: date })
    };

    addCompanySchedule = () => {
        this.setState({ showLocationScheduleModal: true })
    }

    addLocationScheduleSuccess = (event) => {
        anyUpdate = true;
        console.log('APICALL: addLocationScheduleSuccess');



        var t = [];
        console.log('APICALL: addLocationScheduleSuccess items: ' + JSON.stringify(this.state.items));

        for (var i in this.state.items) {
            t.push({
                id: (i.id === null || i.id === undefined) ? -1 : i.id,
                name: i.name,
                loc_name: i.loc_name,
                loc_id: i.loc,
                start_time: Math.floor(i.startDateTime.getTime() / 1000),
                end_time: Math.floor(i.endDateTime.getTime() / 1000)
            })
        }
        console.log('APICALL: addLocationScheduleSuccess: ' + JSON.stringify(t));


        var data = {
            therapist_id: this.props.user.id,
            location_id: this.state.therapistLocationId,
            therapistSlots: t
        }
        //APICALL
        console.log('APICALL: addLocationScheduleSuccess Params: ' + JSON.stringify(data));
        this.props.addTherapistScheduleSlots(data);

        this.setState({ showLocationScheduleModal: false })

    }

    selectCompanyInfo = (event) => {
        this.setState({ therapistLocationId: event })
    }

    loadTypeValues() {
        return (
            <select>
                <option selected value="healness">Healness</option>
                <option value="company">Company</option>
                <option value="other">Other</option>
            </select>
        );
    }

    extractLoc() {


    }

    loadTherapistsValues() {
        var therapists = [{ "id": 15, "name": "Test User" }]
        return therapists
    }

    loadLocValues() {
        console.log('loadLocValues: ' + JSON.stringify(this.props.locations));

        var type_1 = this.props.locations.filter(function (number) {
            return number.location_type == 1;
        });
        var type_2 = this.props.locations.filter(function (number) {
            return number.location_type == 2;
        });
        var type_3 = this.props.locations.filter(function (number) {
            return number.location_type == 3;
        });
        var type_4 = this.props.locations.filter(function (number) {
            return number.location_type == 4;
        });
        //.map(person => ({ id: person.id, name: person.name, room: person.room }));

        console.log('loadLocValues: type_4' + JSON.stringify(type_4));

        // export const locationTypes = {
        //     Private: 1,
        //     Office: 2,
        //     Hotels: 3,
        //     Healness_Locations: 4
        //   }

        var temp = {
            "private": type_1,
            "office": type_2,
            "hotels": type_3,
            "healness_locations": type_4
        }

        return temp;
    }

    loadDataInTable() {
        var content = [], temp = []
        var locs = this.props.locations;
        locs.forEach(element => {
            content.push(<CollapsibleContent className="additionalClassForContent">
                <p>{element.id}</p>
                <p>{element.name}</p>
            </CollapsibleContent>)
        });
        temp.push(<CollapsibleComponent>
            <CollapsibleHead className="additionalClassForHead">
                Healness Locations
            </CollapsibleHead>
            {content}
        </CollapsibleComponent>);

        return temp;
    }

    millisToMinutesAndSeconds(millis) {
        var minutes = Math.floor(millis / 60000);
        // return minutes + ":" + (seconds < 10 ? '0' : '') + seconds;
        return minutes + " mins";
    }

    getTimeDifference(start, end) {
        var dur = end - start;
        // //////////debugger;
        var duratH = this.millisToMinutesAndSeconds(moment.duration(dur, 'Milliseconds'));

        var duratL = moment(start).format("HH:mm")
        var duratE = moment(end).format("HH:mm")
        var date = moment(end).format("DD-MM-YYYY")

        return " " + duratL + " - " + duratE + ", " + date + " (" + duratH + ") "
    }

    renderPerson = (person, idx) => {
        console.log('renderPerson: ' + JSON.stringify(person));

        return (
            <div key={`${person.loc_id}`}>
                <b>{person.loc_name} {" (" + person.name + ") "}</b>
                <b>{this.getTimeDifference(person.start_time, person.end_time)}</b>
            </div>
        );

    }

    onOpenModal = () => {
        this.setState({ open: true });
    };

    onCloseModal = () => {
        this.setState({ open: false });
    };

    closeModal = () => {
        this.setState({ open: false });
    }

    saveAndClose = () => {
    }

    addScheduleBtn = () => {
        // this.props.history.push('/addschedule');
        this.setState({ refreshData: true });
        localStorage.setItem('needToRefresh', true);


        this.props.history.push('/addschedule', { action: "add", data: null, t: this.state.t })

    }

    updateScheduleBtn = () => {
        // this.props.history.push('/addschedule');
        this.props.history.push('/addschedule', { action: "add", data: null, t: this.state.t })

    }
    segmentControlTapped(val) {
        console.log('segmentControlTapped: ' + val);
        this.setState({ filterState: val });
    }

    render = () => {
        console.log('USER ROLE: ' + this.props.user.role_type);

        const { t } = this.props;
        const { therapist_list, treatments, therapistLocations, locations } = this.props;
        var therapistOptions = [], treatmentOptions = [], locationOptions = [];
        var locationList = [];

        if (this.props.user.role_type !== 3) {
            if (therapist_list !== [] && therapist_list != undefined) {
                therapist_list.forEach(therapist => {
                    //resources.push({ id: therapist.id, name: therapist.name });
                    therapistOptions.push({ value: therapist.id, label: therapist.first_name + " " + therapist.last_name });
                });
            }
            treatments.forEach(treatment => {
                treatmentOptions.push({ value: treatment.id, label: treatment.name });
            });

            therapistLocations.forEach(location => {
                locationOptions.push({ value: location.id, label: location.name });
            });
        }
        else {
            locations.forEach(location => {
                locationList.push({ value: location.id, label: location.name })
            });
        }


        var filtered_items = [];
        if (this.state.filterState === "schedule") {
            filtered_items = this.state.items;
        }
        else if (this.state.filterState === "bookings") {
            filtered_items = this.state.mbookings;
        }
        else if (this.state.filterState === "all") {
            filtered_items = this.state.items.concat(this.state.mbookings);
        }

        // const [locale, setLocale] = useState("sv");
        return (

            <Container className="personal-container user-container scheduled-container">
                <Row className="schedule-container">
                    <Col className="col-md-6">
                        <h3 className="pb-5 title-header">{t != null && (this.props.user.role_type === 3 ? t.translations.schedule_when_work : t.translations.schedule)}</h3>
                    </Col>
                    {(this.props.user.role_type == 3 || this.props.user.role_type == 2) &&
                        <Col className="col-md-6">
                            {/* <button id="myBtn" onClick={this.onOpenModal}>Open Modal</button> */}
                            <Button className="btn-block mt-2 float-right btn-login addUser-btn addCompanySchedule" type="button" onClick={this.addScheduleBtn}>{t != null ? t.translations.add_location_schedule : 'Add Location Schedule Local'}</Button>
                            {this.props.user.role_type == 3 && <Button className="btn-block mt-2 float-right btn-login addUser-btn addCompanySchedule" type="button">
                                <CSVLink
                                    filename={this.props.user.first_name + "_" + this.props.user.last_name + "_schedule.csv"}
                                    data={this.state.items}>{t != null ? t.translations.export_schedule : 'Export Schedule (CSV)'}</CSVLink>
                            </Button>
                            }
                        </Col>
                    }
                    <Col className="col-md-6">
                    </Col>
                </Row>

                {/* <Row>
                    <div>
                    <FlatList list={this.props.therapistSlots.therapistSlots} renderItem={this.renderPerson}/>
                    </div>
                </Row> */}
                {this.props.user.role_type === 3 &&
                    <Row>
                        <SegmentedControl
                            name="type_filter"
                            options={[
                                // { label: "All", value: "all"},
                                { label: t.translations.schedule, value: "schedule", default: true },
                                { label: t.translations.bookings, value: "bookings" },
                            ]}
                            setValue={newValue => this.segmentControlTapped(newValue)}
                            style={{ width: 400, height: 40, color: 'black' }} // purple400
                        />
                        <br />
                        <br />
                    </Row>}
                {this.props.user.role_type === 3 &&
                    <Row>
                        <Col className="col-md-12 color-plate">
                            <img
                                alt="Locations Info"
                                style={{ width: '50%' }}
                                src={require('../../assets/color_plate.png')} />
                        </Col>
                    </Row>}
                {(this.props.user.role_type === 3 && this.state.showLocationScheduleModal) && <ConfirmationDialog
                    title={this.state.t != null ? 'Therapist Schedule' : ''}
                    bookingCancelBtn={true}
                    bodyText={
                        <Fragment>
                            <div className="row date-picker-container">
                                <Field
                                    label={t != null ? t.translations.select_location : ''}
                                    type="select"
                                    name="company_id"
                                    classes="Field-input menu-list col-md-12"
                                    options={locationList}
                                    component={FormField}
                                    validate={[required]}
                                    onSelect={this.selectCompanyInfo}
                                    t={this.props.t}
                                    // defaultValue={userInfo.company_id > 0 ? { label: userInfo.company_name, value: userInfo.company_id } : ''}
                                    placeholder={t != null ? t.translations.select_location : ''}
                                />
                                <DatePicker therapistLocationScheduleStartDate={this.state.therapistLocationScheduleStartDate} therapistLocationScheduleEndDate={this.state.therapistLocationScheduleEndDate} handleStartDateChange={this.handleStartDateChange} handleEndDateChange={this.handleEndDateChange} />
                            </div>
                        </Fragment>
                    }
                    handleCancel={() => this.setState({ showLocationScheduleModal: false })}
                    handleOk={this.addLocationScheduleSuccess} />
                }
                <Row>
                    {(this.props.user.role_type !== 3) &&
                        <Col className="col-md-12">
                            {this.state.data != null &&
                                <Scheduler
                                    schedulerData={this.state.data}
                                    prevClick={this.prevClick}
                                    nextClick={this.nextClick}
                                    onSelectDate={this.onSelectDate}
                                    onViewChange={this.onViewChange}
                                    eventItemClick={this.eventClicked}
                                    updateEventStart={this.updateEventStart}
                                    updateEventEnd={this.updateEventEnd}
                                    moveEvent={this.moveEvent}
                                    newEvent={this.newEvent}
                                    slotClickedFunc={this.slotClickedFunc}
                                    toggleExpandFunc={this.toggleExpandFunc}
                                />
                            }
                        </Col>
                    }

                </Row>
                {this.props.user.role_type === 3 && <Row style={{
                    backgroundColor: 'transparent',
                    // paddingTop: '45px',
                    paddingBottom: '45px',
                }}>
                    {/* <Agenda items1={items} /> */}
                    <div className="content-expanded ">
                        {/* <div className="control-buttons">
                          <button  className="button-control" onClick={this._openModal}> <i className="schedule-icon"></i> </button>
                        </div> */}
                        <ReactAgenda
                            minDate={new Date(now.getFullYear(), now.getMonth() - 12)}
                            maxDate={new Date(now.getFullYear(), now.getMonth() + 12)}
                            startDate={this.state.startDate}
                            startAtTime={1}
                            endAtTime={26}
                            cellHeight={this.state.cellHeight}
                            locale="en"
                            state={this.state.filterState}
                            items={filtered_items}
                            numberOfDays={this.state.numberOfDays}
                            headFormat={"DD MMM"}
                            rowsPerHour={this.state.rowsPerHour}
                            itemColors={colors}
                            helper={true}
                            view="calendar"
                            autoScale={true}
                            fixedHeader={true}
                            // onRangeSelection={this.handleRangeSelection.bind(this)}
                            // onChangeEvent={this.handleItemChange.bind(this)}
                            // onChangeDuration={this.handleItemSize.bind(this)}
                            onItemEdit={this.state.filterState === "schedule" ? this.handleItemEdit.bind(this) : null}
                        // onCellSelect={this.handleCellSelection.bind(this)}
                        // onItemRemove={this.removeEvent.bind(this)}
                        // onDateRangeChange={this.handleDateRangeChange.bind(this)}
                        />
                        {
                            this.state.showModal ? <Modal
                                clickOutside={this._closeModal} >
                                <div className="modal-content1">
                                    <ReactAgendaCtrl
                                        viewOnly={this.state.filterState}
                                        clickOutside={this._closeModal}
                                        isAdmin={false}
                                        therapists_values={this.loadTherapistsValues()}
                                        type_values={this.loadTypeValues()}
                                        loc_values={this.loadLocValues()}
                                        items={this.state.items}
                                        itemColors={colors}
                                        selectedCells={this.state.selected}
                                        Addnew={this.addNewEvent}
                                        edit={this.editEvent} />

                                </div>
                            </Modal> : ''
                        }


                    </div>
                    {/* <div className="col-md-12 col-xs-12 float-right">
                        <Button className="btn-block float-right save-btn Field-input" onClick={this.addSchedule}>{t != null && t.translations.publish_changes} {this.props.loading && <i className="fas fa-circle-notch fa-spin loader"></i>}</Button>
                    </div> */}
                </Row>
                }
                {this.state.showDeleteBookingDialogue &&
                    <ConfirmationDialog
                        title={this.state.t != null ? this.state.t.translations.delete_confirmation : ''}
                        bodyText={this.state.t != null && this.state.t.translations.are_you_sure_to_delete}
                        handleCancel={() => this.setState({ showDeleteBookingDialogue: false })}
                        handleOk={() => this.deleteBookingAction(totalItems, itemToBeDeleted)} />}

            </Container >
        );

    }


    slotClickedFunc = (schedulerData, slot) => {
        const data = {
            therapist_id: slot.slotId
        }
        //APICALL
        console.log('APICALL: getTreatmentsByTherapistId');
        this.props.getTreatmentsByTherapistId(data);
        this.setState({
            showTherapistTreatments: true,
            selectedTherapist: slot.slotName
        })

    }

    prevClick = (schedulerData) => {
        schedulerData.prev();
        schedulerData.setEvents(this.state.bookingData);
        this.setState({
            viewModel: schedulerData
        })
    }

    nextClick = (schedulerData) => {
        schedulerData.next();
        schedulerData.setEvents(this.state.bookingData);
        this.setState({
            viewModel: schedulerData
        })
    }

    onViewChange = (schedulerData, view) => {
        schedulerData.setViewType(view.viewType, view.showAgenda, view.isEventPerspective);
        schedulerData.setEvents(this.state.bookingData);
        this.setState({
            viewModel: schedulerData
        })
    }

    onSelectDate = (schedulerData, date) => {
        schedulerData.setDate(date);
        schedulerData.setEvents(this.state.bookingData);
        this.setState({
            viewModel: schedulerData
        })
    }

    eventClicked = (schedulerData, event) => {
        // alert(`You just clicked an event: {id: ${event.id}, title: ${event.title}}`);

        // var slots = this.props.therapistSlots.therapistSlots;

        var slots = this.props.all_schedule.slots_;

        console.log("eventClicked action");
        if (slots != undefined) {
            let filtered_ = slots.filter(el => {
                return el.id === event.id
            });
            this.props.history.push('/editschedule', { action: "edit", data: JSON.stringify(filtered_[0]), t: this.state.t });
            // refreshDataG = true
        }

    };

    newEvent = (schedulerData, slotId, slotName, start, end, type, item) => {
        // if (confirm(`Do you want to create a new event? {slotId: ${slotId}, slotName: ${slotName}, start: ${start}, end: ${end}, type: ${type}, item: ${item}}`)) {

        //     let newFreshId = 0;
        //     schedulerData.events.forEach((item) => {
        //         if (item.id >= newFreshId)
        //             newFreshId = item.id + 1;
        //     });

        //     let newEvent = {
        //         id: newFreshId,
        //         title: 'New event you just created',
        //         start: start,
        //         end: end,
        //         resourceId: slotId,
        //         bgColor: 'purple'
        //     }
        //     schedulerData.addEvent(newEvent);
        //     this.setState({
        //         viewModel: schedulerData
        //     })
        // }
    }

    updateEventStart = (schedulerData, event, newStart) => {
        // if (confirm(`Do you want to adjust the start of the event? {eventId: ${event.id}, eventTitle: ${event.title}, newStart: ${newStart}}`)) {
        //     schedulerData.updateEventStart(event, newStart);
        // }
        this.setState({
            viewModel: schedulerData
        })
    }

    updateEventEnd = (schedulerData, event, newEnd) => {
        // if (confirm(`Do you want to adjust the end of the event? {eventId: ${event.id}, eventTitle: ${event.title}, newEnd: ${newEnd}}`)) {
        //     schedulerData.updateEventEnd(event, newEnd);
        // }
        this.setState({
            viewModel: schedulerData
        })
    }

    moveEvent = (schedulerData, event, slotId, slotName, start, end) => {
        // if (confirm(`Do you want to move the event? {eventId: ${event.id}, eventTitle: ${event.title}, newSlotId: ${slotId}, newSlotName: ${slotName}, newStart: ${start}, newEnd: ${end}`)) {
        //     schedulerData.moveEvent(event, slotId, slotName, start, end);
        //     this.setState({
        //         viewModel: schedulerData
        //     })
        // }
    }

    toggleExpandFunc = (schedulerData, slotId) => {
        schedulerData.toggleExpandStatus(slotId);
        this.setState({
            viewModel: schedulerData
        });
    }

};

const mapStateToProps = state => {
    return {
        t: state.accountRedux.localeInfo.t,
        bookings: state.bookingRedux.bookings,
        loading: state.therapistRedux.loading,
        success: state.therapistRedux.success,
        message: state.therapistRedux.message,
        therapists: state.therapistRedux.therapists,
        treatments: state.treatmentRedux.treatments,
        therapistLocations: state.therapistRedux.therapistLocations,
        schedule: state.therapistRedux.schedule,
        all_schedule: state.therapistRedux.all_schedule,
        user: state.accountRedux.user,
        therapistTreaments: state.therapistRedux.therapistTreaments,
        therapistSlots: state.therapistRedux.therapistSlots,
        therapist_list: state.therapistRedux.allTherapist,
        showTherapistData: state.therapistRedux.showTherapistData,
        locations: state.locationRedux.locations,
        delete_therapist: state.therapistRedux.delete_therapist,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        getBookings: (data) => dispatch(getBookings(data)),
        getTreatments: (data) => dispatch(getTreatments(data)),
        getTherapists: (data) => dispatch(getTherapists(data)),
        getLocations: (data) => dispatch(getLocations(data)),
        getTherapistsByTreatmentId: (data) => dispatch(getTherapistsByTreatmentId(data)),
        getLocationsByTherapistId: (data) => dispatch(getLocationsByTherapistId(data)),
        getSchedule: (data) => dispatch(getSchedule(data)),
        getAllSchedule: (data) => dispatch(getAllSchedule(data)),
        getTreatmentsByTherapistId: (data) => dispatch(getTreatmentsByTherapistId(data)),
        addTherapistScheduleSlots: (data) => dispatch(addTherapistScheduleSlots(data)),
        getTherapistScheduleSlots: (data) => dispatch(getTherapistScheduleSlots(data)),
        deleteTherapistScheduleSlots: (data) => dispatch(deleteTherapistScheduleSlots(data))
    }
};
export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({
    form: 'ScheduleForm'
})(Schedule));