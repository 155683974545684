import { isArray, isUndefined, isEmpty } from 'lodash';
// let csc = require('country-state-city').default;
import csc from 'country-state-city';

export const items = [
  {
    title: "staffdashboard",
    to: "/staffdashboard",
    htmlAfter: ""
  },
  {
    title: "dashboard",
    to: "/dashboard",
    htmlAfter: ""
  },
  {
    title: "schedule",
    to: "/schedule",
    htmlAfter: ""
  },
  {
    title: "bookings",
    to: "/booking",
    htmlAfter: ""
  },
  {
    title: "employee_benefit_program",
    to: "/employeebenefitprogram",
    htmlAfter: ""
  },

  {
    title: "transaction_list",
    to: "/transaction",
    htmlAfter: ""
  },
  {
    title: "receipts",
    to: "/receipts",
    htmlAfter: ""
  },
  {
    title: "promotion",
    to: "/promotion",
    htmlAfter: ""
  },
  {
    title: "customers",
    to: "/customers",
    htmlAfter: ""
  },
  {
    title: "companies",
    to: "/company",
    htmlAfter: ""
  },
  {
    title: "therapist_list",
    to: "/therapist",
    htmlAfter: ""
  },
  {
    title: "locations",
    to: "/location",
    htmlAfter: ""
  },
  {
    title: "treatment_list",
    to: "/treatment",
    htmlAfter: ""
  },
  {
    title: "menu_invoice",
    to: "/invoice",
    htmlAfter: ""
  },
  {
    title: "company_invoices_report",
    to: "/company_invoice",
    htmlAfter: ""
  },
  {
    title: "vat_report",
    to: "/vat",
    htmlAfter: ""
  },
  {
    title: "notifications",
    to: "/notifications",
    htmlAfter: ""
  },
  {
    title: "scheduler",
    to: "/scheduled_events",
    htmlAfter: ""
  },
  {
    title: "events",
    to: "/events",
    htmlAfter: ""
  },

  // {
  //   title: "users",
  //   to: "/user",
  //   htmlAfter: ""
  // },
  {
    title: "contact",
    to: "/contact",
    htmlAfter: ""
  },
  {
    title: "about_system",
    to: "/about_system",
    htmlAfter: ""
  },
  {
    title: "hotel_list",
    to: "/hotel",
    htmlAfter: ""
  },
  {
    title: "reports",
    to: "/report",
    htmlAfter: ""
  },
  // {
  //   title: "personal",
  //   to: "/personal",
  //   htmlAfter: ""
  // },


]

export const RoleTypes = {
  SUPERADMIN: '1',
  ADMIN: '2',
  STAFF: '3'
}

export const DefaultStaffPermissions = {
  StaffDashboard: "staffdashboard",
  Schedule: "schedule",
  Personal: "personal",
  Contact: "contact",
  Locations: "locations",
  About: "about_system",
  Logout: "logout"
};

export const DefaultAdminPermissions = {
  Dashboard: "dashboard",
  Schedule: "schedule",
  EmployeeBenefitProgram: "employee_benefit_program",
  Booking: "bookings",

  Receipt: "receipts",
  Promotion: "promotion",
  Customers: "customers",
  Companies: "companies",
  Therapist: "therapist_list",
  Hotel: "hotel_list",

  Treatment: "treatment_list",
  Invoice: "menu_invoice",
  CompanyInvoice: "company_invoices_report",
  VAT: "vat_report",
  Notification: "notifications",
  Scheduler: "scheduler",
  Events: "events",
  About: "about_system",

  Personal: "personal",
  Users: "users",
  Staff: "staff",
  Logout: "logout",
  Transaction: "transaction_list",
  Reports: "reports",
};


export const groupBy = (list, keyGetter) => {
  const map = new Map();
  list.forEach((item) => {
    const key = keyGetter(item);
    const collection = map.get(key);
    if (!collection) {
      map.set(key, [item]);
    } else {
      collection.push(item);
    }
  });
  return map;
}

export const getKeyByValue = (object, value) => {
  var data = Object.keys(object).find(key => object[key] === value);
  return data;
}

export const DemoData = {
  resources: [
    {
      id: 'r0',
      name: 'Resource0',
      groupOnly: true,
    },
    {
      id: 'r1',
      name: 'Resource1',
      parentId: 'r0',
    },
    {
      id: 'r2',
      name: 'Resource2',
      parentId: 'r3',
    },
    {
      id: 'r3',
      name: 'Resource3',
      parentId: 'r1',
    },
    {
      id: 'r4',
      name: 'Resource4',
    },
    {
      id: 'r5',
      name: 'Resource5',
    },
    {
      id: 'r6',
      name: 'Resource6',
    },
    {
      id: 'r7',
      name: 'Resource7Resource7Resource7Resource7Resource7',
    }
  ],
  events: [
    {
      id: 1,
      start: '2017-12-18 09:30:00',
      end: '2017-12-19 23:30:00',
      resourceId: 'r1',
      title: 'I am finished',
      bgColor: '#D9D9D9',
      showPopover: false
    },
    {
      id: 2,
      start: '2017-12-18 12:30:00',
      end: '2017-12-26 23:30:00',
      resourceId: 'r2',
      title: 'I am not resizable',
      resizable: false
    },
    {
      id: 3,
      start: '2017-12-19 12:30:00',
      end: '2017-12-20 23:30:00',
      resourceId: 'r3',
      title: 'I am not movable',
      movable: false
    },
    {
      id: 4,
      start: '2017-12-19 14:30:00',
      end: '2017-12-20 23:30:00',
      resourceId: 'r4',
      title: 'I am not start-resizable',
      startResizable: false,
    },
    {
      id: 5,
      start: '2017-12-19 15:30:00',
      end: '2017-12-20 23:30:00',
      resourceId: 'r5',
      title: 'I am not end-resizable',
      endResizable: false
    },
    {
      id: 6,
      start: '2017-12-19 15:35:00',
      end: '2017-12-19 23:30:00',
      resourceId: 'r6',
      title: 'I am normal'
    },
    {
      id: 7,
      start: '2017-12-19 15:40:00',
      end: '2017-12-20 23:30:00',
      resourceId: 'r7',
      title: 'I am exceptional',
      bgColor: '#FA9E95'
    },
    {
      id: 8,
      start: '2017-12-19 15:50:00',
      end: '2017-12-19 23:30:00',
      resourceId: 'r1',
      title: 'I am locked',
      movable: false,
      resizable: false,
      bgColor: 'red'
    },
    {
      id: 9,
      start: '2017-12-19 16:30:00',
      end: '2017-12-27 23:30:00',
      resourceId: 'r1',
      title: 'R1 has many tasks 1'
    },
    {
      id: 10,
      start: '2017-12-19 17:30:00',
      end: '2017-12-19 23:30:00',
      resourceId: 'r1',
      title: 'R1 has recurring tasks every week on Tuesday, Friday',
      rrule: 'FREQ=WEEKLY;DTSTART=20171219T013000Z;BYDAY=TU,FR',
      bgColor: '#f759ab'
    },
    {
      id: 11,
      start: '2017-12-19 18:30:00',
      end: '2017-12-20 23:30:00',
      resourceId: 'r1',
      title: 'R1 has many tasks 3'
    },
    {
      id: 12,
      start: '2017-12-20 18:30:00',
      end: '2017-12-20 23:30:00',
      resourceId: 'r1',
      title: 'R1 has many tasks 4'
    },
    {
      id: 13,
      start: '2017-12-21 18:30:00',
      end: '2017-12-24 23:30:00',
      resourceId: 'r1',
      title: 'R1 has many tasks 5'
    },
    {
      id: 14,
      start: '2017-12-23 18:30:00',
      end: '2017-12-27 23:30:00',
      resourceId: 'r1',
      title: 'R1 has many tasks 6'
    },
  ],
  eventsForTaskView: [
    {
      id: 1,
      start: '2017-12-18 09:30:00',
      end: '2017-12-18 23:30:00',
      resourceId: 'r1',
      title: 'I am finished',
      bgColor: '#D9D9D9',
      groupId: 1,
      groupName: 'Task1'
    },
    {
      id: 2,
      start: '2017-12-18 12:30:00',
      end: '2017-12-26 23:30:00',
      resourceId: 'r2',
      title: 'I am not resizable',
      resizable: false,
      groupId: 2,
      groupName: 'Task2'
    },
    {
      id: 3,
      start: '2017-12-19 12:30:00',
      end: '2017-12-20 23:30:00',
      resourceId: 'r3',
      title: 'I am not movable',
      movable: false,
      groupId: 3,
      groupName: 'Task3'
    },
    {
      id: 7,
      start: '2017-12-19 15:40:00',
      end: '2017-12-20 23:30:00',
      resourceId: 'r7',
      title: 'I am exceptional',
      bgColor: '#FA9E95',
      groupId: 4,
      groupName: 'Task4'
    },
    {
      id: 4,
      start: '2017-12-20 14:30:00',
      end: '2017-12-21 23:30:00',
      resourceId: 'r4',
      title: 'I am not start-resizable',
      startResizable: false,
      groupId: 1,
      groupName: 'Task1'
    },
    {
      id: 5,
      start: '2017-12-21 15:30:00',
      end: '2017-12-22 23:30:00',
      resourceId: 'r5',
      title: 'I am not end-resizable',
      endResizable: false,
      groupId: 3,
      groupName: 'Task3'
    },
    {
      id: 9,
      start: '2017-12-21 16:30:00',
      end: '2017-12-21 23:30:00',
      resourceId: 'r1',
      title: 'R1 has many tasks',
      groupId: 4,
      groupName: 'Task4'
    },
    {
      id: 6,
      start: '2017-12-22 15:35:00',
      end: '2017-12-23 23:30:00',
      resourceId: 'r6',
      title: 'I am normal',
      groupId: 1,
      groupName: 'Task1'
    },
    {
      id: 8,
      start: '2017-12-25 15:50:00',
      end: '2017-12-26 23:30:00',
      resourceId: 'r1',
      title: 'I am locked',
      movable: false,
      resizable: false,
      bgColor: 'red',
      groupId: 1,
      groupName: 'Task1'
    },
    {
      id: 10,
      start: '2017-12-26 18:30:00',
      end: '2017-12-26 23:30:00',
      resourceId: 'r2',
      title: 'R2 has many tasks',
      groupId: 4,
      groupName: 'Task4'
    },
    {
      id: 11,
      start: '2017-12-27 18:30:00',
      end: '2017-12-27 23:30:00',
      resourceId: 'r14',
      title: 'R4 has many tasks',
      groupId: 4,
      groupName: 'Task4'
    },
    {
      id: 12,
      start: '2017-12-28 18:30:00',
      end: '2017-12-28 23:30:00',
      resourceId: 'r6',
      title: 'R6 has many tasks',
      groupId: 3,
      groupName: 'Task3'
    },
  ],
  eventsForCustomEventStyle: [
    {
      id: 1,
      start: '2017-12-18 09:30:00',
      end: '2017-12-19 23:30:00',
      resourceId: 'r1',
      title: 'I am finished',
      bgColor: '#D9D9D9',
      type: 1
    },
    {
      id: 2,
      start: '2017-12-18 12:30:00',
      end: '2017-12-26 23:30:00',
      resourceId: 'r2',
      title: 'I am not resizable',
      resizable: false,
      type: 2
    },
    {
      id: 3,
      start: '2017-12-19 12:30:00',
      end: '2017-12-20 23:30:00',
      resourceId: 'r3',
      title: 'I am not movable',
      movable: false,
      type: 3
    },
    {
      id: 4,
      start: '2017-12-19 14:30:00',
      end: '2017-12-20 23:30:00',
      resourceId: 'r4',
      title: 'I am not start-resizable',
      startResizable: false,
      type: 1
    },
    {
      id: 5,
      start: '2017-12-19 15:30:00',
      end: '2017-12-20 23:30:00',
      resourceId: 'r5',
      title: 'I am not end-resizable',
      endResizable: false,
      type: 2
    },
    {
      id: 6,
      start: '2017-12-19 15:35:00',
      end: '2017-12-19 23:30:00',
      resourceId: 'r6',
      title: 'I am normal',
      type: 3
    },
    {
      id: 7,
      start: '2017-12-19 15:40:00',
      end: '2017-12-20 23:30:00',
      resourceId: 'r7',
      title: 'I am exceptional',
      bgColor: '#FA9E95',
      type: 1
    },
    {
      id: 8,
      start: '2017-12-19 15:50:00',
      end: '2017-12-19 23:30:00',
      resourceId: 'r1',
      title: 'I am locked',
      movable: false,
      resizable: false,
      bgColor: 'red',
      type: 2
    },
    {
      id: 9,
      start: '2017-12-19 16:30:00',
      end: '2017-12-27 23:30:00',
      resourceId: 'r1',
      title: 'R1 has many tasks 1',
      type: 3
    },
    {
      id: 10,
      start: '2017-12-20 18:30:00',
      end: '2017-12-20 23:30:00',
      resourceId: 'r1',
      title: 'R1 has many tasks 2',
      type: 1
    },
    {
      id: 11,
      start: '2017-12-21 18:30:00',
      end: '2017-12-22 23:30:00',
      resourceId: 'r1',
      title: 'R1 has many tasks 3',
      type: 2
    },
    {
      id: 12,
      start: '2017-12-23 18:30:00',
      end: '2017-12-27 23:30:00',
      resourceId: 'r1',
      title: 'R1 has many tasks 4',
      type: 3
    },
  ],
}

export const userHistory = [{ first_name: 'sundas', last_name: 'noreen', email: 'sundasnoreen12@gmail.com', mobile_phone: '03065164312', address: 'DPL-Islamabad' },
{ first_name: 'Erik', last_name: 'Afzelius', email: 'erik@afzelius.se', mobile_phone: '', address: 'Erik Dahlbergsgatan 53 lgh 1002, 115 57 Stockholm' },
{ first_name: 'Sana', last_name: 'Takveen', email: 'sana.t@dplit.com', mobile_phone: '03061323323', address: 'DPL-Islamabad' },
{ first_name: 'Abdul', last_name: 'Basit', email: 'abdul.b@dplit.com', mobile_phone: '03063232312', address: 'DPL-Islamabad' },
{ first_name: 'John', last_name: 'Mickel', email: 'john@gmail.com', mobile_phone: '03065164312', address: '4408  Riverside Drive' }]

export const companyHistory = [
  { vat_num: '556012-5790', name: 'Aktiebolaget Volvo', country: 'Västra Götaland County', address: 'Gothenburg', org_num: '556012-5790' },
  { vat_num: '432444', name: '2Heal medical AB', country: 'Stockholm County', address: 'Saltsjöbaden', org_num: '559183-0236' },
  { vat_num: '23123', name: 'Google Sweden AB', country: 'Stockholm County', address: 'Dublin 4', org_num: '556656-6880' },
  { vat_num: '23176', name: 'Allys Apple AB', country: 'Västra Götaland County', address: 'Susisaari', org_num: '559010-4823' },
  { vat_num: '19700723-0191', name: 'SOLE TRADER', country: 'Skåne County', address: 'Tonganoxie', org_num: '19700723-0191 002' }]

export const DATA = [
  {
    headerName: 'ListA',
    isOpened: true,
    // isReactComponent: false,
    items: [{
      title: 'items1'
    },
    {
      title: 'items2'
    },
    {
      title: 'items3'
    },
    {
      title: 'items4'
    },
    {
      title: 'items5'
    },
    {
      title: 'items6'
    }],
    height: 120
  },
  {
    headerName: 'ListB is bravo',
    isOpened: true,
    // isReactComponent: false,
    items: [{
      title: 'items1'
    },
    {
      title: 'items2'
    },
    {
      title: 'items3'
    },
    {
      title: 'items4'
    },
    {
      title: 'items5'
    },
    {
      title: 'items6'
    }],
    height: 120
  },
  {
    headerName: 'ListC is Charlie',
    isOpened: true,
    // isReactComponent: false,
    items: [{
      title: 'items1'
    },
    {
      title: 'items2'
    },
    {
      title: 'items3'
    },
    {
      title: 'items4'
    },
    {
      title: 'items5'
    },
    {
      title: 'items6'
    }],
    height: 120
  },
  {
    headerName: 'ListD is Dynamic',
    isOpened: true,
    isReactComponent: false,
    items: [{
      title: 'items1'
    },
    {
      title: 'items2'
    },
    {
      title: 'items3'
    },
    {
      title: 'items4'
    },
    {
      title: 'items5'
    },
    {
      title: 'items6'
    }],
    height: 120
  },
  {
    headerName: 'ListE',
    isOpened: true,
    isReactComponent: false,
    items: [{
      title: 'items1'
    },
    {
      title: 'items2'
    },
    {
      title: 'items3'
    },
    {
      title: 'items4'
    },
    {
      title: 'items5'
    },
    {
      title: 'items6'
    }],
    height: 120
  }
]

export const locationTypes = {
  Private: 1,
  Office: 2,
  Hotels: 3,
  Healness_Locations: 4
}

export const StatusType = {
  Inactive: 0,
  Active: 1
}
export const locationTypesOption = [{ value: '1', label: 'Private' }, { value: '2', label: 'Office' }, { value: '3', label: 'Hotels' }, { value: '4', label: 'Healness_Locations' }]
export const locationTypesOptionN = [{ value: '4', label: 'Healness' }, { value: '2', label: 'Office' }, { value: '1', label: 'Private' }]

export const treatmentType = [{ value: 'Healness', label: 'Healness' }, { value: 'Massage', label: 'Massage' }, { value: 'Rehab', label: 'Rehab' }]
export const treatmentTypeExcVAT = [{ value: 'Healness', label: '662.94' }, { value: 'Massage', label: '640' }, { value: 'Rehab', label: '754.72' }]

export var slots = [{ value: '1', label: '15' }, { value: '2', label: '30' }, { value: '3', label: '45' }, { value: '4', label: '60' }, { value: '5', label: '90' }, { value: '6', label: '120' }]

export const getState = (id) => {
  var stateList = csc.getStatesOfCountry(id);
  var states = [];
  stateList.forEach(state => {
    states.push({ label: state.name, value: state.id })
  });
  return states;
}
export const decryptToken = (newStr) => {

  var str = newStr
  str = str.replaceAll("&", "0")
  str = str.replaceAll("^^", "I")
  str = str.replaceAll("#", "M")


  return str
}
export const getFormData = (data) => {
  const formData = new FormData();
  for (const key in data) {
    const val = data[key];
    if (isArray(val)) {
      var treatments = [];
      for (const index in val) {
        const innerVal = val[index];
        treatments.push(innerVal);
        formData.append(`${key}[${index}]`, innerVal);

        //  for (const innerKey in innerVal) {
        //     formData.append(`${key}[${index}].${innerKey}`, innerVal[innerKey]);
        //  }
      }
      // formData.append(key,[treatments]);
      if (isEmpty(val)) {
        formData.append(key, [])
      }

    }
    else if (!isUndefined(val)) {
      formData.append(key, val)
    }
  }
  return formData;
}