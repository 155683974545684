import moment from "moment";
import React, { useState, } from "react";
import { MuiPickersUtilsProvider, KeyboardTimePicker, KeyboardDatePicker } from '@material-ui/pickers';
import MomentUtils from "@date-io/moment";
import Grid from '@material-ui/core/Grid';

import "moment/locale/sv";
import "moment/locale/ru";

moment.locale("sv"); // it is required to select default locale manually

// const localeMap = {
//     en: "en",
//     sv: "sv",
//     ru: "ru",
// };

const DatePicker = props => {
    const [locale] = useState("en");
    // const handleMenuOpen = useCallback(e => {
    //     e.stopPropagation();
    // });

    // const selectLocale = useCallback(locale => {
    //     moment.locale(locale);
    //     setLocale(locale);
    // }, []);

    return (
        <MuiPickersUtilsProvider utils={MomentUtils} locale={locale} >
            <Grid container justify="space-around">
                <KeyboardDatePicker
                    margin="normal"
                    id="date-picker-dialog"
                    label="Date"
                    format="MMM/DD/YYYY"
                    className="col-md-12"
                    value={props.therapistLocationScheduleStartDate}
                    onChange={props.handleStartDateChange}
                    KeyboardButtonProps={{
                        'aria-label': 'change date',
                    }}
                />
                <KeyboardTimePicker
                    margin="normal"
                    id="time-picker"
                    label="Time (From)"
                    className="col-md-12"
                    value={props.therapistLocationScheduleStartDate}
                    onChange={props.handleStartDateChange}
                    KeyboardButtonProps={{
                        'aria-label': 'change time',
                    }}
                />
                <KeyboardTimePicker
                    margin="normal"
                    id="time-picker"
                    label="Time (To)"
                    className="col-md-12"
                    value={props.therapistLocationScheduleEndDate}
                    onChange={props.handleEndDateChange}
                    KeyboardButtonProps={{
                        'aria-label': 'change time',
                    }}
                />
            </Grid>
        </MuiPickersUtilsProvider>
    );
}

export default DatePicker;