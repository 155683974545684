/* eslint eqeqeq: 0 */
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Container, Row, Button } from 'shards-react';
import { getUsers, showUserDetail, deleteUser, newUser, getUserAddress, sendPush, getUsersFiltered } from '../../redux/user.redux';
import Header from '../common/header';
import MaterialTable from '../common/materialTable';
import Alert from '../common/Alert';
import ConfirmationDialog from '../common/ConfirmationDialog';
import NotificationDialog from '../common/NotificationDialog';
import { CSVLink } from "react-csv";

var selectedCustomer = null;
var showErr_ = false;
var pull_ = false;

class User extends Component {
    constructor(props) {
        super(props);
        this.state = {
            t: null,
            showAddress: false,
            showErr: false,
            error: "",
            openConfirmationBox: false,
            deleteItem: {},
            userData: [{ id: 71, full_name: 'Denise Cheng', email: '', mobile_phone: '', country: null, },
            { id: 72, full_name: 'Pehr Anders Åström', email: '', mobile_phone: '', country: null, },
            { id: 73, full_name: 'SARA BERGDAHL', email: '', mobile_phone: '', country: null }
            ],
            showNotificationPopup: false,

        }
    }
    componentDidMount() {
        this.setState({ t: this.props.t })
        this.props.getUsers();
        this.props.getUsersFiltered();
        // setTimeout(() => {
        //     this.props.addUser()
        // }, 3000);
    }
    componentDidUpdate() {
        if (this.props.deleted) {
            this.props.getUsers();
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        console.log('componentWillReceiveProps: ' + JSON.stringify(nextProps.userAdd));
        ////debugger;
        console.log("filteredcustomer before data")
        if (nextProps.userData != undefined && nextProps.userData != null && nextProps.userData != this.props.userData) {
            console.log("filteredcustomer ")
            this.setState({ userData: nextProps.userData })
            console.log(nextProps.userData)
        }
        if (nextProps.userAdd != undefined && nextProps.userAdd !== null && (("firstname" in nextProps.userAdd) || ("error_message" in nextProps.userAdd))) {

            if (nextProps.userAdd.error_message != undefined && nextProps.userAdd.error_message !== null && nextProps.userAdd.error_message !== "") {
                if (pull_) {
                    this.setState({ error: nextProps.userAdd.error_message });
                    this.setState({ showErr: true });
                    showErr_ = true;
                    this.setState({ showAddress: false });
                    pull_ = false;
                }
            }
            else {
                //success
                ////debugger;
                if (this.state.showAddress) {
                    pull_ = false;
                    this.setState({ showAddress: false });
                    console.log("User Address: " + JSON.stringify(nextProps.userAdd));
                    this.props.history.push('/editcustomer', { action: "edit_address", address: nextProps.userAdd });
                }



            }
        }

        // if (nextProps.userAdd === true) {
        //     const { id, role_type } = this.props.user;
        //     var data;
        //     if (role_type === 1 || role_type === 2) {
        //         data = { therapist_id: -1 }
        //     }
        //     else {
        //         data = { therapist_id: id }
        //     }
        //     this.props.getLocations(data);
        // }

    }


    showDetail = (event) => {
        // event.person_number = event.person_number.substring(event.person_number.length, event.person_number.length - 10).replace(/(\d{3})(\d{3})(\d{4})/, "$1$2-$3");
        this.props.showDetail(event);
        // this.props.newUser();
        this.props.history.push('/editcustomer', { action: "edit" });
    }

    showBookings = (event) => {
        // event.person_number = event.person_number.substring(event.person_number.length, event.person_number.length - 10).replace(/(\d{3})(\d{3})(\d{4})/, "$1$2-$3");
        this.props.showDetail(event);
        this.props.history.push('/showbookings', { user_id: event.id });
    }

    pullAddress = (event) => {
        pull_ = true;
        console.log("Pull Address: " + JSON.stringify(event));
        // event.person_number = event.person_number.substring(event.person_number.length, event.person_number.length - 10).replace(/(\d{3})(\d{3})(\d{4})/, "$1$2-$3");
        this.props.showDetail(event);
        // alert(`You just clicked an event: {id: ${event.id}, title: ${event.title}}`);
        var data = {
            "person_number": event.person_number, //"194308092222"
        }
        this.setState({ showAddress: true });

        this.props.getUserAddress(data);
        // this.props.history.push('/showbookings', { user_id: event.id});
    }
    addNewUser = () => {
        this.props.newUser();
        this.props.history.push('/addcustomer', { action: "add" })
    }

    deleteUser = (event) => {
        const { deleteItem } = this.state;
        const data = { customerId: deleteItem.id }
        this.props.deleteUser(data);
        this.setState({ openConfirmationBox: false })
    }

    sendNotification = (event) => {
        console.log("sendNotification: " + this.state.textValue);

        if (this.state.textValue !== "") {
            this.setState({ showNotificationPopup: false })

            var data = {
                "deviceId": selectedCustomer.device_token,
                "title": "Healness",
                "message": this.state.textValue,
                "platform": selectedCustomer.device_type
            }

            ////debugger;
            this.props.sendPush(data);
        }
    }

    showNotificationWindow = (event) => {
        console.log("showNotificationWindow: " + JSON.stringify(event));
        selectedCustomer = event;

        if (event.device_token != "" && event.device_token != undefined && event.device_token != "sDeviceToken" && event.device_token != "DEVICE_TOKEN" && event.device_token != "device_token") {
            this.setState({ showNotificationPopup: true })
        }
        else {
            this.setState({ error: "Device token not available." });
            this.setState({ showErr: true });
            showErr_ = true;
        }
    }

    _renderDialog = (event) => {
        this.setState({ openConfirmationBox: true, deleteItem: event })
    }
    format(value, pattern) {
        var i = 0,
            v = value.toString();
        return pattern.replace(/#/g, _ => v[i++]);
    }

    mask(str, maskChar, unmaskedLength, maskFromStart = true) {
        const maskStart = maskFromStart ? 0 : Math.max(0, unmaskedLength);
        const maskEnd = maskFromStart ? Math.max(0, str.length - unmaskedLength) : str.length;
        return str
            .split("")
            .map((char, index) => {
                if (index >= maskStart && index < maskEnd) {
                    return maskChar;
                }
                else {
                    return char;
                }
            })
            .join("");
    }

    maskStr(cardnumber) {
        var first4 = cardnumber.substring(0, 8);
        var mask = cardnumber.substring(cardnumber.length - 4, 4).replace(/\d/g, "*");
        //first4 + 
        return first4 + mask;
    }

    okTapped() {

    }

    setTextValue = (event) => {
        // console.log("setTextValue: " + JSON.stringify(event))
        this.setState({ textValue: event.target.value });
    }

    render() {
        const { loading, message, deleted } = this.props;
        const { t } = this.state;
        var columns = null;
        console.log("RENDER: " + this.state.showErr + " = " + showErr_);

        //.replace(/(\d{3})(\d{3})(\d{4})/, "$1$2-$3")
        if (t !== null) {
            columns = [
                {
                    title: t.translations.customer_id,
                    field: 'id'
                },
                {
                    title: t.translations.person_number,
                    field: 'person_number',
                    render: rowData => <span>{this.maskStr(rowData.person_number)}</span>
                },
                {
                    title: t.translations.first_name,
                    field: 'first_name'
                },
                {
                    title: t.translations.last_name,
                    field: 'last_name',
                },
                {
                    title: t.translations.email,
                    field: 'email',
                },
                {
                    title: t.translations.device_type,
                    field: 'device_type',
                },
                {
                    title: "Can Get Token",
                    field: 'notifiable',
                },
                {
                    title: "Version", // t.translations.email,
                    field: 'app_version',
                }
            ];
        }

        var temp1 = this.props.users.reverse();
        var result = temp1.map(function (el) {
            var o = Object.assign({}, el);
            if (o.device_token === "device_token" || o.device_token === "sDeviceToken") {
                o.notifiable = false;
            }
            else {
                o.notifiable = true;
            }
            return o;
        })

        // debugger;
        return (
            <Fragment>
                <Container fluid className="personal-container user-container">
                    <Row className="">
                        <Header t={this.props.t} addNew={this.addNewUser} title={t !== null && t.translations.users} addNewBtnTitle={t !== null && t.translations.add_new_user} />
                        {deleted == false && <Alert success={true} message={message} t={this.props.t} loading={loading} />}
                        {showErr_ &&
                            <ConfirmationDialog
                                title={'Error'}
                                bodyText={this.state.error}
                                handleOk={null}
                                handleCancel={() => {
                                    showErr_ = false;
                                    this.setState({ showErr: false });
                                }} />}
                        {this.state.openConfirmationBox &&
                            <ConfirmationDialog
                                title={this.state.t !== null ? this.state.t.translations.delete_confirmation : ''}
                                bodyText={this.state.t !== null && this.state.t.translations.are_you_sure_to_delete}
                                handleCancel={() => this.setState({ openConfirmationBox: false })}
                                handleOk={this.deleteUser} />}
                        {this.state.showNotificationPopup &&
                            <NotificationDialog
                                title={this.state.t !== null ? this.state.t.translations.notification_title : ''}
                                bodyText={this.state.t !== null && this.state.t.translations.notification_label}
                                setTextValue={this.setTextValue}
                                handleCancel={() => this.setState({ showNotificationPopup: false })}
                                handleOk={this.sendNotification} />}
                        {
                            this.state.userData &&
                            <CSVLink

                                data={this.state.userData} filename={"healnesss_customers.csv"}>
                                <Button
                                    style={{ position: 'absolute', right: '5%', height: 50, width: 286, }}
                                    // style={{ height: 60, marginLeft: window.innerWidth / 1.592 }}
                                    className="btn-block float-right save-btn"
                                >
                                    Download Customers
                                </Button>

                            </CSVLink>
                        }
                        <div style={{ width: '100%', marginTop: "3%" }} />
                        {/* <div style={{ width: '100%', marginBottom: "20%" }} /> */}
                        {(columns !== null && t !== null) &&
                            <MaterialTable
                                t={this.props.t}
                                columns={columns}
                                justShow={5}
                                // deleteDetail={this._renderDialog} 
                                data={result}
                                showBookings={this.showBookings}
                                showDetail={this.showDetail}
                                sendNotification={this.showNotificationWindow}
                                pullAddress={this.pullAddress}
                                loading={this.props.loading} />
                        }
                    </Row >
                </Container >
            </Fragment >
        )
    }
}

const mapStateToProps = state => {
    return {
        t: state.accountRedux.localeInfo.t,
        users: state.userRedux.usersList,
        message: state.userRedux.message,
        success: state.userRedux.success,
        loading: state.userRedux.loading,
        deleted: state.userRedux.deleted,
        userAdd: state.userRedux.userAdd,
        push_data: state.userRedux.push_data,
        userData: state.userRedux.userData,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        sendPush: (data) => dispatch(sendPush(data)),
        getUserAddress: (data) => dispatch(getUserAddress(data)),
        getUsers: (data) => dispatch(getUsers(data)),
        showDetail: (data) => dispatch(showUserDetail(data)),
        deleteUser: (data) => dispatch(deleteUser(data)),
        newUser: () => dispatch(newUser()),
        getUsersFiltered: (data) => dispatch(getUsersFiltered(data)),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(User);
