import React from 'react';
import { Container, Row, Form, Button, Col } from 'shards-react';
import { Link } from 'react-router-dom';
import { required } from '../../../../helper/validate';
import { FormField } from '../../../../helper/formFields';
import { reduxForm, Field } from 'redux-form';
import { connect } from 'react-redux';
import CompanyInfo from '../../companyInfo';
import {registerStaff} from '../../../../redux/staff.redux';
//signup
class Signup extends React.Component {

    handleSubmit = (values, dispatch) => {
        const {email,password,repeat_password}=values;
        var data={email,password,repeat_password};
        dispatch(registerStaff(data))
    }

    render = () => {
        return (
            <Container fluid className="login-container">
                <Row className="login-content">
                    <CompanyInfo />
                    <Col className=" col-md-6 login-data login-background">
                        <Form onSubmit={this.props.handleSubmit(this.handleSubmit)}>
                            <Row className="my-2">
                                <Field
                                    placeholder="Email Address"
                                    type="email"
                                    name="email"
                                    classes="login-input"
                                    validate={[required]}
                                    component={FormField}
                                />
                            </Row>
                            <Row className="my-2">
                                <Field
                                    placeholder="Password"
                                    type="password"
                                    name="password"
                                    classes="login-input"
                                    validate={[required]}
                                    component={FormField}
                                />
                            </Row>
                            <Row className="my-2">
                                <Field
                                    placeholder="Repeat Password"
                                    type="password"
                                    name="repeat_password"
                                    classes="login-input"
                                    validate={[required]}
                                    component={FormField}
                                />
                            </Row>
                            <Button className="btn-block max-width btn-login login-input" type="submit">SIGNUP {this.props.loading && <i className="fas fa-circle-notch fa-spin loader"></i>}</Button>
                        </Form>
                        <Link to="/" className="forgot-password mt-5 ml-4">Cancel Signup</Link>
                    </Col>
                </Row>
            </Container >
        );
    }
};

const mapStateToProps = state => {
    return {

    };
};
const mapDispatchToProps = dispatch => {
    return {
    }
};
export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({
    form: 'signupForm',
    destroyOnUnmount: false
})(Signup));
