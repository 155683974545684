/* eslint-disable no-use-before-define */
import { call } from 'redux-saga/effects';
// import {logoutUser} from "../redux/login.redux";


export class CustomError extends Error {
   statusCode;
   data;

   constructor(statusCode = 503, data, ...params) {
      super(...params);
      Error.captureStackTrace(this, CustomError);
      this.statusCode = statusCode;
      this.data = data;
   }
}

function* callServer(apiFunction, reqData, showError = true) {
   const response = yield call(apiFunction, reqData, reqData.id);

   const {
      data: resData = {}, ok = false, problem = 'TIMEOUT_ERROR', status = 500
   } = response || {};
   if (showError && !resData.success && resData.message) {
      notify(resData.message)
   }
   if (resData.statusCode === 401) {
      // yield put(logoutUser.trigger());
   }
   if (ok) {
      return { res: resData, statusCode: status };
   }
   const message = extractMessage(resData, problem);
   throw new CustomError(status, resData, message);
}

export const extractMessage = (resData, problem) => {
   if (resData) {
      if (typeof resData.error === 'object' && resData.error.message) {
         return resData.error.message;
      }
      if (resData.message) {
         return resData.message;
      }
      if (resData.error) {
         return resData.error;
      }
      if (resData) {
         return resData;
      }
      return getMessage(resData);
   }
   return getMessage(problem);
};

const getMessage = (error) => {
   if (error === 'TIMEOUT_ERROR') {
      return 'No Response From Server.';
   }
   if (error === 'CONNECTION_ERROR') {
      return 'Server Is Not Available.';
   }
   if (error === 'NETWORK_ERROR') {
      return 'Network not available.';
   }
   return 'Something went wrong. Please try again';
};

const notify = (message) => {
};


export default {
   callServer
};
