import { createRoutine } from 'redux-saga-routines';
export const getBookings = createRoutine('GET_BOOKING');
export const getRefundedBookings = createRoutine('GET_REFUNDED_BOOKING');
export const editBooking = createRoutine('EDIT_BOOKING');
export const showBookingDetail = createRoutine('SHOW_BOOKING_DETAIL');
export const deleteBooking = createRoutine('DELETE_BOOKING');
export const getBookingPriceInformation = createRoutine('GET_BOOKING_PRICE_INFORMATION');

const INITIAL_STATE = {
    error: false,
    success: false,
    message: '',
    loading: false,
    bookings: [],
    bookingInfo: {},
    refundedBookings: [],
    bookingPriceInfo: {}
};
export default (state = INITIAL_STATE, { type, payload }) => {
    switch (type) {

        case getBookings.TRIGGER:
            return { ...state, loading: true, error: false, success: false };
        case getBookings.SUCCESS:
            return { ...state, loading: false, error: false, success: true, bookings: payload };
        case getBookings.FAILURE:
            return { ...state, loading: false, success: false, error: true, message: payload, bookings: [] };


        case editBooking.TRIGGER:
            return { ...state, loading: true, error: false, success: false };
        case editBooking.SUCCESS:
            return { ...state, loading: false, error: false, success: true, booking: payload };
        case editBooking.FAILURE:
            return { ...state, loading: false, success: false, error: true, message: payload, booking: [] };

        case deleteBooking.TRIGGER:
            return { ...state, loading: true, error: false, success: false, message: '' };
        case deleteBooking.SUCCESS:
            return { ...state, loading: false, error: false, success: true, message: payload };
        case deleteBooking.FAILURE:
            return { ...state, loading: false, success: false, error: true, message: payload };

        case getBookingPriceInformation.TRIGGER:
            return { ...state, loading: true, error: false, success: false, bookingPriceInfo: '' };
        case getBookingPriceInformation.SUCCESS:
            return { ...state, loading: false, error: false, success: true, bookingPriceInfo: payload };
        case getBookingPriceInformation.FAILURE:
            return { ...state, loading: false, success: false, error: true, message: payload };

        case showBookingDetail.TRIGGER:
            return { ...state, bookingInfo: payload };

        default:
            return { ...state }

    }
}
