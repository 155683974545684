import React, { Fragment } from 'react'
import { Container, Alert } from 'shards-react';
import { isEqual } from 'lodash';
export default class alert extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!isEqual(this.props, nextProps)) {
      this.setState({
        success: nextProps.success,
        message: nextProps.message
      })
      if (nextProps.success) {
        setTimeout(() => {
          this.setState({
            success: false,
            message: ''
          })
        }, 5000);
      }
    }
    else {
      this.setState({
        success: this.props.success,
        message: this.props.message
      })
      if (this.props.success) {
        setTimeout(() => {
          this.setState({
            success: false,
            message: ''
          })
        }, 5000);
      }
    }
  }
  modalClose = () => {
    this.setState({
      success: false,
      message: ''
    })
  }
  render() {
    const { success, message } = this.state;
    const { loading } = this.props;


    return (
      <Fragment>
        {(message && loading === false) ? <Container fluid className="px-0 pb-2">
          <Alert className={`mb-0 ${success ? 'alert-success' : 'alert-danger'}`}>
            {this.props.t.translations[message] ? this.props.t.translations[message] : message}
            {/* <i className={`fa fa-times mx-2 close-icon`} onClick={this.modalClose}></i> */}
          </Alert>
        </Container> : ''}
      </Fragment>
    )
  }
}
