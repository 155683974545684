import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Container, Row, Col } from 'shards-react';
import { getReceipts } from '../../redux/payment.redux';
import { sendReceipt } from '../../redux/user.redux';

import MaterialTable from '../common/materialTable';
import * as moment from 'moment';
import ViewDialog from '../common/ViewDialog';

import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';


class Receipts extends Component {
    constructor(props) {
        super(props);
        this.state = {
            t: null,
            filterState: "all",
            showDialogue: false,
            selected: "",
            refund: false,
        }
    }
    componentDidMount() {
        this.setState({ t: this.props.t });
        this.props.getReceipts();
    }
    componentDidUpdate(prevProps) {

    }
    showDetail = (event) => {
        var html = event.content;

        console.log("showDetail1: " + JSON.stringify(event));

        debugger;
        if (html !== "" && html != null) {
            console.log("showDetail: " + html);

            this.setState({ refund: false });
            this.setState({ showDialogue: true });
            this.setState({ selected: event });

            console.log("selected: " + this.state.selected);
        }
        else {
            alert("Content not generated yet.");
        }


        // var uri = "data:text/html," + encodeURIComponent(html);
        // window.open(uri,"_blank");
        // window.open("https://www,google.com.pk", "_blank");
    }

    showRefund = (event) => {

        var html = event.content;

        if (html === "" || html === null) {
            if (event.refund) {

                this.setState({ refund: true });
                this.setState({ showDialogue: true });
                this.setState({ selected: event });


            }
            else {
                this.setState({ refund: false });
                alert("Not refunded yet.");
            }
        }
        else {
            alert("Content not generated yet.");
        }


        // var uri = "data:text/html," + encodeURIComponent(html);
        // window.open(uri,"_blank");
        // window.open("https://www,google.com.pk", "_blank");
    }

    segmentControlTapped(val) {
        console.log('segmentControlTapped: ' + val);
        this.setState({ filterState: val });
    }

    generateColumns(t, state_) {
        var columns = null;
        if (t !== null) {
            columns = [
                {
                    title: t.translations.booking_id,
                    field: 'booking_id',
                },
                {
                    title: t.translations.payment_method,
                    field: 'payment_method',
                },
                {
                    title: t.translations.customer_name,
                    field: 'customer_name',
                },
                {
                    title: t.translations.receipt_num, //t.translations.status,
                    field: 'receipt_num',
                },
                {
                    title: t.translations.transaction_id,
                    field: 'transaction_id',
                },
                // {
                //     title: t.translations.refund_1,
                //     field: 'refund',
                //     render: rowData => <span>{rowData.refund.toString()}</span>
                // },
                {
                    title: t.translations.email_receipt_total,
                    field: 'total_payable',
                    render: rowData => <span>{rowData.total_payable.toFixed(2) + " SEK"}</span>
                }

            ];
        }

        return columns;
    }

    filterBookings(val) {
        const { payments } = this.props;

        if (payments.length > 0) {
            if (val === "start") {
                let resultant = payments.filter(item => {
                    // return item.status === 'START';
                    return item.status === 'START' && item.type === "CAPTURE";
                })


                // date and time sorting
                resultant.sort((a, b) => {
                    var c = moment(new Date(0).setUTCSeconds(a.date)).format('YYYY-MM-DD');
                    var d = moment(new Date(0).setUTCSeconds(b.date)).format('YYYY-MM-DD');

                    var cT = moment(new Date(0).setUTCSeconds(a.date)).format('HH:MM');
                    var dT = moment(new Date(0).setUTCSeconds(b.date)).format('HH:MM');

                    return c.localeCompare(d) || cT.localeCompare(dT);
                });

                //reverse arr
                // let rev = resultant.reverse();
                return resultant;
            }
            else if (val === "query") {
                let resultant = payments.filter(item => {
                    // return item.status === 'START';
                    return item.type === "QUERY";
                })


                // date and time sorting
                resultant.sort((a, b) => {
                    var c = moment(new Date(0).setUTCSeconds(a.date)).format('YYYY-MM-DD');
                    var d = moment(new Date(0).setUTCSeconds(b.date)).format('YYYY-MM-DD');

                    var cT = moment(new Date(0).setUTCSeconds(a.date)).format('HH:MM');
                    var dT = moment(new Date(0).setUTCSeconds(b.date)).format('HH:MM');

                    return c.localeCompare(d) || cT.localeCompare(dT);
                });

                //reverse arr
                // let rev = resultant.reverse();
                return resultant;
            }
            else if (val === "failed") {
                let resultant = payments.filter(item => {
                    return item.status === 'FAILED' && item.type === "CAPTURE";
                })


                // date and time sorting
                resultant.sort((a, b) => {
                    var c = moment(new Date(0).setUTCSeconds(a.date)).format('YYYY-MM-DD');
                    var d = moment(new Date(0).setUTCSeconds(b.date)).format('YYYY-MM-DD');

                    var cT = moment(new Date(0).setUTCSeconds(a.date)).format('HH:MM');
                    var dT = moment(new Date(0).setUTCSeconds(b.date)).format('HH:MM');

                    return c.localeCompare(d) || cT.localeCompare(dT);
                });

                //reverse arr
                // let rev = resultant.reverse();

                return resultant;
            }
            else if (val === "success") {
                let resultant = payments.filter(item => {
                    return item.status === 'SUCCESS' && item.type === "CAPTURE";
                })


                // date and time sorting
                resultant.sort((a, b) => {
                    var c = moment(new Date(0).setUTCSeconds(a.date)).format('YYYY-MM-DD');
                    var d = moment(new Date(0).setUTCSeconds(b.date)).format('YYYY-MM-DD');

                    var cT = moment(new Date(0).setUTCSeconds(a.date)).format('HH:MM');
                    var dT = moment(new Date(0).setUTCSeconds(b.date)).format('HH:MM');

                    return c.localeCompare(d) || cT.localeCompare(dT);
                });

                //reverse arr
                // let rev = resultant.reverse();

                return resultant;
            }
            else if (val === "refund") {
                let resultant = payments.filter(item => {
                    return item.status === 'SUCCESS' && item.type === "REFUND";
                })


                // date and time sorting
                resultant.sort((a, b) => {
                    var c = moment(new Date(0).setUTCSeconds(a.date)).format('YYYY-MM-DD');
                    var d = moment(new Date(0).setUTCSeconds(b.date)).format('YYYY-MM-DD');

                    var cT = moment(new Date(0).setUTCSeconds(a.date)).format('HH:MM');
                    var dT = moment(new Date(0).setUTCSeconds(b.date)).format('HH:MM');

                    return c.localeCompare(d) || cT.localeCompare(dT);
                });

                //reverse arr
                // let rev = resultant.reverse();

                return resultant;
            }
            else if (val === "ignored") {
                let resultant = payments.filter(item => {
                    return item.status === 'IGNORED' && item.type === "CAPTURE";
                });

                // date and time sorting
                resultant.sort((a, b) => {
                    var c = moment(new Date(0).setUTCSeconds(a.date)).format('YYYY-MM-DD');
                    var d = moment(new Date(0).setUTCSeconds(b.date)).format('YYYY-MM-DD');

                    var cT = moment(new Date(0).setUTCSeconds(a.date)).format('HH:MM');
                    var dT = moment(new Date(0).setUTCSeconds(b.date)).format('HH:MM');

                    return c.localeCompare(d) || cT.localeCompare(dT);
                });

                //reverse arr
                // let rev = resultant.reverse();

                return resultant;
            }
            else if (val === "old") {
                let resultant = payments.filter(item => {
                    return item.status === '' && (item.type === "CAPTURE" || item.type === "DELETED");
                });

                // date and time sorting
                // resultant.sort((a, b) => {
                //     var c = moment(new Date(0).setUTCSeconds(a.date)).format('YYYY-MM-DD');
                //     var d = moment(new Date(0).setUTCSeconds(b.date)).format('YYYY-MM-DD');

                //     var cT = moment(new Date(0).setUTCSeconds(a.date)).format('HH:MM');
                //     var dT = moment(new Date(0).setUTCSeconds(b.date)).format('HH:MM');

                //     return c.localeCompare(d) || cT.localeCompare(dT);
                // });

                //reverse arr
                // let rev = resultant.reverse();

                return resultant;
            }
            else if (val === "cancel") {
                let resultant = payments.filter(item => {
                    return item.type === "CANCEL_BOOKING_3";
                });

                // date and time sorting
                resultant.sort((a, b) => {
                    var c = moment(new Date(0).setUTCSeconds(a.date)).format('YYYY-MM-DD');
                    var d = moment(new Date(0).setUTCSeconds(b.date)).format('YYYY-MM-DD');

                    var cT = moment(new Date(0).setUTCSeconds(a.date)).format('HH:MM');
                    var dT = moment(new Date(0).setUTCSeconds(b.date)).format('HH:MM');

                    return c.localeCompare(d) || cT.localeCompare(dT);
                });

                //reverse arr
                let rev = resultant.reverse();

                return rev;
            }
            else if (val === "change_date") {
                let resultant = payments.filter(item => {
                    return item.status === 'DONE' && item.type === "CHANGE_DATE";
                });

                // date and time sorting
                resultant.sort((a, b) => {
                    var c = moment(new Date(0).setUTCSeconds(a.date)).format('YYYY-MM-DD');
                    var d = moment(new Date(0).setUTCSeconds(b.date)).format('YYYY-MM-DD');

                    var cT = moment(new Date(0).setUTCSeconds(a.date)).format('HH:MM');
                    var dT = moment(new Date(0).setUTCSeconds(b.date)).format('HH:MM');

                    return c.localeCompare(d) || cT.localeCompare(dT);
                });

                //reverse arr
                // let rev = resultant.reverse();

                return resultant;
            }
            else if (val === "change_therapist") {
                let resultant = payments.filter(item => {
                    return item.status === 'DONE' && item.type === "CHANGE_THERAPIST";
                });

                // date and time sorting
                resultant.sort((a, b) => {
                    var c = moment(new Date(0).setUTCSeconds(a.date)).format('YYYY-MM-DD');
                    var d = moment(new Date(0).setUTCSeconds(b.date)).format('YYYY-MM-DD');

                    var cT = moment(new Date(0).setUTCSeconds(a.date)).format('HH:MM');
                    var dT = moment(new Date(0).setUTCSeconds(b.date)).format('HH:MM');

                    return c.localeCompare(d) || cT.localeCompare(dT);
                });

                //reverse arr
                // let rev = resultant.reverse();

                return resultant;
            }
        }
        return payments;
    }

    sendReceipt = () => {
        const { selected } = this.state;

        const data = {
            "booking_id": selected.booking_id,
            "email": selected.customer_email
        }
        this.props.sendReceipt(data);
        this.setState({ showDialogue: false });
    }

    _exportPdf = () => {

        html2canvas(document.querySelector("#capture"), {
            scrollY: -window.scrollY
        }).then(canvas => {
            document.body.appendChild(canvas);  // if you want see your screenshot in body.
            //    var img = canvas.toDataURL();
            //    window.open(img);
            const imgData = canvas.toDataURL('image/png');
            const pdf = new jsPDF();
            pdf.addImage(imgData, 'PNG', 0, 0);
            pdf.save(this.state.selected.booking_id + "_.pdf");
        });

    }

    render() {
        const { loading, } = this.props;
        const { selected, refund } = this.state;
        //////debugger;
        // const { t } = this.state;
        const { t } = this.props;
        var columns = this.generateColumns(t, this.state.filterState);

        var filtered_ = this.filterBookings(this.state.filterState);
        filtered_.sort(function (a, b) {
            return b.id - a.id;
        });
        console.log("REFUND: " + (refund ? "refund_content" : "content"));

        return (
            <Fragment>
                <Container fluid className="personal-container user-container">
                    {this.state.showDialogue &&
                        <ViewDialog
                            title={this.state.t != null ? (refund ? this.state.t.translations.email_refund_title : this.state.t.translations.email_receipt_title) : ''}
                            bookingCancelBtn={true}
                            bodyText={
                                <Fragment>
                                    <div id="capture" dangerouslySetInnerHTML={{ __html: (refund ? selected.refund_content : selected.content) }} />
                                </Fragment>
                            }
                            customer_email={selected.customer_email}
                            handleReceipt={this.sendReceipt}
                            handleDownload={this._exportPdf}
                            handleCancel={() => this.setState({ showDialogue: false })}
                            handleOk={null} />}
                    <Row>
                        <Col className="right-container col-md-12">
                            <h2 className="pb-5 col-md-8 float-left title-header">
                                {this.props.t.translations.receipts}
                            </h2>
                        </Col>
                    </Row>
                    <Row className="">
                        {(columns !== null && t != null) &&
                            <MaterialTable t={this.props.t}
                                justShow={9}
                                columns={columns}
                                data={filtered_}
                                showPayment={true}
                                hideDelete={true}
                                showRefund={this.showRefund}
                                showDetail={this.showDetail}
                                loading={loading} />}

                        {/* <Col className="col-md-12 mt-5 data-table-container">
                            <h3 className="ml-5 under-construction">Under Construction</h3>
                        </Col> */}
                    </Row>
                </Container >
            </Fragment>
        )
    }
}

const mapStateToProps = state => {
    return {
        t: state.accountRedux.localeInfo.t,
        loading: state.paymentRedux.loading,
        success: state.paymentRedux.success,
        message: state.paymentRedux.message,
        payments: state.paymentRedux.receipts,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getReceipts: (data) => dispatch(getReceipts(data)),
        sendReceipt: (data) => dispatch(sendReceipt(data)),

    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Receipts);
