/* eslint eqeqeq: 0 */
import React from 'react';
import { Container, Row, Form, Button, Col } from 'shards-react';
import { required, validEmail, validPhone } from '../../helper/validate';
import { FormField } from '../../helper/formFields';
import { reduxForm, Field } from 'redux-form';
import { connect } from 'react-redux';
import { updateContact } from '../../redux/staff.redux'
import { loginAdmin } from '../../redux/account.redux'
import Alert from '../common/Alert';
var global_data = [];

class Contact extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            t: null,
            mobile_phone: "",
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props !== prevProps) {
            this.setState({ t: this.props.t });

            console.log("componentDidUpdate_contact: " + this.props.success + " = " + prevProps.success);
            if (this.props.success != prevProps.success) {
                console.log("Global: " + JSON.stringify(global_data));
                // debugger
                // REDUX ISSUE WHILE UPDATING LOCAL CACHE - CONTACT /SANA
                console.log("BEFORE: " + JSON.stringify(this.props));
                // yield put(loginAdmin.success(global_data));
                // dispatchEvent(loginAdmin('SUCCESS', global_data));
                // debugger
                // this.props.dispatch(loginAdmin(global_data));
                // this.props.loginAdmin(this.props.user.person_number);

                console.log("AFTER: " + JSON.stringify(this.props.user));
            }
        }
    }
    componentDidMount() {
        var user = this.props.user;
        this.setState({ t: this.props.t });
        this.setState({ mobile_phone: this.props.user.mobile_phone });


        // user["street_address"] = "ABC";

        // const data = JSON.parse(this.props.data);

        // console.log("user data" + JSON.stringify(this.props.data))
        // debugger
        if (this.props.data)
            user = this.props.data
        this.props.initialize({
            ...user,
        });
        //////debugger;

    }

    handleSubmit = (values, dispatch) => {
        const { zip, street_address, email, city } = values;
        const { user } = this.props;
        const { id, } = user;
        var mobile_phone = this.state.mobile_phone;
        //////debugger;

        var data = {
            "id": id,
            "street_address": street_address,
            "zip": zip,
            "city": city,
            "email": email,
            "mobile_phone": mobile_phone
        };

        global_data = data;
        console.log("UpdateContact: " + data);
        dispatch(updateContact(data));

    }

    render = () => {
        const { t } = this.state;
        // console.log("user data" + JSON.stringify(this.props.data))
        return (
            <Container fluid className="personal-container user-container">
                <Col className="right-container col-md-12">
                    <h2 className="pb-5 col-md-8 float-left title-header">
                        {t != null && t.translations.contact}
                    </h2>
                </Col>
                {/* <Header t={this.props.t} title={t != null && t.translations.contact} /> */}
                <Col className=" ml-3 mt-5  col-md-6 data-table-container">
                    <Form onSubmit={this.props.handleSubmit(this.handleSubmit)}>
                        <Alert success={this.props.success} message={this.props.message} t={this.props.t} />
                        <Row className="my-2">
                            <Field
                                label={t != null ? t.translations.street_address : ''}
                                type="text"
                                name="street_address"
                                classes="Field-input"
                                validate={[required]}
                                component={FormField}
                                t={this.props.t}
                            />
                        </Row>
                        <Row className="">
                            <Field
                                label={t != null ? t.translations.zip : ''}
                                type="text"
                                name="zip"
                                classes="Field-input"
                                validate={[required]}
                                component={FormField}
                                t={this.props.t}
                            />
                        </Row>
                        <Row className="">
                            <Field
                                label={t != null ? t.translations.city : ''}
                                type="text"
                                name="city"
                                classes="Field-input"
                                validate={[required]}
                                component={FormField}
                                t={this.props.t}
                            />
                        </Row>
                        <Row className="">
                            <Field
                                label={t != null ? t.translations.email : ''}
                                type="email"
                                name="email"
                                classes="Field-input"
                                validate={[required, validEmail]}
                                component={FormField}
                                t={this.props.t}
                            />
                        </Row>
                        <Row className="">
                            {/* <div className="col-md-12 col-xs-12 float-left react-phone-number">
                                <label>{t != null ? t.translations.mobile : ''}</label>
                                <PhoneInput
                                    placeholder={t != null ? t.translations.mobile : ''}
                                    value={!isEmpty(this.props.therapistInfo) ? this.props.therapistInfo.mobile_phone : ''}
                                    className="Field-input"
                                    length={12}
                                    onChange={mobile_phone => this.setState({ mobile_phone })} />
                            </div>                             */}
                            <Field
                                label={t != null ? t.translations.mobile : ''}
                                type="mobile_phone"
                                name="mobile_phone"
                                classes="Field-input"
                                validate={[required, validPhone]}
                                component={FormField}
                                t={this.props.t}
                                defaultValue={this.state.mobile_phone}
                                onChange={mobile_phone => this.setState({ mobile_phone })}
                                placeholder={t != null ? t.translations.mobile : ''}
                            />
                        </Row>
                        <Button className="btn-block float-right save-btn Field-input" type="submit">{t != null && t.translations.save_changes} {this.props.loading && <i className="fas fa-circle-notch fa-spin loader"></i>}</Button>
                    </Form>
                </Col>
            </Container >
        );
    }
};

const mapStateToProps = state => {
    return {
        user: state.accountRedux.user,
        message: state.staffRedux.message,
        data: state.staffRedux.data,
        error: state.staffRedux.error,
        loading: state.staffRedux.loading,
        success: state.staffRedux.success,
        t: state.accountRedux.localeInfo.t
    };
};
const mapDispatchToProps = dispatch => {
    return {
        loginAdmin: (data) => dispatch(loginAdmin(data)),
    }
};
export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({
    form: 'ContactForm',
    destroyOnUnmount: false
})(Contact));
