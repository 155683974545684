import React from 'react';
import { Container, Row, Form, Col } from 'shards-react';
import { required } from '../../../../helper/validate';
import { FormField } from '../../../../helper/formFields';
import { reduxForm, Field } from 'redux-form';
import { connect } from 'react-redux';
import CompanyInfo from '../../companyInfo';
import { loginAdmin, secretLogin } from '../../../../redux/account.redux';
import Alert from '../../../common/Alert';
import { isEmpty } from 'lodash';
import { Redirect } from "react-router-dom";
import { APP_VERSION, } from '../../../../constants/urls';



class SecretLogin extends React.Component {
    personNumber = '';
    callcollect = false;
    constructor(props) {
        super(props);
        console.log("SecretLogin - constructor");

        this.state = {
            personalNumber: "",
            orderRef: "",
            startCollect: false,
            baseurlhandled: false,
            showErrorMesage: '',
            showError: false,
            validateBankid: false,
            isBankIdError: false,
            bankIdErrorMessage: ''
        }
    }


    saveState() {
        ////////debugger;
        console.log('saveState');
    }

    componentDidMount() {
        window.addEventListener('onbeforeunload', this.saveState)
        // this.handleSubmit({ person_number: 199604046764 })
        // this.handleSubmit({ person_number: 198908081816 })
    }

    componentWillUnmount() {
        window.removeEventListener('beforeunload', this.saveState)
    }

    handleSubmit = (values, dispatch) => {
        const data = {
            name: values.user_name,
            password: values.password
        }
        this.props.secretLogin(data);
        return;


    }
    render = () => {

        var errorMessage = ''

        if (!isEmpty(this.props.message)) {
            errorMessage = this.props.message;
        }

        return (
            <Container fluid className="login-container login-error-message-container">
                {!isEmpty(this.props.secretUser) && <Redirect to={'/'} />}
                <Row className="login-content">
                    <CompanyInfo props={this.props} />
                    <Col className=" col-md-6 login-data login-background" >
                        <Form onSubmit={this.props.handleSubmit(this.handleSubmit)}>
                            {!isEmpty(this.props.bankIdCollectErrorResponse) && <Alert success={this.props.success} message={errorMessage} t={this.props.t} />}
                            {!this.props.success && <Alert success={this.props.success} loading={this.props.loading} message={this.props.message} t={this.props.t} />}
                            <Row className="my-2">
                                <Field
                                    placeholder="Username"
                                    type="text"
                                    name="user_name"
                                    classes="login-input"
                                    validate={[required]} // validPersonNumber
                                    component={FormField}
                                    t={this.props.t}
                                />
                            </Row>
                            <Row className="my-2">
                                <Field
                                    placeholder="Password"
                                    type="password"
                                    name="password"
                                    classes="login-input"
                                    validate={[required]} // validPersonNumber
                                    component={FormField}
                                    t={this.props.t}
                                />
                            </Row>
                            {this.state.showError && <p className="person-number-invalid">{this.props.t.translations[this.state.showErrorMesage]}</p>}

                            <button class="btn-login2">
                                Logga in
                                {this.props.loading && <i className="fas fa-circle-notch fa-spin loader"></i>}
                            </button>
                            {/* <Button className="btn-block btn-login1 max-width login-input" type="submit">{this.props.t.translations.login_btn_app} {this.props.loading && <i className="fas fa-circle-notch fa-spin loader"></i>}</Button> */}


                            <p className="text-center mt-3" style={{ 'color': 'white' }}>{APP_VERSION}</p>
                        </Form>
                    </Col>
                </Row>
            </Container >
        );
    }
};

const mapStateToProps = state => {
    return {
        message: state.accountRedux.message,
        success: state.accountRedux.success,
        error: state.accountRedux.error,
        user: state.accountRedux.user,
        secretUser: state.accountRedux.secretUser,
        loading: state.accountRedux.loading,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        loginAdmin: (data) => dispatch(loginAdmin(data)),
        secretLogin: (data) => dispatch(secretLogin(data)),
    }
};
export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({
    form: 'SecretLoginForm',
    destroyOnUnmount: false
})(SecretLogin));