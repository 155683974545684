import { call, put, takeLatest } from "redux-saga/effects";
import Api from "../services/ApiCaller";
import {
   getAllPayments, getAllLogs, getSystemLogs, getReceipts
} from "../redux/payment.redux";

function* GetAllPaymentsRequest(api, { payload = {} }) {
   try {
      const { res = {} } = yield call(Api.callServer, api, payload);
      const { message, success, data } = res;
      if (success) {
         yield put(getAllPayments.success(data));
      } else {
         yield put(getAllPayments.failure(message));
      }
   } catch (e) {
      yield put(getAllPayments.failure(e.result));
   }
}

function* GetAllLogsRequest(api, { payload = {} }) {
   //////debugger;
   try {
      const { res = {} } = yield call(Api.callServer, api, payload);
      const { message, success, data } = res;
      if (success) {
         yield put(getAllLogs.success(data));
      } else {
         yield put(getAllLogs.failure(message));
      }
   } catch (e) {
      yield put(getAllLogs.failure(e.result));
   }
}

function* GetSystemLogsRequest(api, { payload = {} }) {
   //////debugger;
   try {
      const { res = {} } = yield call(Api.callServer, api, payload);
      const { message, success, data } = res;
      if (success) {
         yield put(getSystemLogs.success(data));
      } else {
         yield put(getSystemLogs.failure(message));
      }
   } catch (e) {
      yield put(getSystemLogs.failure(e.result));
   }
}

function* GetReceiptsRequest(api, { payload = {} }) {
   //////debugger;
   try {
      const { res = {} } = yield call(Api.callServer, api, payload);
      const { message, success, data } = res;
      if (success) {
         yield put(getReceipts.success(data));
      } else {
         yield put(getReceipts.failure(message));
      }
   } catch (e) {
      yield put(getReceipts.failure(e.result));
   }
}

export default (api) => {
   const getAllPaymentsApi = (data) => api.get("v1/GetAllPayments", data)
   const getAllLogsApi = (data) => api.get("v1/ListCronLogs", data)
   const getSystemLogsApi = (data) => api.get("v1/ListTransactionLogs", data)
   const getReceiptsApi = (data) => api.get("v1/GetReceipts", data)



   return [
      takeLatest(getAllPayments.TRIGGER, GetAllPaymentsRequest, getAllPaymentsApi),
      takeLatest(getAllLogs.TRIGGER, GetAllLogsRequest, getAllLogsApi),
      takeLatest(getSystemLogs.TRIGGER, GetSystemLogsRequest, getSystemLogsApi),
      takeLatest(getReceipts.TRIGGER, GetReceiptsRequest, getReceiptsApi),

   ];
};