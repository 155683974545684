import { call, put, takeLatest } from "redux-saga/effects";
import Api from "../services/ApiCaller";
import {
   addTherapist, getTherapists, updateTherapist, statsTherapist, deleteTherapist, getTherapistsByTreatmentId,
   getLocationsByTherapistId, getSchedule, getAllSchedule, getTreatmentsByTherapistId, addTherapistScheduleSlots,
   getTherapistScheduleSlots, deleteTherapistScheduleSlots, checkScheduleSlots
} from "../redux/therapist.redux";
import { getFormData } from '../constants';

function* addTherapistRequest(api, { payload = {} }) {
   try {
      const formData = getFormData(payload);

      ////////debugger;
      const { res = {} } = yield call(Api.callServer, api, formData);
      const { message, success } = res;
      if (success) {
         yield put(addTherapist.success(message));
      } else {
         yield put(addTherapist.failure(message));
      }
   } catch (e) {
      yield put(addTherapist.failure(e.result));
   }
}
function* updateTherapistRequest(api, { payload = {} }) {
   try {
      const formData = getFormData(payload);
      const { res = {} } = yield call(Api.callServer, api, formData);
      // const {res={}}= yield call(Api.callServer, api, payload);
      const { message, success } = res;
      if (success) {
         yield put(updateTherapist.success(message));
      } else {
         yield put(updateTherapist.failure(message));
      }
   } catch (e) {
      yield put(updateTherapist.failure(e.result));
   }
}
function* statsTherapistRequest(api, { payload = {} }) {
   try {
      // const formData = getFormData(payload);
      // const {res={}}= yield call(Api.callServer, api, formData);
      const { res = {} } = yield call(Api.callServer, api, payload);
      const { message, success, data } = res;
      if (success) {
         yield put(statsTherapist.success(data));
      } else {
         yield put(statsTherapist.failure(message));
      }
   } catch (e) {
      yield put(statsTherapist.failure(e.result));
   }
}
function* getTherapistsRequest(api, { payload = {} }) {
   try {
      const { res = {} } = yield call(Api.callServer, api, payload);
      const { message, data, success } = res;
      if (success) {
         yield put(getTherapists.success(data));
      } else {
         yield put(getTherapists.failure(message));
      }
   } catch (e) {
      yield put(getTherapists.failure(e.result));
   }
}
function* getLocationsByTherapistIdRequest(api, { payload = {} }) {
   try {
      const { res = {} } = yield call(Api.callServer, api, payload);
      const { message, data, success } = res;
      if (success) {
         yield put(getLocationsByTherapistId.success(data));
      } else {
         yield put(getLocationsByTherapistId.failure(message));
      }
   } catch (e) {
      yield put(getLocationsByTherapistId.failure(e.result));
   }
}
function* getTherapistsByTreatmentIdRequest(api, { payload = {} }) {
   try {
      const { res = {} } = yield call(Api.callServer, api, payload);
      const { message, data, success } = res;
      if (success) {
         yield put(getTherapistsByTreatmentId.success(data));
      } else {
         yield put(getTherapistsByTreatmentId.failure(message));
      }
   } catch (e) {
      yield put(getTherapistsByTreatmentId.failure(e.result));
   }
}
function* deleteTherapistRequest(api, { payload = {} }) {
   try {
      const { res = {} } = yield call(Api.callServer, api, payload);
      const { message, success } = res;
      if (success) {
         yield put(deleteTherapist.success(message));
      } else {
         yield put(deleteTherapist.failure(message));
      }
   } catch (e) {
      yield put(deleteTherapist.failure(e.result));
   }
}
function* getScheduleRequest(api, { payload = {} }) {
   try {
      const { res = {} } = yield call(Api.callServer, api, payload);
      const { message, data, success } = res;
      if (success) {
         yield put(getSchedule.success(data));
      } else {
         yield put(getSchedule.failure(message));
      }
   } catch (e) {
      yield put(getSchedule.failure(e.result));
   }
}
function* getAllScheduleRequest(api, { payload = {} }) {
   try {
      const { res = {} } = yield call(Api.callServer, api, payload);
      const { message, data, success } = res;
      if (success) {
         yield put(getAllSchedule.success(data));
      } else {
         yield put(getAllSchedule.failure(message));
      }
   } catch (e) {
      yield put(getAllSchedule.failure(e.result));
   }
}
function* getTreatmentsByTherapistIdRequest(api, { payload = {} }) {
   try {
      const { res = {} } = yield call(Api.callServer, api, payload);
      const { message, data, success } = res;
      if (success) {
         yield put(getTreatmentsByTherapistId.success(data));
      } else {
         yield put(getTreatmentsByTherapistId.failure(message));
      }
   } catch (e) {
      yield put(getTreatmentsByTherapistId.failure(e.result));
   }
}
function* addTherapistScheduleSlotsRequest(api, { payload = {} }) {
   try {
      console.log('addTherapistScheduleSlotsRequest: URL' + JSON.stringify(api));
      console.log('addTherapistScheduleSlotsRequest: PARAMS' + JSON.stringify(payload));


      const { res = {} } = yield call(Api.callServer, api, payload);
      const { message, success } = res;
      console.log('addTherapistScheduleSlotsRequest: Response' + JSON.stringify(res));

      if (success) {
         yield put(addTherapistScheduleSlots.success(res));
      }
      else {
         yield put(addTherapistScheduleSlots.failure(message));
      }
   } catch (e) {
      yield put(addTherapistScheduleSlots.failure(e.result));
   }
}
function* deleteTherapistScheduleSlotsRequest(api, { payload = {} }) {
   try {
      console.log('deleteTherapistScheduleSlotsRequest: URL' + api);
      console.log('deleteTherapistScheduleSlotsRequest: Params' + JSON.stringify(payload));

      const { res = {} } = yield call(Api.callServer, api, payload);
      const { message, success } = res;
      console.log('deleteTherapistScheduleSlotsRequest: Response' + JSON.stringify(res));

      if (success) {
         yield put(deleteTherapistScheduleSlots.success(res));
      }
      else {
         yield put(deleteTherapistScheduleSlots.failure(message));
      }
   } catch (e) {
      yield put(deleteTherapistScheduleSlots.failure(e.result));
   }
}
function* getTherapistScheduleSlotsRequest(api, { payload = {} }) {
   try {
      const { res = {} } = yield call(Api.callServer, api, payload);
      const { message, data, success } = res;
      if (success) {
         yield put(getTherapistScheduleSlots.success(data));
      } else {
         yield put(getTherapistScheduleSlots.failure(message));
      }
   } catch (e) {
      yield put(getTherapistScheduleSlots.failure(e.result));
   }
}
function* checkScheduleSlotsRequest(api, { payload = {} }) {
   try {
      const { res = {} } = yield call(Api.callServer, api, payload);
      const { message, success } = res;
      if (success) {
         yield put(checkScheduleSlots.success(res));
      }
      else {
         yield put(checkScheduleSlots.failure(message));
      }
   } catch (e) {
      yield put(checkScheduleSlots.failure(e.result));
   }
}

export default (api) => {
   const addTherapistApi = (data) => api.post("/registerTherapist", data); // garbage
   const updateTherapistApi = (data) => api.put("/UpdateTherapist", data); // garbage
   const statsTherapistApi = (data) => api.get("v1/getTherapistsStats", data);


   const deleteTherapistApi = (data) => api.delete("v1/deleteTherapist", data);
   const getTherapistsApi = (data) => api.get("/getAllTherapistForAdmin", data);
   const getTherapistsByTreatmentIdApi = (data) => api.get("v1/getAllTherapistsByTreamentId", data);
   const getLocationsByTherapistIdApi = (data) => api.get("v1/getAllLocationsByTherapistId", data);
   const getScheduleApi = (data) => api.get("v1/getTherapistSchedule", data);
   const getTreatmentByTherapistApi = (data) => api.get("v1/getAllTreatmentsByTherapistId", data);
   const addTherapistScheduleSlotsApi = (data) => api.post("v2/addTherapistSchedule", data);
   const getTherapistScheduleSlotsApi = (data) => api.get("v2/getTherapistScheduleForAdmin", data);
   const deleteTherapistScheduleSlotsApi = (data) => api.delete("v2/deleteTherapistSchedule", data);
   const getAllScheduleApi = (data) => api.get("v1/GetAllScheduledSlots", data);
   const checkScheduleSlotsApi = (data) => api.delete("v2/checkTherapistSchedule", data);



   return [
      takeLatest(addTherapist.TRIGGER, addTherapistRequest, addTherapistApi),
      takeLatest(updateTherapist.TRIGGER, updateTherapistRequest, updateTherapistApi),
      takeLatest(statsTherapist.TRIGGER, statsTherapistRequest, statsTherapistApi),
      takeLatest(deleteTherapist.TRIGGER, deleteTherapistRequest, deleteTherapistApi),
      takeLatest(getTherapists.TRIGGER, getTherapistsRequest, getTherapistsApi),
      takeLatest(getTherapistsByTreatmentId.TRIGGER, getTherapistsByTreatmentIdRequest, getTherapistsByTreatmentIdApi),
      takeLatest(getLocationsByTherapistId.TRIGGER, getLocationsByTherapistIdRequest, getLocationsByTherapistIdApi),
      takeLatest(getSchedule.TRIGGER, getScheduleRequest, getScheduleApi),
      takeLatest(getTreatmentsByTherapistId.TRIGGER, getTreatmentsByTherapistIdRequest, getTreatmentByTherapistApi),
      takeLatest(addTherapistScheduleSlots.TRIGGER, addTherapistScheduleSlotsRequest, addTherapistScheduleSlotsApi),
      takeLatest(getTherapistScheduleSlots.TRIGGER, getTherapistScheduleSlotsRequest, getTherapistScheduleSlotsApi),
      takeLatest(deleteTherapistScheduleSlots.TRIGGER, deleteTherapistScheduleSlotsRequest, deleteTherapistScheduleSlotsApi),
      takeLatest(getAllSchedule.TRIGGER, getAllScheduleRequest, getAllScheduleApi),
      takeLatest(checkScheduleSlots.TRIGGER, checkScheduleSlotsRequest, checkScheduleSlotsApi)

   ];
};