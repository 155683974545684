/* eslint eqeqeq: 0 */

import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Container, Row } from 'shards-react';
import Header from '../common/header';
import { addPromoCode, getPromoCodes, disablePromoCode } from '../../redux/user.redux';
import MaterialTable from '../common/materialTable';
import * as moment from 'moment';
import ConfirmationDialog from '../common/ConfirmationDialog';

class Promotion extends Component {
    constructor(props) {
        super(props);
        this.state = {
            t: null,
            filterState: "auth",
            addPromo: false,
            deleteItem: [],
            showDeletePromoDialogue: false,

        }
    }
    componentDidMount() {
        this.setState({ t: this.props.t });
        this.props.getPromoCodes();
    }
    componentDidUpdate(prevProps) {

    }
    showDetail = (event) => {

    }

    deleteDetail = (event) => {
        this.setState({ deleteItem: event });
        this.setState({ showDeletePromoDialogue: true });
    }

    deletePromo = (event) => {
        const { deleteItem } = this.state;
        const data = { promo_id: deleteItem.id }
        this.props.disablePromoCode(data);
        this.setState({ showDeletePromoDialogue: false });
        setTimeout(() => {
            this.props.getPromoCodes();
        }, 2000);
    }

    locationsDetail = (event) => {

    }

    treatmentsDetail = (event) => {

    }

    editDetail = (event) => {
        console.log("editDetail: " + JSON.stringify(event));
        return (this.props.history.push('/editpromotion', { object: event, action: "edit" }));
    }

    addNewPromo = () => {
        // alert("Not implemented yet.")
        return (this.props.history.push('/addpromotion', { action: "add" }));
    }

    segmentControlTapped(val) {
        console.log('segmentControlTapped: ' + val);
        this.setState({ filterState: val });
    }

    generateColumns(t) {
        var columns = null;
        if (t !== null) {
            columns = [
                {
                    title: t.translations.promotion_id,
                    field: 'id',
                },
                {
                    title: t.translations.promotion_text,
                    field: 'text'
                },
                {
                    title: t.translations.discount_percent,
                    field: 'discount_percent',
                },
                {
                    title: t.translations.opening_time,
                    field: 'start_time',
                    render: rowData => <span>{rowData.start_time == 0 ? '' : moment(new Date(0).setUTCSeconds(rowData.start_time)).format('DD-MM-YYYY')}</span>
                },
                {
                    title: t.translations.closing_time,
                    field: 'end_time',
                    render: rowData => <span>{rowData.end_time == 0 ? '' : moment(new Date(0).setUTCSeconds(rowData.end_time)).format('DD-MM-YYYY')}</span>
                },
                // {
                //     title: t.translations.locations,
                //     field: 'locations',
                //     render: rowData => <span>{rowData.locations === 'locations' ? '' : rowData.locations}</span>
                // },
                // {
                //     title: t.translations.treatment_list,
                //     field: 'treatments',
                //     render: rowData => <span>{rowData.treatments === 'treatments' ? '' : rowData.treatments}</span>

                // }
            ];
        }
        else {
            columns = [
                {
                    title: 'ID',
                    field: 'id',
                },
                {
                    title: 'Rabattkod',
                    field: 'text'
                },
                {
                    title: 'Rabatt i %',
                    field: 'discount_percent',
                },
                {
                    title: 'Starttid',
                    field: 'start_time',
                    render: rowData => <span>{rowData.start_time == 0 ? '' : moment(new Date(0).setUTCSeconds(rowData.start_time)).format('DD-MM-YYYY')}</span>
                },
                {
                    title: 'Sluttid',
                    field: 'end_time',
                    render: rowData => <span>{rowData.end_time == 0 ? '' : moment(new Date(0).setUTCSeconds(rowData.end_time)).format('DD-MM-YYYY')}</span>
                },
                // {
                //     title: 'Platser',
                //     field: 'locations',
                // },
                // {
                //     title: 'Behandlingar',
                //     field: 'treatments',
                // }
            ];
        }

        return columns;
    }

    filterBookings(val) {

        return [];
    }




    render() {
        const { loading, promo_list, } = this.props;
        const { t } = this.state;
        var columns = this.generateColumns(t);


        return (
            <Fragment>
                <Container fluid className="personal-container user-container">
                    {this.state.showDeletePromoDialogue &&
                        <ConfirmationDialog
                            title={this.state.t != null ? this.state.t.translations.delete_confirmation : ''}
                            bodyText={this.state.t != null && this.state.t.translations.are_you_sure_to_delete}
                            handleCancel={() => this.setState({ showDeletePromoDialogue: false })}
                            handleOk={this.deletePromo} />}
                    <Row className="">
                        <Header
                            t={this.props.t}
                            addNew={this.addNewPromo}
                            title={t != null && t.translations.promotion}
                            addNewBtnTitle={t != null && t.translations.add_promo}
                        />
                        {/* <Header
                            t={this.props.t}
                            addNew={this.addNewPromo}
                            title={t != null && t.translations.promotion}
                            addNewBtnTitle={t != null && t.translations.add_promo} /> */}
                    </Row>
                    <Row>
                        <MaterialTable t={this.props.t}
                            justShow={14}
                            columns={columns}
                            data={promo_list}
                            showDetail={this.showDetail}
                            editDetail={this.editDetail}
                            deleteDetail={this.deleteDetail}
                            loading={loading}
                            view={true} />

                    </Row>
                </Container>
            </Fragment>
        )
    }
}

const mapStateToProps = state => {
    return {
        t: state.accountRedux.localeInfo.t,
        loading: state.userRedux.loading,
        success: state.userRedux.success,
        message: state.userRedux.message,
        promo_list: state.userRedux.promo_list,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        addPromoCode: (data) => dispatch(addPromoCode(data)),
        disablePromoCode: (data) => dispatch(disablePromoCode(data)),
        getPromoCodes: (data) => dispatch(getPromoCodes(data)),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Promotion);
