import { call, put, takeLatest } from "redux-saga/effects";
import Api from "../services/ApiCaller";
import {
   addUser,
   getUsers,
   getCustomersWithBenefits,
   editUser,
   deleteUser,
   getUserAddress,
   getPromoCodes,
   disablePromoCode,
   addPromoCode,
   sendPush,
   changeTherapist,
   getAvailableTherapist,
   sendReceipt,
   getPreliminaryDetails,
   getPreliminaryPrice,
   createPreliminaryDetails,
   addCustomerCredit,
   updatePreliminaryDetails,
   refundBooking,
   getNotificationUsers,
   sendNotifAdmin,
   getNotifAdmin,
   getUsersFiltered
} from "../redux/user.redux";


function* addUserRequest(api, { payload = {} }) {
   try {
      const { res = {} } = yield call(Api.callServer, api, payload);
      const { message, success } = res;
      if (success) {
         yield put(addUser.success(message));
      } else {
         yield put(addUser.failure(message));
      }
   } catch (e) {
      yield put(addUser.failure(e.result));
   }
}

function* editUserRequest(api, { payload = {} }) {
   try {
      const { res = {} } = yield call(Api.callServer, api, payload);
      const { message, success } = res;
      if (success) {
         yield put(editUser.success(message));
      } else {
         yield put(editUser.failure(message));
      }
   } catch (e) {
      yield put(editUser.failure(e.result));
   }
}

function* getCustomersWithBenefitsRequest(api, { payload = {} }) {
   try {
      const { res = {} } = yield call(Api.callServer, api, payload);
      const { message, data, success } = res;
      if (success) {
         yield put(getCustomersWithBenefits.success(data));
      } else {
         yield put(getCustomersWithBenefits.failure(message));
      }
   } catch (e) {
      yield put(getCustomersWithBenefits.failure(e.result));
   }
}

function* getUsersRequest(api, { payload = {} }) {
   try {
      const { res = {} } = yield call(Api.callServer, api, payload);
      const { message, data, success } = res;
      if (success) {
         yield put(getUsers.success(data));
      } else {
         yield put(getUsers.failure(message));
      }
   } catch (e) {
      yield put(getUsers.failure(e.result));
   }
}

function* getUsersFilteredRequest(api, { payload = {} }) {
   try {
      const { res = {} } = yield call(Api.callServer, api, payload);
      const { message, data, success } = res;

      if (success) {
         yield put(getUsersFiltered.success(data));
      } else {
         yield put(getUsersFiltered.failure(message));
      }
   } catch (e) {
      yield put(getUsersFiltered.failure(e.result));
   }
}

function* deleteUserRequest(api, { payload = {} }) {
   try {
      const { res = {} } = yield call(Api.callServer, api, payload);
      const { message, success } = res;
      if (success) {
         yield put(deleteUser.success(message));
      } else {
         yield put(deleteUser.failure(message));
      }
   } catch (e) {
      yield put(deleteUser.failure(e.result));
   }
}

function* getUserAddressRequest(api, { payload = {} }) {
   try {
      const { res = {} } = yield call(Api.callServer, api, payload);
      const { message, data, success } = res;
      if (success) {
         yield put(getUserAddress.success(data));
      }
      else {
         yield put(getUserAddress.failure(message));
      }
   } catch (e) {
      yield put(getUserAddress.failure(e.result));
   }
}

function* addPromoCodeRequest(api, { payload = {} }) {
   try {
      console.log('addPromoCodeRequest Base URL: ' + Api.callServer);
      console.log('API URL: ' + api);
      console.log('Params: ' + JSON.stringify(payload));

      const { res = {} } = yield call(Api.callServer, api, payload);

      console.log('Reponse: ' + JSON.stringify(res));

      const { message, data, success } = res;
      ////debugger;


      if (success) {
         yield put(addPromoCode.success(data));
      }
      else {
         yield put(addPromoCode.failure(message));
      }
   } catch (e) {
      yield put(addPromoCode.failure(e.result));
   }
}

function* getPromoCodesRequest(api, { payload = {} }) {
   try {
      const { res = {} } = yield call(Api.callServer, api, payload);
      const { message, data, success } = res;
      if (success) {
         yield put(getPromoCodes.success(data));
      }
      else {
         yield put(getPromoCodes.failure(message));
      }
   } catch (e) {
      yield put(getPromoCodes.failure(e.result));
   }
}

function* disablePromoCodeRequest(api, { payload = {} }) {
   try {
      const { res = {} } = yield call(Api.callServer, api, payload);
      const { message, data, success } = res;
      if (success) {
         yield put(disablePromoCode.success(data));
      }
      else {
         yield put(disablePromoCode.failure(message));
      }
   } catch (e) {
      yield put(disablePromoCode.failure(e.result));
   }
}

function* sendPushRequest(api, { payload = {} }) {
   try {
      const { res = {} } = yield call(Api.callServer, api, payload);
      const { message, data, success } = res;
      if (success) {
         yield put(sendPush.success(data));
      }
      else {
         yield put(sendPush.failure(message));
      }
   } catch (e) {
      yield put(sendPush.failure(e.result));
   }
}

function* changeTherapistRequest(api, { payload = {} }) {
   try {
      const { res = {} } = yield call(Api.callServer, api, payload);
      const { message, data, success } = res;
      if (success) {
         yield put(changeTherapist.success(data));
      }
      else {
         yield put(changeTherapist.failure(message));
      }
   }
   catch (e) {
      yield put(changeTherapist.failure(e.result));
   }
}

function* getAvailableTherapistRequest(api, { payload = {} }) {
   try {
      const { res = {} } = yield call(Api.callServer, api, payload);
      const { message, data, success } = res;
      if (success) {
         yield put(getAvailableTherapist.success(data));
      }
      else {
         yield put(getAvailableTherapist.failure(message));
      }
   } catch (e) {
      yield put(getAvailableTherapist.failure(e.result));
   }
}

function* sendReceiptRequest(api, { payload = {} }) {
   try {


      const { res = {} } = yield call(Api.callServer, api, payload);
      const { message, data, success } = res;
      if (success) {
         yield put(sendReceipt.success(data));
      }
      else {
         yield put(sendReceipt.failure(message));
      }
   } catch (e) {
      yield put(sendReceipt.failure(e.result));
   }
}

function* getPreliminaryDetailsRequest(api, { payload = {} }) {
   try {

      const { res = {} } = yield call(Api.callServer, api, payload);
      const { message, data, success } = res;
      if (success) {
         yield put(getPreliminaryDetails.success(data));
      }
      else {
         yield put(getPreliminaryDetails.failure(message));
      }
   } catch (e) {
      yield put(getPreliminaryDetails.failure(e.result));
   }
}

function* getPreliminaryPriceRequest(api, { payload = {} }) {
   try {

      const { res = {} } = yield call(Api.callServer, api, payload);
      const { message, data, success } = res;
      if (success) {
         yield put(getPreliminaryPrice.success(data));
      }
      else {
         yield put(getPreliminaryPrice.failure(message));
      }
   } catch (e) {
      yield put(getPreliminaryPrice.failure(e.result));
   }
}

function* addCustomerCreditRequest(api, { payload = {} }) {
   try {

      const { res = {} } = yield call(Api.callServer, api, payload);
      const { message, data, success } = res;
      if (success) {
         yield put(addCustomerCredit.success(data));
      }
      else {
         yield put(addCustomerCredit.failure(message));
      }
   } catch (e) {
      yield put(addCustomerCredit.failure(e.result));
   }
}
function* createPreliminaryDetailsRequest(api, { payload = {} }) {
   try {

      const { res = {} } = yield call(Api.callServer, api, payload);
      const { message, data, success } = res;
      if (success) {
         yield put(createPreliminaryDetails.success(data));
      }
      else {
         yield put(createPreliminaryDetails.failure(message));
      }
   } catch (e) {
      yield put(createPreliminaryDetails.failure(e.result));
   }
}

function* updatePreliminaryDetailsRequest(api, { payload = {} }) {
   try {

      const { res = {} } = yield call(Api.callServer, api, payload);
      const { message, data, success } = res;
      if (success) {
         yield put(updatePreliminaryDetails.success(data));
      }
      else {
         yield put(updatePreliminaryDetails.failure(message));
      }
   } catch (e) {
      yield put(updatePreliminaryDetails.failure(e.result));
   }
}


function* refundBookingRequest(api, { payload = {} }) {
   try {
      const { res = {} } = yield call(Api.callServer, api, payload);
      const { message, data, success } = res;
      if (success) {
         yield put(refundBooking.success(data));
      }
      else {
         yield put(refundBooking.failure(message));
      }
   } catch (e) {
      yield put(refundBooking.failure(e.result));
   }
}

function* getNotificationUsersRequest(api, { payload = {} }) {
   try {
      const { res = {} } = yield call(Api.callServer, api, payload);
      const { message, data, success } = res;
      if (success) {
         yield put(getNotificationUsers.success(data));
      }
      else {
         yield put(getNotificationUsers.failure(message));
      }
   }
   catch (e) {
      yield put(getNotificationUsers.failure(e.result));
   }
}

function* sendNotifAdminRequest(api, { payload = {} }) {
   try {
      const { res = {} } = yield call(Api.callServer, api, payload);
      const { message, data, success } = res;
      if (success) {
         yield put(sendNotifAdmin.success(data));
      }
      else {
         yield put(sendNotifAdmin.failure(message));
      }
   }
   catch (e) {
      yield put(sendNotifAdmin.failure(e.result));
   }
}

function* getNotifAdminRequest(api, { payload = {} }) {
   try {
      const { res = {} } = yield call(Api.callServer, api, payload);
      const { message, data, success } = res;
      if (success) {
         yield put(getNotifAdmin.success(data));
      }
      else {
         yield put(getNotifAdmin.failure(message));
      }
   }
   catch (e) {
      yield put(getNotifAdmin.failure(e.result));
   }
}

export default (api) => {
   const addUserApi = (data) => api.post("v1/user", data);
   const editUserApi = (data) => api.put("v1/user", data);
   const deleteUserApi = (data) => api.delete("v1/deleteUser", data);
   const getUsersApi = (data) => api.get("v1/getAllCustomers", data);
   const getCustomersWithBenefitsApi = (data) => api.get("v1/getAllCustomersWithBenefits", data);
   const getUsersDataApi = (data) => api.get("v1/getAllCustomersFiltered", data);
   const getUserAddressApi = (data) => api.get("v2/getUserAddress", data);
   const getPromoCodesApi = (data) => api.get("v1/GetAllPromoCodes", data);
   const addPromoCodeApi = (data) => api.get("v1/AddUpdatePromotion", data);
   const disablePromoCodeApi = (data) => api.get("v1/DisablePromotion", data);
   const sendPushApi = (data) => api.post("v1/sendPushNotification", data);

   const getAvailableTherapistApi = (data) => api.get("v2/getAllocatableTherapist", data); // garbage
   const changeTherapistApi = (data) => api.get("v1/changeTherapist", data);
   const refundBookingApi = (data) => api.get("v1/RefundPayments", data);
   const getNotificationUsersApi = (data) => api.get("v1/GetUsersForNotifications", data);
   const getNotifAdminApi = (data) => api.get("v1/getNotificationsFromAdmin", data);
   const sendNotifAdminApi = (data) => api.get("v1/sendNotificationFromAdmin", data);
   const sendReceiptApi = (data) => api.post("v2/sendReceiptByAdmin", data);
   const getPreliminaryDetailsApi = (data) => api.get("v1/getPreliminaryDetails", data);
   const getPreliminaryPriceApi = (data) => api.get("v1/getPreliminaryPrice", data);
   const createPreliminaryDetailsApi = (data) => api.post("v1/createPreliminaryBooking", data);
   const addCustomerCreditApi = (data) => api.get("v1/addCustomerCredit", data);
   const updatePreliminaryDetailsApi = (data) => api.post("v1/updatePreliminaryBooking", data);



   return [
      takeLatest(sendNotifAdmin.TRIGGER, sendNotifAdminRequest, sendNotifAdminApi),
      takeLatest(getNotifAdmin.TRIGGER, getNotifAdminRequest, getNotifAdminApi),

      takeLatest(getNotificationUsers.TRIGGER, getNotificationUsersRequest, getNotificationUsersApi),

      takeLatest(changeTherapist.TRIGGER, changeTherapistRequest, changeTherapistApi),
      takeLatest(getAvailableTherapist.TRIGGER, getAvailableTherapistRequest, getAvailableTherapistApi),
      takeLatest(sendReceipt.TRIGGER, sendReceiptRequest, sendReceiptApi),
      takeLatest(getPreliminaryDetails.TRIGGER, getPreliminaryDetailsRequest, getPreliminaryDetailsApi),
      takeLatest(getPreliminaryPrice.TRIGGER, getPreliminaryPriceRequest, getPreliminaryPriceApi),

      takeLatest(addCustomerCredit.TRIGGER, addCustomerCreditRequest, addCustomerCreditApi),
      takeLatest(createPreliminaryDetails.TRIGGER, createPreliminaryDetailsRequest, createPreliminaryDetailsApi),
      takeLatest(updatePreliminaryDetails.TRIGGER, updatePreliminaryDetailsRequest, updatePreliminaryDetailsApi),

      takeLatest(refundBooking.TRIGGER, refundBookingRequest, refundBookingApi),

      takeLatest(sendPush.TRIGGER, sendPushRequest, sendPushApi),

      takeLatest(getPromoCodes.TRIGGER, getPromoCodesRequest, getPromoCodesApi),
      takeLatest(addPromoCode.TRIGGER, addPromoCodeRequest, addPromoCodeApi),
      takeLatest(disablePromoCode.TRIGGER, disablePromoCodeRequest, disablePromoCodeApi), // not consumed

      takeLatest(getUserAddress.TRIGGER, getUserAddressRequest, getUserAddressApi),
      takeLatest(addUser.TRIGGER, addUserRequest, addUserApi),
      takeLatest(editUser.TRIGGER, editUserRequest, editUserApi),
      takeLatest(deleteUser.TRIGGER, deleteUserRequest, deleteUserApi),
      takeLatest(getUsers.TRIGGER, getUsersRequest, getUsersApi),
      takeLatest(getCustomersWithBenefits.TRIGGER, getCustomersWithBenefitsRequest, getCustomersWithBenefitsApi),
      takeLatest(getUsersFiltered.TRIGGER, getUsersFilteredRequest, getUsersDataApi),
   ];
};