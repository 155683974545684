import React from 'react';
import { Container, Row, Form, Button, Col } from 'shards-react';
import { Link } from 'react-router-dom';
import { required } from '../../../../helper/validate';
import { FormField } from '../../../../helper/formFields';
import { reduxForm, Field } from 'redux-form';
import { connect } from 'react-redux';
import { loginAdmin } from '../../../../redux/account.redux';
import logo from '../../../../images/logo.png';


class AdminLogin extends React.Component {

    constructor(props) {
        super(props);
        console.log("AdminLogin - constructor");
    }


    componentDidUpdate(prevProps) {
        if (this.props.success) {
            console.log('LOGGED IN: ' + this.props.user);
            // this.props.history.push('/personal')
        }
    }
    handleSubmit = (values, dispatch) => {
        //////////debugger;
        const { email, password } = values;
        const data = {
            Email: email,
            Password: password,
            Role: 1
        }
        dispatch(loginAdmin(data))
    }

    render = () => {
        return (
            <Container fluid className="login-container">
                <Row className="login-content">
                    <Col className="col-md-12 col-xs-12 logo-container">
                        <div className="row company-name">
                            <Col className="col-md-12 company-item mt-5">
                                <img src={logo} alt="logo"></img>
                            </Col>
                            <Col className="col-md-12 company-item ">
                                <h3 className="login-admin-header">Healness Administration</h3>
                            </Col>
                            <Col className=" col-md-12 login-data">
                                <Form onSubmit={this.props.handleSubmit(this.handleSubmit)}>
                                    <Row className="my-2">
                                        <Field
                                            placeholder="Email Address"
                                            type="email"
                                            name="email"
                                            classes="login-input"
                                            validate={[required]}
                                            component={FormField}
                                        />
                                    </Row>
                                    <Row className="">
                                        <Field
                                            placeholder="Password"
                                            type="password"
                                            name="password"
                                            classes="login-input"
                                            validate={[required]}
                                            component={FormField}
                                        />
                                    </Row>
                                    <Button className="btn-block btn-login max-width login-input" type="submit">LOGIN {this.props.loading && <i className="fas fa-circle-notch fa-spin loader"></i>}</Button>
                                </Form>
                                <Link to="/forgot-password" className="forgot-password mt-5 ml-4">Forgot Password</Link>
                            </Col>

                        </div>
                    </Col>
                </Row>
            </Container >
        );
    }
};

const mapStateToProps = state => {
    return {
        message: state.accountRedux.message,
        success: state.accountRedux.success,
        error: state.accountRedux.error,
        user: state.accountRedux.user,
        loading: state.accountRedux.loading
    };
};

export default connect(mapStateToProps)(reduxForm({
    form: 'adminLoginForm',
    destroyOnUnmount: false
})(AdminLogin));