import React from 'react';
import { Col } from 'shards-react';
import logo from '../../images/logo.png';


class CompanyInfo extends React.Component {
    render = () => {
        return (
            <Col className="col-md-6 col-xs-12 logo-container">
                <div className="row company-name">
                    <Col className="col-md-12 company-item">
                        <img src={logo} alt="logo"></img>
                    </Col>
                    <Col className="col-md-12 company-item">
                        <h3 className="login-header">{this.props.props.t.translations.healness_staff_platform}</h3>
                    </Col>
                </div>
            </Col>
        );
    }
};

export default CompanyInfo;