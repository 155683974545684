// export const BASEURL = 'https://localhost:5001/api';

export const BASEURL = 'https://api.healness.se/api';
export const APP_VERSION = 'v1.0.211 (Live)';

export const PENDING = "PENDING";
export const PAYMENT_INITIATED = "PAYMENT_INITIATED"
export const AUTHORIZED = "AUTHORIZED";
export const CANCELED = "CANCELED";
export const PAID = "PAID";
export const UNPAID = "UNPAID";
export const BOOKING_UPDATE = "BOOKING_UPDATE";
export const REFUNDED = "REFUNDED";
export const COMPLETED = "COMPLETED";
export const INCOMPLETE = "INCOMPLETE";
export const DISCARD = "DISCARD";

export const BOOKED_PAID = "BOOKED_PAID";
export const SWISH_REFUND = "SWISH_REFUND";


export const S_LOGIN = "eyJuYW1laWQiOi^^x^^iwianRp^^joiNG^^4#mZi#W#tYTUxZC&&OTgxLTk3NzEt#jBiZmJi#2Nj#j#5^^iwibmJm^^joxNjQyNDEzNzk3LCJleHAiOjE2ND^^&#jA5OTcs^^mlhdC^^6#TY&#jQx#zc5N3&";
