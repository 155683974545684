/* eslint eqeqeq: 0 */
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Container, Row, Col } from 'shards-react';
import { getBookings, showBookingDetail, deleteBooking, getBookingPriceInformation } from '../../redux/booking.redux';
import { changeTherapist, getAvailableTherapist, refundBooking, sendReceipt } from '../../redux/user.redux';

import * as moment from 'moment';
import { reduxForm } from 'redux-form';
import { AUTHORIZED, CANCELED, INCOMPLETE, COMPLETED, REFUNDED } from '../../constants/urls';


var reload_ = false;

class About extends Component {
    constructor(props) {
        super(props);
        this.state = {
            t: null,
            available_therapists: [],
            show_available_therapists: false,
            show_available_therapists_error: false,
            show_receipt_error: false,
            show_receipt_dialog: false,
            show_receipt_success: false,
            available_therapists_error: "",
            filterState: "today",
            change_cust_email: "",
            showDialogue: false,
            filtered_bookings: [],
            deleteItem: {},
            showDeleteBookingDialogue: false,
            showRefundDialogue: false
        }
    }
    componentDidMount() {
        this.setState({ t: this.props.t });
        var user_id = this.props.user.id;
        var role_type = this.props.user.role_type;

        if (role_type == 3) {
            var data = { "therapist_id": user_id }
            this.props.getBookings(data);
        }
        else {
            this.props.getBookings();
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props !== prevProps) {
            //debugger;

            if (this.props.bookings !== null && this.props.bookings !== undefined) {

                if (this.props.user.role_type == 3) {
                    var user_id = this.props.user.id;
                    let recvd_bookings = this.props.bookings;
                    // // //////////debugger;
                    console.log('MY THERAPIST ID: ' + user_id);
                    let filtered_ = recvd_bookings.filter(el => {
                        // var status = (el.status == 'Pending');
                        // // status = true;
                        console.log('Filtered: ' + el.therapist_id + " = " + user_id);

                        return (el.therapist_id === user_id)
                    });
                    this.setState({ filtered_bookings: filtered_ });
                }
                else {
                    let recvd_bookings = this.props.bookings;
                    this.setState({ filtered_bookings: recvd_bookings });

                }
            }

            if (this.props.avail_therapists != null && this.props.avail_therapists != undefined && this.props.avail_therapists != "") {
                // debugger;
                if (this.props.avail_therapists.allocated_therapist != undefined &&
                    this.props.avail_therapists.allocated_therapist != null &&
                    this.props.avail_therapists.allocated_therapist != "") {

                    if (this.props.avail_therapists.allocated_therapist.length == 0) {
                        this.setState({ show_available_therapists_error: true });
                        this.setState({ available_therapists_error: "No therapist available right now." });
                    }
                    else {
                        this.setState({ show_available_therapists: true });
                        this.setState({ available_therapists: this.props.avail_therapists.allocated_therapist });
                    }

                }


            }

            if (this.props.send_receipt != null && this.props.send_receipt != undefined && this.props.send_receipt != "") {
                // debugger;

                this.setState({ show_receipt_error: false });
                if (this.props.send_receipt == "sent") {
                    this.setState({ show_receipt_success: true });
                }
                else {
                    this.setState({ show_receipt_success: false });
                }


            }

            if (this.props.update_therapist != null && this.props.update_therapist != undefined && this.props.update_therapist != "") {
                if (reload_) {
                    reload_ = false;
                    this.props.getBookings();
                }
            }

            if (this.props.refund_data != null && this.props.refund_data != undefined && this.props.refund_data != "") {
                if (reload_) {
                    reload_ = false;
                    this.props.getBookings();
                }
            }
        }
    }

    showDetail = (event) => {
        this.props.showBookingDetail(event);

        this.setState({ show_available_therapists: false });
        this.setState({ showDialogue: true });
    }

    addNewBooking = () => {
        // this.props.history.push('/addbooking');
        this.props.history.push('/addbooking', { action: "add", data: null, t: this.state.t })
    }

    addOtherBooking = () => {
        this.props.history.push('/addotherbooking', { action: "add", data: null, t: this.state.t })
    }

    _renderDialog = (event) => {
        this.setState({ showDialogue: false });
        this.setState({ showDeleteBookingDialogue: true, deleteItem: event })
    }

    sendReceipt = (event) => {
        const { change_booking_id } = this.state;
        const data = {
            "booking_id": change_booking_id
        }
        // debugger;
        this.props.sendReceipt(data);
        this.setState({ show_receipt_dialog: false });
    }

    deleteBooking = (event) => {
        const { deleteItem } = this.state;
        const data = { booking_id: deleteItem.id }
        this.props.deleteBooking(data);
        this.setState({ showDeleteBookingDialogue: false });
        setTimeout(() => {
            this.props.getBookings();
        }, 2000);
    }

    handleSubmit = (values, dispatch) => {
        const { booking_id } = values;
        const data = {
            booking_id
        }
        this.props.getBookingPriceInformation(data);
    }

    minTwoDigits(n) {
        return (n < 10 ? '0' : '') + n;
    }

    segmentControlTapped(val) {
        console.log('segmentControlTapped: ' + val);
        this.setState({ schedule_view: val });
        this.setState({ filterState: val });

        // if (val == 'set_schedule'){
        //     this._openModal();
        // }

    }

    mapStatusStr(status) {
        console.log("mapStatusStr: " + status);

        var temp = "";
        if (status == "Auth" || status == AUTHORIZED) {
            temp = "Booked";
        }
        else if (status == "Cancelled" || status == CANCELED) {
            temp = "Cancelled";
        }
        else if (status == "Completed" || status == COMPLETED) {
            temp = "Confirmed";
        }
        else if (status == "Incompleted" || status == INCOMPLETE) {
            temp = "Failed";
        }
        else if (status == "Refunded" || status == REFUNDED) {
            temp = "Refunded";
        }
        else if (status == "BOOKED_PAID") {
            temp = "BOOKED_PAID"
        }

        return temp;
    }

    filterBookings(val) {
        // const { bookings } = this.props;
        var bookings = this.state.filtered_bookings;
        var date_ = "";

        ////debugger;

        if (bookings.length > 0) {
            if (val == "past") {
                date_ = moment().format("YYYY-MM-DD");
                let resultant = bookings.filter(item => {
                    var formatted_ = moment(new Date(0).setUTCSeconds(item.date)).format('YYYY-MM-DD');
                    // console.log('BookingID: ' + item.id + " => " + formatted_);
                    return formatted_ < date_ && item.status != "PENDING";
                })


                // date and time sorting
                resultant.sort((a, b) => {
                    var c = moment(new Date(0).setUTCSeconds(a.date)).format('YYYY-MM-DD');
                    var d = moment(new Date(0).setUTCSeconds(b.date)).format('YYYY-MM-DD');

                    var cT = moment(new Date(0).setUTCSeconds(a.date)).format('HH:MM');
                    var dT = moment(new Date(0).setUTCSeconds(b.date)).format('HH:MM');

                    return c.localeCompare(d) || cT.localeCompare(dT);
                });

                //reverse arr
                let rev = resultant.reverse();

                return rev;

            }
            else if (val == "today") {
                date_ = moment().format("YYYY-MM-DD");
                let resultant = bookings.filter(item => {
                    // var formatted_ = moment.utc(item.date).format("YYYY-MM-DD");
                    var formatted_ = moment(new Date(0).setUTCSeconds(item.date)).format('YYYY-MM-DD');
                    // console.log('BookingID: ' + item.id + " => " + formatted_);
                    return formatted_ === date_ && item.status != "PENDING";
                })


                // date and time sorting
                resultant.sort((a, b) => {
                    var c = moment(new Date(0).setUTCSeconds(a.date)).format('YYYY-MM-DD');
                    var d = moment(new Date(0).setUTCSeconds(b.date)).format('YYYY-MM-DD');

                    var cT = moment(new Date(0).setUTCSeconds(a.date)).format('HH:MM');
                    var dT = moment(new Date(0).setUTCSeconds(b.date)).format('HH:MM');

                    return c.localeCompare(d) || cT.localeCompare(dT);
                });

                //reverse arr
                let rev = resultant.reverse();

                return rev;
            }
            else if (val == "tomorrow") {
                date_ = moment().add(1, "days").format("YYYY-MM-DD");
                let resultant = bookings.filter(item => {
                    // var formatted_ = moment.utc(item.date).format("YYYY-MM-DD");
                    var formatted_ = moment(new Date(0).setUTCSeconds(item.date)).format('YYYY-MM-DD');
                    // console.log('BookingID: ' + item.id + " => " + formatted_);
                    return formatted_ === date_ && item.status != "PENDING";
                })


                // date and time sorting
                resultant.sort((a, b) => {
                    var c = moment(new Date(0).setUTCSeconds(a.date)).format('YYYY-MM-DD');
                    var d = moment(new Date(0).setUTCSeconds(b.date)).format('YYYY-MM-DD');

                    var cT = moment(new Date(0).setUTCSeconds(a.date)).format('HH:MM');
                    var dT = moment(new Date(0).setUTCSeconds(b.date)).format('HH:MM');

                    return c.localeCompare(d) || cT.localeCompare(dT);
                });

                //reverse arr
                let rev = resultant.reverse();

                return rev;
            }
            else if (val == "week") {
                const today = moment();
                const from_date = today.startOf('week').format("YYYY-MM-DD");
                const to_date = today.endOf('week').format("YYYY-MM-DD");

                ////debugger;
                let resultant = bookings.filter(item => {
                    // var formatted_ = moment.utc(item.date).format("YYYY-MM-DD");
                    var formatted_ = moment(new Date(0).setUTCSeconds(item.date)).format('YYYY-MM-DD');
                    // console.log('BookingID: ' + item.id + " => " + formatted_);
                    return (formatted_ >= from_date && formatted_ <= to_date) && item.status != "PENDING";
                });

                // date and time sorting
                resultant.sort((a, b) => {
                    var c = moment(new Date(0).setUTCSeconds(a.date)).format('YYYY-MM-DD');
                    var d = moment(new Date(0).setUTCSeconds(b.date)).format('YYYY-MM-DD');

                    var cT = moment(new Date(0).setUTCSeconds(a.date)).format('HH:MM');
                    var dT = moment(new Date(0).setUTCSeconds(b.date)).format('HH:MM');

                    return c.localeCompare(d) || cT.localeCompare(dT);
                });

                //reverse arr
                let rev = resultant.reverse();

                return rev;
            }
            else if (val == "month") {
                const today = moment(new Date());
                const from_date = today.startOf('month').format("YYYY-MM-DD");
                const to_date = today.endOf('month').format("YYYY-MM-DD");

                let resultant = bookings.filter(item => {
                    // var formatted_ = moment.utc(item.date).format("YYYY-MM-DD");
                    var formatted_ = moment(new Date(0).setUTCSeconds(item.date)).format('YYYY-MM-DD');
                    // console.log('BookingID: ' + item.id + " => " + formatted_);
                    return (formatted_ >= from_date && formatted_ <= to_date) && item.status != "PENDING";
                });

                // date and time sorting
                resultant.sort((a, b) => {
                    var c = moment(new Date(0).setUTCSeconds(a.date)).format('YYYY-MM-DD');
                    var d = moment(new Date(0).setUTCSeconds(b.date)).format('YYYY-MM-DD');

                    var cT = moment(new Date(0).setUTCSeconds(a.date)).format('HH:MM');
                    var dT = moment(new Date(0).setUTCSeconds(b.date)).format('HH:MM');

                    return c.localeCompare(d) || cT.localeCompare(dT);
                });

                //reverse arr
                let rev = resultant.reverse();

                return rev;
            }
            else if (val == "future") {

                date_ = moment().add(1, "days").format("YYYY-MM-DD");

                // const today1 = moment(new Date());
                // var t = today.format("YYYY-MM-DD");

                // const startOfMonth = moment().startOf('month').format('YYYY-MM-DD');
                // const endOfMonth   = moment().endOf('month').format('YYYY-MM-DD');
                // // date_ = moment().add(1,"days").format("YYYY-MM-DD");
                // const from_date = today.startOf('month').format("YYYY-MM-DD");
                // const to_date = today.endOf('month').format("YYYY-MM-DD");

                let resultant = bookings.filter(item => {
                    // var formatted_ = moment.utc(item.date).format("YYYY-MM-DD");
                    var formatted_ = moment(new Date(0).setUTCSeconds(item.date)).format('YYYY-MM-DD');
                    // console.log('BookingID: ' + item.id + " => " + formatted_);
                    return formatted_ > date_ && item.status != "PENDING";
                });


                // date and time sorting
                resultant.sort((a, b) => {
                    var c = moment(new Date(0).setUTCSeconds(a.date)).format('YYYY-MM-DD');
                    var d = moment(new Date(0).setUTCSeconds(b.date)).format('YYYY-MM-DD');

                    var cT = moment(new Date(0).setUTCSeconds(a.date)).format('HH:MM');
                    var dT = moment(new Date(0).setUTCSeconds(b.date)).format('HH:MM');

                    return d.localeCompare(c) || dT.localeCompare(cT);
                });

                //reverse arr
                let rev = resultant.reverse();

                return rev;
            }
            else if (val == "preliminary") {
                let resultant = bookings.filter(item => {
                    return item.preliminary == true && item.status === "PENDING";
                });


                // date and time sorting
                resultant.sort((a, b) => {
                    var c = moment(new Date(0).setUTCSeconds(a.date)).format('YYYY-MM-DD');
                    var d = moment(new Date(0).setUTCSeconds(b.date)).format('YYYY-MM-DD');

                    var cT = moment(new Date(0).setUTCSeconds(a.date)).format('HH:MM');
                    var dT = moment(new Date(0).setUTCSeconds(b.date)).format('HH:MM');

                    return d.localeCompare(c) || dT.localeCompare(cT);
                });

                //reverse arr
                let rev = resultant.reverse();

                return rev;
            }

        }
        return bookings;
    }

    generateColumns(t) {
        var bookingColumns = null;

        if (t != null) {
            if (this.state.filterState === "preliminary") {
                bookingColumns = [
                    {
                        title: t.translations.booking_id,
                        field: 'raw'
                        // render: rowData => <span>{Buffer.from(rowData.id, 'base64').toString('ascii')}</span>
                    },
                    {
                        title: t.translations.customer_name,
                        field: 'customer.first_name',
                        render: rowData => <span>{rowData.customer.first_name + " " + rowData.customer.last_name}</span>
                    },
                    // {
                    //     title: t.translations.payment_method,
                    //     field: 'payment_method',
                    //     render: rowData => <span>{rowData.payment_method == "NETS" ? "Card" : rowData.payment_method}</span>
                    // },
                    {
                        title: t.translations.treatment_category,
                        field: 'category'
                    },
                    {
                        title: t.translations.confirmBooking_treatment_app,
                        field: 'treatment_name'
                    },
                    {
                        title: t.translations.staff,
                        field: 'therapist_name'
                    },
                    {
                        title: t.translations.select_slot,
                        field: 'slot_time',
                    },
                    // {
                    //     title: t.translations.companyBooking_price_app,
                    //     field: 'price',
                    //     render: rowData => <span>{rowData.price.toFixed(2) + " SEK"}</span>
                    // },
                    {
                        title: t.translations.schedule_date,
                        field: 'date',
                        render: rowData => <span>{rowData.reserved_slot >= 2200 ? rowData.reserved_date : moment(new Date(0).setUTCSeconds(rowData.date)).format('YYYY-MM-DD') + " " + this.minTwoDigits(Math.trunc(rowData.reserved_slot / 100)) + ":" + this.minTwoDigits(rowData.reserved_slot % 100)}</span>
                        // render: rowData => <span>{"Test1:" + moment(new Date(0).setUTCSeconds(rowData.date - 7200)).format('YYYY-MM-DD') + " " + this.minTwoDigits(Math.trunc(rowData.reserved_slot/100)) + ":" + this.minTwoDigits(rowData.reserved_slot%100)}</span>
                    },

                ];
            }
            else {
                bookingColumns = [
                    {
                        title: t.translations.booking_id,
                        field: 'raw'
                        // render: rowData => <span>{Buffer.from(rowData.id, 'base64').toString('ascii')}</span>
                    },
                    {
                        title: t.translations.customer_name,
                        field: 'customer.first_name',
                        render: rowData => <span>{rowData.customer.first_name + " " + rowData.customer.last_name}</span>
                    },
                    {
                        title: t.translations.payment_method,
                        field: 'payment_method',
                        render: rowData => <span>{rowData.payment_method == "NETS" ? "Card" : rowData.payment_method}</span>
                    },
                    {
                        title: t.translations.treatment_category,
                        field: 'category'
                    },
                    {
                        title: t.translations.confirmBooking_treatment_app,
                        field: 'treatment_name'
                    },
                    {
                        title: t.translations.staff,
                        field: 'therapist_name'
                    },
                    {
                        title: t.translations.select_slot,
                        field: 'slot_time',
                    },
                    {
                        title: t.translations.companyBooking_price_app,
                        field: 'price',
                        render: rowData => <span>{rowData.price.toFixed(2) + " SEK"}</span>
                    },
                    {
                        title: t.translations.schedule_date,
                        field: 'date',
                        // render: rowData => <span>{moment(new Date(0).setUTCSeconds(rowData.date - 7200)).format('YYYY-MM-DD') + " " + this.minTwoDigits(Math.trunc(rowData.reserved_slot/100)) + ":" + this.minTwoDigits(rowData.reserved_slot%100)}</span>
                        render: rowData => <span>{rowData.reserved_slot >= 2200 ? rowData.reserved_date : moment(new Date(0).setUTCSeconds(rowData.date)).format('YYYY-MM-DD') + " " + this.minTwoDigits(Math.trunc(rowData.reserved_slot / 100)) + ":" + this.minTwoDigits(rowData.reserved_slot % 100)}</span>
                    },
                    // {
                    //     title: t.translations.transaction_id,
                    //     field: 'transaction_id'
                    // },
                    {
                        title: t.translations.status,
                        field: 'status',
                        render: rowData => <span>{this.mapStatusStr(rowData.status)}</span>
                    }
                    // ,
                    // {
                    //     title: t.translations.authorization_id,
                    //     field: 'authorization_id'
                    // }
                ];
            }

        }

        return bookingColumns;
    }

    capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    changeTherapistMethod(event) {
        // = (event) =>
        console.log("changeTherapistMethod: " + JSON.stringify(event));
        this.setState({ showDialogue: false });
        this.setState({ change_booking_id: event.raw });

        ////debugger;
        var data = {
            "user_id": event.customer.id,
            "treatment_type": event.category,
            "treatment_id": event.treatment_id,
            "location_id": event.location.id,
            "slot_id": event.slot_id,
            "reserved_slot": event.reserved_slot,
            "schedule_date": event.date,
        }
        this.props.getAvailableTherapist(data);
    }

    updatePreliminaryBooking(event) {
        // = (event) =>
        console.log("updatePreliminaryBooking: " + JSON.stringify(event));
        this.setState({ showDialogue: false });
        this.props.history.push('/editbooking', { action: "edit", data: event, t: this.state.t })
    }

    deletePreliminaryBooking(event) {
        // = (event) =>
        console.log("deletePreliminaryBooking: " + JSON.stringify(event));
        this.setState({ showDialogue: false });

    }

    sendReceiptMethod(event) {
        console.log("sendReceiptMethod: " + JSON.stringify(event));
        this.setState({ showDialogue: false });
        this.setState({ change_booking_id: event.raw });
        this.setState({ change_cust_email: event.customer.email });

        this.setState({ show_receipt_dialog: true });

        ////debugger;
        // var data = {
        //     "booking_id" : event.raw,
        // }
        // var data = {
        //     booking_id: event.raw
        // }
        // this.props.sendReceipt(data);
    }

    showPayments(event) {
        console.log("showPayments: " + JSON.stringify(event));
        this.props.history.push('/transaction', { booking_info: event });
        // this.setState({ showDialogue: false });
        // this.setState({ change_booking_id: event.raw });
        // this.setState({ change_cust_email: event.customer.email });

        // this.setState({ show_receipt_dialog: true });

        ////debugger;
        // var data = {
        //     "booking_id" : event.raw,
        // }
        // var data = {
        //     booking_id: event.raw
        // }
        // this.props.sendReceipt(data);
    }

    updateTherapistMethod(event) {
        console.log('updateTherapistMethod: ' + JSON.stringify(event));
        this.setState({ show_available_therapists: false });

        ////debugger;
        var { selected_therapist } = this.state;
        if (selected_therapist != undefined && selected_therapist != "") {
            var data = {
                "booking_id": this.state.change_booking_id,
                "new_therapist_id": selected_therapist
            }

            reload_ = true;

            console.log("Update Booking: " + JSON.stringify(data));
            this.props.changeTherapist(data);
        }
    }

    refundBookingMethod(event) {
        console.log("refundBookingMethod: " + JSON.stringify(event));
        this.setState({ refund_booking_id: event.raw });
        this.setState({ showDialogue: false });
        this.setState({ showRefundDialogue: true });
    }

    refundBooking = (event) => {
        console.log("refundBooking: ");
        this.setState({ showDialogue: false });
        this.setState({ showRefundDialogue: false });


        if (this.state.refund_booking_id != undefined && this.state.refund_booking_id != null && this.state.refund_booking_id != "") {
            var data = {
                "booking_id": this.state.refund_booking_id
            }
            reload_ = true;
            this.props.refundBooking(data);
        }
    }

    selectNewTherapist = (event) => {
        this.setState({ selected_therapist: event });
    }

    cancelAvailableTherapistPopup(event) {
        this.setState({ show_available_therapists: false });
    }

    render() {

        //ID conversion
        // console.log(Buffer.from("jwEAAA==", 'base64').toString('ascii'));
        var filtered_therapists = this.state.available_therapists.map(person => ({ value: person.id, label: person.name }));
        console.log("filtered_therapists: about screen ===" + JSON.stringify(filtered_therapists));

        // .filter( el => {
        //     var obj = { value: el.id, label: el.first_name + " " + el.last_name }
        //     return true;
        // }); filter() {
        //     currentLocationTypeValue = { value: user.id, label: user.first_name + " " + user.last_name }

        // }

        //.split(',').slice(0, -1).join()

        // let rev_bookings = this.filterBookings(this.state.filterState);   //bookings.reverse();
        // var bookingColumns = this.generateColumns(t);

        const textArray = this.props.t.translations.about_desc.split('\n');

        return (
            <Fragment>
                <Container fluid className="personal-container user-container">
                    <Row>
                        <Col className="invoice-right-container col-md-12">
                            <h3 className="pb-5 col-md-8 float-left title-header">
                                {this.props.t.translations.about_system}
                            </h3>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="col-md-12 mt-5">
                            {textArray.map((value) => {
                                return <p>{value}</p>;
                            })}
                            {/* <div dangerouslySetInnerHTML={{ __html: this.props.t.translations.about_desc }} /> */}
                            {/* <p>{this.props.t.translations.about_desc}</p> */}
                        </Col>
                    </Row>
                </Container >
            </Fragment>
        )
    }
}

const mapStateToProps = state => {
    return {
        t: state.accountRedux.localeInfo.t,
        loading: state.bookingRedux.loading,
        success: state.bookingRedux.success,
        message: state.bookingRedux.message,
        bookings: state.bookingRedux.bookings,
        user: state.accountRedux.user,
        bookingInfo: state.bookingRedux.bookingInfo,
        bookingPriceInfo: state.bookingRedux.bookingPriceInfo,

        refund_data: state.userRedux.refund_data,
        update_therapist: state.userRedux.update_therapist,
        avail_therapists: state.userRedux.avail_therapists,
        send_receipt: state.userRedux.send_receipt,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getAvailableTherapist: (data) => dispatch(getAvailableTherapist(data)),
        sendReceipt: (data) => dispatch(sendReceipt(data)),

        refundBooking: (data) => dispatch(refundBooking(data)),
        changeTherapist: (data) => dispatch(changeTherapist(data)),

        getBookings: (data) => dispatch(getBookings(data)),
        showBookingDetail: (data) => dispatch(showBookingDetail(data)),
        deleteBooking: (data) => dispatch(deleteBooking(data)),
        getBookingPriceInformation: (data) => dispatch(getBookingPriceInformation(data))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({
    form: 'AboutForm',
    destroyOnUnmount: false
})(About));
