/* eslint eqeqeq: 0 */
import React from 'react';
import { Container, Form, Button, Col } from 'shards-react';
import { required, isPositiveNumber } from '../../helper/validate';
import { FormField } from '../../helper/formFields';
import { reduxForm, Field } from 'redux-form';
import { connect } from 'react-redux';
import Alert from '../common/Alert';
import Header from '../common/header';
import { treatmentType, treatmentTypeExcVAT } from '../../constants';
import { addTreatment, updateTreatment } from '../../redux/treatment.redux';
import { isEmpty, isEqual } from 'lodash';


// var default_treat_cat = [];
// var default_treat_cat_vat = [];

class AddTreatment extends React.Component {
    slot_values = [];
    addedSlots = false;
    constructor(props) {
        super(props);
        this.state = {
            slot_ids: [],
            hasTreatChange: false,
            default_treatment_category: 'Healness',
            price_per_unit_ex_vat: '-1',
        }
    }

    componentDidMount() {
        var treatment = this.props.treatmentInfo;
        if (treatment !== "") {
            var cat_ = treatment.category;

            if (cat_ !== "") {
                let filtered_VAT = treatmentTypeExcVAT.filter(el => {
                    return (el.value === cat_);
                });
                if (filtered_VAT.length > 0) {
                    if (treatment.id = 29) {
                        treatment["price_per_unit_ex_vat"] = treatment.price_per_unit_ex_vat
                        this.setState({ price_per_unit_ex_vat: treatment.price_per_unit_ex_vat });
                    }
                    else {
                        treatment["price_per_unit_ex_vat"] = filtered_VAT[0].label;
                        this.setState({ price_per_unit_ex_vat: filtered_VAT[0].label });
                    }
                }
            }


            this.setState({ default_treatment_category: cat_ });
            this.setState({ hasTreatChange: true });

            this.props.initialize({
                ...treatment,
            });

            var slot_ids = [];
            if (!isEmpty(treatment)) {
                treatment.timeSlots.forEach(slot => {
                    slot_ids.push(slot.id);
                });
                this.setState({ slot_ids })
            }
        }
    }

    componentDidUpdate(prevProps) {
        if (!isEqual(prevProps.loading, this.props.loading)) {
            if (this.props.success) {
                this.props.history.push('/treatment');
            }
        }
    }

    handleSubmit = (values, dispatch) => {
        console.log('handleSubmit');
        const { slot_ids } = this.state;
        const { id, name, description, price_per_unit, category, price_per_unit_ex_vat } = values;

        var parsed = parseInt(price_per_unit_ex_vat);

        if (id > 0) {
            console.log('handleSubmit id greater so update');

            const data = { id, name, description, price_per_unit, price_per_unit_ex_vat: parsed, category, slot_ids };
            dispatch(updateTreatment(data));
        }
        else {
            console.log('handleSubmit add');

            const data = { id, name, description, price_per_unit, price_per_unit_ex_vat: parsed, category, slot_ids };
            dispatch(addTreatment(data));
        }
    }

    getTreatment = (treatments) => {
        console.log('getTreatment: ' + treatments);
        this.setState({ default_treatment_category: treatments });
        this.setState({ hasTreatChange: true });

        let filtered_VAT = treatmentTypeExcVAT.filter(el => {
            return (el.value === treatments);
        });
        if (treatment.id = 29) {
            this.setState({ price_per_unit_ex_vat: treatment.price_per_unit_ex_vat });
        }
        else
            this.setState({ price_per_unit_ex_vat: filtered_VAT[0].label });

        var treatment = this.props.treatmentInfo;
        if (treatment !== "") {
            if (treatment.id == 29)
                treatment["price_per_unit_ex_vat"] = treatment.price_per_unit_ex_vat
            else
                treatment["price_per_unit_ex_vat"] = filtered_VAT[0].label;

            this.props.initialize({
                ...treatment,
            });
        }
        else {
            var temp = [];
            temp["price_per_unit_ex_vat"] = filtered_VAT[0].label;
            temp["category"] = treatments;

            this.props.initialize({
                ...temp,
            });
        }



        // var slot_ids = [];
        // var slot_values = [];
        // if (treatments !== null) {
        //     slots.forEach(slot => {
        //         slot_ids.push(slot.value);
        //         slot_values.push({ value: `'${slot.value}'`, label: `'${slot.label}'` })
        //     });
        // }
        // this.setState({ slot_ids })
        // this.slot_values = slot_values;
    }

    getSlot = (slots) => {
        var slot_ids = [];
        var slot_values = [];
        if (slots !== null) {
            slots.forEach(slot => {
                slot_ids.push(slot.value);
                slot_values.push({ value: `'${slot.value}'`, label: `'${slot.label}'` })
            });
        }
        this.setState({ slot_ids })
        this.slot_values = slot_values;
    }

    render = () => {
        const { t, treatmentInfo } = this.props;
        var defaultTreatmentSlots = [];
        var slotsArray = [{ value: '1', label: '15' }, { value: '2', label: '30' }, { value: '3', label: '45' }, { value: '4', label: '60' }, { value: '5', label: '90' }, { value: '6', label: '120' }]



        //////debugger;
        if (this.state.hasTreatChange == true) {

            let filtered_VAT = treatmentTypeExcVAT.filter(el => {
                return (el.value === this.state.default_treatment_category);
            });

            if (filtered_VAT.length > 0) {
                if (treatmentInfo.id == 29)

                    this.setState({ price_per_unit_ex_vat: treatmentInfo.price_per_unit_ex_vat });
                else
                    this.setState({ price_per_unit_ex_vat: filtered_VAT[0].label });
            }
            this.setState({ hasTreatChange: false });
        }

        console.log('Treatment: => ' + this.state.default_treatment_category + " => " + this.state.price_per_unit_ex_vat);

        ////////debugger;
        if (!isEmpty(treatmentInfo)) {
            //edit
            const { timeSlots } = treatmentInfo;
            timeSlots.forEach(slot => {
                defaultTreatmentSlots.push({ label: slot.time, value: slot.id });
                var i = 0;
                slotsArray.forEach(slotData => {
                    if (parseInt(slotData.value) === slot.id) {
                        slotsArray.splice(i, 1);
                        return false;
                    }
                    i = i + 1;
                });
            });
            if (this.addedSlots == false) {
                this.slot_values = defaultTreatmentSlots;
                this.addedSlots = true;
            }
        }
        else {
            //add
            var temp = [];
            temp["price_per_unit_ex_vat"] = this.state.price_per_unit_ex_vat;
            temp["category"] = this.state.default_treatment_category;

            // this.props.initialize({
            //     ...temp,
            // });
        }

        console.log('ABC: ' + this.state.default_treatment_category);

        return (
            <Container fluid className="personal-container user-container">
                <Header t={this.props.t} title={t !== null && t.translations.menu_treatment} props={this.props} />
                <Col className="col-md-12 mt-5 data-table-container">
                    <Form onSubmit={this.props.handleSubmit(this.handleSubmit)}>
                        <Alert success={this.props.success} message={this.props.message} t={this.props.t} />

                        <div className="row">
                            <div className="col-md-6 col-xs-12 float-left">
                                <Field
                                    label={t !== null ? t.translations.name : ''}
                                    type="text"
                                    name="name"
                                    classes="Field-input"
                                    validate={[required]}
                                    component={FormField}
                                    t={this.props.t}
                                />
                            </div>
                            <div className="col-md-6 col-xs-12 float-right">
                                <Field
                                    label={t !== null ? t.translations.description : ''}
                                    type="text"
                                    name="description"
                                    classes="Field-input"
                                    validate={[required]}
                                    component={FormField}
                                    t={this.props.t}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6 col-xs-12 float-left">
                                <Field
                                    label={t !== null ? t.translations.price_per_unit : ''}
                                    type="number"
                                    name="price_per_unit"
                                    classes="Field-input"
                                    validate={[isPositiveNumber]}
                                    component={FormField}
                                    t={this.props.t}
                                />
                            </div>
                            <div className="col-md-6 col-xs-12 float-left">
                                <Field
                                    label={t !== null ? t.translations.price_per_unit_ex_vat : ''}
                                    type="vat"
                                    // extra="vat"
                                    disabled
                                    // defaultValue={this.state.price_per_unit_ex_vat}
                                    // value={this.state.price_per_unit_ex_vat}
                                    name="price_per_unit_ex_vat"
                                    classes="Field-input"
                                    component={FormField}
                                    t={this.props.t}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6 col-xs-12 float-right">
                                <Field
                                    label={t !== null ? t.translations.treatment_type : ''}
                                    type="select"
                                    name="category"
                                    classes="Field-input"
                                    options={treatmentType}
                                    validate={[required]}
                                    component={FormField}
                                    onChange={this.getTreatment}
                                    t={this.props.t}
                                    defaultValue={!isEmpty(treatmentInfo) ? { label: this.state.default_treatment_category, value: this.state.default_treatment_category } : { label: this.state.default_treatment_category, value: this.state.default_treatment_category }}
                                    placeholder={t !== null ? t.translations.select_treatment_type : ''}
                                />
                            </div>
                            <div className="col-md-6 col-xs-12 float-left">
                                <Field
                                    label={t !== null ? t.translations.select_slot : ''}
                                    options={slotsArray}
                                    ismulti
                                    type="multiSelect"
                                    name="slot_id"
                                    classes="Field-input"
                                    onChange={this.getSlot}
                                    errorMessage={t.translations.slot_required}
                                    // validate={this.slot_values===[] ?[required]:''}
                                    component={FormField}
                                    defaultValue={!isEmpty(this.slot_values) ? this.slot_values : []}
                                    t={this.props.t}
                                    placeholder={t !== null ? t.translations.select_treatment_slot : ''}
                                />
                            </div>
                        </div>
                        <Button className="btn-block float-right save-btn Field-input" type="submit">{t !== null && t.translations.save_changes} {this.props.loading && <i className="fas fa-circle-notch fa-spin loader"></i>}</Button>
                    </Form>
                </Col>
            </Container >
        );
    }
};

const mapStateToProps = state => {
    return {
        t: state.accountRedux.localeInfo.t,
        loading: state.treatmentRedux.loading,
        success: state.treatmentRedux.success,
        message: state.treatmentRedux.message,
        treatmentInfo: state.treatmentRedux.treatmentInfo
    };
};

const mapDispatchToProps = dispatch => {
    return {
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({
    form: 'treatmentForm',
    destroyOnUnmount: false
})(AddTreatment));