/* eslint eqeqeq: 0 */
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Container, Button, Col } from 'shards-react';
import { getUsers, showUserDetail, deleteUser, newUser, getUserAddress, sendPush, getCustomersWithBenefits } from '../../redux/user.redux';
import Header from '../common/header';
import MaterialTable from '../common/materialTable';
import Alert from '../common/Alert';
import ConfirmationDialog from '../common/ConfirmationDialog';
import NotificationDialog from '../common/NotificationDialog';
import { CSVLink } from "react-csv";

var selectedCustomer = null;
var showErr_ = false;
var pull_ = false;

class EmployeeBenefitProgram extends Component {
    constructor(props) {
        super(props);
        this.state = {
            t: null,
            showAddress: false,
            showErr: false,
            error: "",
            openConfirmationBox: false,
            deleteItem: {},
            totalCredit: 0.0,
            showNotificationPopup: false,
            customerBenefitsInfo: [{ id: 71, full_name: 'Denise Cheng', email: '', mobile_phone: '', country: null, },
            { id: 72, full_name: 'Pehr Anders Åström', email: '', mobile_phone: '', country: null, },
            { id: 73, full_name: 'SARA BERGDAHL', email: '', mobile_phone: '', country: null }
            ]
        }
        debugger
    }
    componentDidMount() {
        this.setState({ t: this.props.t })
        this.props.getCustomersWithBenefits();
    }
    componentDidUpdate(prevProps) {

        if (prevProps.match.params !== this.props.match.params) {
            this.props.getCustomersWithBenefits();
        }


    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        console.log('componentWillReceiveProps: ' + JSON.stringify(nextProps.userAdd));
        ////debugger;
        console.log("filteredcustomer before data")
        if (nextProps.customerBenefitsInfo != undefined && nextProps.customerBenefitsInfo !== null &&
            nextProps.customerBenefitsInfo.customerBenefits != undefined && nextProps.customerBenefitsInfo.customerBenefits !== null
            && nextProps.customerBenefitsInfo !== this.props.customerBenefitsInfo) {

            this.setState({
                customerBenefitsInfo: nextProps.customerBenefitsInfo.customerBenefits,
                totalCredit: nextProps.customerBenefitsInfo.total_credit
            })
            console.log(nextProps.customerBenefitsInfo)
        }


    }


    _renderDialog = (event) => {
        this.setState({ openConfirmationBox: true, deleteItem: event })
    }
    format(value, pattern) {
        var i = 0,
            v = value.toString();
        return pattern.replace(/#/g, _ => v[i++]);
    }

    mask(str, maskChar, unmaskedLength, maskFromStart = true) {
        const maskStart = maskFromStart ? 0 : Math.max(0, unmaskedLength);
        const maskEnd = maskFromStart ? Math.max(0, str.length - unmaskedLength) : str.length;
        return str
            .split("")
            .map((char, index) => {
                if (index >= maskStart && index < maskEnd) {
                    return maskChar;
                }
                else {
                    return char;
                }
            })
            .join("");
    }

    maskStr(cardnumber) {
        var first4 = cardnumber.substring(0, 8);
        var mask = cardnumber.substring(cardnumber.length - 4, 4).replace(/\d/g, "*");
        //first4 + 
        return first4 + mask;
    }

    okTapped() {

    }

    setTextValue = (event) => {
        // console.log("setTextValue: " + JSON.stringify(event))
        this.setState({ textValue: event.target.value });
    }
    addCredit = () => {
        // this.props.history.push('/addbooking');
        this.props.history.push('/addCredit', { action: "add", data: null, t: this.state.t })
    }
    render() {
        const { t } = this.state;
        var columns = null;

        if (t !== null) {
            columns = [
                {
                    title: t.translations.customer_id,
                    field: 'id'
                },
                {
                    title: t.translations.first_name,
                    field: 'first_name'
                },
                {
                    title: t.translations.last_name,
                    field: 'last_name',
                },
                {
                    title: t.translations.company,
                    field: 'company',
                },
                {
                    title: t.translations.benefit_type,
                    field: 'benefit_type',
                },
                {
                    title: t.translations.balance,
                    field: 'balance',
                    render: rowData => <span>{rowData.credit_amount + " SEK"}</span>
                },

            ];
        }

        return (
            // <Fragment>
            <Container fluid className="personal-container user-container">
                <Header t={this.props.t}
                    addNewBtnTitle="No"
                    title={t !== null && t.translations.employee_benefit_program} />
                {/* {(this.props.user.role_type == 3 || this.props.user.role_type == 2) &&
                        <Col className="col-md-6">
                            <h5 align="center" >{"Total employee benefit credit left: " + this.state.totalCredit + " SEK"}</h5>
                            <Button className="btn-block mt-2 float-right btn-login addUser-btn addCompanySchedule" type="button" onClick={this.addCredit}>{t != null ? "Add credit" : ''}</Button>


                        </Col>
                    } */}
                {/* <Row> */}
                {/* <Col className="col-md-12"> */}
                {/* <div className="row" style={{backgroundColor:'yellow'}}> */}
                <div className="col-md-12 float-center" style={{ marginBottom: 100 }} >

                    <Col className="col-md-12 float-right">

                        <Button className="btn-block mt-2 float-right btn-login addUser-btn " type="button" onClick={this.addCredit}>{t != null ? "Add credit" : ''}</Button>
                    </Col>
                    <h5 align="center" >{"Total employee benefit credit left: " + this.state.totalCredit + " SEK"}</h5>
                </div>

                {(columns !== null && t !== null) &&
                    <MaterialTable
                        t={this.props.t}
                        columns={columns}
                        justShow={10}
                        // deleteDetail={this._renderDialog} 
                        data={this.state.customerBenefitsInfo}
                        showBookings={this.showBookings}
                        showDetail={this.showDetail}
                        loading={this.props.loading} />}
                {/* </Row> */}
            </Container >
            // </Fragment>
        )
    }
}

const mapStateToProps = state => {
    return {
        t: state.accountRedux.localeInfo.t,
        users: state.userRedux.usersList,
        user: state.accountRedux.user,
        message: state.userRedux.message,
        success: state.userRedux.success,
        loading: state.userRedux.loading,
        customerBenefitsInfo: state.userRedux.customerBenefitsInfo,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getCustomersWithBenefits: (data) => dispatch(getCustomersWithBenefits(data)),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(EmployeeBenefitProgram);
