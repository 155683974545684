import { createRoutine } from 'redux-saga-routines';
export const addTreatment = createRoutine('ADD_TREATMENT');
export const resetMessage = createRoutine('RESET_MESSAGE');
export const updateTreatment = createRoutine('UPDATE_TREATMENT');
export const getTreatments = createRoutine('GET_TREATMENTS');
export const deleteTreatment = createRoutine('DELETE_TREATMENT');
export const showTreatmentDetail = createRoutine('SHOW_Treatment_DETAIL');

const INITIAL_STATE = {
    error: false,
    success: false,
    message: '',
    loading: false,
    treatmentInfo: {},
    treatments: [],
    deleted: false,

};
export default (state = INITIAL_STATE, { type, payload }) => {
    switch (type) {
        case addTreatment.TRIGGER:
            return { ...state, loading: true, error: false, success: false, message: '', deleted: false };
        case addTreatment.SUCCESS:
            return { ...state, loading: false, error: false, success: true, message: payload, deleted: false };
        case addTreatment.FAILURE:
            return { ...state, loading: false, success: false, error: true, message: payload, deleted: false };

        case updateTreatment.TRIGGER:
            return { ...state, loading: true, error: false, success: false, message: '', deleted: false };
        case updateTreatment.SUCCESS:
            return { ...state, loading: false, error: false, success: true, message: payload, deleted: false };
        case updateTreatment.FAILURE:
            return { ...state, loading: false, success: false, error: true, message: payload, deleted: false };

        case getTreatments.TRIGGER:
            return { ...state, loading: true, error: false, success: false, locations: [], treatmentInfo: '', deleted: false };
        case getTreatments.SUCCESS:
            return { ...state, loading: false, error: false, success: true, treatments: payload, deleted: false };
        case getTreatments.FAILURE:
            return { ...state, loading: false, success: false, error: true, message: payload, deleted: false };

        case deleteTreatment.TRIGGER:
            return { ...state, loading: true, error: false, success: false, deleted: false };
        case deleteTreatment.SUCCESS:
            return { ...state, loading: false, error: false, success: true, message: payload, deleted: true };
        case deleteTreatment.FAILURE:
            return { ...state, loading: false, success: false, error: true, message: payload, deleted: false };

        case showTreatmentDetail.TRIGGER:
            return { ...state, treatmentInfo: payload };

        case resetMessage.TRIGGER:
            return { ...state, message: '' };

        default:
            return { ...state }

    }
}