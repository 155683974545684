import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Container, Row, Col } from 'shards-react';
import { getSystemLogs } from '../../redux/payment.redux';
import MaterialTable from '../common/materialTable';
import * as moment from 'moment';
import { SegmentedControl } from 'segmented-control'

class Events extends Component {
    constructor(props) {
        super(props);
        this.state = {
            t: null,
            filterState: "all"
        }
    }
    componentDidMount() {
        this.setState({ t: this.props.t });
        this.props.getSystemLogs();
    }
    componentDidUpdate(prevProps) {

    }
    showDetail = (event) => {

    }

    segmentControlTapped(val) {
        console.log('segmentControlTapped: ' + val);
        this.setState({ filterState: val });
    }

    generateColumns(t, state_) {
        var columns = null;
        if (t !== null) {
            columns = [
                {
                    title: "ID", //t.translations.status,
                    field: 'id',
                },
                {
                    title: t.translations.treatment_category,
                    field: 'type',
                },
                {
                    title: t.translations.booking_id,
                    field: 'booking_id',
                },
                {
                    title: t.translations.transaction_id,
                    field: 'transaction_id',
                },
                // {
                //     title: t.translations.state,
                //     field: 'statement',
                // },
                {
                    title: t.translations.response,
                    field: 'response',
                },
                {
                    title: t.translations.created_at,
                    field: 'created_time',
                    render: rowData => <span>{moment(new Date(0).setUTCSeconds(rowData.created_time)).tz('Europe/Stockholm').format('YYYY-MM-DD HH:mm')}</span>

                    // field: 'created_at',
                    // render: rowData => <span>{moment(rowData.created_at).format('YYYY-MM-DD HH:mm')}</span>
                }

            ];
        }

        return columns;
    }

    filterBookings(val) {
        const { payments } = this.props;

        if (payments.length > 0) {
            if (val === "register") {
                let resultant = payments.filter(item => {
                    // return item.status === 'START';
                    return item.type === 'REGISTER' || item.type === "REGISTER_SWISH";
                })


                // date and time sorting
                resultant.sort((a, b) => {
                    var c = moment(new Date(0).setUTCSeconds(a.date)).format('YYYY-MM-DD');
                    var d = moment(new Date(0).setUTCSeconds(b.date)).format('YYYY-MM-DD');

                    var cT = moment(new Date(0).setUTCSeconds(a.date)).format('HH:MM');
                    var dT = moment(new Date(0).setUTCSeconds(b.date)).format('HH:MM');

                    return c.localeCompare(d) || cT.localeCompare(dT);
                });

                //reverse arr
                // let rev = resultant.reverse();
                return resultant;
            }
            else if (val === "auth") {
                let resultant = payments.filter(item => {
                    // return item.status === 'START';
                    return item.type === "PROCESS_AUTH";
                })


                // date and time sorting
                resultant.sort((a, b) => {
                    var c = moment(new Date(0).setUTCSeconds(a.date)).format('YYYY-MM-DD');
                    var d = moment(new Date(0).setUTCSeconds(b.date)).format('YYYY-MM-DD');

                    var cT = moment(new Date(0).setUTCSeconds(a.date)).format('HH:MM');
                    var dT = moment(new Date(0).setUTCSeconds(b.date)).format('HH:MM');

                    return c.localeCompare(d) || cT.localeCompare(dT);
                });

                //reverse arr
                // let rev = resultant.reverse();
                return resultant;
            }
            else if (val === "annul") {
                let resultant = payments.filter(item => {
                    return item.type === "PROCESS_ANNUL";
                })


                // date and time sorting
                resultant.sort((a, b) => {
                    var c = moment(new Date(0).setUTCSeconds(a.date)).format('YYYY-MM-DD');
                    var d = moment(new Date(0).setUTCSeconds(b.date)).format('YYYY-MM-DD');

                    var cT = moment(new Date(0).setUTCSeconds(a.date)).format('HH:MM');
                    var dT = moment(new Date(0).setUTCSeconds(b.date)).format('HH:MM');

                    return c.localeCompare(d) || cT.localeCompare(dT);
                });

                //reverse arr
                // let rev = resultant.reverse();

                return resultant;
            }
            else if (val === "capture") {
                let resultant = payments.filter(item => {
                    return item.type === "PROCESS_CAPTURE";
                })


                // date and time sorting
                resultant.sort((a, b) => {
                    var c = moment(new Date(0).setUTCSeconds(a.date)).format('YYYY-MM-DD');
                    var d = moment(new Date(0).setUTCSeconds(b.date)).format('YYYY-MM-DD');

                    var cT = moment(new Date(0).setUTCSeconds(a.date)).format('HH:MM');
                    var dT = moment(new Date(0).setUTCSeconds(b.date)).format('HH:MM');

                    return c.localeCompare(d) || cT.localeCompare(dT);
                });

                //reverse arr
                // let rev = resultant.reverse();

                return resultant;
            }
            else if (val === "query") {
                let resultant = payments.filter(item => {
                    return item.type === 'QUERY' && item.type === "QUERY_SWISH";
                })


                // date and time sorting
                resultant.sort((a, b) => {
                    var c = moment(new Date(0).setUTCSeconds(a.date)).format('YYYY-MM-DD');
                    var d = moment(new Date(0).setUTCSeconds(b.date)).format('YYYY-MM-DD');

                    var cT = moment(new Date(0).setUTCSeconds(a.date)).format('HH:MM');
                    var dT = moment(new Date(0).setUTCSeconds(b.date)).format('HH:MM');

                    return c.localeCompare(d) || cT.localeCompare(dT);
                });

                //reverse arr
                // let rev = resultant.reverse();

                return resultant;
            }
            else if (val === "credit") {
                let resultant = payments.filter(item => {
                    return item.type === "PROCESS_CREDIT";
                });

                // date and time sorting
                resultant.sort((a, b) => {
                    var c = moment(new Date(0).setUTCSeconds(a.date)).format('YYYY-MM-DD');
                    var d = moment(new Date(0).setUTCSeconds(b.date)).format('YYYY-MM-DD');

                    var cT = moment(new Date(0).setUTCSeconds(a.date)).format('HH:MM');
                    var dT = moment(new Date(0).setUTCSeconds(b.date)).format('HH:MM');

                    return c.localeCompare(d) || cT.localeCompare(dT);
                });

                //reverse arr
                // let rev = resultant.reverse();

                return resultant;
            }
            else if (val === "callback") {
                let resultant = payments.filter(item => {
                    return item.type === "CALLBACK";
                });

                // date and time sorting
                resultant.sort((a, b) => {
                    var c = moment(new Date(0).setUTCSeconds(a.date)).format('YYYY-MM-DD');
                    var d = moment(new Date(0).setUTCSeconds(b.date)).format('YYYY-MM-DD');

                    var cT = moment(new Date(0).setUTCSeconds(a.date)).format('HH:MM');
                    var dT = moment(new Date(0).setUTCSeconds(b.date)).format('HH:MM');

                    return c.localeCompare(d) || cT.localeCompare(dT);
                });

                //reverse arr
                // let rev = resultant.reverse();

                return resultant;
            }
            else if (val === "transaction_status") {
                let resultant = payments.filter(item => {
                    return item.type === "TRANSACTION_STATUS";
                });

                // date and time sorting
                resultant.sort((a, b) => {
                    var c = moment(new Date(0).setUTCSeconds(a.date)).format('YYYY-MM-DD');
                    var d = moment(new Date(0).setUTCSeconds(b.date)).format('YYYY-MM-DD');

                    var cT = moment(new Date(0).setUTCSeconds(a.date)).format('HH:MM');
                    var dT = moment(new Date(0).setUTCSeconds(b.date)).format('HH:MM');

                    return c.localeCompare(d) || cT.localeCompare(dT);
                });

                //reverse arr
                let rev = resultant.reverse();

                return rev;
            }
            else if (val === "exception") {
                let resultant = payments.filter(item => {
                    return item.type === "EXCEPTION";
                });

                // date and time sorting
                resultant.sort((a, b) => {
                    var c = moment(new Date(0).setUTCSeconds(a.date)).format('YYYY-MM-DD');
                    var d = moment(new Date(0).setUTCSeconds(b.date)).format('YYYY-MM-DD');

                    var cT = moment(new Date(0).setUTCSeconds(a.date)).format('HH:MM');
                    var dT = moment(new Date(0).setUTCSeconds(b.date)).format('HH:MM');

                    return c.localeCompare(d) || cT.localeCompare(dT);
                });

                //reverse arr
                // let rev = resultant.reverse();

                return resultant;
            }
        }
        return payments;
    }

    render() {
        const { loading, payments } = this.props;
        console.log("payments: " + JSON.stringify(payments));
        //////debugger;
        // const { t } = this.state;
        const { t } = this.props;
        var columns = this.generateColumns(t, this.state.filterState);

        var filtered_ = this.filterBookings(this.state.filterState);

        return (
            <Fragment>
                <Container fluid className="personal-container user-container">
                    <Row>
                        <Col className="right-container col-md-12">
                            <h2 className="pb-5 col-md-8 float-left title-header">
                                {this.props.t.translations.events}
                            </h2>
                        </Col>
                    </Row>
                    <Row>
                        <SegmentedControl
                            name="filterBooking"
                            options={[
                                // { label: "All", value: "all", default: true },

                                { label: 'REGISTER', value: "register" },
                                { label: 'AUTH', value: "auth" },
                                { label: 'ANNUL', value: "annul" },
                                { label: 'CAPTURE', value: "capture", default: true },
                                { label: 'QUERY', value: "query" },
                                { label: 'CREDIT', value: "credit" },
                                { label: 'CALLBACK', value: "callback" },
                                { label: 'TRANSACTION_STATUS', value: "transaction_status" },
                                { label: 'EXCEPTION', value: "exception" },
                            ]}
                            setValue={newValue => this.segmentControlTapped(newValue)}
                            style={{ width: '90%', height: 40, color: 'black' }} // purple400
                        />
                    </Row>
                    <Row className="">
                        {(columns !== null && t != null) &&
                            <MaterialTable t={this.props.t}
                                justShow={7}
                                columns={columns}
                                data={filtered_}
                                showPayment={true}
                                hideDelete={true}
                                showDetail={this.showDetail}
                                loading={loading} />}

                        {/* <Col className="col-md-12 mt-5 data-table-container">
                            <h3 className="ml-5 under-construction">Under Construction</h3>
                        </Col> */}
                    </Row>
                </Container >
            </Fragment>
        )
    }
}

const mapStateToProps = state => {
    return {
        t: state.accountRedux.localeInfo.t,
        loading: state.paymentRedux.loading,
        success: state.paymentRedux.success,
        message: state.paymentRedux.message,
        payments: state.paymentRedux.logs,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getSystemLogs: (data) => dispatch(getSystemLogs(data))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Events);
