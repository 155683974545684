import React from 'react'
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from "shards-react";

const style = {
  frame: {
    margin: 0,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#868e96',
    backgroundColor: 'transparent',
  },
  label: {
    textAlign: 'center',
    fontSize: '12px'
  },
  leave: {
    border: '2px solid #86BEFF'
  },
  enter: {
    border: '2px dashed #86BEFF'
  },
  changeImage: {
    background: '#fffffaD0',
    border: '1px solid #222',
    boxShadow: '0 0 3px 3px #FFF'
  }
}

class ImageDropZone extends React.Component {
  constructor(props) {
    super(props)
    this.state = { image: props.initValue, error: '', over: false, deleted: false, open: false ,file:{}}
  }

  static getDerivedStateFromProps(props, state) {
    // if deleted the don't reset to image default
    if (state.deleted) {
      return null
    }

    // set image default
    if (!state.image && props.imageDefault) {
      return { image: props.imageDefault }
    } else {
      return null
    }
  }

  handleFile = event => {
    const { imagePicked, onChange } = this.props;
    //this.onLoad(event.target.files[0]);
    var image = URL.createObjectURL(event.target.files[0])
    var file = event.target.files[0]
    this.setState({ file, image });

    if (imagePicked) {
      imagePicked({ index: this.props.imageIndex, file, image })
    }
    onChange(file);
  }

  deleteFile = event => {
    const { imageDeleted, imagePicked, onChange } = this.props

    imagePicked && imagePicked({ index: this.props.imageIndex, file: null, image: null })
    imageDeleted && imageDeleted(this.props)
    onChange(null);
    this.setState({ image: null, deleted: true });

    event.preventDefault();
    event.stopPropagation();
  }

  toggle = event => {
    this.setState(prevState => {
      return { open: !prevState.open };
    });
  }

  onDragOver = event => {
    event.preventDefault()
  }

  onDragEnter = event => {
    this.setState({ over: true })
  }

  onDragLeave = event => {
    this.setState({ over: false })
  }

  onDrop = event => {
    event.preventDefault()
    let file = event.dataTransfer.files[0]
    let image = URL.createObjectURL(file)

    this.setState({
      image,
      over: false
    });

    const { imagePicked, onChange } = this.props

    if (imagePicked) {
      imagePicked({ index: this.props.imageIndex, file, image })
    }
    onChange(file);
  }

  onLoad = event => {
    const { naturalWidth, naturalHeight } = event.target
    const { imageWidth, imageHeight, anySize } = this.props

    if (!anySize && ((imageWidth && imageWidth !== naturalWidth) || (imageHeight && imageHeight !== naturalHeight))) {
      this.setState({
        error: `Wrong image dimensions ${naturalWidth}x${naturalHeight}`,
        image: null
      })
    } else {
      this.setState({ error: '' })
    }
  }

  render() {
    const { image, error, over } = this.state
    const {
      name,
      // width,
      // height = 100,
      showDeleteButton = true,
      fontSize
    } = this.props

    return (
      <div className="image-drop-zone">
        <label
          htmlFor="fileInput"
          onDrop={this.onDrop}
          onDragOver={this.onDragOver}
          onDragLeave={this.onDragLeave}
          onDragEnter={this.onDragEnter}
          style={Object.assign(
            {},
            {
              width: `170px`,
              height: `170px`,
              backgroundImage: `url(${image ? image : ''})`,
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
              backgroundSize: 'cover'
            },
            style.frame,
            over ? style.enter : style.leave
          )}>

          <div style={{ pointerEvents: 'none' }}>
            <div style={{ ...style.label, fontSize: fontSize ? `${fontSize}px` : '12px' }}>
              {!image ? <span><i className="fas fa-plus"></i> Upload an image</span> : ''}
              <div>{error}</div>
            </div>
            <input style={{ display: 'none' }} id="fileInput" name={name} type="file" value="" onChange={this.handleFile} />
          </div>
          {image && <Dropdown open={this.state.open} toggle={this.toggle} className="float-right">
            <DropdownToggle><i className="fas fa-pencil-alt"></i> Change</DropdownToggle>
            <DropdownMenu>
              <DropdownItem>Change</DropdownItem>
              {showDeleteButton && <DropdownItem onClick={this.deleteFile}>Remove</DropdownItem>}
            </DropdownMenu>
          </Dropdown>}
        </label>
      </div>
    )
  }
}

export default ImageDropZone