/* eslint eqeqeq: 0 */
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Container, Row, Col } from 'shards-react';
import { getAllPayments } from '../../redux/payment.redux';
import MaterialTable from '../common/materialTable';
import * as moment from 'moment';
import { SegmentedControl } from 'segmented-control'

class Transaction extends Component {
    constructor(props) {
        super(props);
        this.state = {
            t: null,
            filterState: "all",
            selected_row: -1,
        }
    }

    UNSAFE_componentWillMount() {
        if (this.props.location && this.props.location.state != undefined) {
            if (this.props.location.state.booking_info != undefined) {
                console.log('UNSAFE_componentWillMount: ' + JSON.stringify(this.props.location.state.booking_info));

                var data = this.props.location.state.booking_info;
                this.setState({ selected_row: data.raw });
            }
        }
    }
    componentDidMount() {
        this.setState({ t: this.props.t });
        this.props.getAllPayments();
    }
    componentDidUpdate(prevProps) {

    }
    showDetail = (event) => {

    }

    segmentControlTapped(val) {
        console.log('segmentControlTapped: ' + val);
        this.setState({ filterState: val });
    }

    generateColumns(t) {
        var columns = null;
        if (t !== null) {


            columns = [
                {
                    title: t.translations.payment_reference,
                    field: 'reference',
                },
                {
                    title: t.translations.payment_method,
                    field: 'payment_method',
                },
                {
                    title: t.translations.customer_name,
                    field: 'customer_name'
                },
                // {
                //     title: t.translations.treatment_category,
                //     field: 'type'
                // },
                {
                    title: t.translations.confirmBooking_treatment_app,
                    field: 'treatment',
                },
                {
                    title: t.translations.menu_therapist,
                    field: 'therapist',
                },
                {
                    title: t.translations.amount,
                    field: 'amount',
                    render: rowData => <span>{rowData.amount.toFixed(2) + " SEK"}</span>

                },
                {
                    title: t.translations.payment_status,
                    field: 'payment_status',
                },
                {
                    title: t.translations.transaction_id,
                    field: 'transaction_id',
                },
                // {
                //     title: t.translations.authorization_id,
                //     field: 'authorization_id',
                // },
                {
                    title: t.translations.transaction_date,
                    field: 'payment_date',
                    render: rowData => <span>{moment(new Date(0).setUTCSeconds(rowData.payment_date)).tz('Europe/Stockholm').format('YYYY-MM-DD HH:mm')}</span>
                    // render: rowData => <span>{moment(new Date(0).setUTCSeconds(rowData.payment_date)).format('YYYY-MM-DD HH:mm')}</span>
                }
            ];

        }

        return columns;
    }

    filterBookings(val) {
        const { payments } = this.props;
        ////////debugger;

        if (payments.length > 0) {
            if (val === "auth") {
                let resultant = payments.filter(item => {
                    return item.payment_status === 'success' || item.payment_status === 'Success';
                })


                // date and time sorting
                resultant.sort((a, b) => {
                    var c = moment(new Date(0).setUTCSeconds(a.date)).format('YYYY-MM-DD');
                    var d = moment(new Date(0).setUTCSeconds(b.date)).format('YYYY-MM-DD');

                    var cT = moment(new Date(0).setUTCSeconds(a.date)).format('HH:MM');
                    var dT = moment(new Date(0).setUTCSeconds(b.date)).format('HH:MM');

                    return c.localeCompare(d) || cT.localeCompare(dT);
                });


                return resultant;
            }
            else if (val === "completed") {
                let resultant = payments.filter(item => {
                    return item.payment_status === 'captured' || item.payment_status === 'Captured';
                })


                // date and time sorting
                resultant.sort((a, b) => {
                    var c = moment(new Date(0).setUTCSeconds(a.date)).format('YYYY-MM-DD');
                    var d = moment(new Date(0).setUTCSeconds(b.date)).format('YYYY-MM-DD');

                    var cT = moment(new Date(0).setUTCSeconds(a.date)).format('HH:MM');
                    var dT = moment(new Date(0).setUTCSeconds(b.date)).format('HH:MM');

                    return c.localeCompare(d) || cT.localeCompare(dT);
                });


                return resultant;
            }
            else if (val === "failed") {
                let resultant = payments.filter(item => {
                    return item.payment_status === 'captured_failed';
                });

                // date and time sorting
                resultant.sort((a, b) => {
                    var c = moment(new Date(0).setUTCSeconds(a.date)).format('YYYY-MM-DD');
                    var d = moment(new Date(0).setUTCSeconds(b.date)).format('YYYY-MM-DD');

                    var cT = moment(new Date(0).setUTCSeconds(a.date)).format('HH:MM');
                    var dT = moment(new Date(0).setUTCSeconds(b.date)).format('HH:MM');

                    return c.localeCompare(d) || cT.localeCompare(dT);
                });


                return resultant;
            }
            else if (val === "cancelled") {
                let resultant = payments.filter(item => {
                    return item.payment_status === 'annulled' || item.payment_status === 'credited';
                });

                // date and time sorting
                resultant.sort((a, b) => {
                    var c = moment(new Date(0).setUTCSeconds(a.date)).format('YYYY-MM-DD');
                    var d = moment(new Date(0).setUTCSeconds(b.date)).format('YYYY-MM-DD');

                    var cT = moment(new Date(0).setUTCSeconds(a.date)).format('HH:MM');
                    var dT = moment(new Date(0).setUTCSeconds(b.date)).format('HH:MM');

                    return c.localeCompare(d) || cT.localeCompare(dT);
                });


                return resultant;
            }
            else if (val === "all") {
                let resultant = payments;


                // date and time sorting
                resultant.sort((a, b) => {
                    var c = moment(new Date(0).setUTCSeconds(a.date)).format('YYYY-MM-DD');
                    var d = moment(new Date(0).setUTCSeconds(b.date)).format('YYYY-MM-DD');

                    var cT = moment(new Date(0).setUTCSeconds(a.date)).format('HH:MM');
                    var dT = moment(new Date(0).setUTCSeconds(b.date)).format('HH:MM');

                    return c.localeCompare(d) || cT.localeCompare(dT);
                });

                //reverse arr
                // let rev = resultant.reverse();
                return resultant;
            }
        }
        return payments;
    }

    render() {
        const { loading } = this.props;
        const { t } = this.state;
        var columns = this.generateColumns(t);
        var filtered_ = this.filterBookings(this.state.filterState);

        return (
            <Fragment>
                <Container fluid className="personal-container user-container">
                    <Row>
                        <Col className="right-container col-md-12">
                            <h2 className="pb-5 col-md-8 float-left title-header">
                                {this.props.t.translations.transaction_list}
                            </h2>
                        </Col>
                    </Row>
                    <Row>
                        <SegmentedControl
                            name="filterBooking"
                            options={[
                                // { label: "All", value: "all", default: true },
                                { label: (t != null ? t.translations.booked : ''), value: "auth" },
                                { label: (t != null ? t.translations.confirmed : ''), value: "completed", default: true },
                                { label: (t != null ? t.translations.cancelled : ''), value: "cancelled" },
                                { label: (t != null ? t.translations.failed : ''), value: "failed" }
                            ]}
                            setValue={newValue => this.segmentControlTapped(newValue)}
                            style={{ width: 600, height: 45, color: 'black' }} // purple400
                        />
                        <br /> <br />
                    </Row>
                    <Row className="">
                        {(columns !== null && t != null) &&
                            <MaterialTable t={this.props.t}
                                justShow={0}
                                columns={columns}
                                data={filtered_}
                                showPayment={true}
                                hideDelete={true}
                                selected_row_id={this.state.selected_row != undefined ? this.state.selected_row : -1}
                                showDetail={this.showDetail}
                                loading={loading} />}

                        {/* <Col className="col-md-12 mt-5 data-table-container">
                            <h3 className="ml-5 under-construction">Under Construction</h3>
                        </Col> */}
                    </Row>
                </Container >
            </Fragment>
        )
    }
}

const mapStateToProps = state => {
    return {
        t: state.accountRedux.localeInfo.t,
        loading: state.paymentRedux.loading,
        success: state.paymentRedux.success,
        message: state.paymentRedux.message,
        payments: state.paymentRedux.payments,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getAllPayments: (data) => dispatch(getAllPayments(data))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Transaction);
