import React from 'react';
import { Container, Form, Button, Col } from 'shards-react';
import { addCompany, updateCompany } from '../../redux/company.redux';
import { required, validEmail, validOrgNum, validVATNum } from '../../helper/validate';
import { FormField } from '../../helper/formFields';
import { reduxForm, Field } from 'redux-form';
import { connect } from 'react-redux';
import Alert from '../common/Alert';
import Header from '../common/header';
import { isEmpty, isEqual } from 'lodash';
import PhoneInput from 'react-phone-number-input';


class AddCompany extends React.Component {
    states = [];
    constructor(props) {
        super(props);
        this.state = {
            t: null,
            sub_amount_error_message: false,
            email_error_message: false,
        }
    }
    componentDidMount() {
        var company = this.props.companyDetail;
        if (!isEmpty(company)) {
            company.org_num = company.org_num.replace(/(\d{3})(\d{3})(\d{4})/, "$1$2-$3");
        }
        this.setState({ t: this.props.t, cp_phone: company.cp_phone });
        if (company.sub_amount === 0) {
            company.sub_amount = ''
        }
        if (company.zip === 0) {
            company.zip = ''
        }
        this.props.initialize({
            ...company,

        });
    }
    componentDidUpdate(prevProps) {
        if (!isEqual(prevProps.loading, this.props.loading)) {
            if (this.props.success) {
                this.props.history.push('/company');
            }
        }
    }
    handleSubmit = (values, dispatch) => {
        var { id, vat_num, name, zip, address, org_num, landline, cp_name
            , cp_email, sub_status, sub_amount, city } = values;
        var sub_amount_error = false;
        var email_error = false;
        // if (!isEmpty(cp_email)) {
        //     if (!cp_email.match(/[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}/)) {
        //         email_error = true;
        //         this.setState({
        //             email_error_message: true
        //         })
        //     }
        // }
        if (sub_amount < 1 || sub_amount > 100) {
            if (sub_amount === '') {
                sub_amount = 0
            }
            else {
                sub_amount_error = true;
                this.setState({ sub_amount_error_message: true })
            }
        }
        if (!sub_amount_error && !email_error) {
            var val = org_num.replace(/[^0-9]/g, "").toString();
            const { cp_phone } = this.state;
            if (id > 0) {
                const data = {
                    id, vat_Num: vat_num, name, city, zip, address, Org_Num: val, landline, Contact_Person_Name: cp_name,
                    Contact_Person_Phone: cp_phone, Contact_Person_Email: cp_email, Subsidiary_Status: sub_status,
                    Subsidiary_Amount: sub_amount
                }
                dispatch(updateCompany(data));
            }
            else {
                const data = {
                    vat_Num: vat_num, name, city, zip, address, Org_Num: val, landline, Contact_Person_Name: cp_name,
                    Contact_Person_Phone: cp_phone, Contact_Person_Email: cp_email, Subsidiary_Status: sub_status,
                    Subsidiary_Amount: sub_amount
                }
                dispatch(addCompany(data));
            }
        }
    }


    render = () => {
        const { t } = this.state;
        return (
            <Container fluid className="personal-container user-container">
                <Header t={this.props.t} title={t != null && t.translations.company} props={this.props} />
                <Col className="mt-5 col-md-12 data-table-container">
                    <Form onSubmit={this.props.handleSubmit(this.handleSubmit)} autoComplete="off">
                        <Alert success={this.props.success} message={this.props.message} t={this.props.t} />
                        <div className="row">
                            <div className="col-md-6 col-xs-12 float-left">
                                <Field
                                    label={t != null ? t.translations.vat_num : ''}
                                    type="text"
                                    name="vat_num"
                                    classes="Field-input"
                                    validate={[required, validVATNum]}
                                    component={FormField}
                                    t={this.props.t}
                                />
                            </div>
                            <div className="col-md-6 col-xs-12 float-right">
                                <Field
                                    label={t != null ? t.translations.name : ''}
                                    type="text"
                                    name="name"
                                    classes="Field-input"
                                    validate={[required]}
                                    component={FormField}
                                    t={this.props.t}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6 col-xs-12 float-left">
                                <Field
                                    label={t != null ? t.translations.city : ''}
                                    type="text"
                                    name="city"
                                    classes="Field-input"
                                    component={FormField}
                                    t={this.props.t}
                                />
                            </div>
                            <div className="col-md-6 col-xs-12 float-right">
                                <Field
                                    label={t != null ? t.translations.postal_code : ''}
                                    type="number"
                                    name="zip"
                                    classes="Field-input"
                                    maxLength={5}
                                    component={FormField}
                                    t={this.props.t}
                                />
                            </div>
                        </div>
                        <div className="row">

                            <div className="col-md-6 col-xs-12 float-left">
                                <Field
                                    label={t != null ? t.translations.street_address : ''}
                                    type="text"
                                    name="address"
                                    classes="Field-input"
                                    component={FormField}
                                    t={this.props.t}
                                />
                            </div>
                            <div className="col-md-6 col-xs-12 float-right">
                                <Field
                                    label={t != null ? t.translations.org_num : ''}
                                    type="text"
                                    name="org_num"
                                    classes="Field-input"
                                    validate={[required, validOrgNum]}
                                    component={FormField}
                                    t={this.props.t}
                                />
                            </div>
                        </div>
                        <div className="row">

                            <div className="col-md-6 col-xs-12 float-left">
                                <Field
                                    label={t != null ? t.translations.landline : ''}
                                    type="number"
                                    name="landline"
                                    classes="Field-input"
                                    component={FormField}
                                    t={this.props.t}
                                />
                            </div>
                            <div className="col-md-6 col-xs-12 float-right">
                                <Field
                                    label={t != null ? t.translations.contact_person_name : ''}
                                    type="text"
                                    name="cp_name"
                                    classes="Field-input"
                                    component={FormField}
                                    t={this.props.t}
                                />
                            </div>
                        </div>
                        <div className="row ">

                            <div className="col-md-6 col-xs-12 float-left react-phone-number">

                                <label>{t != null ? t.translations.contact_person_phone : ''}</label>
                                <PhoneInput
                                    placeholder={t != null ? t.translations.mobile : ''}
                                    value={!isEmpty(this.props.companyDetail) ? this.props.companyDetail.cp_phone : ''}
                                    className="Field-input"
                                    length={12}
                                    onChange={cp_phone => this.setState({ cp_phone })} />
                            </div>
                            <div className="col-md-6 col-xs-12 float-right">
                                <Field
                                    label={t != null ? t.translations.contact_person_email : ''}
                                    type="email"
                                    name="cp_email"
                                    classes="Field-input"
                                    validate={[validEmail]}
                                    component={FormField}
                                    t={this.props.t}
                                />
                                {this.state.email_error_message && <p className="custom-error-message"> {t != null && t.translations.email + " " + t.translations.is_not_valid_validation_message} </p>}
                            </div>
                        </div>
                        <div className="row">

                            <div className="col-md-6 col-xs-12 float-left">
                                <Field
                                    label={t != null ? t.translations.sub_amount + ' (%)' : ''}
                                    type="number"
                                    name="sub_amount"
                                    classes="Field-input"
                                    // validate={[percentageMatch]}
                                    component={FormField}
                                    t={this.props.t}
                                />
                                {this.state.sub_amount_error_message && <p className="custom-error-message"> {t != null && t.translations.percentage_match_error_message} </p>}
                            </div>
                            <div className="col-md-6 col-xs-12 float-right subsidiary-status">
                                <Field
                                    label={t != null ? t.translations.sub_status : ''}
                                    type="checkbox"
                                    name="sub_status"
                                    classes="Field-input subsidiary"
                                    component={FormField}
                                    t={this.props.t}
                                />

                            </div>
                        </div>
                        <Button className="btn-block float-right save-btn Field-input" type="submit">{t != null && t.translations.save_changes} {this.props.loading && <i className="fas fa-circle-notch fa-spin loader"></i>}</Button>
                    </Form>
                </Col>
            </Container >
        );
    }
};

const mapStateToProps = state => {
    return {
        t: state.accountRedux.localeInfo.t,
        success: state.companyRedux.success,
        message: state.companyRedux.message,
        error: state.companyRedux.error,
        companyDetail: state.companyRedux.companyInfo,
        loading: state.companyRedux.loading,
    };
};
const mapDispatchToProps = dispatch => {
    return {
    }
};
export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({
    form: 'ContactForm',
    destroyOnUnmount: false
})(AddCompany));