/* eslint eqeqeq: 0 */
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Container, Row, } from 'shards-react';
import Header from '../common/header';
import { getTreatments, showTreatmentDetail, deleteTreatment, resetMessage } from '../../redux/treatment.redux';
import MaterialTable from '../common/materialTable';
import Alert from '../common/Alert';
import ConfirmationDialog from '../common/ConfirmationDialog';

class Treatment extends Component {
    constructor(props) {
        super(props);
        this.state = {
            t: null, openConfirmationBox: false, deleteItem: {}
        }
    }
    componentDidMount() {
        this.setState({ t: this.props.t });
        this.props.getTreatments();
        setTimeout(() => {
            this.props.resetMessage()
        }, 3000);
    }
    componentDidUpdate() {
        if (this.props.deleted) {
            this.props.getTreatments();
        }
    }
    showDetail = (event) => {

        this.props.showDetail(event);
        this.props.history.push('/edittreatment');
    }

    addNewTreatment = () => {
        this.props.history.push('/addtreatment')
    }
    deleteTreatment = (event) => {
        const { deleteItem } = this.state;
        const data = { treatmentId: deleteItem.id }
        this.props.deleteTreatment(data);
        this.setState({ openConfirmationBox: false })
    }
    _renderDialog = (event) => {
        this.setState({ openConfirmationBox: true, deleteItem: event })
    }
    render() {
        const { t } = this.state;
        const { loading, success, message, deleted } = this.props;
        var columns = null;
        if (t !== null) {
            columns = [
                {
                    title: t.translations.name,
                    field: 'name'
                },
                {
                    title: t.translations.description,
                    field: 'description'
                },
                {
                    title: t.translations.price_per_unit,
                    field: 'price_per_unit',
                    render: rowData => <div>{rowData.price_per_unit} (SEK)</div>
                },
                {
                    title: t.translations.treatment_type,
                    field: 'category',
                }
            ];
        }
        return (
            <Fragment>
                <Container fluid className="personal-container user-container">
                    <Row className="">
                        <Header t={this.props.t} addNew={this.addNewTreatment} title={t != null && t.translations.treatment_list} addNewBtnTitle={t != null && t.translations.add_new_treatment} />
                        {deleted == false && <Alert success={success} message={message} t={this.props.t} loading={loading} />}
                        {this.state.openConfirmationBox &&
                            <ConfirmationDialog
                                title={this.state.t != null ? this.state.t.translations.delete_confirmation : ''}
                                bodyText={this.state.t != null && this.state.t.translations.are_you_sure_to_delete}
                                handleCancel={() => this.setState({ openConfirmationBox: false })}
                                handleOk={this.deleteTreatment} />}
                        {(columns !== null && t != null) &&
                            <MaterialTable
                                t={this.props.t}
                                justShow={0}
                                selected_row_id={-1}
                                columns={columns}
                                data={this.props.treatments}
                                showDetail={this.showDetail}
                                deleteDetail={this._renderDialog}
                                loading={this.props.loading} />}
                    </Row>
                </Container >
            </Fragment>
        )
    }
}

const mapStateToProps = state => {
    return {
        t: state.accountRedux.localeInfo.t,
        loading: state.treatmentRedux.loading,
        success: state.treatmentRedux.success,
        message: state.treatmentRedux.message,
        treatments: state.treatmentRedux.treatments,
        deleted: state.treatmentRedux.deleted,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getTreatments: (data) => dispatch(getTreatments(data)),
        deleteTreatment: (data) => dispatch(deleteTreatment(data)),
        showDetail: (data) => dispatch(showTreatmentDetail(data)),
        resetMessage: () => dispatch(resetMessage())
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Treatment);
