/* eslint eqeqeq: 0 */
import React, { Fragment } from 'react'
import { Container, Col, Row } from 'shards-react';
import { required, } from '../../helper/validate';
import { FormField } from '../../helper/formFields';
import { reduxForm, Field } from 'redux-form';
import { connect } from 'react-redux';
import MaterialTable from '../common/materialTable';
import { getInvoices } from '../../redux/account.redux';
import { isEqual } from 'lodash';
import * as moment from 'moment';

var selected_month = "Jan";
var selected_month_id = 0;
var selected_month_index = 0;
var total_revenue = 0;

var total_rev_6 = 0;
var total_rev_25 = 0;
var total_temp = 0;
var abc = 0;

var lbl1 = "";
var lbl2 = "";
var total_bookings = 0;
var total_refunded = 0;
var total_completed = 0;

class Invoice extends React.Component {
    slot_values = [];
    addedSlots = false;

    getMonth(idx) {
        // console.log("mn: " + JSON.stringify(this.props.localeInformation ))
        var objDate = new Date();
        objDate.setDate(1);
        objDate.setMonth(idx - 1);

        var locale = "sv";
        var month = objDate.toLocaleString(locale, { month: "short" });

        return month;
    }

    getAllMonths() {
        return [
            this.getMonth(1),
            this.getMonth(2),
            this.getMonth(3),
            this.getMonth(4),
            this.getMonth(5),
            this.getMonth(6),
            this.getMonth(7),
            this.getMonth(8),
            this.getMonth(9),
            this.getMonth(10),
            this.getMonth(11),
            this.getMonth(12)
        ]
    }
    constructor(props) {
        super(props);
        this.state = {
            slot_ids: [],
            // selected_month: "Apr",
            selected_month_id: (new Date()).getMonth(),
            selected_month_index: 0,
            total_revenue: 0,
            total_paid: 0,
            total_vat: 0,

            total_rev_6: 0,
            total_rev_25: 0,
            total_vat_6: 0,
            total_vat_25: 0,

            lbl1: "",
            lbl2: "",
            total_revenue_earned: 0,
            total_revenue_refunded: 0,

            filterState: "date",
            months: [
                { value: '0', label: this.getMonth(1) },
                { value: '1', label: this.getMonth(2) },
                { value: '2', label: this.getMonth(3) },
                { value: '3', label: this.getMonth(4) },
                { value: '4', label: this.getMonth(5) },
                { value: '5', label: this.getMonth(6) },
                { value: '6', label: this.getMonth(7) },
                { value: '7', label: this.getMonth(8) },
                { value: '8', label: this.getMonth(9) },
                { value: '9', label: this.getMonth(10) },
                { value: '10', label: this.getMonth(11) },
                { value: '11s', label: this.getMonth(12) }
            ],
            hasTreatChange: false,
            default_treatment_category: 'Healness',
            price_per_unit_ex_vat: '662.94',
        }
    }


    componentDidMount() {
        var d = new Date();
        var temp = [];
        moment.locale("sv");

        for (let index = 0; index < 12; index++) {
            // d.setMonth(d.getMonth() - index);
            // var str = moment(d).format('MMM, YY');
            var dte = moment(d).subtract(index, 'months');
            var str = dte.format('MMM YY');

            console.log('Iteration: ' + str);
            console.log('month: ' + dte.month());
            console.log('year: ' + dte.year());

            var ident = ((dte.month() + 1) * 10000) + dte.year();
            if (index === 0) {
                selected_month_id = ident;
            }
            var dict = { value: ident, label: str }
            temp.push(dict);
        }
        this.setState({ months: temp });
        console.log("temp: " + JSON.stringify(temp));
        moment.locale("en");


        // var data = { "month" : parseInt(this.state.selected_month_id) };
        var month_ = Math.round(selected_month_id / 10000);
        var year_ = selected_month_id % 10000;
        console.log("selected_month_id (componentDidMount): " + selected_month_id);
        const data = {
            month: parseInt(month_),
            year: parseInt(year_)
        }
        this.props.getInvoices(data);
    }

    minTwoDigits(n) {
        return (n < 10 ? '0' : '') + n;
    }

    componentDidUpdate(prevProps) {
        if (!isEqual(prevProps.loading, this.props.loading)) {
            if (this.props.success) {
                this.props.history.push('/treatment');
            }
        }
    }


    onChangeMonth = (month) => {
        console.log('onChangeMonth: ' + month);
        this.setState({ selected_month_id: month });
        var t = this.state.months.filter(element => {
            return element.value == month;
        })

        selected_month_id = month;
        selected_month = t[0].label;
        console.log('selected_month: ' + JSON.stringify(selected_month));


        // if (month == 0) {
        //     selected_month = this.getMonth(1) ;
        // }
        // if (month == 1) {
        //     selected_month = this.getMonth(2) ;
        // }
        // if (month == 2) {
        //     selected_month = this.getMonth(3) ;
        // }
        // if (month == 3) {
        //     selected_month = this.getMonth(4) ;
        // }
        // if (month == 4) {
        //     selected_month = this.getMonth(5) ;
        // }
        // if (month == 5) {
        //     selected_month = this.getMonth(6) ;
        // }
        // if (month == 6) {
        //     selected_month = this.getMonth(7) ;
        // }
        // if (month == 7) {
        //     selected_month = this.getMonth(8) ;
        // }
        // if (month == 8) {
        //     selected_month = this.getMonth(9) ;
        // }
        // if (month == 9) {
        //     selected_month = this.getMonth(10) ;
        // }
        // if (month == 10) {
        //     selected_month = this.getMonth(11) ;
        // }
        // if (month == 11) {
        //     selected_month = this.getMonth(12) ;
        // }

        let index = this.state.months.findIndex(x => x.value === month);
        this.setState({ selected_month_index: index });
        selected_month_index = index;


        var month_ = Math.round(month / 10000);
        var year_ = month % 10000;

        console.log("Month: " + month_);
        console.log("Year: " + year_);

        total_rev_6 = 0;
        total_rev_25 = 0;
        abc = 0;

        total_bookings = 0;
        total_completed = 0;
        total_refunded = 0;


        total_revenue = 0;

        const data = {
            month: parseInt(month_),
            year: parseInt(year_)
        }
        this.props.getInvoices(data);
    }

    processStats(invoices) {
        // var filter = t.filter(function(el) {
        //     return el.location_type == data;
        //   });
        var temp = [];

        ////debugger;

        if (invoices.length > 0) {
            var total_rev_6_ = invoices.reduce((prev, next) => prev + next.revenue_6_percent, 0);
            var total_rev_25_ = invoices.reduce((prev, next) => prev + next.revenue_25_percent, 0);
            // var total_vat_6_ = invoices.reduce((prev, next) => prev + next.vat_6_percent, 0);
            // var total_vat_25_ = invoices.reduce((prev, next) => prev + next.vat_25_percent, 0);

            var total = (total_rev_6_ + total_rev_25_);
            // var total_vat_ = (total_vat_6_ + total_vat_25_);

            debugger;
            // if (this.state.total_revenue != total) {
            total_revenue = total;

            total_rev_6 = total_rev_6_;
            total_rev_25 = total_rev_25_;
            // }

            console.log("Total Receipts: " + JSON.stringify(invoices.length));
            const unique_therapists = [...new Set(invoices.map(item => item.therapist_id))];
            console.log("unique_therapists: " + JSON.stringify(unique_therapists.length));

            unique_therapists.forEach(element => {
                // therapist based bookings
                var filter_ = invoices.filter(function (el) {
                    return el.therapist_id == element;
                });
                console.log("therapist based bookings: " + JSON.stringify(filter_.length));

                //unique dates
                const unique_dates = [...new Set(filter_.map(item => item.date))];
                console.log("Unique Dates: " + JSON.stringify(unique_dates));
                unique_dates.forEach(date => {

                    var sum = 0;
                    //date wise check
                    var filter_b = filter_.filter(function (el) {
                        var cond = (el.date == date && el.therapist_id == element);
                        if (cond) {
                            sum += el.slot_time;
                        }
                        return cond;
                    });

                    console.log("filter_b: " + JSON.stringify(filter_b));


                    //////debugger;
                    var result = [];
                    if (sum >= 180) { // 3 or 2.75
                        result = filter_b.map((el) => {
                            el["isMoreThanThreeHrs"] = true;
                            return el;
                        })
                        // result = filter_b.map(function(el) {
                        //     el["isMoreThanThreeHrs"] = true;
                        //     return el;
                        // });
                    }
                    else {
                        result = filter_b.map((el) => {
                            el["isMoreThanThreeHrs"] = false;
                            return el;
                        })
                    }
                    temp = temp.concat(result);

                    // temp.push(result);
                });

            });

            console.log("temp: " + JSON.stringify(temp.length));
            temp.forEach(element => {
                console.log("BID: " + JSON.stringify(element["booking_id"]));

            });

            var temp_data = [];

            total_revenue = 0;

            total_rev_6 = 0;
            total_rev_25 = 0;


            total_temp = 0;

            total_bookings = temp.length;


            lbl1 = "Total Bookings: " + temp.length;
            console.log(lbl1);
            var refund_d = temp.filter(function (el) {
                return el["refund"] == true;
            });
            var completed_d = temp.filter(function (el) {
                return el["refund"] == false;
            });

            total_completed = completed_d.length;
            total_refunded = refund_d.length;
            lbl2 = "Completed: " + completed_d.length + "     Refunded: " + refund_d.length;
            console.log(lbl2);

            var my_temp_1 = 0;
            abc = 0;
            for (var i = 0, len = temp.length; i < len; i++) {
                var main = { ...temp[i] };
                var obj = { ...temp[i] };
                var obj1 = { ...temp[i] };



                obj1["is_refunded"] = false;
                temp_data.push(obj1);

                my_temp_1 += obj1["total_payable"];
                console.log("Booking ID: " + obj["booking_id"] + "      total_payable: " + obj1["total_payable"] + "    refund: " + obj1["refund"]);

                if (main.refund) {
                    obj["is_refunded"] = true;
                    temp_data.push(obj);


                    total_rev_25 -= obj1["revenue_25_percent"];
                    total_rev_6 -= obj1["revenue_6_percent"];

                    // total_vat -= (obj1["vat_6_percent"] + obj1["vat_25_percent"]);

                    total_revenue -= (obj1["revenue_6_percent"] + obj1["revenue_25_percent"]);
                    // total_paid -= obj1["total_payable"];


                    // total_revenue_refunded += obj["revenue_25_percent"] + obj["revenue_6_percent"];
                }
                else {

                    console.log("Not refunded: " + obj1["id"])


                    total_rev_25 += obj1["revenue_25_percent"];
                    total_rev_6 += obj1["revenue_6_percent"];

                    console.log("revenue_6_percent: " + obj1["revenue_6_percent"])
                    console.log("revenue_25_percent: " + obj1["revenue_25_percent"])

                    abc += (obj1["revenue_6_percent"] + obj1["revenue_25_percent"]);

                    // total_vat += (obj1["vat_6_percent"] + obj1["vat_25_percent"]);

                    total_revenue += (obj1["revenue_6_percent"] + obj1["revenue_25_percent"]);
                    // total_paid += obj1["total_payable"];

                    // total_revenue_earned += obj["revenue_25_percent"] + obj["revenue_6_percent"];
                }

                console.log("Total Paid: " + my_temp_1);

                total_temp += obj1["revenue_25_percent"] + obj1["revenue_6_percent"];
                console.log("Total R12: " + total_temp + " = " + total_revenue);
                console.log("Total Rev: " + total_rev_25 + " - " + total_rev_6 + " - " + abc);

                // console.log("Booking ID: " + obj["booking_id"] + "      Total Paid: " + total_paid + "      Refund: " + obj1["refund"]);
                // var sum1 = 0;
                // var t1 = temp.filter(function(el) {
                //     var main_ = { ...el };
                //     sum1 += main_["total_payable"];
                //     return true ;
                // });
                // console.log("Booking ID: " + obj["booking_id"] + "      Total Paid: " + total_paid + "      Refund: " + obj1["refund"]);

            }

        }

        return temp_data;

    }

    segmentControlTapped(val) {
        console.log('segmentControlTapped: ' + val);
        this.setState({ filterState: val });
    }

    generateColumns(t) {
        var bookingColumns = null;
        console.log("generateColumns");

        if (t != null) {
            if (this.state.filterState === "therapist") {
                bookingColumns = [
                    {
                        title: t.translations.staff,
                        field: 'therapist_name',
                        render: rowData => <span>{(rowData.therapist_name ? (rowData.revenue_6_percent + rowData.revenue_25_percent) * 0.70 : (rowData.revenue_6_percent + rowData.revenue_25_percent) * 0.65).toFixed(2) + " SEK"}</span>
                    },
                    {
                        title: t.translations.schedule_date,
                        field: 'date',
                        render: rowData => <span>{rowData.date + " " + rowData.time}</span>
                    },
                    {
                        title: t.translations.select_slot,
                        field: 'slot_time',
                        render: rowData => <span>{rowData.slot_time}</span>
                    },
                    {
                        title: t.translations.customer_name,
                        field: 'customer_name'
                    },
                    {
                        title: t.translations.confirmBooking_treatment_app,
                        field: 'treatment_name'
                    },
                    {
                        title: t.translations.location,
                        field: 'location'
                    },
                    {
                        title: t.translations.revenue_6_percent,
                        field: 'total_revenue_6',
                        render: rowData => <span>{(rowData != undefined ? (rowData.is_refunded ? "-" : "") + (rowData.revenue_6_percent).toFixed(2) : (total_rev_6).toFixed(2) + " SEK")}</span>
                    },
                    {
                        title: t.translations.revenue_25_percent,
                        field: 'total_revenue_25',
                        render: rowData => <span>{(rowData != undefined ? (rowData.is_refunded ? "-" : "") + (rowData.revenue_25_percent).toFixed(2) : (total_rev_25).toFixed(2) + " SEK")}</span>
                    },
                    {
                        title: t.translations.total_revenue,
                        field: 'revenue',
                        render: rowData => <span>{(rowData.isMoreThanThreeHrs ? (rowData.revenue_6_percent + rowData.revenue_25_percent) * 0.70 : (rowData.revenue_6_percent + rowData.revenue_25_percent) * 0.65).toFixed(2) + " SEK"}</span>
                    },
                ];
            }
            else {
                bookingColumns = [
                    {
                        title: t.translations.booking_id,
                        field: 'booking_id'
                    },
                    {
                        title: t.translations.payment_method,
                        field: 'payment_method'
                    },
                    {
                        title: t.translations.schedule_date,
                        field: 'date',
                        render: rowData => <span>{rowData.date + " " + rowData.time}</span>
                    },
                    {
                        title: t.translations.select_slot,
                        field: 'slot_time',
                        // render: rowData => <span>{ rowData.slot_time   }</span>
                    },
                    {
                        title: t.translations.staff,
                        field: 'therapist_name',
                        // render: rowData => <span>{(rowData != undefined ? rowData.therapist_name :  "123")}</span>
                    },
                    {
                        title: t.translations.customer_name,
                        field: 'customer_name'
                    },
                    {
                        title: t.translations.confirmBooking_treatment_app,
                        field: 'treatment_name'
                    },
                    {
                        title: t.translations.location,
                        field: 'location'
                    },
                    {
                        title: t.translations.revenue_6_percent,
                        field: 'total_revenue_6',
                        render: rowData => <span>{(rowData != undefined ? (rowData.is_refunded ? "-" : "") + (rowData.isMoreThanThreeHrs ? (rowData.revenue_6_percent) * 0.70 : (rowData.revenue_6_percent) * 0.65).toFixed(2) : " SEK")}</span>
                    },
                    {
                        title: t.translations.revenue_25_percent,
                        field: 'total_revenue_25',
                        render: rowData => <span>{(rowData != undefined ? (rowData.is_refunded ? "-" : "") + (rowData.isMoreThanThreeHrs ? (rowData.revenue_25_percent) * 0.70 : (rowData.revenue_25_percent) * 0.65).toFixed(2) : " SEK")}</span>
                    },
                    {
                        title: t.translations.therapist_net_revenue,
                        field: 'revenue',
                        // render: rowData => <span>{(rowData != undefined ?((rowData.id > 1000) ? "-" : "") + (rowData.total_payable).toFixed(2) + " SEK" : "0.00 SEK" )}</span>
                        render: rowData => <span>{rowData != undefined ? ((rowData.is_refunded ? "-" : "") + (rowData.isMoreThanThreeHrs ? (rowData.revenue_6_percent + rowData.revenue_25_percent) * 0.70 : (rowData.revenue_6_percent + rowData.revenue_25_percent) * 0.65).toFixed(2) + " SEK") : ""}</span>
                    },
                ];
            }

        }

        return bookingColumns;
    }

    filterInvoices(val) {
        const { invoices } = this.props;
        var invoices_ = invoices;

        var invoices_temp = [];
        //////debugger;

        if (invoices_.length > 0) {
            if (val === "date") {
                const unique_therapists = [...new Set(invoices_.map(item => item.therapist_id))];
                var temp = [];
                unique_therapists.forEach(element => {
                    var filter_ = invoices_.filter(function (el) {
                        return el.therapist_id == element;
                    });
                    // const unique_dates = [...new Set(filter_.map(item => item.date))];

                    let map = filter_.reduce((prev, next) => {
                        if (next.date in prev) {
                            prev[next.date].vat_25_percent += next.vat_25_percent;
                            prev[next.date].vat_6_percent += next.vat_6_percent;
                            prev[next.date].slot_time += next.slot_time;
                        }
                        else {
                            prev[next.date] = next;
                        }
                        return prev;
                    }, {});

                    const values = Object.values(map);

                    temp += values;
                });

                invoices_temp = temp;//[invoices_[0]];

            }
            else {
                invoices_temp = invoices_;
            }
        }


        return invoices_temp;
    }

    showMonthReport = () => {
        // if (this.props.user.role_type === 3) {
        //     this.setState({ showLocationDropDown: true });
        // const data1 = { therapist_id: -1 }
        // this.props.getALLLocationsForTherapist(data1)
        // }
        // else {
        //     this.props.newLocation();
        //     this.props.history.push('/addlocation')
        // }
    }

    showVATReport = (event) => {
        this.props.history.push('/vat_report');
    }

    render = () => {
        const { t, invoices, loading, } = this.props;
        var bookingColumns = this.generateColumns(t);
        // var filtered_invoices = this.filterInvoices(this.state.filterState);
        var processed = this.processStats(invoices);
        var show_selected_month = [{ label: selected_month, value: selected_month_id }];
        console.log("show_selected_month: " + JSON.stringify(show_selected_month));


        // var ffdate = new Date(2020, selected_month_id, 1);  // 2009-11-10
        // const month = ffdate.toLocaleString('default', { month: 'long' });

        var month_ = Math.round(selected_month_id / 10000);
        var year_ = selected_month_id % 10000;
        var ffdate = new Date(year_, month_, 1);  // 2009-11-10
        var dte = moment(ffdate);
        var month = dte.format('MMM YY');

        //////debugger;

        return (
            <Fragment>
                <Container fluid className="personal-container user-container">
                    {/* <Header
                    t={this.props.t}
                    title={t != null && t.translations.invoice_list}
                    addNewBtnTitle={"No"}
                    addNew={this.showMonthReport}
                    props={this.props}/> */}
                    <Row>
                        <Col className="invoice-right-container col-md-12">
                            <h2 className="pb-5 col-md-8 float-left title-header">
                                {this.props.t.translations.invoice_list}
                            </h2>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="invoice-right-container col-md-12">
                            <h6 className="pb-5 col-md-8 float-left title-header">
                                {this.props.t.translations.invoices_report_detail}
                            </h6>
                        </Col>
                    </Row>
                    {/* <Header
                    t={this.props.t}
                    title={t != null && t.translations.invoice_list}
                    props={this.props} />
                <Row>
                    <Col className="col-md-12">
                        <Button className="btn-block mt-2 float-right btn-login addUser-btn addCompanySchedule" type="button" onClick={this.showVATReport}>{'VAT Report'}</Button>
                    </Col>
                </Row> */}

                    <Row>
                        <Col className="col-md-6 mt-5">
                            {/* <div className="row"> */}
                            {/* <div className="col-md-12 col-xs-12 float-center"> */}
                            <Field
                                label={t != null ? t.translations.select_month : ''}
                                type="select"
                                name="month"
                                classes="Field-input"
                                options={this.state.months}
                                validate={[required]}
                                component={FormField}
                                onChange={this.onChangeMonth}
                                t={this.props.t}
                                defaultValue={this.state.months[selected_month_index]}
                                placeholder={t != null ? t.translations.month : ''}
                            />
                            {/* </div> */}
                            {/* </div> */}
                        </Col>
                    </Row>
                    <Row>
                        <Col className="col-md-12">
                            <div className="row">
                                <div className="col-md-12 col-xs-12 float-left" style={{ paddingLeft: 40 }}>
                                    <h6 align="left">{t.translations.total_bookings + ": " + total_bookings}</h6>
                                    <h6 align="left">{t.translations.completed_bookings + ": " + total_completed}</h6>
                                    <h6 align="left">{t.translations.refunded_bookings + ": " + total_refunded}</h6>

                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12 col-xs-12 float-center" style={{ paddingLeft: 40 }}>
                                    <h6 align="left">{t.translations.invoice_lbl_2 + ": " + abc.toFixed(2) + " SEK"}</h6>
                                </div>
                            </div>
                            {/* <div className="row">
                            <div className="col-md-12 col-xs-12 float-center">
                            <h6 align="center">{t.translations.revenue_6_percent + ": " + total_rev_6.toFixed(2) + " SEK" }</h6>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12 col-xs-12 float-center">
                            <h6 align="center">{t.translations.revenue_25_percent + ": " + total_rev_25.toFixed(2) + " SEK"}</h6>
                            </div>
                        </div> */}
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            {/* <Row className=""> */}
                            <MaterialTable t={this.props.t}
                                justShow={8}
                                showExpand={this.state.filterState === "therapist"}
                                selected_month={month}
                                columns={bookingColumns}
                                data={processed}
                                loading={loading}
                                view={true} />
                            {/* </Row> */}
                        </Col>
                    </Row>
                </Container >
            </Fragment>
        );
    }
};

const mapStateToProps = state => {
    return {
        t: state.accountRedux.localeInfo.t,
        // loading: state.accountRedux.loading,
        // success: state.accountRedux.success,
        // message: state.accountRedux.message,
        invoices: state.accountRedux.invoices
    };
};
const mapDispatchToProps = dispatch => {
    return {
        getInvoices: (data) => dispatch(getInvoices(data)),
    }
};
export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({
    form: 'treatmentForm',
    destroyOnUnmount: false
})(Invoice));
