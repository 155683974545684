import { all } from 'redux-saga/effects';
import API from '../services/Api';
import { BASEURL } from '../constants/urls';
import initCompanySaga from './company.saga';
import initAccountSaga from './account.saga';
import initUserSaga from './user.saga';
import initStaffSaga from './staff.saga';
import initLocationSaga from './location.saga';
import initPersonalSaga from './personal.saga';
import initTreatmentSaga from './treatment.saga';
import initTherapistSaga from './therapist.saga';
import initStartUpSaga from './startup.saga';
import initPaymentSaga from './payment.saga';
import initBookingSaga from './booking.saga';
import initDashboardSaga from './dashboard.saga';

const { create } = API;
export const api = create(BASEURL);

export default function* root() {
   yield all([
      // Start Up
      ...initStartUpSaga(api),

      // Personal Api
      ...initPersonalSaga(api),

      // Company Api
      ...initCompanySaga(api),

      // Account Api
      ...initAccountSaga(api),

      // User Api
      ...initUserSaga(api),

      // Staff Api
      ...initStaffSaga(api),

      // Location Api
      ...initLocationSaga(api),

      // Treatment Api
      ...initTreatmentSaga(api),

      // Therapist Api
      ...initTherapistSaga(api),

      // Payment Api
      ...initPaymentSaga(api),

      // Booking Api
      ...initBookingSaga(api),

      // Dashboard Api
      ...initDashboardSaga(api),
   ]);
};
