/* eslint eqeqeq: 0 */
// @flow
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { guid, getLast, getFirst } from './helpers.js';
import Rdate from 'react-datetime';
import './reactAgendaCtrl.css';
import t from "../../locales/sv/Healness_sv.json";

var now = new Date();


export default class ReactAgendaCtrl extends Component {
  constructor() {
    super();
    //console.log('constructor - ReactAgendaCtrl');

    this.state = {
      editMode: false,
      showCtrl: false,
      multiple: {},
      name: '',
      loc: '',
      // classes: 'priority-1',
      classes: 'color-1',
      startDateTime: now,
      endDateTime: now,
      selected_type: "",
      selected_loc: "",
      selected_loc_id: "",
      selected_therapist: "",
      selected_type_: "",
      locChanged: false,
      typeChanged: false,
      therapistChanged: false,
    }
    this.handleDateChange = this.handleDateChange.bind(this)
    this.addEvent = this.addEvent.bind(this)
    this.updateEvent = this.updateEvent.bind(this)
    this.dispatchEvent = this.dispatchEvent.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleEdit = this.handleEdit.bind(this)
    // this.onClose = this.onClose.bind(this)

  }

  componentDidMount() {

    if (this.props.itemColors) {
      this.setState({ classes: Object.keys(this.props.itemColors)[0] })

    }

    //console.log('componentDidMount: ' + this.state.classes);
    //console.log('ReactAgendaCtrl componentDidMount - selectedCells: ' + JSON.stringify(this.props.selectedCells));

    if (this.props.selectedCells) {

      if (this.props.selectedCells[0] != undefined && this.props.selectedCells[0] !== null) {
        if (this.props.selectedCells[0].name === 'healness_locations') {
          this.setState({ classes: Object.keys(this.props.itemColors)[0] })
        }
        else if (this.props.selectedCells[0].name === 'private') {
          this.setState({ classes: Object.keys(this.props.itemColors)[2] })
        }
        else {
          this.setState({ classes: Object.keys(this.props.itemColors)[1] })
        }
      }


      // this.setState({
      //   selected_type : this.props.selectedCells[0].name 
      // },() => {
      //     //console.log('HELLO!: ' + this.state.selected_type);
      //     this.setState({selected_type: this.props.selectedCells[0].name})

      // })
      // this.setState({selected_type: this.props.selectedCells[0].name})

      // //console.log('Hello: '+ this.state.selected_type + " " + this.props.selectedCells[0].name);

    }

    setTimeout(function () {
      if (this.refs.eventName) {
        this.refs.eventName.focus()
      }

    }.bind(this), 50);

    if (!this.props.selectedCells) {
      //console.log('Level1');
      let start = now
      let endT = moment(now).add(60, 'Minutes');
      return this.setState({ editMode: false, name: '', startDateTime: start, endDateTime: endT });
    }


    if (this.props.selectedCells && this.props.selectedCells[0] && (this.props.selectedCells[0]._id || this.props.selectedCells[0].id)) {
      //console.log('Level2');

      // let start = moment.utc(this.props.selectedCells[0].startDateTime);
      // let endT = moment.utc(this.props.selectedCells[0].endDateTime);

      let start_main_ = this.props.selectedCells[0].startDateTime;      // en
      var end_main_ = this.props.selectedCells[0].endDateTime;      // en


      let start_str = start_main_.toString()
      var end_str = end_main_.toString()

      // var split1 = start_str.split('T').join(' '); //2020-02-17T19:00:00
      var sdateObj = new Date(start_str);
      let start = moment(sdateObj);

      // var split2 = end_str.split('T').join(' '); //2020-02-17T19:00:00
      var edateObj = new Date(end_str);
      var end = moment(edateObj);


      let startM = moment.tz(start, "Europe/Stockholm");
      let endTM = moment.tz(end, "Europe/Stockholm");


      return this.setState({ editMode: true, name: this.props.selectedCells[0].name, loc: this.props.selectedCells[0].loc, classes: this.props.selectedCells[0].classes, startDateTime: startM, endDateTime: endTM });

    }


    if (this.props.selectedCells && this.props.selectedCells.length === 1) {
      //console.log('Level3: '+ JSON.stringify(this.props.selectedCells));


      // let locale_ =  moment.locale();   
      var first_ = this.props.selectedCells[0];      // en

      var split1 = first_.split('T').join(' '); //2020-02-17T19:00:00
      var dateObj = new Date(split1);
      let start = moment(dateObj);
      // var endT1 = moment(dateObj).add(60, 'minutes');
      var endT = start.clone();
      endT.add(60, "minutes");


      let startM = moment.tz(start, "Europe/Stockholm");
      let endTM = moment.tz(start, "Europe/Stockholm").add(60, 'minutes');

      // let start = moment(split1);
      // let endT = moment(first_).add(2, 'minutes');

      //console.log('Level3: Start'+ JSON.stringify(start));
      //console.log('Level3: End'+ JSON.stringify(endT));

      //console.log('Level3_: Start'+ JSON.stringify(startM));
      //console.log('Level3_: End'+ JSON.stringify(endTM));

      return this.setState({ editMode: false, name: this.props.selectedCells[0].name, loc: this.props.selectedCells[0].loc, startDateTime: startM, endDateTime: endTM, selected_type: this.props.selectedCells[0].name, classes: 'color-1' });
    }

    if (this.props.selectedCells && this.props.selectedCells.length > 0) {
      //console.log('Level4');

      let start = moment(getFirst(this.props.selectedCells));
      let endT = moment(getLast(this.props.selectedCells)) || now;
      this.setState({ editMode: false, name: '', loc: '', startDateTime: start, endDateTime: endT });
    }

    // if (this.state.editMode) {
    //   this.setState({ selected_loc: this.state.name});
    // }
  }

  handleChange(event) {
    if (event.target.tagName === 'BUTTON') {
      event.preventDefault();
    }

    var data = this.state;
    data[event.target.name] = event.target.value;

    this.setState(data);
  }

  handleDateChange(ev, date) {
    var endD = moment(this.state.endDateTime)
    var data = this.state;
    data[ev] = date;

    if (ev === 'startDateTime' && endD.diff(date) < 0) {
      data['endDateTime'] = moment(date).add(60, 'minutes');
    }

    this.setState(data);

  }


  dispatchEvent(obj) {
    //console.log('dispatchEvent: ' + JSON.stringify(obj));

    var newAdded = []
    var items = this.props.items;
    if (obj['multiple']) {
      var array = obj['multiple']
      Object.keys(array).forEach(function (key) {
        var newAr = array[key].filter(function (val, ind) {
          return array[key].indexOf(val) === ind;
        })
        let start = newAr[0];
        var endT = newAr[newAr.length - 1] || now;
        var lasobj = {
          _id: guid(),
          name: obj.name,
          loc_name: obj.loc,
          loc: obj.loc_id,
          startDateTime: new Date(start),
          endDateTime: new Date(endT),
          classes: obj.classes
        }
        items.push(lasobj)
        newAdded.push(lasobj)
      });
      return this.props.Addnew(items, newAdded)
    }

    obj._id = guid();
    items.push(obj)
    this.props.Addnew(items, obj)
  }

  addEvent(e) {
    // if (this.state.name.length < 1) {
    //   return;
    // }

    //console.log('addEvent Data: ' + this.state.selected_type_ + " = " + this.state.selected_loc + " = " + this.state.selected_loc_id  + " = " + this.state.selected_therapist);
    console.log('addEvent Data: ' + this.state.startDateTime + " = " + this.state.endDateTime);

    if (this.props.selectedCells && this.props.selectedCells.length > 0) {

      var obj = this.props.selectedCells.reduce((r, v, i, a, k = v.substring(0, 10)) => ((r[k] = r[k] || []).push(v), r), {});

      if (Object.values(obj).length > 1) {
        let newObj = {
          name: this.state.selected_type_,
          loc: this.state.selected_type_ === 'private' ? '0' : this.state.selected_loc_id,
          loc_name: this.state.selected_type_ === 'private' ? 'Private' : this.state.selected_loc,
          startDateTime: new Date(this.state.startDateTime),
          endDateTime: new Date(this.state.endDateTime),
          classes: this.state.classes,
          multiple: obj
        }

        //console.log('addEvent: 2 id ' + this.state.selected_loc_id);
        //console.log('addEvent: 1 ' + JSON.stringify(newObj));
        return this.dispatchEvent(newObj);

      }

    }

    let newObj = {
      name: this.state.selected_type_,
      loc: this.state.selected_type_ === 'private' ? '0' : this.state.selected_loc_id,
      loc_name: this.state.selected_type_ === 'private' ? 'Private' : this.state.selected_loc,
      startDateTime: new Date(this.state.startDateTime),
      endDateTime: new Date(this.state.endDateTime),
      classes: this.state.classes
    }

    //console.log('addEvent: 2 id ' + this.state.selected_loc_id);
    //console.log('addEvent: 2 ' + JSON.stringify(newObj));
    this.dispatchEvent(newObj);
  }

  updateEvent(e) {
    //console.log('updateEvent: ' + this.props.selectedCells[0].id);
    //console.log('updateEvent Data: ' + this.state.startDateTime + " = " + this.state.endDateTime);

    if (this.props.selectedCells[0].id && this.props.items) {

      let newObj = {
        id: this.props.selectedCells[0].id,
        name: this.state.selected_type_,
        loc: this.state.selected_type_ === 'private' ? '0' : this.state.selected_loc_id,
        loc_name: this.state.selected_type_ === 'private' ? 'Private' : this.state.selected_loc,
        startDateTime: new Date(this.state.startDateTime),
        endDateTime: new Date(this.state.endDateTime),
        classes: this.state.classes
      }
      var items = this.props.items
      for (var i = 0; i < items.length; i++) {
        if (items[i].id === newObj.id)
          items[i] = newObj;
      }
      if (this.props.edit) {
        this.props.edit(items, newObj);
      }

    }

  }


  handleSubmit(e) {
    console.log('handleSubmit:' + e.target.value);

    e.preventDefault();
    this.addEvent(e);
  }

  handleEdit(e) {
    //console.log('handleEdit: ' + e);
    e.preventDefault();
    this.updateEvent(e);
  }

  createSelectItems(str) {
    let items = [];
    for (let i = 0; i <= this.props.maxValue; i++) {
      items.push(<option key={i} value={i}>{str + " " + i}</option>);
      //here I will be creating my options dynamically based on
      //what props are currently passed to the parent component
    }
    return items;
  }

  onDropdownSelected(e) {
    //console.log("THE VAL", e.target.value);
    //here you will see the current selected value of the select input
  }


  setGender(event) {
    //console.log('setGender: ' + event.target.value);
  }

  handleTypeChange = (event) => {
    //console.log('handleTypeChange: ' + event.target.value);
    this.setState({ typeChanged: true });

    if (event.target.value === 'healness_locations') {
      this.setState({ classes: Object.keys(this.props.itemColors)[0] })
    }
    else if (event.target.value === 'private') {
      this.setState({ classes: Object.keys(this.props.itemColors)[2] })
    }
    else {
      this.setState({ classes: Object.keys(this.props.itemColors)[1] })
    }
    this.setState({ selected_type_: event.target.value })
  }

  handleLocChange = (event) => {
    //console.log('handleLocChange: ' +JSON.stringify( event.target.value) );
    this.setState({ locChanged: true });

    var te1 = this.props.loc_values["office"];
    var te2 = this.props.loc_values["hotels"];
    var comb = te1.concat(te2);

    var t = this.state.selected_type_ === 'healness_locations' ? this.props.loc_values["healness_locations"] : (this.state.selected_type_ === 'private' ? this.props.loc_values["private"] : (comb));
    var filter = t.filter(function (el) {
      return el.id === event.target.value;
    });

    //console.log('handleLocChange Filtered: '+ JSON.stringify(filter));

    if (filter.length > 0) {
      var res = filter[0];

      this.setState({ selected_loc: res.name })
      this.setState({ selected_loc_id: res.id });
    }

  }

  handleTherapistChange = (event) => {
    //console.log('handleTherapistChange: ' + event.target.value )
    this.setState({ therapistChanged: true });

    this.setState({ selected_therapist: event.target.value })
  }

  typeValuesRender() {
    var arr = [
      " Healness        ",
      " Office        ",
      " Private        "
    ]

    var temp = [];
    var keys = [
      "healness_locations",
      "office",
      "private"
    ]

    console.log('typeValuesRender: ' + keys);

    // //////////debugger;
    if (this.state.editMode) {
      var selected_ = this.props.selectedCells[0];
      //console.log('typeValuesRender Selected Obj: ' + JSON.stringify(selected_));
      if (this.state.selected_type_ !== selected_.name && !this.state.typeChanged) {
        this.setState({ selected_type_: selected_.name }, function () {
          //console.log('typeValuesRender: DONE ' + this.state.selected_type_);
        });
      }
    }
    else {
      if (keys.length > 0) {
        const key = keys[0];

        if (this.state.selected_type_ === "") {
          //console.log('typeValuesRender: index 0  = ' + this.state.selected_type);
          this.setState({ selected_type_: key }, function () {
            //console.log('typeValuesRender: DONE ' + this.state.selected_type_);  
          });
        }
      }
    }

    for (let index = 0; index < keys.length; index++) {
      const key_ = keys[index];
      let val_ = arr[index];
      // if (index===0 && this.state.selected_type_==="") {
      //   //console.log('typeValuesRender: index ' + index + " = " + this.state.selected_type);

      //   this.setState({ selected_type_: key}, function() {
      //     // do something with new state
      //     //console.log('typeValuesRender: DONE ' + this.state.selected_type_);
      //     // this.setState({ selected_type: this.state.selected_type_ });

      //   });
      // }
      if (key_ === this.state.selected_type_) {
        temp.push(<input type="radio" value={key_} defaultChecked name="type" />);
      }
      else {
        temp.push(<input type="radio" value={key_} name="type" />);
      }
      temp.push(val_);
      // temp.push(<option value={key}>{val_}</option>);
    }
    // var t1 = [];
    // t1.push(<select>{temp}</select>)

    // //console.log('typeValuesRender: selected_type ' + this.state.selected_type);

    return temp;
  }

  therapistValuesRender() {
    console.log('therapistValuesRender: ' + JSON.stringify(this.props.therapists_values));
    var temp = []

    if (this.state.editMode) {
      var select = this.props.selectedCells[0];
      // if (this.state.selected_therapist !== select. && && !this.state.therapistChanged)  //therapist_name missing
    }
    else {
      if (this.state.selected_therapist === "" && this.props.therapists_values[0] != undefined) {
        this.setState({ selected_therapist: this.props.therapists_values[0].first_name + " " + this.props.therapists_values[0].last_name });
      }
    }
    for (let index = 0; index < this.props.therapists_values.length; index++) {
      const obj = this.props.therapists_values[index];
      var id_ = obj.id;
      if (obj.name === this.state.selected_therapist) {
        temp.push(<option value={id_} label={obj.first_name + " " + obj.last_name} selected>{obj.first_name + " " + obj.last_name}</option>);
      }
      else {
        temp.push(<option value={id_} label={obj.first_name + " " + obj.last_name}>{obj.first_name + " " + obj.last_name}</option>);
      }
    }
    // //console.log('locValuesRender: ' + JSON.stringify(temp));

    var t1 = [];
    t1.push(<select className="select-style">{temp}</select>)
    return t1;
  }

  locValuesRender() {
    console.log('locValuesRender: ' + JSON.stringify(this.props.loc_values));
    //console.log('Selected type: ' + this.state.selected_type_);

    // return (
    //   <select>
    //     <option value="grapefruit">Grapefruit</option>
    //     <option value="lime">Lime</option>
    //     <option defaultValue value="coconut">Coconut</option>
    //     <option value="mango">Mango</option>
    //   </select>
    // )

    var temp = []
    var te1 = this.props.loc_values["office"];
    var te2 = this.props.loc_values["hotels"];
    var comb = te1.concat(te2);

    // //console.log('COUNT: ' + JSON.stringify(comb));

    var t = this.state.selected_type_ === 'healness_locations' ? this.props.loc_values["healness_locations"] : (this.state.selected_type_ === 'private' ? this.props.loc_values["private"] : (comb));
    // var colors = this.state.selected_type_==='healness_locations' ?  Object.keys(this.props.itemColors)[0]: (this.state.selected_type_==='private' ?  Object.keys(this.props.itemColors)[2]: Object.keys(this.props.itemColors)[1] );

    if (this.state.selected_type_ !== "") {
      // //console.log('COUNT: ' + t.length);
      if (this.state.editMode) {
        var select = this.props.selectedCells[0];
        if (this.state.selected_loc !== select.loc_name && !this.state.locChanged) {
          this.setState({ selected_loc: select.loc_name });
          this.setState({ selected_loc_id: select.loc });
        }
      }
      else {
        if (t.length > 0 && this.state.selected_loc === "") {
          this.setState({ selected_loc: t[0].name });
          this.setState({ selected_loc_id: t[0].id });
        }
      }




      //console.log('Objects for ' + this.state.selected_type_ + ' => '+ JSON.stringify(t));
      //console.log('Objects Colors for ' + this.state.selected_type_ + ' => '+ JSON.stringify(this.state.classes));

      for (let index = 0; index < t.length; index++) {
        const obj = t[index];
        var id_ = obj.id;
        var room_ = (obj.room === null ? "" : " (" + obj.room + ") ");

        if (obj.name === this.state.selected_loc) {
          temp.push(<option value={id_} selected>{obj.name + room_ + id_}</option>);
        }
        else {
          temp.push(<option value={id_}>{obj.name + room_ + id_}</option>);
        }
      }
      // //console.log('locValuesRender: ' + JSON.stringify(temp));
      //console.log('Selected selected_loc: ' + this.state.selected_loc);
      //console.log('Selected selected_loc_id: ' + this.state.selected_loc_id);

      var t1 = [];
      t1.push(<select className="select-style">{temp}</select>);
    }

    return t1;
  }

  editRadioBtns() {
    //console.log('RENDER tyPE:' + this.state.selected_type_);

    var arr = [
      " Healness        ",
      " Office        ",
      " Private        "
    ]

    var keys = [
      "healness_locations",
      "office",
      "private"
    ]
    // defaultChecked
    var temp = []
    for (let index = 0; index < arr.length; index++) {
      const obj = arr[index];
      const key = keys[index];

      if (key === this.state.selected_type_) {
        temp.push(<input type="radio" value={key} defaultChecked name="type" />);
        temp.push(obj);

      }
      else {
        temp.push(<input type="radio" value={key} name="type" />);
        temp.push(obj);
      }
    }

    return temp
  }


  clickedOutside(e) {

    if ((e.key === 'Escape' || e.key === 'Esc' || e.keyCode === 27)) {
      e.preventDefault();
      this.props.clickOutside(e)
      return false;
    }

    if (this.props.clickOutside && e.target.classList.contains('modal-content')) {
      this.props.clickOutside(e)
    }

  }

  closeFunc(e) {
    console.log('closeFunc: ' + this.props.clickOutside);

    if (this.props.clickOutside) {
      this.props.clickOutside(e)
    }
  }

  // onClose(e) {
  //   e.preventDefault();

  // }

  returnNewPopup() {


    const divStyle = {};
    return (
      <div className="agendCtrls-wrapper" style={divStyle}>
        {/* <h4>Add Schedule</h4> */}
        <form onSubmit={this.handleSubmit}>
          {/* <br /><br /> */}
          <div className="agendCtrls-label-wrapper">
            <div className="agendCtrls-label-inline">
              <label>{t != null ? t.schedule_select_type : ''}</label>
              <div>
                <fieldset className="my-field" onChange={this.handleTypeChange}>
                  {this.typeValuesRender()}
                </fieldset>
              </div>
              {/* <div className="agendCtrls-dropdown"> */}
              {/* {type_values_} */}
              {/* </div> */}
            </div>
          </div>
          <div className="agendCtrls-label-wrapper">
            {this.state.selected_type_ != 'private' &&
              <div className="agendCtrls-label-inline">
                <label>{t != null ? t.schedule_select_loc : ''}</label>
                {/* <div className="agendCtrls-dropdown"> */}
                <fieldset className="my-field" onChange={this.handleLocChange}>
                  {this.locValuesRender()}
                </fieldset>
                {/* </div> */}
              </div>
            }
            {this.props.isAdmin && this.props.showTherapist && <div className="agendCtrls-label-inline">
              <label>{t != null ? t.schedule_select_therapist : ''}</label>
              {/* <div className="agendCtrls-dropdown"> */}
              <fieldset className="my-field" onChange={this.handleTherapistChange}>
                {this.therapistValuesRender()}
              </fieldset>
              {/* </div> */}
            </div>}
          </div>
          <div className="agendCtrls-timePicker-wrapper">
            <div className="agendCtrls-time-picker">
              <label >{t != null ? t.schedule_start_date : ''}</label>
              <Rdate
                // input={false}
                dateFormat={'YYYY-MM-DD'}
                timeFormat={'HH:mm'}
                value={this.state.startDateTime}
                onChange={this.handleDateChange.bind(null, 'startDateTime')}
              />
              {/* <Rdate 
            // utc={true}
            closeOnSelect={true}
            dateFormat={'YYYY-MM-DD'}
            timeFormat={'h:m'}
            value={this.state.startDateTime} 
            onChange={this.handleDateChange.bind(null, 'startDateTime')} 
            input={false} 
            viewMode="time" ></Rdate> */}
            </div>
            <div className="agendCtrls-time-picker">
              <label >{t != null ? t.schedule_end_date : ''}</label>
              <Rdate
                // input={false}
                dateFormat={'YYYY-MM-DD'}
                timeFormat={'HH:mm'}
                value={this.state.endDateTime}
                onChange={this.handleDateChange.bind(null, 'endDateTime')}
              />
              {/* <Rdate 
            // utc={true}
            closeOnSelect={true}
            dateFormat={'YYYY-MM-DD'}
            timeFormat={'h:m'}
            value={this.state.endDateTime} 
            onChange={this.handleDateChange.bind(null, 'endDateTime')} 
            input={false} 
            viewMode="time" ></Rdate> */}
            </div>
          </div>
          <br /><br />
          <div class="panel-footer row class1num">
            <div class="col-xs-6 text-left">
              <div class="previous">
                <input type="submit" id="save" value={t != null ? t.schedule_save : ''} />
              </div>
            </div>
            <div class="col-xs-6 text-right">
              <div class="next">
                <input type="submit" value={t != null ? t.cancel_forgot : ''} onClick={this.closeFunc.bind(this)} />
              </div>
            </div>
          </div>
          {/* <div className="my-btn-view">
      <button type="button" class="btn-block mt-2 float-right btn-login addUser-btn addCompanySchedule btn btn-primary" onClick={this.handleSubmit}>{t != null ? t.schedule_save : ''}</button>        
      <button type="button" class="btn-block mt-2 float-right btn-login addUser-btn addCompanySchedule btn btn-primary" onClick={this.closeFunc}>{t != null ? t.cancel_forgot : ''}</button> */}

          {/* <input type="submit" value={t != null ? t.schedule_save : ''}/> <t /><t /><t />
        <Button>Cancel</Button>
        <button type="button" class="btn-block mt-2 float-right btn-login addUser-btn addCompanySchedule btn btn-primary">Submit</button>
      <input type="reset" value={t != null ? t.cancel_forgot : ''} onclo/>

        <div className="my-btn">
        </div>
        <div className="my-btn">
        </div> */}
          {/* </div> */}
          {/* <div className="my-btn"></div> */}
          <br /><br />
        </form>
      </div>
    );

  }

  returnNewPopup1() {

    const divStyle = {};


    return (
      <div className="agendCtrls-wrapper" style={divStyle}>
        <form onSubmit={this.handleSubmit}>
          <div className="row-title">
            <label class="row-label">{t != null ? t.schedule_select_type : ''}</label>
          </div>
          <div className="row-val">
            <fieldset className="my-field" onChange={this.handleTypeChange}>
              {this.typeValuesRender()}
            </fieldset>
          </div>
          <div class="row-title">
            <label class="row-label">{t != null ? t.schedule_select_loc : ''}</label>
          </div>
          <div class="row-val">
            <fieldset className="my-field" onChange={this.handleLocChange}>
              {this.locValuesRender()}
            </fieldset>
          </div>
          <div class="row-title">
            <label class="row-label">{t != null ? t.schedule_start_date : ''}</label>
          </div>
          <div class="row-val">
            <Rdate
              // input={false}
              dateFormat={'YYYY-MM-DD'}
              timeFormat={'HH:mm'}
              value={this.state.startDateTime}
              onChange={this.handleDateChange.bind(null, 'startDateTime')}
            />
          </div>
          <div class="row-title">
            <label class="row-label">{t != null ? t.schedule_end_date : ''}</label>
          </div>
          <div class="row-val">
            <Rdate
              // input={false}
              dateFormat={'YYYY-MM-DD'}
              timeFormat={'HH:mm'}
              value={this.state.endDateTime}
              onChange={this.handleDateChange.bind(null, 'endDateTime')}
            />
          </div>
          <div class="row">
            <div class="column">
              <input type="submit" id="save" value={t != null ? t.schedule_save : ''} />
            </div>
            <div class="column">
              <input type="submit" value={t != null ? t.cancel_forgot : ''} onClick={this.closeFunc.bind(this)} />
            </div>
          </div>
        </form>
      </div>
    );

  }

  render() {

    const divStyle = {};

    if (this.state.editMode) {

      return (
        <div className="agendCtrls-wrapper" style={divStyle}>
          <form onSubmit={this.handleEdit}>
            <br /><br />
            <div className="agendCtrls-label-wrapper">
              <div className="agendCtrls-label-inline">
                <label>{t != null ? t.schedule_select_type : ''}</label>
                <div>
                  <fieldset className="my-field" onChange={this.handleTypeChange}>
                    {this.typeValuesRender()}
                  </fieldset>
                </div>

              </div>
            </div>
            <div className="agendCtrls-label-wrapper">
              {this.state.selected_type_ != 'private' &&
                <div className="agendCtrls-label-inline">
                  <label>{t != null ? t.schedule_select_loc : ''}</label>
                  {/* <div className="agendCtrls-dropdown"> */}
                  <fieldset className="my-field" onChange={this.handleLocChange}>
                    {this.locValuesRender()}
                  </fieldset>
                  {/* </div> */}
                </div>}
              {this.props.isAdmin && this.props.showTherapist && <div className="agendCtrls-label-inline">
                <label>{t != null ? t.schedule_select_therapist : ''}</label>
                {/* <div className="agendCtrls-dropdown"> */}
                <fieldset className="my-field" onChange={this.handleTherapistChange}>
                  {this.therapistValuesRender()}
                </fieldset>
                {/* </div> */}
              </div>}
            </div>
            <div className="agendCtrls-timePicker-wrapper">
              <div className="agendCtrls-time-picker">
                <label >{t != null ? t.schedule_start_date : ''}</label>
                <Rdate
                  // utc={true}
                  dateFormat={'YYYY-MM-DD'}
                  timeFormat={'h:m'}
                  value={this.state.startDateTime}
                  onChange={this.handleDateChange.bind(null, 'startDateTime')}
                  input={false}
                  viewMode="time" ></Rdate>
              </div>
              <div className="agendCtrls-time-picker">
                <label >{t != null ? t.schedule_end_date : ''}</label>
                <Rdate
                  // utc={true}
                  dateFormat={'YYYY-MM-DD'}
                  timeFormat={'h:m'}
                  value={this.state.endDateTime}
                  onChange={this.handleDateChange.bind(null, 'endDateTime')}
                  input={false}
                  viewMode="time" ></Rdate>
              </div>
            </div>

            <br /><br />
            <input type="submit" value={t != null ? t.schedule_save : ''} />
            <br />
            <input type="reset" value={t != null ? t.cancel_forgot : ''} />
            <br /><br />
          </form>
        </div>
      );

    }

    else {
      return this.returnNewPopup1()
    }
  }
}


ReactAgendaCtrl.propTypes = {
  therapists_values: PropTypes.object,
  type_values: PropTypes.object,
  loc_values: PropTypes.object,
  items: PropTypes.array,
  itemColors: PropTypes.object,
  selectedCells: PropTypes.array,
  edit: PropTypes.func,
  Addnew: PropTypes.func,
  showTherapist: PropTypes.bool,
  isAdmin: PropTypes.bool,
  clickOutside: PropTypes.func,
  viewOnly: PropTypes.string,

};

ReactAgendaCtrl.defaultProps = {
  type_values: [],
  loc_values: [],
  items: [],
  viewOnly: "schedule",
  itemColors: {},
  selectedCells: [],
  showTherapist: true,
  isAdmin: false
}
