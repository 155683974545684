/* eslint eqeqeq: 0 */
import React from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';


class PieChart extends React.Component {
    render() {
        var options = {};
        if (!isEmpty(this.props.revenueResult) && this.props.revenueResult != undefined) {
            options = {
                chart: {
                    type: 'pie'
                },
                title: {
                    text: 'Revenue'
                },

                plotOptions: {
                    pie: {
                        allowPointSelect: true,
                        cursor: 'pointer',
                        dataLabels: {
                            enabled: true,
                            // format: '<b>{point.name}</b>: { (point.percentage) :.1f}  %'
                            formatter: function () {
                                return '<b>' + this.point.name + '</b> : <b>' + (this.point.y * this.point.total_revenue / 100).toFixed(2) + '</b>';
                            }

                        }
                    }
                },

                series: [{
                    name: 'Revenue',
                    colorByPoint: true,
                    data: this.props.revenueResult
                }]
            }
        }
        return (
            <div>
                <HighchartsReact
                    highcharts={Highcharts}
                    options={options}
                />
            </div>
        )
    }
}
const mapStateToProps = state => {
    return {
        total_revenue: state.dashboardRedux.revenueResult[0],
        t: state.accountRedux.localeInfo.t,
        revenueResult: state.dashboardRedux.revenueResult
    };
};

export default connect(mapStateToProps)(PieChart);
