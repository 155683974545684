/* eslint eqeqeq: 0 */
import React, { Fragment } from 'react';
import { Container, } from 'shards-react';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import Header from '../common/header';
import * as moment from 'moment';
import MaterialTable from '../common/materialTable';
import { getBookings, } from '../../redux/booking.redux';

var bookingColumns_ = [];

class ShowBookings extends React.Component {
    personNumber = '';
    constructor(props) {
        super(props);
        this.state = {
            t: null,
            filterState: "processed",
            showDialogue: false,
            deleteItem: {},
            showDeleteBookingDialogue: false
        }
    }

    componentDidMount() {
        this.setState({ t: this.props.t });
        var user_id = this.props.location.state.user_id;

        var data = { user_id: user_id }
        this.props.getBookings(data);
    }


    segmentControlTapped(val) {
        console.log('segmentControlTapped: ' + val);
        this.setState({ schedule_view: val });
        this.setState({ filterState: val });

        // if (val === 'set_schedule'){
        //     this._openModal();
        // }
    }

    filterBookings(val) {
        const { bookings } = this.props;

        if (bookings.length > 0) {
            if (val === "processed") {
                let resultant = bookings.filter(item => {
                    return item.status !== 'Cancelled';
                })


                // date and time sorting
                resultant.sort((a, b) => {
                    var c = moment(new Date(0).setUTCSeconds(a.date)).format('YYYY-MM-DD');
                    var d = moment(new Date(0).setUTCSeconds(b.date)).format('YYYY-MM-DD');

                    var cT = moment(new Date(0).setUTCSeconds(a.date)).format('HH:MM');
                    var dT = moment(new Date(0).setUTCSeconds(b.date)).format('HH:MM');

                    return c.localeCompare(d) || cT.localeCompare(dT);
                });

                //reverse arr
                // let rev = resultant.reverse();

                return resultant;
            }
            else if (val === "cancelled") {
                let resultant = bookings.filter(item => {
                    return item.status === 'Cancelled';
                })


                // date and time sorting
                resultant.sort((a, b) => {
                    var c = moment(new Date(0).setUTCSeconds(a.date)).format('YYYY-MM-DD');
                    var d = moment(new Date(0).setUTCSeconds(b.date)).format('YYYY-MM-DD');

                    var cT = moment(new Date(0).setUTCSeconds(a.date)).format('HH:MM');
                    var dT = moment(new Date(0).setUTCSeconds(b.date)).format('HH:MM');

                    return c.localeCompare(d) || cT.localeCompare(dT);
                });

                //reverse arr
                // let rev = resultant.reverse();

                return resultant;
            }
        }
        return bookings;
    }

    generateColumns(t) {
        var bookingColumns = null;

        if (t != null) {
            // this.setState({ t: this.props.t });
            // t = this.props.t;

            bookingColumns = [
                {
                    title: t.translations.customer_name,
                    field: 'customer.first_name',
                    render: rowData => <span>{rowData.customer.first_name + " " + rowData.customer.last_name}</span>
                },
                {
                    title: t.translations.treatment_category,
                    field: 'category'
                },
                {
                    title: t.translations.confirmBooking_treatment_app,
                    field: 'treatment_name'
                },
                {
                    title: t.translations.staff,
                    field: 'therapist_name'
                },
                {
                    title: t.translations.select_slot,
                    field: 'slot_time',
                },
                {
                    title: t.translations.companyBooking_price_app,
                    field: 'price',
                    render: rowData => <span>{rowData.price}</span>
                },
                {
                    title: t.translations.schedule_date,
                    field: 'date',
                    render: rowData => <span>{moment(new Date(0).setUTCSeconds(rowData.date)).format('YYYY-MM-DD') + " " + this.minTwoDigits(Math.trunc(rowData.reserved_slot / 100)) + ":" + this.minTwoDigits(rowData.reserved_slot % 100)}</span>
                },
                {
                    title: t.translations.transaction_id,
                    field: 'transaction_id'
                },
                {
                    title: t.translations.status,
                    field: 'status'
                }
                ,
                // {
                //     title: t.translations.authorization_id,
                //     field: 'authorization_id'
                // }
            ];
        }

        return bookingColumns;
    }

    capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    minTwoDigits(n) {
        return (n < 10 ? '0' : '') + n;
    }

    render = () => {
        const { loading, bookings, } = this.props;
        const { t } = this.state;
        if (t != null) {
            bookingColumns_ = this.generateColumns(t);
        }

        //////debugger;
        var filtered_ = this.filterBookings(this.state.filterState);
        console.log('Show Booking: ' + bookings);
        console.log('Show Booking Columns: ' + bookingColumns_);


        return (
            <Fragment>
                <Container fluid className="personal-container user-container">

                    <Header t={this.props.t} title={t != null && t.translations.user} props={this.props} />
                    {/* <Row className=""> 
                    <SegmentedControl
                        name="filterBooking"
                        options={[
                            { label: "Processed", value: "processed",  default: true },
                            { label: "Cancelled", value: "cancelled" }
                        ]}
                        setValue={newValue => this.segmentControlTapped(newValue)}
                        style={{ width: 600, height: 40, color: '#0191e1' }} // purple400
                        />
                </Row> */}
                    {/* <Row className=""> */}
                    {bookingColumns_ != [] && <MaterialTable t={this.props.t}
                        justShow={4}
                        columns={bookingColumns_}
                        data={filtered_}
                        // showDetail={this.showDetail}
                        loading={loading}
                        // deleteDetail={this._renderDialog}
                        view={true} />
                    }
                    {/* </Row> */}
                </Container >
            </Fragment>
        );
    }
};

const mapStateToProps = state => {
    return {
        t: state.accountRedux.localeInfo.t,
        loading: state.bookingRedux.loading,
        success: state.bookingRedux.success,
        message: state.bookingRedux.message,
        bookings: state.bookingRedux.bookings
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getBookings: (data) => dispatch(getBookings(data))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({
    form: 'BookingForm',
    destroyOnUnmount: false
})(ShowBookings));