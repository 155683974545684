/* eslint eqeqeq: 0 */
import React from 'react';
import { Container, Form, Button, Col } from 'shards-react';
import { addLocation, updateLocation } from '../../redux/location.redux';
import { getTreatments } from '../../redux/treatment.redux';
import { required } from '../../helper/validate';
import { FormField } from '../../helper/formFields';
import { reduxForm, Field } from 'redux-form';
import { connect } from 'react-redux';
import Alert from '../common/Alert';
import { locationTypesOption } from '../../constants';
import { isEmpty } from 'lodash';
import Header from '../common/header';
import PhoneInput from 'react-phone-number-input';

class Location extends React.Component {
    without_treatment = false;
    currentLocationTypeValue_ = {};

    constructor(props) {
        super(props);
        this.state = {
            t: null,
            open: false,
            locationType: '',
            location_type: {},
            locationTypeValue: '',
            country: '',
            treatment_ids: [],
            location_treatment_ids: [],
            is_treatment_empty: false,
            currentLocationTypeValue: {}
        }
        this.toggle = this.toggle.bind(this);
    }
    UNSAFE_componentWillMount() {
        this.props.getTreatments();

        var location = this.props.locationInfo;
        if (location.zip === 0) {
            location.zip = ''
        }
        this.setState({ mobile_phone: location.mobile_phone });
        this.props.initialize({
            ...location,
        });

        if (!isEmpty(location)) {
            locationTypesOption.forEach(type => {
                // debugger;
                if (location.location_type === type.value) {
                    this.setState({ currentLocationTypeValue: [{ value: type.value, label: type.label }] });
                    this.currentLocationTypeValue_ = { value: type.value, label: type.label };
                    this.setState({ location_type: [{ value: type.value, label: type.label }] });

                }
            });
        }

        var treatmentIds = [];
        this.props.treatments.forEach(treatment => {
            treatmentIds.push(treatment.id);
        });
        if (!isEmpty(treatmentIds)) {
            if (isEmpty(this.state.treatment_ids)) {
                this.setState({ treatment_ids: treatmentIds })
            }
        }
        if (!isEmpty(this.props.locationInfo)) {
            var location_treatments = [];
            this.props.locationInfo.treatments.forEach(element => {
                location_treatments.push(element.value);
            });
            this.setState({ location_treatment_ids: location_treatments })
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props !== prevProps) {
            this.setState({
                t: this.props.t
            });
            if (!isEmpty(this.props.message)) {
                this.props.history.push('/location');
                // if (this.props.user.role_type === 3) {
                //     this.props.history.push('/location');
                // }
                // else {
                //     this.props.history.push('/company');
                // }
            }
        }
    }

    toggle() {
        this.setState(prevState => {
            return { open: !prevState.open };
        });
    }

    handleSubmit = (values, dispatch) => {
        ////debugger;
        const { role_type, } = this.props.user;
        const { t, location_treatment_ids, mobile_phone } = this.state;
        var country = t.translations.default_country;
        var { name, address, floor, room, apartment_num, zip, id, treatment_id, notes, address_lat, address_lng, city, location_type } = values;

        // debugger;
        // var therapist_id = -1;
        // if (this.props.user.role_type === 3) {
        //     therapist_id = this.props.user.id
        // }

        if (!isEmpty(location_treatment_ids)) {
            if (isEmpty(zip)) {
                if (zip > 0) {
                }
                else {
                    zip = 0;
                }
            }

            ////////debugger;
            if (id > 0) {
                let data = {
                    id, treatment_id, mobile_phone, city, address_lat, address_lng, treatment_ids: location_treatment_ids, name, address, floor, room, apartment_num, notes, country, zip, location_type: location_type, therapist_id: role_type === 3 ? this.props.user.id : 0, company_id: this.props.company.id
                }
                dispatch(updateLocation(data))
            }
            else {
                let data = {
                    name, address_lng, address_lat, city, treatment_id, mobile_phone, treatment_ids: location_treatment_ids, address, floor, room, apartment_num, notes, country, zip, location_type: location_type, therapist_id: role_type === 3 ? id : 0, company_id: this.props.company.id
                }
                dispatch(addLocation(data))
            }
        }
    }

    selectCountry(val) {
        this.setState({ country: val });
    }

    getTreatment = (treatments) => {
        var treatment_ids = [];
        treatments != null && treatments.forEach(treatment => {
            treatment_ids.push(treatment.value);
        });

        this.setState({ treatment_ids: treatment_ids })
        this.setState({ location_treatment_ids: treatment_ids })

        if (treatments === null) {
            this.setState({ is_treatment_empty: true });
            this.without_treatment = true;
            // this.props.onChange('therapistInfo.treatments',[]);
        }
    }

    getLocType = (data) => {

        locationTypesOption.forEach(type => {
            console.log("getLocType (iterate): " + JSON.stringify(type));
            if (type.value === data) {
                // d = { value: type.value, label: type.label };
                this.setState({ currentLocationTypeValue: { value: type.value, label: type.label } });
                this.currentLocationTypeValue_ = { value: type.value, label: type.label };
            }
        });

        // this.setState({ currentLocationTypeValue: d });
        // this.setState({ location_type: d });

        console.log("getLocType (currentLocationTypeValue): " + JSON.stringify(this.state.currentLocationTypeValue));
        console.log("(currentLocationTypeValue): " + JSON.stringify(this.currentLocationTypeValue_));

        // this.setState({ location_type: data });
    }

    render = () => {
        const { t, mobile_phone } = this.state;
        var treatmentList = [];
        const { treatments, locationInfo } = this.props;

        console.log('LIST123: ' + JSON.stringify(treatments));
        treatments.forEach(treatment => {
            if (treatment.active != undefined && treatment.active === true) {
                treatmentList.push({ value: treatment.id, label: treatment.name });
            }
        });
        return (
            <Container fluid className="location-container">
                <Header t={this.props.t} title={t != null && t.translations.location} props={this.props} />
                <Col className="mt-5 ml-3 col-md-12 data-table-container">
                    <Form onSubmit={this.props.handleSubmit(this.handleSubmit)}>
                        <Alert success={this.props.success} message={this.props.message} t={this.props.t} />
                        <div className="row">
                            <div className="col-md-6 col-xs-12 float-left">
                                <Field
                                    label={t != null ? t.translations.name : ''}
                                    type="text"
                                    name="name"
                                    classes="Field-input"
                                    validate={[required]}
                                    component={FormField}
                                    t={this.props.t}
                                />
                            </div>
                            <div className="col-md-6 col-xs-12 float-right">
                                <Field
                                    label={t != null ? t.translations.address : ''}
                                    type="text"
                                    name="address"
                                    classes="Field-input"
                                    validate={[required]}
                                    component={FormField}
                                    t={this.props.t}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6 col-xs-12 float-left">
                                <Field
                                    label={t != null ? t.translations.floor : ''}
                                    type="text"
                                    name="floor"
                                    classes="Field-input"
                                    component={FormField}
                                    t={this.props.t}
                                />
                            </div>
                            <div className="col-md-6 col-xs-12 float-right">
                                <Field
                                    label={t != null ? t.translations.room : ''}
                                    type="text"
                                    name="room"
                                    classes="Field-input"
                                    component={FormField}
                                    t={this.props.t}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6 col-xs-12 float-left">
                                <Field
                                    label={t != null ? t.translations.apartment_num : ''}
                                    type="text"
                                    name="apartment_num"
                                    classes="Field-input"
                                    component={FormField}
                                    t={this.props.t}
                                />
                            </div>
                        </div>
                        <div className="row">

                            <div className="col-md-6 col-xs-12 float-right">
                                <Field
                                    label={t != null ? t.translations.city : ''}
                                    type="text"
                                    name="city"
                                    classes="Field-input"
                                    component={FormField}
                                    t={this.props.t}
                                />
                            </div>
                            <div className="col-md-6 col-xs-12 float-left">
                                <Field
                                    label={t != null ? t.translations.postal_code : ''}
                                    type="number"
                                    name="zip"
                                    maxLength={5}
                                    classes="Field-input"
                                    component={FormField}
                                    t={this.props.t}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6 col-xs-12 float-right">
                                <Field
                                    label={t != null ? t.translations.latitude : ''}
                                    type="number"
                                    name="address_lat"
                                    classes="Field-input"
                                    component={FormField}
                                    t={this.props.t}
                                />
                            </div>
                            <div className="col-md-6 col-xs-12 float-left">
                                <Field
                                    label={t != null ? t.translations.longitude : ''}
                                    type="number"
                                    name="address_lng"
                                    classes="Field-input"
                                    component={FormField}
                                    t={this.props.t}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6 col-xs-12 float-left react-phone-number">

                                <label>{t != null ? t.translations.contact_person_phone : ''}</label>
                                <PhoneInput
                                    placeholder={t != null ? t.translations.mobile : ''}
                                    value={mobile_phone}
                                    className="Field-input"
                                    length={12}
                                    onChange={mobile_phone => this.setState({ mobile_phone })} />
                            </div>
                            <div className="col-md-6 col-xs-12 float-right">
                                {/* <Field
                                    label={t != null && t.translations.select_location_type}
                                    type="select"
                                    name="notif_type"
                                    options={this.state.types}
                                    classes="Field-input"
                                    validate={[required]}
                                    component={FormField}
                                    t={this.props.t}
                                /> */}
                                <Field
                                    label={t != null ? t.translations.select_location_type : ''}
                                    type="select"
                                    name="location_type"
                                    options={locationTypesOption}
                                    classes="Field-input"
                                    validate={[required]}
                                    component={FormField}
                                    t={this.props.t}
                                    onChange={this.getLocType}
                                    defaultValue={this.currentLocationTypeValue_}
                                    // //defaultValue={(!isEmpty(locationInfo.treatments)) && this.state.currentLocationTypeValue}
                                    placeholder={t != null ? t.translations.select_location_type : ''}
                                    noOptionsMessage={t != null ? t.translations.no_options : ''}
                                />
                            </div>
                        </div>
                        <div className="row">

                            <div className="col-md-6 col-xs-12 float-left">
                                <Field
                                    label={t != null ? t.translations.select_treatment : ''}
                                    type="multiSelect"
                                    ismulti
                                    onChange={this.getTreatment}
                                    name="treatment_id"
                                    classes="Field-input-loc"
                                    // classes="Field-input"
                                    options={treatmentList}
                                    component={FormField}
                                    t={this.props.t}
                                    defaultValue={(!isEmpty(locationInfo.treatments) && !this.state.is_treatment_empty) ? locationInfo.treatments : []}
                                    placeholder={t != null ? t.translations.select_treatment : ''}
                                />
                            </div>
                            <div className="col-md-6 col-xs-12 float-right">
                                <Field
                                    label={t != null ? t.translations.notes : ''}
                                    type="text"
                                    name="notes"
                                    classes="Field-input"
                                    component={FormField}
                                    t={this.props.t}
                                />
                            </div>
                        </div>
                        <Button className="btn-block float-right save-btn Field-input" type="submit">{t != null && t.translations.save_changes} {this.props.loading && <i className="fas fa-circle-notch fa-spin loader"></i>}</Button>
                    </Form>
                </Col>
            </Container >
        );
    }
};

const mapStateToProps = state => {
    return {
        t: state.accountRedux.localeInfo.t,
        success: state.locationRedux.success,
        message: state.locationRedux.message,
        error: state.locationRedux.error,
        loading: state.locationRedux.loading,
        user: state.accountRedux.user,
        company: state.companyRedux.companyInfo,
        locationInfo: state.locationRedux.locationInfo,
        treatments: state.treatmentRedux.treatments
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getTreatments: () => dispatch(getTreatments()),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({
    form: 'LocationForm'
})(Location));