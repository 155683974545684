import React from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import * as moment from 'moment';


class StackedChart extends React.Component {

    getMonth(idx) {
        // console.log("mn: " + JSON.stringify(this.props.localeInformation ))
        var objDate = new Date();
        objDate.setDate(1);
        objDate.setMonth(idx - 1);

        var locale = "sv";
        var month = objDate.toLocaleString(locale, { month: "short" });

        return month;
    }

    getAllMonths() {
        var whole_data = this.props.dashboardAnalyticsStack.monthlyRevenueStack;
        console.log("whole_data: " + JSON.stringify(whole_data));
        var temp = [];
        // var options = {
        //   year: "2-digit",
        //   month: "short"
        // };


        moment.locale("sv");
        whole_data.forEach(element => {
            var str = element.month + " 1, " + element.year;
            console.log("STR: " + str);

            var objDate = Date.parse(str);
            console.log("objDate: " + objDate);

            // var month = objDate.toLocaleString("sv-SE", options);
            var month = moment(objDate).format('MMM, YY');

            temp.push(month);
        });

        console.log("Temp: " + JSON.stringify(temp));

        return temp;
        // var months = whole_data.filer
        // let therapist_treatments = whole_data.map(x => {x.treatment_id , x.treatment_id });

        //   return [
        //       this.getMonth(1),
        //       this.getMonth(2),
        //       this.getMonth(3),
        //       this.getMonth(4),
        //       this.getMonth(5),
        //       this.getMonth(6),
        //       this.getMonth(7),
        //       this.getMonth(8),
        //       this.getMonth(9),
        //       this.getMonth(10),
        //       this.getMonth(11),
        //       this.getMonth(12)
        //   ]
    }

    render() {
        var lang = this.props.t;
        var t = this.props.dashboardAnalyticsStack;
        var monthlyRevenueStack_ = t.monthlyRevenueStack;
        var system_revenue_arr = monthlyRevenueStack_.map(function (val, index) {
            return val.system_revenue;
        });

        var therapist_revenue_arr = monthlyRevenueStack_.map(function (val, index) {
            return val.therapist_revenue;
        });

        console.log("Data: " + JSON.stringify(monthlyRevenueStack_));
        console.log("System: " + JSON.stringify(system_revenue_arr));
        console.log("Therapist: " + JSON.stringify(therapist_revenue_arr));

        var months_ = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        months_ = this.getAllMonths();

        var options = {};
        if (!isEmpty(this.props.dashboardAnalyticsStack)) {
            options = {
                chart: {
                    type: 'column'
                },
                title: {
                    text: this.props.title
                },
                xAxis: {
                    categories: months_
                },
                yAxis: {
                    allowDecimals: false,
                    min: 0,
                    title: {
                        text: 'SEK'
                    }
                },
                tooltip: {
                    formatter: function () {
                        return '<b>' + this.x + '</b><br/>' +
                            this.series.name + ': ' + this.y + ' SEK<br/>' +
                            lang.translations.email_receipt_total + ': ' + this.point.stackTotal + " SEK";
                    }
                },
                plotOptions: {
                    column: {
                        stacking: 'normal'
                    }
                },
                series: [{
                    name: this.props.entity1,
                    data: system_revenue_arr,
                    stack: 'male'
                },
                {
                    name: this.props.entity2,
                    data: therapist_revenue_arr,
                    stack: 'male'
                },
                    //   {
                    //     name: 'Jane',
                    //     data: [2, 5, 6, 2, 1],
                    //     stack: 'female'
                    //   },
                    //   {
                    //     name: 'Janet',
                    //     data: [3, 0, 4, 4, 3],
                    //     stack: 'female'
                    //   }
                ]
            }
        }
        return (
            <div>
                <HighchartsReact
                    highcharts={Highcharts}
                    options={options}
                />
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        t: state.accountRedux.localeInfo.t,
        dashboardAnalytics: state.dashboardRedux.dashboardAnalytics,
        dashboardAnalyticsStack: state.dashboardRedux.dashboardAnalyticsStack,
        revenueReport: state.dashboardRedux.revenueReport
    };
};

export default connect(mapStateToProps)(StackedChart);
