import { call, put, takeLatest } from "redux-saga/effects";
import Api from "../services/ApiCaller";
import {
   addCompany,
   getCompanies,
   getCompanyCustomers,
   updateCompany,
   deleteCompany,
   getCompanyLocations,
   addCompanyLocations,
   deleteCompanyLocation
} from "../redux/company.redux";

function* addCompanyRequest(api, { payload = {} }) {
   try {
      const {res={}}= yield call(Api.callServer, api, payload);
      const { message,success } = res;
      if (success) {
         yield put(addCompany.success(res));
      } else {
         yield put(addCompany.failure(message));
      }
   } catch (e) {
      yield put(addCompany.failure(e.result));
   }
}
function* updateCompanyRequest(api, { payload = {} }) {
   try {
      const {res={}}= yield call(Api.callServer, api, payload);
      const { message,success } = res;
      if (success) {
         yield put(updateCompany.success(message));
      } else {
         yield put(updateCompany.failure(message));
      }
   } catch (e) {
      yield put(updateCompany.failure(e.result));
   }
}
function* getCompaniesRequest(api, { payload = {} }) {
   try {
      const {res={}} = yield call(Api.callServer, api, payload);
      const {data,success,message}=res;
      if (success) {
         yield put(getCompanies.success(data));
      } else {
         yield put(getCompanies.failure(message));
      }
   } catch (e) {
      yield put(getCompanies.failure(e.message));
   }
}
function* getCompanyCustomersRequest(api, { payload = {} }) {
   try {
      const {res={}} = yield call(Api.callServer, api, payload);
      const {data,success,message}=res;
      if (success) {
         yield put(getCompanyCustomers.success(data));
      } else {
         yield put(getCompanyCustomers.failure(message));
      }
   } catch (e) {
      yield put(getCompanyCustomers.failure(e.message));
   }
}
function* getCompanyLocationsRequest(api, { payload = {} }) {
   try {
      const {res={}} = yield call(Api.callServer, api, payload);
      const {data,success,message}=res;
      if (success) {
         yield put(getCompanyLocations.success(data));
      } else {
         yield put(getCompanyLocations.failure(message));
      }
   } catch (e) {
      yield put(getCompanyLocations.failure(e.message));
   }
}
function* deleteCompanyRequest(api, { payload = {} }) {
   try {
      const {res={}} = yield call(Api.callServer, api, payload);
      const {success,message}=res;
      if (success) {
         yield put(deleteCompany.success(message));
      } else {
         yield put(deleteCompany.failure(message));
      }
   } catch (e) {
      yield put(deleteCompany.failure(e.message));
   }
}
function* addCompanyLocationsRequest(api, { payload = {} }) {
   try {
      const {res={}}= yield call(Api.callServer, api, payload);
      const { message,success } = res;
      if (success) {
         yield put(addCompanyLocations.success(res));
      } else {
         yield put(addCompanyLocations.failure(message));
      }
   } catch (e) {
      yield put(addCompanyLocations.failure(e.result));
   }
}
function* deleteCompanyLocationRequest(api, { payload = {} }) {
   try {
      const {res={}} = yield call(Api.callServer, api, payload);
      const {success,message}=res;
      if (success) {
         yield put(deleteCompanyLocation.success(res));
      } else {
         yield put(deleteCompanyLocation.failure(message));
      }
   } catch (e) {
      yield put(deleteCompanyLocation.failure(e.message));
   }
}

export default (api) => {
   const addCompanyApi = (data) => api.post("v1/company", data);
   const updateCompanyApi = (data) => api.put("v1/company", data);
   const getCompaniesApi = (data) => api.get("v1/companies", data);
   const deleteCompanyApi = (data) => api.delete("v1/deleteCompany", data);
   const getCompanyCustomersApi = (data) => api.get("v1/getAllCustomersByCompanyId", data);
   const getCompanyLocationsApi = (data) => api.get("v1/getCompanyLocations", data);
   const addCompanyLocationsApi = (data) => api.post("v1/addCompanyLocations", data);
   const deleteCompanyLocationApi = (data) => api.delete("v1/deleteCompanyLocation", data);



   return [
      takeLatest(addCompany.TRIGGER, addCompanyRequest, addCompanyApi),
      takeLatest(updateCompany.TRIGGER, updateCompanyRequest, updateCompanyApi),
      takeLatest(getCompanies.TRIGGER, getCompaniesRequest, getCompaniesApi),
      takeLatest(deleteCompany.TRIGGER, deleteCompanyRequest, deleteCompanyApi),
      takeLatest(getCompanyCustomers.TRIGGER, getCompanyCustomersRequest, getCompanyCustomersApi),
      takeLatest(getCompanyLocations.TRIGGER, getCompanyLocationsRequest, getCompanyLocationsApi),
      takeLatest(addCompanyLocations.TRIGGER, addCompanyLocationsRequest, addCompanyLocationsApi),
      takeLatest(deleteCompanyLocation.TRIGGER, deleteCompanyLocationRequest, deleteCompanyLocationApi),
   ];
};