/* eslint eqeqeq: 0 */
import React, { useEffect } from 'react'
import { useIdleTimer } from 'react-idle-timer'
import ConfirmationDialog from './components/common/ConfirmationDialog';
import { useState } from 'react';

const SESSION_IDEL_MINUTES = 60;

function AutoLagoutTimer(props) {
    const { ComposedClass } = props

    const [showDialouge, setShowDialouge] = useState(false)
    const [dialogeText, setDialogeText] = useState({ title: "", body: "" });

    useEffect(() => {
        const language = (window.navigator.userLanguage || window.navigator.language) == 'en' ? 'en' : 'sv'
        language == "en" ? setDialogeText({ title: "Timeout", body: "Sorry, we have to ask you to sign in again." }) :
            setDialogeText({ title: "Timeout", body: "Beklagar, vi måste be dig logga in igen." })
    }, [])

    const handleOnIdle = (event: any) => {
        // SHOW YOUR MODAL HERE AND LAGOUT
        // history.push("/lagout")
        console.log('last active', getLastActiveTime())

        setShowDialouge(true)

    }

    const { getLastActiveTime } = useIdleTimer({
        timeout: 1000 * 60 * SESSION_IDEL_MINUTES,
        onIdle: handleOnIdle,
        debounce: 500,
    })

    const handleOk = () => {
        var base_url = window.location.origin;
        window.location.href = base_url;
        localStorage.setItem('localInfo', null);
    }
    return <div>

        {showDialouge &&
            <ConfirmationDialog
                title={dialogeText.title}
                hideCancelBtn={true}
                bodyText={dialogeText.body}
                handleOk={handleOk} />
        }
        <ComposedClass />
    </div>
}

export default AutoLagoutTimer;
