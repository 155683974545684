/* eslint eqeqeq: 0 */
import React from 'react';
import { Container, Form, Button, Col } from 'shards-react';
import { getTreatments } from '../../redux/treatment.redux';
import { getAllLocations } from '../../redux/location.redux';
import { addPromoCode } from '../../redux/user.redux';

import { required } from '../../helper/validate';
import { FormField } from '../../helper/formFields';
import { reduxForm, Field } from 'redux-form';
import { connect } from 'react-redux';
import Alert from '../common/Alert';
import Header from '../common/header';
import { isEqual } from 'lodash';
import ConfirmationDialog from '../common/ConfirmationDialog';


var ssDate = '';
var eeDate = '';

class AddPromotion extends React.Component {
    states = [];
    constructor(props) {
        super(props);
        this.state = {
            t: null,
            sdate: new Date(),
            edate: new Date(),
            show_err: false,
            title: "test1",
            discount: "25",
            is_treatment_empty: false,
            is_loc_empty: false,
            selectedTreatments: [],
            selectedLocs: [],
            sub_amount_error_message: false,
            email_error_message: false,
        }

        this.handleSubmit1 = this.handleSubmit1.bind(this);

    }

    componentDidMount() {
        this.setState({ t: this.props.t });
        this.props.getTreatments();
        this.props.getAllLocations();
        this.loadData();
    }

    loadData() {
        if (this.props.location.state.action === "edit") {
            let object = this.props.location.state.object;
            let t1 = [];
            t1["title"] = object.text;
            t1["discount"] = object.discount_percent;

            if (object.start_time != null) {
                let dt = new Date(parseInt(object.start_time) * 1000);
                t1["ssdate"] = dt;
                ssDate = dt;
                this.setState({ sdate: ssDate })

            }
            if (object.end_time != null) {
                let dt = new Date(parseInt(object.end_time) * 1000);
                t1["eedate"] = dt;
                eeDate = dt;
                this.setState({ edate: eeDate })

                // if (eeDate != '' && this.state.edate != eeDate) {
                // }
            }
            // t1["end_time"]    = object.end_time;
            // t1["end_time"]    = object.end_time;

            if (object.locations != null) {
                t1["locations"] = object.locations;
                // locs = object.locations;
            }

            if (object.treatments != null) {
                t1["treatments"] = object.treatments;
                // treats = object.treatments;
            }
            this.props.initialize({
                ...t1,
            });

            console.log("Add Promotion: " + JSON.stringify(object));
        }
        else if (this.props.location.state.action === "add") {
            let object = this.props.location.state.object;
            let t1 = [];
            t1["title"] = "";
            t1["discount"] = "";
            const currDate = new Date();
            t1["ssdate"] = currDate;
            t1["eedate"] = currDate;
            ssDate = currDate;
            this.setState({ sdate: currDate });
            this.setState({ edate: currDate });
            this.props.initialize({
                ...t1,
            });

            console.log("Add Promotion: " + JSON.stringify(object));
        }
    }

    componentDidUpdate(prevProps) {
        if (!isEqual(prevProps.loading, this.props.loading)) {

            if ((this.props.success) || !(this.props.error || this.props.message)) {
                // this.props.history.push('/promotion');
                setTimeout(() => {
                    this.props.history.push('/promotion');
                }, 100);
            }
            else {
                this.setState({ show_err: true });
                console.log("ERR: " + this.props.message);
            }
        }
    }

    handleSubmit1(e) {
        e.preventDefault();


        console.log(e.target[0].value);
        console.log(e.target.title.value);
        console.log(e.target.discount.value);
        var { sdate, edate, selectedTreatments, selectedLocs } = this.state;
        console.log(sdate);
        console.log(edate);
        console.log(selectedTreatments);
        console.log(selectedLocs);

        var treats_ = selectedTreatments.join();
        var locs_ = selectedLocs.join();

        var sDate = sdate;
        var suserTimezoneOffset = sDate.getTimezoneOffset() * 60000;

        var sd = new Date();
        sd.setDate(sDate.getDate());
        sd.setMonth(sDate.getMonth());
        sd.setFullYear(sDate.getFullYear());
        sd.setHours(0);
        sd.setMinutes(0);
        sd.setSeconds(0);
        let t1 = sd.getTime();
        var eEpochS = t1 - suserTimezoneOffset //sd.getUTCMilliseconds();
        var sRes = eEpochS.toString().substr(0, 10);

        var aDate = edate;
        var userTimezoneOffset = aDate.getTimezoneOffset() * 60000;
        var ed = new Date();
        ed.setDate(aDate.getDate());
        ed.setMonth(aDate.getMonth());
        ed.setFullYear(aDate.getFullYear());
        ed.setHours(0);
        ed.setMinutes(0);
        ed.setSeconds(0);
        var t2 = ed.getTime();
        var eEpoch = t2 - userTimezoneOffset //sd.getUTCMilliseconds();
        var eRes = eEpoch.toString().substr(0, 10);

        var id_ = (this.props.location.state.action === "edit" ? this.props.location.state.object.id : -1);

        var data = {
            id: id_,
            text: e.target.title.value,
            discount_percent: e.target.discount.value,
            locations: locs_,
            treatments: treats_,
            active: true,
            start_time: sRes,
            end_time: eRes
        }

        console.log('Param: ' + JSON.stringify(data));
        // dispatch(addPromoCode(data));
        this.props.addPromoCode(data);

    }


    handleSubmit = (values, dispatch) => {


        // var { promo_title, promo_percent, start_time, end_time, locations, treatments } = values;
        // var { title, discount, sdate, edate, selectedTreatments, selectedLocs } = this.state;

        var { title, discount, } = values;

        var data = {
            id: -1,
            text: title,
            discount_percent: discount,
            locations: "",
            treatments: "",
            active: true,
            start_time: -1,
            end_time: -1
        }

        console.log('Param: ' + JSON.stringify(data));
        // dispatch(addPromoCode(data));
        this.props.addPromoCode(data);
    }

    onTitleChange = title_ => this.setState({ title: title_ })
    onDiscountChange = discount_ => this.setState({ discount: discount_ })
    onStartChange = sdate => this.setState({ sdate })
    onEndChange = edate => this.setState({ edate })

    removeDuplicates(originalArray, prop) {
        var newArray = [];
        var lookupObject = {};

        for (var i in originalArray) {
            lookupObject[originalArray[i][prop]] = originalArray[i];
        }

        for (i in lookupObject) {
            newArray.push(lookupObject[i]);
        }
        return newArray;
    }


    getLocs = (days) => {
        var selectedLocs = [];
        var days_values = [];
        if (days != null) {
            days.forEach(day => {
                selectedLocs.push(day.value);
                days_values.push({ label: day.label, value: day.value })
            });
        }

        if (this.props.location.state.action === "edit") {
            this.setState({ is_loc_empty: true });

        }
        this.setState({ selectedLocs });
        this.days_values = days_values;
    }

    getTreatments = (days) => {
        var selectedTreatments = [];
        var days_values = [];
        this.setState({ is_treatment_empty: true });

        if (days != null) {
            days.forEach(day => {
                selectedTreatments.push(day.value);
                days_values.push({ label: day.label, value: day.value })
            });
        }
        this.setState({ selectedTreatments })
        this.days_values = days_values;
    }

    render = () => {
        const { t } = this.state;

        if (ssDate != '' && this.state.sdate != ssDate) {
            this.setState({ sdate: ssDate })
        }

        console.log("this.props." + this.props.location.state.action + "\ntitle: " + this.state.title);

        return (
            <Container fluid className="personal-container user-container">
                {this.state.show_err &&
                    <ConfirmationDialog
                        title={'Error'}
                        bookingCancelBtn={true}
                        bodyText="Duplicate Promo Code"
                        handleOk={() => this.setState({ show_err: false })} />}
                <Header t={this.props.t} title={t != null && t.translations.promotion} props={this.props} />
                <Col className="mt-5 col-md-12 data-table-container">
                    <Form onSubmit={this.handleSubmit1}>
                        <Alert success={this.state.show_err} message={this.props.message} t={this.props.t} />
                        <Alert success={this.props.success} message={this.props.message} t={this.props.t} />
                        {/* <Row className="my-2"> */}
                        <div className="row">
                            <div className="col-md-6 col-xs-12 float-left">
                                <Field
                                    label={t != null ? t.translations.promocode : ''}
                                    type="text"
                                    name="title"
                                    classes="Field-input"
                                    validate={[required]}
                                    component={FormField}
                                    onChange={this.onTitleChange}
                                    defaultValue={this.state.title}
                                    t={this.props.t}
                                />
                            </div>
                            <div className="col-md-6 col-xs-12 float-right">
                                <Field
                                    label={t != null ? t.translations.discount_percent : ''}
                                    type="number"
                                    name="discount"
                                    classes="Field-input"
                                    validate={[required]}
                                    component={FormField}
                                    onChange={this.onDiscountChange}
                                    value={this.state.discount}
                                    t={this.props.t}
                                />
                            </div>
                        </div>
                        {/* </Row> */}
                        <div className="row">
                            <div className="col-md-6 col-xs-12 float-left">
                                <Field
                                    label={t != null ? t.translations.schedule_start_date : 'Shift start time'}
                                    type="myDatePicker"
                                    name="start_time"
                                    //disableField={true}
                                    classes="Field-input"
                                    defaultValue={this.state.sdate}
                                    onChange={this.onStartChange}
                                    validate={[required]}
                                    component={FormField}
                                    t={this.props.t}
                                />
                            </div>
                            <div className="col-md-6 col-xs-12 float-right">
                                <Field
                                    label={t != null ? t.translations.schedule_end_date : 'Shift end time'}
                                    type="myDatePicker"
                                    name="end_time"
                                    //disableField={true}
                                    defaultValue={this.state.edate}
                                    onChange={this.onEndChange}
                                    classes="Field-input"
                                    validate={[required]}
                                    component={FormField}
                                    t={this.props.t}
                                />
                            </div>
                        </div>
                        <Button className="btn-block float-right save-btn Field-input" type="submit">{t != null && t.translations.save_changes} {this.props.loading && <i className="fas fa-circle-notch fa-spin loader"></i>}</Button>
                    </Form>

                </Col>
            </Container >
        );
    }
};

const mapStateToProps = state => {
    return {
        t: state.accountRedux.localeInfo.t,
        success: state.userRedux.success,
        message: state.userRedux.message,
        error: state.userRedux.error,
        loading: state.userRedux.loading,
        treatments: state.treatmentRedux.treatments,
        all_locations: state.locationRedux.all_locations,
        promo_data: state.userRedux.promo_data
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getTreatments: () => dispatch(getTreatments()),
        getAllLocations: () => dispatch(getAllLocations()),
        addPromoCode: (data) => dispatch(addPromoCode(data)),
    }
};
export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({
    form: 'PromotionForm',
    destroyOnUnmount: false
})(AddPromotion));
