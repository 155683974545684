import { call, put, takeLatest, } from "redux-saga/effects";
import Api from "../services/ApiCaller";
import { getDashboardAnalytics, getDashboardRevenueReport, getRevenueReport, getDashboardAnalyticsForStack } from "../redux/dashboard.redux";

function* getDashboardAnalyticsRequest(api, { payload = {} }) {
   try {
      const { res = {} } = yield call(Api.callServer, api, payload);
      const { message, data, success } = res;
      if (success) {
         yield put(getDashboardAnalytics.success(data));
      } else {
         yield put(getDashboardAnalytics.failure(message));
      }
   } catch (e) {
      yield put(getDashboardAnalytics.failure(e.result));
   }
}

function* getDashboardAnalyticsStackRequest(api, { payload = {} }) {
   try {

      debugger;
      const { res = {} } = yield call(Api.callServer, api, payload);
      const { message, data, success } = res;
      if (success) {
         yield put(getDashboardAnalyticsForStack.success(data));
      }
      else {
         yield put(getDashboardAnalyticsForStack.failure(message));
      }
   }
   catch (e) {
      yield put(getDashboardAnalyticsForStack.failure(e.result));
   }
}

function* getRevenueReportRequest(api, { payload = {} }) {
   try {
      const { res = {} } = yield call(Api.callServer, api, payload);
      const { message, data, success } = res;
      if (success) {
         yield put(getRevenueReport.success(data));
      } else {
         yield put(getRevenueReport.failure(message));
      }
   } catch (e) {
      yield put(getRevenueReport.failure(e.result));
   }
}

function* getDashboardRevenueReportRequest(api, { payload = {} }) {
   try {
      const { res = {} } = yield call(Api.callServer, api, payload);
      const { message, data, success } = res;
      if (success) {
         yield put(getDashboardRevenueReport.success(data));
      } else {
         yield put(getDashboardRevenueReport.failure(message));
      }
   } catch (e) {
      yield put(getDashboardRevenueReport.failure(e.result));
   }
}

export default (api) => {
   const getDashboardAnalyticsApi = (data) => api.get("v2/getDashboardAnalytics", data); // Garbage replaced by getDashboardAnalyticsForStack
   const getDashboardAnalyticsStackApi = (data) => api.get("v1/getDashboardAnalyticsForStack", data);
   const getDashboardRevenueReportApi = (data) => api.get("v2/getDashboardRevenue", data);
   const getRevenueReportApi = (data) => api.get("v2/getRevenueReport", data);

   return [
      takeLatest(getDashboardAnalytics.TRIGGER, getDashboardAnalyticsRequest, getDashboardAnalyticsApi),
      takeLatest(getDashboardAnalyticsForStack.TRIGGER, getDashboardAnalyticsStackRequest, getDashboardAnalyticsStackApi),
      takeLatest(getDashboardRevenueReport.TRIGGER, getDashboardRevenueReportRequest, getDashboardRevenueReportApi),
      takeLatest(getRevenueReport.TRIGGER, getRevenueReportRequest, getRevenueReportApi),
   ];
};