/* eslint eqeqeq: 0 */
import React, { Fragment } from "react";

import { Container } from 'shards-react';
import { connect } from 'react-redux';
import StackedChart from '../common/stackedChart';

import { getDashboardAnalytics, getDashboardAnalyticsForStack, getDashboardRevenueReport } from '../../redux/dashboard.redux'
import { isEmpty } from 'lodash';
import { Field, reduxForm } from 'redux-form';
import { FormField } from '../../helper/formFields';
import { getTherapists } from '../../redux/therapist.redux';
import * as moment from 'moment';

// reactstrap components
import {
    Card,
    CardBody,
    CardFooter,
    Row,
    Col
} from "reactstrap";


class Dashboard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selected_therapist: [],
            therapistSearchOptions: []
        }
    }
    UNSAFE_componentWillMount() {

        this.props.getDashboardAnalyticsForStack();
        // this.props.getDashboardAnalytics();

        this.props.getTherapists();
        const data = {
            therapist_id: 0
        }
        this.props.getDashboardRevenueReport(data)
    }
    componentDidUpdate(prevProps) {
        if (this.props !== prevProps) {
            if (this.props.therapists !== []) {
                var therapistArray = [{ value: -1, label: "All" }]
                this.props.therapists.forEach(element => {
                    therapistArray.push({ value: element.id, label: element.first_name })
                });
                this.setState({ therapistSearchOptions: therapistArray })
            }
        }
    }
    getTherapistRevenue = (data) => {
        debugger;

        console.log("getTherapistRevenue: " + JSON.stringify(data));
        // var result = data.map(function(el) {
        //     return el.value;
        // });
        // console.log("getTherapistRevenue: result: " + JSON.stringify(result[0]));

        var filtered_ = [];
        this.props.therapists.forEach(element => {
            // if (result.includes(element.id)) {
            if (data == element.id) {
                filtered_.push({ value: element.id, label: element.first_name })
            }
        });
        this.setState({ selected_therapist: filtered_ });

        var str = data;

        const params = {
            therapist_id: str == -1 ? 0 : str
        }
        // this.props.getDashboardRevenueReport(params);
        this.props.getDashboardAnalyticsForStack(params);

    }

    render() {
        const { t, dashboardStack } = this.props;
        return (
            <>
                <Container fluid className="personal-container user-container location-container dashboard">
                    <Col className="right-container col-md-12">
                        <h2 className="pb-5 col-md-8 float-left title-header">
                            {t.translations.dashboard}
                        </h2>
                    </Col>
                    <br />
                    {isEmpty(dashboardStack) && <i className="fa fa-spinner fa-spin"></i>}
                    {!isEmpty(dashboardStack) &&
                        <Fragment>
                            <Row className="row-container">
                                <Col lg="3" md="6" sm="6">
                                    <Card className="card-stats">
                                        <CardBody>
                                            <Row className="dashboard-statistics">
                                                <Col>
                                                    <div className="icon-big text-center icon-warning">
                                                        <i className="fa fa-users icon-size" />
                                                    </div>
                                                </Col>
                                            </Row>
                                        </CardBody>
                                        <CardFooter>
                                            <hr />
                                            <div className="stats">
                                                {/* <i className="fa fa-refresh pr-2" /> */}
                                                {t.translations.new_customers_24} = {dashboardStack.customer_count}
                                            </div>
                                        </CardFooter>
                                    </Card>
                                </Col>
                                <Col lg="3" md="6" sm="6">
                                    <Card className="card-stats">
                                        <CardBody>
                                            <Row className="dashboard-statistics">
                                                <Col>
                                                    <div className="icon-big text-center icon-warning">
                                                        <i className="fa fa-users icon-size" />
                                                    </div>
                                                </Col>
                                            </Row>
                                        </CardBody>
                                        <CardFooter>
                                            <hr />
                                            <div className="stats">
                                                {/* <i className="fa fa-calendar-o pr-2" /> */}
                                                {t.translations.active_therapists} = {dashboardStack.therapist_count}
                                            </div>
                                        </CardFooter>
                                    </Card>
                                </Col>
                                <Col lg="3" md="6" sm="6">
                                    <Card className="card-stats">
                                        <CardBody>
                                            <Row className="dashboard-statistics">
                                                <Col>
                                                    <div className="icon-big text-center icon-warning">
                                                        <i className="far fa-building icon-size" />
                                                    </div>
                                                </Col>
                                            </Row>
                                        </CardBody>
                                        <CardFooter>
                                            <hr />
                                            <div className="stats">
                                                {/* <i className="fa fa-clock-o pr-2" /> */}
                                                {t.translations.active_companies} = {dashboardStack.company_count}
                                            </div>
                                        </CardFooter>
                                    </Card>
                                </Col>
                                <Col lg="3" md="6" sm="6">
                                    <Card className="card-stats">
                                        <CardBody>
                                            <Row className="dashboard-statistics">
                                                <Col>
                                                    <div className="icon-big text-center icon-warning">
                                                        <i className="fas fa-shopping-cart icon-size" />
                                                    </div>
                                                </Col>
                                            </Row>
                                        </CardBody>
                                        <CardFooter>
                                            <hr />
                                            <div className="stats">
                                                {/* <i className="fa fa-refresh pr-2" /> */}
                                                {t.translations.new_bookings_24} = {dashboardStack.booking_count}
                                            </div>
                                        </CardFooter>
                                    </Card>
                                </Col>
                            </Row>
                            <Row className="row-container">
                                <div className="col-md-6 col-xs-12 float-right">
                                    <Field
                                        label={t != null ? t.translations.email_receipt_therapist : ''}
                                        type="select"
                                        // ismulti={false}
                                        name="category"
                                        classes="Field-input"
                                        options={this.state.therapistSearchOptions}
                                        onChange={this.getTherapistRevenue}
                                        // validate={[required]}
                                        defaultValue={this.state.selected_therapist}
                                        component={FormField}
                                        t={this.props.t}
                                        // defaultValue={!isEmpty(treatmentInfo) ? { label: treatmentInfo.category, value: treatmentInfo.category } : ''}
                                        placeholder={t != null ? t.translations.select : ''}
                                    />
                                </div>
                                <Col md="12">
                                    {/* <BasicLineChart /> */}
                                    {/* <DrillDownChart /> */}
                                    <StackedChart
                                        title={t.translations.rev_split + " (" + moment().subtract(1, "year").format('YYYY') + " - " + moment().format('YYYY') + ")"}
                                        entity1={t.translations.healness_co}
                                        entity2={t.translations.menu_therapist}
                                    />
                                </Col>
                            </Row>
                        </Fragment>
                    }
                </Container>
            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        t: state.accountRedux.localeInfo.t,
        dashboardAnalytics: state.dashboardRedux.dashboardAnalytics,
        dashboardStack: state.dashboardRedux.dashboardAnalyticsStack,
        user: state.accountRedux.user,
        therapists: state.therapistRedux.therapists
    };
};
const mapDispatchToProps = dispatch => {
    return {
        getDashboardAnalytics: () => dispatch(getDashboardAnalytics()),
        getDashboardAnalyticsForStack: (data) => dispatch(getDashboardAnalyticsForStack(data)),
        getTherapists: (data) => dispatch(getTherapists(data)),
        getDashboardRevenueReport: (data) => dispatch(getDashboardRevenueReport(data))
    }
};
export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({
    form: 'DashboardForm'
})(Dashboard));
