import { call, put, takeLatest,select } from "redux-saga/effects";
import Api from "../services/ApiCaller";
import {
  updatePersonalInfo
} from "../redux/personal.redux";

function* updateStaffRequest(api, { payload = {} }) {
   try {
      const {res={}}= yield call(Api.callServer, api, payload);
      const { message, data,success } = res;
      if (success) {
         const state = yield select();
         //yield put(loginAdmin.success(data));
         yield put(updatePersonalInfo.success(res));
         Object.assign({},state.accountRedux,{user:data});
      } else {
         yield put(updatePersonalInfo.failure(message));
      }
   } catch (e) {
      yield put(updatePersonalInfo.failure(e.result));
   }
}

export default (api) => {
   const updateStaffApi = (data) => api.put("/v1/UpdateStaff", data);

   return [
      takeLatest(updatePersonalInfo.TRIGGER, updateStaffRequest, updateStaffApi),
   ];
};