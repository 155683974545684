import { createRoutine } from 'redux-saga-routines';
export const addLocation = createRoutine('ADD_LOCATION');
export const addTherapistLocations = createRoutine('ADD_THERAPIST_LOCATION');
export const newLocation = createRoutine('ADD_NEW_LOCATION');
export const updateLocation = createRoutine('UPDATE_LOCATION');
export const getLocations = createRoutine('GET_LOCATIONS');
export const getLocationsForCompany = createRoutine('GET_LOCATIONS_FOR_COMPANY');
export const getALLLocationsForTherapist = createRoutine('GET_ALL_LOCATIONS');
export const deleteLocation = createRoutine('DELETE_LOCATION');
export const showLocationDetail = createRoutine('SHOW_LOCATION_DETAIL');
export const updateTherapistLocationStatus = createRoutine('UPDATE_THERAPIST_LOCATION_STATUS');

export const getAllLocations = createRoutine('GET_ALL_LOCATIONS_COMPLETE');

const INITIAL_STATE = {
    error: false,
    success: false,
    message: '',
    loading: false,
    locations: [],
    locationInfo: {},
    companyLocations: [],
    AllLocations: [],
    all_locations: []

};
export default (state = INITIAL_STATE, { type, payload }) => {
    switch (type) {
        case getAllLocations.TRIGGER:
            return { ...state, loading: true, error: false, success: false, all_locations: [], deleted: false };
        case getAllLocations.SUCCESS:
            return { ...state, loading: false, error: false, success: true, all_locations: payload, deleted: false };
        case getAllLocations.FAILURE:
            return { ...state, loading: false, success: false, error: true, message: payload, all_locations: [], deleted: false };


        case addLocation.TRIGGER:
            return { ...state, loading: true, error: false, success: false, message: '', deleted: false };
        case addLocation.SUCCESS:
            return { ...state, loading: false, error: false, success: true, message: payload, deleted: false };
        case addLocation.FAILURE:
            return { ...state, loading: false, success: false, error: true, message: payload, deleted: false };

        case updateLocation.TRIGGER:
            return { ...state, loading: true, error: false, success: false, message: '', deleted: false };
        case updateLocation.SUCCESS:
            return { ...state, loading: false, error: false, success: true, message: payload, deleted: false };
        case updateLocation.FAILURE:
            return { ...state, loading: false, success: false, error: true, message: payload, deleted: false };

        case addTherapistLocations.TRIGGER:
            return { ...state, loading: true, error: false, success: false, message: '', locations: [], deleted: false };
        case addTherapistLocations.SUCCESS:             
            return { ...state, loading: false, error: false, success: true, message: payload.message, locations: payload.data, deleted: false };
        case addTherapistLocations.FAILURE:
            return { ...state, loading: false, success: false, error: true, message: payload, locations: [], deleted: false };

        case getLocations.TRIGGER:
            return { ...state, loading: true, error: false, success: false, locations: [], deleted: false };
        case getLocations.SUCCESS:
            return { ...state, loading: false, error: false, success: true, locations: payload, deleted: false };
        case getLocations.FAILURE:
            return { ...state, loading: false, success: false, error: true, message: payload, deleted: false };

        case getALLLocationsForTherapist.TRIGGER:
            return { ...state, loading: true, error: false, success: false, AllLocations: [], deleted: false };
        case getALLLocationsForTherapist.SUCCESS:
            return { ...state, loading: false, error: false, success: true, AllLocations: payload, deleted: false };
        case getALLLocationsForTherapist.FAILURE:
            return { ...state, loading: false, success: false, error: true, message: payload, deleted: false };

        case getLocationsForCompany.TRIGGER:
            return { ...state, loading: true, error: false, success: false, companyLocations: [], deleted: false };
        case getLocationsForCompany.SUCCESS:
            return { ...state, loading: false, error: false, success: true, companyLocations: payload, deleted: false };
        case getLocationsForCompany.FAILURE:
            return { ...state, loading: false, success: false, error: true, message: payload, deleted: false };

        case updateTherapistLocationStatus.TRIGGER:
            return { ...state, loading: true, error: false, success: false, message: '', deleted: false };
        case updateTherapistLocationStatus.SUCCESS:
            const { message, data } = payload;
            return { ...state, loading: false, error: false, success: true, message: message, locations: data, deleted: false };
        case updateTherapistLocationStatus.FAILURE:
            return { ...state, loading: false, success: false, error: true, message: payload, deleted: false };

        case deleteLocation.TRIGGER:
            return { ...state, loading: true, error: false, success: false, message: '', deleted: false };
        case deleteLocation.SUCCESS:
            return { ...state, loading: false, error: false, success: true, message: payload, deleted: true };
        case deleteLocation.FAILURE:
            return { ...state, loading: false, success: false, error: true, message: payload, deleted: false };

        case showLocationDetail.TRIGGER:
            return { ...state, message: '', locationInfo: payload };

        case newLocation.TRIGGER:
            return { ...state, message: '', locationInfo: {} };

        default:
            return { ...state }

    }
}