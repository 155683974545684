import { call, put, takeLatest } from "redux-saga/effects";
import Api from "../services/ApiCaller";
import {
   addLocation, getLocations, updateTherapistLocationStatus, updateLocation, deleteLocation, getLocationsForCompany,
   getALLLocationsForTherapist, addTherapistLocations, getAllLocations
} from "../redux/location.redux";

function* addLocationRequest(api, { payload = {} }) {
   try {
      const { res = {} } = yield call(Api.callServer, api, payload);
      const { message, success } = res;
      if (success) {
         yield put(addLocation.success(message));
      } else {
         yield put(addLocation.failure(message));
      }
   } catch (e) {
      yield put(addLocation.failure(e.result));
   }
}

function* addTherapistLocationsRequest(api, { payload = {} }) {
   try {
      const { res = {} } = yield call(Api.callServer, api, payload);
      const { message, success } = res;
      if (success) {
         yield put(addTherapistLocations.success(res));
      } else {
         yield put(addTherapistLocations.failure(message));
      }
   } catch (e) {
      yield put(addTherapistLocations.failure(e.result));
   }
}

function* updateLocationRequest(api, { payload = {} }) {
   try {
      const { res = {} } = yield call(Api.callServer, api, payload);
      const { message, success } = res;
      if (success) {
         yield put(updateLocation.success(message));
      } else {
         yield put(updateLocation.failure(message));
      }
   } catch (e) {
      yield put(updateLocation.failure(e.result));
   }
}

function* deleteLocationRequest(api, { payload = {} }) {
   try {
      const { res = {} } = yield call(Api.callServer, api, payload);
      const { message, success } = res;
      if (success) {
         yield put(deleteLocation.success(message));
      } else {
         yield put(deleteLocation.failure(message));
      }
   } catch (e) {
      yield put(deleteLocation.failure(e.result));
   }
}

function* getLocationsRequest(api, { payload = {} }) {
   try {
      const { res = {} } = yield call(Api.callServer, api, payload);
      const { message, data, success } = res;

      console.log("getLocationsRequest: " + JSON.stringify(res));
      if (success) {
         yield put(getLocations.success(data));
      } else {
         yield put(getLocations.failure(message));
      }
   } catch (e) {
      yield put(getLocations.failure(e.result));
   }
}

function* getAllTherapistLocationsRequest(api, { payload = {} }) {
   try {
      const { res = {} } = yield call(Api.callServer, api, payload);
      const { message, data, success } = res;
      if (success) {
         yield put(getALLLocationsForTherapist.success(data));
      } else {
         yield put(getALLLocationsForTherapist.failure(message));
      }
   } catch (e) {
      yield put(getALLLocationsForTherapist.failure(e.result));
   }
}

function* updateTherapistLocationStatusRequest(api, { payload = {} }) {
   try {
      const { res = {} } = yield call(Api.callServer, api, payload);
      const { message, success } = res;
      if (success) {
         yield put(updateTherapistLocationStatus.success(res));
      }
      else {
         yield put(updateTherapistLocationStatus.failure(message));
      }
   } catch (e) {
      yield put(updateTherapistLocationStatus.failure(e.result));
   }
}

function* getCompanyLocationsRequest(api, { payload = {} }) {
   try {
      const { res = {} } = yield call(Api.callServer, api, payload);
      const { message, data, success } = res;
      if (success) {
         yield put(getLocationsForCompany.success(data));
      } else {
         yield put(getLocationsForCompany.failure(message));
      }
   } catch (e) {
      yield put(getLocationsForCompany.failure(e.result));
   }
}


function* getAllLocationsRequest(api, { payload = {} }) {
   try {
      const { res = {} } = yield call(Api.callServer, api, payload);
      const { message, data, success } = res;
      if (success) {
         yield put(getAllLocations.success(data));
      } else {
         yield put(getAllLocations.failure(message));
      }
   } catch (e) {
      yield put(getAllLocations.failure(e.result));
   }
}

export default (api) => {
   const addLocationApi = (data) => api.post("v1/addLocation", data);
   const addTherapistLocationsApi = (data) => api.post("v2/addLocationsForTherapist", data);
   const updateLocationApi = (data) => api.put("v1/updateLocation", data);
   const deleteLocationApi = (data) => api.delete("v1/deleteLocation", data);
   const getLocationsApi = (data) => api.get("v1/getLocation", data);
   const getLocationsForCompanyApi = (data) => api.get("v2/getLocationsForCompany", data);
   const updateTherapistLocationStatusApi = (data) => api.put("v1/updateTherapistLocationStatus", data);
   const getAllLocationsApi = (data) => api.get("v1/getAllLocations", data);

   return [
      takeLatest(getAllLocations.TRIGGER, getAllLocationsRequest, getAllLocationsApi),
      takeLatest(addLocation.TRIGGER, addLocationRequest, addLocationApi),
      takeLatest(addTherapistLocations.TRIGGER, addTherapistLocationsRequest, addTherapistLocationsApi),
      takeLatest(updateLocation.TRIGGER, updateLocationRequest, updateLocationApi),
      takeLatest(deleteLocation.TRIGGER, deleteLocationRequest, deleteLocationApi),
      takeLatest(getLocations.TRIGGER, getLocationsRequest, getLocationsApi),
      takeLatest(getALLLocationsForTherapist.TRIGGER, getAllTherapistLocationsRequest, getLocationsApi),
      takeLatest(getLocationsForCompany.TRIGGER, getCompanyLocationsRequest, getLocationsForCompanyApi),
      takeLatest(updateTherapistLocationStatus.TRIGGER, updateTherapistLocationStatusRequest, updateTherapistLocationStatusApi),
   ];
};