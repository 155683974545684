import { call, put, takeLatest, select } from "redux-saga/effects";
import Api from "../services/ApiCaller";
import {
   registerStaff, updateStaff, updateContact
} from "../redux/staff.redux";
// import {
//    loginAdmin
// } from "../redux/account.redux";

function* registerStaffRequest(api, { payload = {} }) {
   try {
      const { res = {} } = yield call(Api.callServer, api, payload);
      const { message, success } = res;
      if (success) {
         yield put(registerStaff.success(message));
      } else {
         yield put(registerStaff.failure(message));
      }
   } catch (e) {
      yield put(registerStaff.failure(e.result));
   }
}

function* updateStaffRequest(api, { payload = {} }) {
   try {
      const { res = {} } = yield call(Api.callServer, api, payload);
      const { message, data, success } = res;
      if (success) {
         const state = yield select();
         //yield put(loginAdmin.success(data));
         yield put(updateStaff.success(message));
         Object.assign({}, state, { user: data });
      } else {
         yield put(updateStaff.failure(message));
      }
   } catch (e) {
      yield put(updateStaff.failure(e.result));
   }
}

function* updateContactRequest(api, { payload = {} }) {
   try {
      const { res = {} } = yield call(Api.callServer, api, payload);
      const { message, data, success } = res;
      if (success) {
         const state = yield select();
         yield put(updateContact.success(payload));
         Object.assign({}, state, { user: data });
      }
      else {
         yield put(updateContact.failure(message));
      }
   } catch (e) {
      yield put(updateContact.failure(e.result));
   }
}

export default (api) => {
   const registerStaffApi = (data) => api.post("/registerStaff", data); // garbage
   const updateStaffApi = (data) => api.put("/v1/UpdateStaff", data);
   const updateContactApi = (data) => api.post("/v1/UpdateContact", data);

   return [
      takeLatest(registerStaff.TRIGGER, registerStaffRequest, registerStaffApi),
      takeLatest(updateStaff.TRIGGER, updateStaffRequest, updateStaffApi),
      takeLatest(updateContact.TRIGGER, updateContactRequest, updateContactApi),
   ];
};