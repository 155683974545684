/* eslint eqeqeq: 0 */
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Container, Row } from 'shards-react';
import { getCompanies, showCompanyDetail, getCompanyCustomers, deleteCompanyLocation, deleteCompany, getCompanyLocations, addNewCompany, addCompanyLocations } from '../../redux/company.redux';
import Header from '../common/header';
import MaterialTable from '../common/materialTable';
import { FormField } from '../../helper/formFields';
import { showUserDetail, deleteUser } from '../../redux/user.redux';
import { showLocationDetail, deleteLocation, newLocation, getLocationsForCompany } from '../../redux/location.redux';
import { getKeyByValue, StatusType } from '../../constants'
import Alert from '../common/Alert';
import ConfirmationDialog from '../common/ConfirmationDialog';
import { reduxForm, Field } from 'redux-form';
import locationImage from '../../images/maps-and-flags.png';
import userIcon from '../../images/multiple-users-silhouette.png';
import { isEmpty } from 'lodash';
class Company extends Component {
    constructor(props) {
        super(props);
        this.state = {
            t: null,
            company_location_ids: [],
            showCompanyCustomers: false, openConfirmationBox: false, deleteItem: {}, showCompanyLocations: false, addLocation: false
        }
    }
    componentDidMount() {
        this.setState({ t: this.props.t });
        this.props.getCompanies();
        setTimeout(() => {
            this.props.addNewCompany();
        }, 5000);
    }

    componentDidUpdate(prevProps) {
        // if (this.props.isCompanylocationsAdded && this.props.addLocation === true) {
        //     this.setState({ addLocation: false })
        // }
        if (this.props !== prevProps) {
            this.setState({
                t: this.props.t
            })
            if (this.props.companyUsers !== prevProps.companyUsers) {
                this.setState({ showCompanyCustomers: true })
            }
        }
        if (this.props.deleted) {
            this.props.getCompanies();
        }
    }
    showDetail = (event) => {
        this.props.showCompanyDetail(event);
        this.props.history.push('/editcompany')
    }

    showUserDetail = (event) => {
        this.props.showUserDetail(event);
        this.props.history.push('/editcustomer');
    }

    showLocationDetail = (event) => {
        this.props.showLocationDetail(event);
        this.props.history.push('/editlocation');
    }

    addNewCompany = () => {
        return (this.props.history.push('/addcompany'))
    }

    addNewLocation = () => {
        // setTimeout(() => {
        //     this.props.addNewCompany();
        // }, 4000);
        this.setState({ addLocation: true });
        //this.props.newLocation();
        // return (this.props.history.push('/addlocation'))
    }

    showCustomers = (company) => {
        const data = {
            company_id: company.id
        }
        this.props.showCompanyDetail(company);
        this.props.getCompanyCustomers(data);
        this.setState({ showCompanyCustomers: true });
    }

    showLocations = (company) => {
        const data = {
            company_id: company.id
        }
        this.props.showCompanyDetail(company);
        this.props.getCompanyLocations(data);

        var locdata = { therapist_id: -1 }

        this.props.getLocationsForCompany(locdata);
        this.setState({ showCompanyLocations: true });
    }

    deleteCompany = () => {
        const { deleteItem } = this.state;
        const data = { companyId: deleteItem.id }
        this.props.deleteCompany(data);
        this.setState({ openConfirmationBox: false })
    }

    deleteLocation = () => {
        const { deleteItem } = this.state;
        const data = {
            company_id: this.props.companyInfo.id,
            location_id: deleteItem.id
        }
        this.props.deleteCompanyLocation(data);
        this.setState({ openConfirmationBox: false })
    }
    deleteUser = (event) => {
        const { deleteItem } = this.state;
        const data = { customerId: deleteItem.id }
        this.props.deleteUser(data);
        this.setState({ openConfirmationBox: false, showCompanyCustomers: false, showCompanyLocations: false })
    }
    _renderDialog = (event) => {
        this.setState({ openConfirmationBox: true, deleteItem: event })
    }
    resetState = () => {
        this.setState({ showCompanyCustomers: false, showCompanyLocations: false })
    }

    getLocations = (locations) => {
        var location_ids = [];
        locations != null && locations.forEach(loc => {
            location_ids.push(loc.value);
        });
        this.setState({ company_location_ids: location_ids })
        // if (treatments === null) {
        //     this.setState({ is_treatment_empty: true });
        //     this.without_treatment = true;
        //     // this.props.onChange('therapistInfo.treatments',[]);
        // }
    }
    addCompanyLocations = () => {
        const { company_location_ids } = this.state;
        const company = this.props.companyInfo
        const data = {
            location_ids: company_location_ids,
            company_id: company.id
        }
        this.props.addCompanyLocations(data);
        this.setState({ addLocation: false })
    }

    render() {
        const { loading, Customers, success, message, locations } = this.props;
        const { t, showCompanyCustomers, showCompanyLocations } = this.state;
        var multipleLocations = [];
        this.props.locations.forEach(element => {
            multipleLocations.push({ value: element.id, label: element.name })
        });
        var columns = null;
        var customerColumns = null;
        var locationsColumns = null;
        if (t != null) {
            customerColumns = [
                {
                    title: t.translations.person_number,
                    field: 'person_number'
                },
                {
                    title: t.translations.first_name,
                    field: 'first_name'
                },
                {
                    title: t.translations.last_name,
                    field: 'last_name'
                },
                {
                    title: t.translations.email,
                    field: 'email'
                }
            ];
            columns = [
                {
                    title: t.translations.org_num,
                    field: 'org_num',
                    render: rowData => <span>{rowData.org_num.replace(/(\d{3})(\d{3})(\d{4})/, "$1$2-$3")}</span>
                },
                {
                    title: t.translations.name,
                    field: 'name'
                },
                {
                    title: t.translations.address,
                    field: 'address'
                },
                {
                    title: "Type",
                    field: 'benefit_type'
                },
                {
                    title: t.translations.manage_customers,
                    // render: rowData => <Button className="float-left btn-block mt-2 view_btn view-users col-md-6" type="button" onClick={() => this.showCustomers(rowData)}><img scr={locationImage}></img></Button>,
                    render: rowData => <div className="image-icon-container"> <img alt="User" src={userIcon} onClick={() => this.showCustomers(rowData)} className="image-icon"></img></div>,
                },
                {
                    title: t.translations.manage_locations,
                    // render: rowData => <Button className="float-left btn-block mt-2 view_btn view-users col-md-6" type="button" onClick={() => this.showLocations(rowData)}>{t.translations.manage_locations}</Button>,
                    render: rowData => <div className="image-icon-container"> <img alt="Location" src={locationImage} onClick={() => this.showLocations(rowData)} className="image-icon"></img></div>,
                },
            ];
            locationsColumns = [
                {
                    title: t.translations.location_name,
                    field: 'name',
                },
                {
                    title: t.translations.address,
                    field: 'address'
                },
                {
                    title: t.translations.status,
                    field: 'active',
                    render: rowData => <span>{(getKeyByValue(StatusType, parseInt(rowData.active))).replace('_', ' ')} </span>

                },
            ];
        }

        return (
            <Fragment>
                <Container fluid className="personal-container user-container">
                    <Row className="">
                        <Header t={this.props.t} addNew={showCompanyLocations ? this.addNewLocation : this.addNewCompany} title={t != null && t.translations.companies} addNewBtnTitle={showCompanyLocations ? t != null && t.translations.add_location : t != null && t.translations.add_new_company} resetState={this.resetState} showCompanyCustomers={this.state.showCompanyCustomers} showCompanyLocations={this.state.showCompanyLocations} companyDetail={this.props.companyInfo} />

                        <Alert success={success} message={message} t={this.props.t} loading={loading} />
                        {this.state.openConfirmationBox &&
                            <ConfirmationDialog
                                title={this.state.t != null ? this.state.t.translations.delete_confirmation : ''}
                                bodyText={this.state.t != null && this.state.t.translations.are_you_sure_to_delete}
                                handleCancel={() => this.setState({ openConfirmationBox: false })}
                                handleOk={showCompanyLocations ? this.deleteLocation : (showCompanyCustomers ? this.deleteUser : this.deleteCompany)} />}

                        {this.state.addLocation &&
                            <ConfirmationDialog
                                title={this.state.t != null ? this.state.t.translations.select_location : ''}
                                bodyText=
                                {
                                    <div className="col-md-12 company-location">
                                        <Field
                                            label={t != null ? t.translations.select_location : ''}
                                            type="multiSelect"
                                            ismulti
                                            onChange={this.getLocations}
                                            name="location_ids"
                                            classes="Field-input"
                                            options={this.props.allLocations}
                                            component={FormField}
                                            t={this.props.t}
                                            defaultValue={!isEmpty(multipleLocations) ? multipleLocations : []}
                                            placeholder={t != null ? t.translations.select_location : ''}
                                        />
                                    </div>
                                }
                                handleCancel={() => this.setState({ addLocation: false })}
                                handleOk={this.state.addLocation ? this.addCompanyLocations : ''}
                            />
                        }


                        {(columns !== null && t !== null && showCompanyCustomers === false && showCompanyLocations === false) &&
                            <MaterialTable t={this.props.t}
                                justShow={0}
                                selected_row_id={-1}
                                columns={columns}
                                data={this.props.companies}
                                showDetail={this.showDetail}
                                deleteDetail={this._renderDialog}
                                loading={this.props.loading} />}

                        {(customerColumns !== null && t != null && showCompanyCustomers == true) &&
                            <MaterialTable t={this.props.t}
                                justShow={0}
                                selected_row_id={-1}
                                columns={customerColumns}
                                data={Customers}
                                showDetail={this.showUserDetail}
                                showCompanyCustomers={showCompanyCustomers}
                                deleteDetail={this._renderDialog}
                                loading={loading} />}

                        {(locationsColumns !== null && t != null && showCompanyLocations == true) &&
                            <MaterialTable t={this.props.t}
                                justShow={0}
                                selected_row_id={-1}
                                columns={locationsColumns}
                                data={locations}
                                removeEditIcon={true}
                                showDetail={this.showLocationDetail}
                                showCompanyLocations={showCompanyLocations}
                                deleteDetail={this._renderDialog}
                                loading={loading} />}
                    </Row>
                </Container >
            </Fragment>
        )
    }
}

const mapStateToProps = state => {
    return {
        t: state.accountRedux.localeInfo.t,
        loading: state.companyRedux.loading,
        success: state.companyRedux.success,
        message: state.companyRedux.message,
        companies: state.companyRedux.companies,
        Customers: state.companyRedux.showCustomers,
        locations: state.companyRedux.showLocations,
        allLocations: state.locationRedux.companyLocations,
        deleted: state.companyRedux.deleted,
        companyInfo: state.companyRedux.companyInfo,
        isCompanylocationsAdded: state.companyRedux.isCompanylocationsAdded
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getCompanies: (data) => dispatch(getCompanies(data)),
        showCompanyDetail: (data) => dispatch(showCompanyDetail(data)),
        getCompanyCustomers: (data) => dispatch(getCompanyCustomers(data)),
        showUserDetail: (data) => dispatch(showUserDetail(data)),
        deleteCompany: (data) => dispatch(deleteCompany(data)),
        getCompanyLocations: (data) => dispatch(getCompanyLocations(data)),
        showLocationDetail: (data) => dispatch(showLocationDetail(data)),
        deleteLocation: (data) => dispatch(deleteLocation(data)),
        newLocation: () => dispatch(newLocation()),
        deleteUser: (data) => dispatch(deleteUser(data)),
        addNewCompany: () => dispatch(addNewCompany()),
        getLocationsForCompany: (data) => dispatch(getLocationsForCompany(data)),
        addCompanyLocations: (data) => dispatch(addCompanyLocations(data)),
        deleteCompanyLocation: (data) => dispatch(deleteCompanyLocation(data))
    }
};
export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({
    form: 'CompanyLocationForm'
})(Company));
