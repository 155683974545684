import {put, takeLatest} from "redux-saga/effects";
import {GET_CURRENT_USER} from "../redux/account.redux";

export function* startupSaga({setHeader}, {key = "", payload: {authToken: token = ""} = {}}) {
   try {
      if (key === "auth" && token) {
         yield setHeader('Authorization', `Bearer ${token}`);
         yield put(GET_CURRENT_USER.trigger());
      }
   } catch ({message}) {
   }
}


export default (api) => {

   return [
      takeLatest("persist/REHYDRATE", startupSaga, {setHeader: api.setHeader}),
   ]
};
