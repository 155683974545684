import { call, put, takeLatest } from "redux-saga/effects";
import Api from "../services/ApiCaller";

import {
   loginAdmin, changePassword, bankIdAuthentication, bankIdCollect, getInvoices, getCompanyInvoices, secretLogin
} from "../redux/account.redux";
// import { BASEURL } from "../constants/urls";

function* loginAdminRequest({ loginAdminApi, setHeader }, { payload }) {

   try {
      yield setHeader('Authorization', '');
      yield setHeader('Content-Type', 'application/json');
      const { res = {} } = yield call(Api.callServer, loginAdminApi, payload);

      const { message, data, success } = res;

      if (success) {
         const { token = '' } = data
         yield setHeader('Authorization', `Bearer ${token}`);
         yield setHeader('X-API-Key', 'AQElhmfuXNWTK0Qc+iSUgmgZpOeUXYVZNjnUW4MkrHWbLUV8f/jPuxDBXVsNvuR83LVYjEgiTGAH-utTK5eeL8vtKMDeOOBoKf/cMDyN+5MMj3yMOi6yqQUA=-zM96tPz9UUMWveE5');
         localStorage.setItem('localInfo', JSON.stringify(message));
         console.log('Setting session data....');

         yield put(loginAdmin.success(data));
      } else {
         yield put(loginAdmin.failure(message));
      }
   } catch (e) {
      yield put(loginAdmin.failure(e.result));
   }
}
function* secretLoginRequest({ secretLoginApi, setHeader, api }, { payload }) {

   try {
      // const url = BASEURL+ "/secretLogin"

      // const formData = new FormData()
      // formData.append ("name",payload.name);
      // formData.append ("password",payload.password);

      const formData = new FormData();
      formData.append('name', 'swair');
      formData.append('id', '1');
      formData.append('password', "dfsdfs");

      yield setHeader('Authorization', '');
      yield setHeader('Content-Type', 'application/json');

      const { res = {} } = yield call(Api.callServer, secretLoginApi, payload);

      const { message, data, success } = res;
      //////////debugger;  
      if (success) {

         yield put(secretLogin.success(data));
      } else {
         yield put(secretLogin.failure(message));
      }
   } catch (e) {
      console.log(e.result)
      yield put(secretLogin.failure(e.result));
   }
}

function* bankIdAuthenticationRequest({ bankIdAuthenticationApi, setHeader }, { payload }) {
   try {
      const { res = {} } = yield call(Api.callServer, bankIdAuthenticationApi, payload);
      const { message, data, success } = res;
      if (success) {
         yield put(bankIdAuthentication.success(data));
      } else {
         yield put(bankIdAuthentication.failure(message));
      }
   } catch (e) {
      yield put(bankIdAuthentication.failure(e.result));
   }
}

function* bankIdCollectRequest(bankIdCollectApi, { payload }) {
   try {
      const { res = {} } = yield call(Api.callServer, bankIdCollectApi, payload);
      const { data, success } = res;

      if (success) {
         const result = {
            data,
            message: ''
         }
         yield put(bankIdCollect.success(result));
         // if(data!=null)
         // {
         //    if(data.completionData!=null)
         //    {

         //    }
         // }
      }
      // if (!isEmpty(bankIdCollectResponse)) {
      //    const { completionData } = bankIdCollectResponse.data;
      //    console.log(completionData);
      //    if (isEmpty(completionData)) {
      //       const { res = {} } = yield call(Api.callServer, bankIdCollectApi, payload);
      //       const { message, data, success } = res;
      //       if (success) {
      //          if (data.completionData!==null) {
      //             const result = {
      //                data,
      //                message: ''
      //             }
      //             yield put(bankIdCollect.success(result));
      //          }
      //          if (data.hintCode === 'outstandingTransaction') {
      //             const result = {
      //                data,
      //                message: 'RFA13'
      //             }
      //             yield put(bankIdCollect.success(result));
      //          }
      //          else if (data.hintCode === 'noClient') {
      //             const result = {
      //                data,
      //                message: 'RFA1'
      //             }
      //             yield put(bankIdCollect.success(result));
      //          }
      //          else if (data.hintCode === 'expiredTransaction') {
      //             const result = {
      //                data,
      //                message: 'RFA8'
      //             }
      //             yield put(bankIdCollect.success(result));
      //          }
      //          else if(data.hintCode==='userSign')
      //          {
      //             const result = {
      //                data,
      //                message: 'RFA8'
      //             }
      //             yield put(bankIdCollect.success(result));
      //          }
      //          else {
      //             yield put(bankIdCollect.failure(data));
      //          }
      //       } else {
      //          yield put(bankIdCollect.failure(message));
      //       }
      //    }
      // }
      //else {

      // }
   } catch (e) {
      yield put(bankIdCollect.failure(e.result));
   }
}

function* changePasswordRequest(api, { payload }) {
   try {

      const { res = {} } = yield call(Api.callServer, api);
      const { message, success } = res;
      if (success) {
         yield put(changePassword.success(message));
      } else {
         yield put(changePassword.failure(message));
      }
   } catch (e) {
      yield put(changePassword.failure(e.result));
   }
}


function* getInvoicesRequest(api, { payload = {} }) {
   try {
      console.log('Base URL: ' + Api.callServer);
      console.log('API URL: ' + api);
      console.log('Params: ' + JSON.stringify(payload));

      const { res = {} } = yield call(Api.callServer, api, payload);
      const { data, message, success } = res;
      console.log('Response: ' + JSON.stringify(res));

      if (success) {
         yield put(getInvoices.success(data));
      }
      else {
         yield put(getInvoices.failure(message));
      }
   }
   catch (e) {
      yield put(getInvoices.failure(e.result));
   }
}

function* getCompanyInvoicesRequest(api, { payload = {} }) {
   try {
      console.log('Base URL: ' + Api.callServer);
      console.log('API URL: ' + api);
      console.log('Params: ' + JSON.stringify(payload));

      const { res = {} } = yield call(Api.callServer, api, payload);
      const { data, message, success } = res;
      console.log('Response: ' + JSON.stringify(res));

      if (success) {
         yield put(getCompanyInvoices.success(data));
      }
      else {
         yield put(getCompanyInvoices.failure(message));
      }
   }
   catch (e) {
      yield put(getCompanyInvoices.failure(e.result));
   }
}

export default (api) => {
   const loginAdminApi = (data) => api.get("/adminLogin", data);
   const secretLoginApi = (data) => api.post("/secretLogin", data);
   const bankIdAuthenticationApi = (data) => api.get("/Auth", data); // Sign //Garbage as couldn't find in APIs
   const changePasswordApi = (data) => api.post("/changePassword", data); // Garbage : no /account but used in /personal, takes email and password whereas on UI first and last name is also changed
   const bankIdCollectApi = (data) => api.get("/Collect", data); //Garbage as couldn't find in APIs
   const getInvoicesApi = (data) => api.get("v1/GetInvoicesForMonth", data);
   const getCompanyInvoicesApi = (data) => api.get("v1/GetCompanyInvoicesForMonth", data);

   return [
      takeLatest(getInvoices.TRIGGER, getInvoicesRequest, getInvoicesApi),
      takeLatest(getCompanyInvoices.TRIGGER, getCompanyInvoicesRequest, getCompanyInvoicesApi),
      takeLatest(loginAdmin.TRIGGER, loginAdminRequest, { loginAdminApi, setHeader: api.setHeader }),
      takeLatest(secretLogin.TRIGGER, secretLoginRequest, { secretLoginApi, setHeader: api.setHeader, api }),
      takeLatest(bankIdAuthentication.TRIGGER, bankIdAuthenticationRequest, { bankIdAuthenticationApi, setHeader: api.setHeader }),
      takeLatest(bankIdCollect.TRIGGER, bankIdCollectRequest, bankIdCollectApi),
      takeLatest(changePassword.TRIGGER, changePasswordRequest, changePasswordApi),
   ];
};