import { createRoutine } from 'redux-saga-routines';
export const addUser = createRoutine('ADD_USER');
export const editUser = createRoutine('EDIT_USER');
export const deleteUser = createRoutine('DELETE_USER');
export const getUsers = createRoutine('GET_USERS');
export const getUsersFiltered = createRoutine('GET_USERS_FILTERED');
export const getCustomersWithBenefits = createRoutine('getCustomersWithBenefits');
export const showUserDetail = createRoutine('SHOW_USER_DETAIL');
export const addCustomerCredit = createRoutine('ADD_CUSTOMER_CREDIT');
export const getUserAddress = createRoutine('SHOW_USER_ADDRESS');

export const getPromoCodes = createRoutine('GET_PROMO_CODES');
export const addPromoCode = createRoutine('ADD_PROMO_CODE');
export const disablePromoCode = createRoutine('DISABLE_PROMO_CODE');

export const newUser = createRoutine('ADD_NEW_USER');
export const sendPush = createRoutine('SEND_PUSH_CUSTOMER');

export const changeTherapist = createRoutine('CHANGE_THERAPIST');
export const getAvailableTherapist = createRoutine('GET_AVAILABLE_THERAPIST');
export const refundBooking = createRoutine('REFUND_BOOKING');
export const sendReceipt = createRoutine('SEND_RECEIPT');

export const getPreliminaryPrice = createRoutine('GET_PRELIMINARY_PRICE');
export const getPreliminaryDetails = createRoutine('GET_PRELIMINARY_DETAILS');
export const createPreliminaryDetails = createRoutine('CREATE_PRELIMINARY_DETAILS');
export const updatePreliminaryDetails = createRoutine('UPDATE_PRELIMINARY_DETAILS');


export const getNotificationUsers = createRoutine('GET_NOTIFICATION_USERS');
export const sendNotifAdmin = createRoutine('SEND_NOTIFICATION_ADMIN');
export const getNotifAdmin = createRoutine('GET_NOTIFICATION_ADMIN');



const INITIAL_STATE = {
    error: false,
    success: false,
    message: '',
    loading: false,
    user: { country_id: 0 },
    userInfo: {},
    userDataInfo: [],
    usersList: [],
    promo_list: [],
    userAdd: [],
    push_data: "",
    promo_data: "",
    send_notif_data: "",
    update_therapist: "",
    avail_therapists: [],
    notifications: [],
    notif_users: [],
    refund_data: "",
    get_details: "",
    get_price: "",
    create_pbooking: "",
    update_pbooking: "",
    deleted: false
};
export default (state = INITIAL_STATE, { type, payload }) => {
    switch (type) {
        case sendNotifAdmin.TRIGGER:
            return { ...state, loading: true, error: false, success: false, send_notif_data: [], deleted: false };
        case sendNotifAdmin.SUCCESS:
            return { ...state, loading: false, error: false, success: true, send_notif_data: payload, deleted: false };
        case sendNotifAdmin.FAILURE:
            return { ...state, loading: false, success: false, error: true, message: payload, send_notif_data: [], deleted: false };

        case getNotifAdmin.TRIGGER:
            return { ...state, loading: true, error: false, success: false, notifications: [], deleted: false };
        case getNotifAdmin.SUCCESS:
            return { ...state, loading: false, error: false, success: true, notifications: payload, deleted: false };
        case getNotifAdmin.FAILURE:
            return { ...state, loading: false, success: false, error: true, message: payload, notifications: [], deleted: false };

        case getNotificationUsers.TRIGGER:
            return { ...state, loading: true, error: false, success: false, notif_users: [], deleted: false };
        case getNotificationUsers.SUCCESS:
            return { ...state, loading: false, error: false, success: true, notif_users: payload, deleted: false };
        case getNotificationUsers.FAILURE:
            return { ...state, loading: false, success: false, error: true, message: payload, notif_users: [], deleted: false };


        case sendPush.TRIGGER:
            return { ...state, loading: true, error: false, success: false, push_data: "", deleted: false };
        case sendPush.SUCCESS:
            return { ...state, loading: false, error: false, success: true, push_data: payload, deleted: false };
        case sendPush.FAILURE:
            return { ...state, loading: false, success: false, error: true, message: payload, push_data: "", deleted: false };

        case refundBooking.TRIGGER:
            return { ...state, loading: true, error: false, success: false, refund_data: "", deleted: false };
        case refundBooking.SUCCESS:
            return { ...state, loading: false, error: false, success: true, refund_data: payload, deleted: false };
        case refundBooking.FAILURE:
            return { ...state, loading: false, success: false, error: true, message: payload, refund_data: "", deleted: false };

        case changeTherapist.TRIGGER:
            return { ...state, loading: true, error: false, success: false, update_therapist: "", deleted: false };
        case changeTherapist.SUCCESS:
            return { ...state, loading: false, error: false, success: true, update_therapist: payload, deleted: false };
        case changeTherapist.FAILURE:
            return { ...state, loading: false, success: false, error: true, message: payload, update_therapist: "", deleted: false };

        case getAvailableTherapist.TRIGGER:
            return { ...state, loading: true, error: false, success: false, avail_therapists: "", deleted: false };
        case getAvailableTherapist.SUCCESS:
            return { ...state, loading: false, error: false, success: true, avail_therapists: payload, deleted: false };
        case getAvailableTherapist.FAILURE:
            return { ...state, loading: false, success: false, error: true, message: payload, avail_therapists: "", deleted: false };

        case sendReceipt.TRIGGER:
            return { ...state, loading: true, error: false, success: false, send_receipt: "", deleted: false };
        case sendReceipt.SUCCESS:
            return { ...state, loading: false, error: false, success: true, send_receipt: payload, deleted: false };
        case sendReceipt.FAILURE:
            return { ...state, loading: false, success: false, error: true, message: payload, send_receipt: "", deleted: false };


        case getPreliminaryDetails.TRIGGER:
            return { ...state, loading: true, error: false, success: false, get_details: "", deleted: false };
        case getPreliminaryDetails.SUCCESS:
            return { ...state, loading: false, error: false, success: true, message: payload.message, get_details: payload, deleted: false };
        case getPreliminaryDetails.FAILURE:
            return { ...state, loading: false, success: false, error: true, message: payload, get_details: "", deleted: false };

        case getPreliminaryPrice.TRIGGER:
            return { ...state, loading: true, error: false, success: false, get_price: "" };
        case getPreliminaryPrice.SUCCESS:
            return { ...state, loading: false, error: false, success: true, get_price: payload };
        case getPreliminaryPrice.FAILURE:
            return { ...state, loading: false, success: false, error: true, get_price: "" };


        case createPreliminaryDetails.TRIGGER:
            return { ...state, loading: true, error: false, success: false, create_pbooking: "", deleted: false };
        case createPreliminaryDetails.SUCCESS:
            return { ...state, loading: false, error: false, success: true, create_pbooking: payload, deleted: false };
        case createPreliminaryDetails.FAILURE:
            return { ...state, loading: false, success: false, error: true, message: payload, create_pbooking: "", deleted: false };

        case addCustomerCredit.TRIGGER:
            return { ...state, loading: true, error: false, success: false, add_credit: "", deleted: false };
        case addCustomerCredit.SUCCESS:
            return { ...state, loading: false, error: false, success: true, add_credit: payload, deleted: false };
        case addCustomerCredit.FAILURE:
            return { ...state, loading: false, success: false, error: true, message: payload, add_credit: "", deleted: false };


        case updatePreliminaryDetails.TRIGGER:
            return { ...state, loading: true, error: false, success: false, update_pbooking: "", deleted: false };
        case updatePreliminaryDetails.SUCCESS:
            return { ...state, loading: false, error: false, success: true, update_pbooking: payload, deleted: false };
        case updatePreliminaryDetails.FAILURE:
            return { ...state, loading: false, success: false, error: true, message: payload, update_pbooking: "", deleted: false };



        case getPromoCodes.TRIGGER:
            return { ...state, loading: true, error: false, success: false, promo_list: [], deleted: false };
        case getPromoCodes.SUCCESS:
            return { ...state, loading: false, error: false, success: true, promo_list: payload, deleted: false };
        case getPromoCodes.FAILURE:
            return { ...state, loading: false, success: false, error: true, message: payload, promo_list: [], deleted: false };

        case disablePromoCode.TRIGGER:
            return { ...state, loading: true, error: false, success: false, data: '', deleted: false };
        case disablePromoCode.SUCCESS:
            return { ...state, loading: false, error: false, success: true, data: payload, deleted: false };
        case disablePromoCode.FAILURE:
            return { ...state, loading: false, success: false, error: true, message: payload, deleted: false };


        case addPromoCode.TRIGGER:
            return { ...state, loading: true, error: false, success: false, promo_data: '', deleted: false };
        case addPromoCode.SUCCESS:
            return { ...state, loading: false, error: false, success: true, promo_data: payload, deleted: false };
        case addPromoCode.FAILURE:
            return { ...state, loading: false, success: false, error: true, message: payload, promo_data: '', deleted: false };


        case addUser.TRIGGER:
            return { ...state, loading: true, error: false, success: false, message: '', deleted: false };
        case addUser.SUCCESS:
            return { ...state, loading: false, error: false, success: true, message: payload, deleted: false };
        case addUser.FAILURE:
            return { ...state, loading: false, success: false, error: true, message: payload, user: '', deleted: false };

        case editUser.TRIGGER:
            return { ...state, loading: true, error: false, success: false, message: '', deleted: false };
        case editUser.SUCCESS:
            return { ...state, loading: false, error: false, success: true, message: payload, deleted: false };
        case editUser.FAILURE:
            return { ...state, loading: false, success: false, error: true, message: payload, user: '', deleted: false };

        case deleteUser.TRIGGER:
            return { ...state, loading: true, error: false, success: false, message: '', deleted: false };
        case deleteUser.SUCCESS:
            return { ...state, loading: false, error: false, success: true, message: payload, deleted: true };
        case deleteUser.FAILURE:
            return { ...state, loading: false, success: false, error: true, message: payload, user: '', deleted: false };

        case getUsers.TRIGGER:
            return { ...state, loading: true, error: false, success: false, userInfo: '', deleted: false };
        case getUsers.SUCCESS:
            return { ...state, loading: false, error: false, success: true, usersList: payload, deleted: false };
        case getUsers.FAILURE:
            return { ...state, loading: false, success: false, error: true, message: payload, usersList: '', deleted: false };

        case getUsersFiltered.TRIGGER:
            return { ...state, loading: true, error: false, success: false, userData: '', deleted: false };
        case getUsersFiltered.SUCCESS:
            return { ...state, loading: false, error: false, success: true, userData: payload, deleted: false };
        case getUsersFiltered.FAILURE:
            return { ...state, loading: false, success: false, error: true, message: payload, userData: '', deleted: false };

        case getCustomersWithBenefits.TRIGGER:
            return { ...state, loading: true, error: false, success: false, customerBenefitsInfo: '', deleted: false };
        case getCustomersWithBenefits.SUCCESS:
            return { ...state, loading: false, error: false, success: true, customerBenefitsInfo: payload, deleted: false };
        case getCustomersWithBenefits.FAILURE:
            return { ...state, loading: false, success: false, error: true, message: payload, customerBenefitsInfo: '', deleted: false };

        case getUserAddress.TRIGGER:
            return { ...state, loading: true, error: false, success: false, userAdd: [], deleted: false };
        case getUserAddress.SUCCESS:
            return { ...state, loading: false, error: false, success: true, userAdd: payload, deleted: false };
        case getUserAddress.FAILURE:
            return { ...state, loading: false, success: false, error: true, message: payload, userAdd: [], deleted: false };


        case showUserDetail.TRIGGER:
            return { ...state, userInfo: payload };

        case newUser.TRIGGER:
            return { ...state, message: '' };

        default:
            return { ...state }

    }
}