import React, { Component } from 'react';
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import Button from "@material-ui/core/Button";
import { connect } from 'react-redux';

class ViewDialog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            t: null
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props !== prevProps) {
            this.setState({
                t: this.props.t
            })
        }
    }
    componentDidMount() {
        this.setState({
            t: this.props.t
        })
    }
    render() {
        const { t } = this.state;
        const { bookingCancelBtn } = this.props;
        console.log('btn is : ' + this.props.bookingCancelBtn);
        return (
            <Dialog
                disableBackdropClick
                disableEscapeKeyDown
                maxWidth="false"
                fullScreen={true}
                // onEntering={''}
                aria-labelledby="confirmation-dialog-title"
                open={true}
            >
                <DialogTitle id="confirmation-dialog-title">{this.props.title}</DialogTitle>
                <DialogContent dividers>{this.props.bodyText}</DialogContent>
                <DialogActions>
                    {this.props.customer_email != null &&
                    <Button onClick={this.props.handleReceipt} color="primary">
                        {t != null && t.translations.share_receipt}
                    </Button>}
                    <Button onClick={this.props.handleCancel} color="primary">
                        {t != null && (bookingCancelBtn === true ? t.translations.close : t.translations.cancel_forgot)}
                    </Button>
                    {/* <Button onClick={this.props.handleDownload} color="primary">
                        {t != null && t.translations.download_pdf}
                    </Button> */}
                    {this.props.handleOk !== null &&
                        <Button onClick={this.props.handleOk} color="primary">
                            {t != null && t.translations.ok_btn}
                        </Button>
                    }
                </DialogActions>
            </Dialog>
        );
    }
}
const mapStateToProps = state => {
    return {
        t: state.accountRedux.localeInfo.t
    };
};
const mapDispatchToProps = dispatch => {
    return {
    }
};
export default connect(mapStateToProps, mapDispatchToProps)(ViewDialog);