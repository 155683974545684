import { createRoutine } from 'redux-saga-routines';
export const updatePersonalInfo = createRoutine('UPDATE_PERSONAL_INFO');
const INITIAL_STATE = {
    error: false,
    success: false,
    message: '',
    loading: false,
    personalDetails:{}
};
export default (state = INITIAL_STATE, { type, payload }) => {
    switch (type) {
       
        case updatePersonalInfo.TRIGGER:
            return { ...state, loading: true, error: false, success: false, message: '' };
        case updatePersonalInfo.SUCCESS:
                const { message, data } = payload;
            return { ...state, loading: false, error: false, success: true, message:message,personalDetails:data };
        case updatePersonalInfo.FAILURE:
            return { ...state, loading: false, success: false, error: true, message: payload };

        default:
            return { ...state }

    }
}