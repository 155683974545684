/* eslint eqeqeq: 0 */
import React, { Fragment } from 'react';
import { Container } from 'shards-react';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { getLocations, updateTherapistLocationStatus, showLocationDetail, deleteLocation, newLocation, getALLLocationsForTherapist, addTherapistLocations } from '../../redux/location.redux';
import { locationTypes, getKeyByValue, } from '../../constants'
import Alert from '../common/Alert';
import Header from '../common/header'
import MaterialTable from '../common/materialTable';
import { isEmpty } from 'lodash';
import ConfirmationDialog from '../common/ConfirmationDialog';
import Select from 'react-select';

class Location extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            addNewLocation: false,
            t: null,
            my_options: [],
            locations: [],
            newAlllocations_: [],
            selectedOption: null,
            openConfirmationBox: false,
            deleteItem: {},
            showLocationDropDown: true,
            therapist_location_ids: [],
            newAlllocations: []
        }
    }

    componentDidMount() {
        this.setState({ t: this.props.t });
        const { id, role_type } = this.props.user;
        var data;
        if (role_type === 1 || role_type === 2) {
            data = { therapist_id: -1 } //Added by Sana: Return all locations to admin
        }
        else {
            data = { therapist_id: id }
        }
        this.props.getLocations(data);

        if (this.props.user.role_type === 3) {
            this.setState({ showLocationDropDown: true });
            const data1 = { therapist_id: -1 }
            this.props.getALLLocationsForTherapist(data1)
        }

        // setTimeout(() => {
        //     this.props.newLocation();
        // }, 10000);
    }
    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.deleted === true) {
            const { id, role_type } = this.props.user;
            var data;
            if (role_type === 1 || role_type === 2) {
                data = { therapist_id: -1 }
            }
            else {
                data = { therapist_id: id }
            }
            this.props.getLocations(data);
        }

    }

    componentDidUpdate(prevProps) {
        // ////////debugger;
        if (this.props !== prevProps) {
            this.setState({
                t: this.props.t
            })
            if (!isEmpty(this.props.locations)) {
                this.setState({ selectedOption: null }) //OLD

                // this.setState({ showLocationDropDown: false }) //OLD
                this.setState({
                    locations: this.props.locations
                })
            }
            console.log("update location didupdate")

        }
    }
    handleChange = name => event => {
        // setState({ ...state, [name]: event.target.checked });
    };
    handleSubmit = (values, dispatch) => {
    }

    addNewLocation = () => {
        if (this.props.user.role_type === 3) {
            this.setState({ showLocationDropDown: true });
            const data1 = { therapist_id: -1 }
            this.props.getALLLocationsForTherapist(data1)
        }
        else {
            this.props.newLocation();
            this.props.history.push('/addlocation')
        }
    }
    _renderLocations = () => {
        return (
            this.state.locations.forEach(location => (
                <span>{location.name}</span>
            ))
        )
    }
    updateLocation = (location_id) => {
        const { id } = this.props.user;
        const data = {
            therapist_id: id,
            location_id: location_id
        }
        this.props.updateTherapistLocationStatus(data);
        console.log("update location")
    }
    showDetail = (event) => {
        this.props.showLocationDetail(event);
        this.props.history.push('/editlocation');
    }
    _renderDialog = (event) => {
        this.setState({ openConfirmationBox: true, deleteItem: event })
    }
    deleteLocation = () => {
        const { deleteItem } = this.state;
        ////////debugger;

        if (this.props.user.role_type === 3) {

            var existing_therapist_location_ids = this.state.locations.map(ele => ele.id);
            var uniq = existing_therapist_location_ids.filter(item => item !== deleteItem.id)

            console.log("addLocationsForTherapist: " + existing_therapist_location_ids);
            console.log("addLocationsForTherapist Unique: " + uniq);



            const therapist_id = this.props.user.id
            const data = {
                therapist_location_ids: uniq,
                therapist_id: therapist_id
            }
            this.props.addTherapistLocations(data);
            this.setState({ openConfirmationBox: false })
        }
        else {
            const data = { location_id: deleteItem.id }
            this.props.deleteLocation(data);
            this.setState({ openConfirmationBox: false })
        }
        console.log("delete location")


    }
    getLocations = (locations) => {
        this.setState(
            { locations },
            () => console.log(`Option selected:`, this.state.selectedOption)
        );

        var location_ids = [];
        console.log('getLocations: ' + locations);
        locations != null && locations.forEach(loc => {
            location_ids.push(loc.value);
        });
        this.setState({ therapist_location_ids: location_ids })
    }
    addLocationsForTherapist = () => {
        // const { therapist_location_ids } = this.state; //OLD
        var uniq = [];
        if (this.state.locations.length > 0) {
            var existing_therapist_location_ids = this.state.locations.map(ele => ele.id);
            var therapist_location_ids = this.state.selectedOption.map(ele => ele.value);
            var merged_ = existing_therapist_location_ids.concat(therapist_location_ids);
            uniq = [...new Set(merged_)];

        }
        else {
            uniq = this.state.selectedOption.map(ele => ele.value);
        }

        console.log("addLocationsForTherapist: " + therapist_location_ids);
        console.log("addLocationsForTherapist Unique: " + uniq);



        const therapist_id = this.props.user.id
        const data = {
            therapist_location_ids: uniq,
            therapist_id: therapist_id
        }
        this.props.addTherapistLocations(data);
        // this.setState({ showLocationDropDown: false }) //OLD
    }

    handleChange = selectedOption => {
        console.log('handleChangeselectedOption: ' + selectedOption);

        this.setState(
            { selectedOption },
            () => console.log(`Option selected:`, this.state.selectedOption)
        );
    };

    render = () => {
        const { selectedOption } = this.state;
        const { t, loading, message, } = this.props;
        const { locations } = this.state;
        const { role_type } = this.props.user;
        var therapistSelectedLocation = [];
        this.props.locations.forEach(element => {
            therapistSelectedLocation.push({ value: element.id, label: element.name })
        });
        var columns = [
            {
                title: t.translations.location_name,
                field: 'name',
                render: rowData => <span>{rowData.name + "" + (rowData.room === null ? "" : " " + rowData.room)}</span>
            },
            {
                title: t.translations.confirmBooking_type_app,
                field: 'location_type',
                render: rowData => <span>{!isEmpty(rowData.location_type) ? (getKeyByValue(locationTypes, parseInt(rowData.location_type))).replace('_', ' ') : 'N/A'}
                </span>
            },

        ];
        var newAlllocations = [];
        console.log('AllLocations Data: ' + JSON.stringify(this.props.AllLocations));

        console.log('AllLocations: ' + JSON.stringify(this.props.AllLocations.length));
        console.log('Added AllLocations: ' + JSON.stringify(this.state.locations.length));

        var unioned = this.props.AllLocations.filter(a => this.state.locations.some(b => a.id === b.id));
        var temp1 = this.props.AllLocations.filter((i) => !unioned.includes(i));

        console.log('Intersection AllLocations: ' + JSON.stringify(unioned.length));
        console.log('After Intersection AllLocations: ' + JSON.stringify(temp1.length));


        if (temp1 != undefined && this.state.showLocationDropDown == true) {
            if (temp1.length > 0) {
                // const { AllLocations } = this.props;
                temp1.forEach(element => {
                    if (element.location_type !== "1") {
                        newAlllocations.push({ value: element.id, label: element.name + (element.room === null ? "" : " " + element.room) })
                    }
                });
            }

            if (this.state.newAlllocations_ == []) {
                this.setState({ newAlllocations_: newAlllocations })
            }
        }

        console.log("newAlllocations_ data1234: " + newAlllocations.length);

        return (
            <Fragment>
                <Container fluid className="personal-container user-container location-container">
                    <Header
                        t={this.props.t}
                        title={(t != null && this.props.user.role_type !== 3) ? t.translations.location_list : t.translations.locations_for_work}
                        addNewBtnTitle={this.props.user.role_type !== 3 ? t.translations.add_location : "No"}
                        addNew={this.addNewLocation}
                        props={this.props} />
                    <Alert success={this.props.success} message={message} loading={loading} t={this.props.t} />

                    {role_type === 3 &&

                        <div class="outer1">
                            <div class="inner1">
                                <div className="select-view">
                                    <Select
                                        isMulti={true}
                                        placeholder={t != null ? t.translations.select_location : "Select location"}
                                        value={selectedOption}
                                        onChange={this.handleChange}
                                        options={newAlllocations}
                                    />
                                </div>
                            </div>
                            <div class="inner1">
                                <button type="submit" class="btn-1" onClick={this.addLocationsForTherapist}>{t != null ? t.translations.save_location : 'Spara plats'}</button></div>
                        </div>}
                    {
                        (role_type === 3 && locations) && <Fragment>
                            {this.state.openConfirmationBox &&
                                <ConfirmationDialog
                                    title={this.state.t != null ? this.state.t.translations.delete_confirmation : ''}
                                    bodyText={this.state.t != null && this.state.t.translations.are_you_sure_to_delete}
                                    handleCancel={() => this.setState({ openConfirmationBox: false })}
                                    handleOk={this.deleteLocation} />}

                            <MaterialTable
                                t={this.props.t}
                                columns={columns}
                                data={locations}
                                justShow={2}
                                view={true}
                                showDetail={this.showDetail}
                                deleteDetail={this._renderDialog}
                                loading={loading} />
                        </Fragment>
                    }
                    {((role_type === 1 || role_type === 2)) && <Fragment>

                        {this.state.openConfirmationBox &&
                            <ConfirmationDialog
                                title={this.state.t != null ? this.state.t.translations.delete_confirmation : ''}
                                bodyText={this.state.t != null && this.state.t.translations.are_you_sure_to_delete}
                                handleCancel={() => this.setState({ openConfirmationBox: false })}
                                handleOk={this.deleteLocation} />}

                        <MaterialTable
                            justShow={0}
                            selected_row_id={-1}
                            t={this.props.t}
                            columns={columns}
                            data={locations}
                            showDetail={this.showDetail}
                            deleteDetail={this._renderDialog}
                            loading={loading} />
                    </Fragment>
                    }
                </Container >
            </Fragment>

        );
    }
};

const mapStateToProps = state => {
    return {
        loading: state.locationRedux.loading,
        success: state.locationRedux.success,
        message: state.locationRedux.message,
        t: state.accountRedux.localeInfo.t,
        user: state.accountRedux.user,
        deleted: state.locationRedux.deleted,
        locations: state.locationRedux.locations,
        AllLocations: state.locationRedux.AllLocations
    };
};
const mapDispatchToProps = dispatch => {
    return {
        getLocations: (data) => dispatch(getLocations(data)),
        getALLLocationsForTherapist: (data) => dispatch(getALLLocationsForTherapist(data)),
        updateTherapistLocationStatus: (data) => dispatch(updateTherapistLocationStatus(data)),
        showLocationDetail: (data) => dispatch(showLocationDetail(data)),
        deleteLocation: (data) => dispatch(deleteLocation(data)),
        addTherapistLocations: (data) => dispatch(addTherapistLocations(data)),
        newLocation: () => dispatch(newLocation())
    }
};
export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({
    form: 'userForm',
    destroyOnUnmount: false
})(Location));