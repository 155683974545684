import { createRoutine } from 'redux-saga-routines';
export const addCompany = createRoutine('ADD_COMPANY');
export const updateCompany = createRoutine('UPDATE_COMPANY');
export const deleteCompany = createRoutine('DELETE_COMPANY');
export const getCompanies = createRoutine('GET_COMPANIES');
export const showCompanyDetail = createRoutine('SHOW_COMPANY_DETAIL');
export const getCompanyCustomers = createRoutine('GET_COMPANY_CUSTOMERS');
export const getCompanyLocations = createRoutine('GET_COMPANY_LOCATIONS');
export const addNewCompany = createRoutine('ADD_NEW_COMPANY');
export const addCompanyLocations = createRoutine('ADD_COMPANY_LOCATIONS');
export const deleteCompanyLocation = createRoutine('DELETE_COMPANY_LOCATION');

const INITIAL_STATE = {
    error: false,
    success: false,
    message: '',
    loading: false,
    companyInfo: {},
    showCustomers: [],
    companies: [],
    showLocations: [],
    isCompanylocationsAdded: false,
    deleted: false
};
export default (state = INITIAL_STATE, { type, payload }) => {
    switch (type) {
        case addCompany.TRIGGER:
            return { ...state, loading: true, error: false, success: false, message: '', deleted: false };
        case addCompany.SUCCESS:
            return { ...state, loading: false, error: false, success: true, message: payload, deleted: false };
        case addCompany.FAILURE:
            return { ...state, loading: false, success: false, error: true, message: payload, deleted: false };

        case updateCompany.TRIGGER:
            return { ...state, loading: true, error: false, success: false, message: '', deleted: false };
        case updateCompany.SUCCESS:
            return { ...state, loading: false, error: false, success: true, message: payload, deleted: false };
        case updateCompany.FAILURE:
            return { ...state, loading: false, success: false, error: true, message: payload, deleted: false };

        case deleteCompany.TRIGGER:
            return { ...state, loading: true, error: false, success: false, message: '', deleted: false };
        case deleteCompany.SUCCESS:
            return { ...state, loading: false, error: false, success: true, message: payload, deleted: true };
        case deleteCompany.FAILURE:
            return { ...state, loading: false, success: false, error: true, message: payload, deleted: false };

        case getCompanies.TRIGGER:
            return { ...state, loading: true, error: false, success: false, companyInfo: '', deleted: false };
        case getCompanies.SUCCESS:
            return { ...state, loading: false, error: false, success: true, companies: payload, deleted: false };
        case getCompanies.FAILURE:
            return { ...state, loading: false, success: false, error: true, message: payload, deleted: false };

        case getCompanyCustomers.TRIGGER:
            return { ...state, loading: true, error: false, success: false, message: '', deleted: false };
        case getCompanyCustomers.SUCCESS:
            return { ...state, loading: false, error: false, success: true, showCustomers: payload, deleted: false };
        case getCompanyCustomers.FAILURE:
            return { ...state, loading: false, success: false, error: true, message: payload, showCustomers: [], deleted: false };

        case getCompanyLocations.TRIGGER:
            return { ...state, loading: true, error: false, success: false, message: '' };
        case getCompanyLocations.SUCCESS:
            return { ...state, loading: false, error: false, success: true, showLocations: payload };
        case getCompanyLocations.FAILURE:
            return { ...state, loading: false, success: false, error: true, message: payload, showLocations: [] };

        case addCompanyLocations.TRIGGER:
            return { ...state, loading: true, error: false, success: false, message: '', isCompanylocationsAdded: false };
        case addCompanyLocations.SUCCESS:
            const { message, data } = payload;
            return { ...state, loading: false, error: false, success: true, message: message, showLocations: data, isCompanylocationsAdded: true };
        case addCompanyLocations.FAILURE:
            return { ...state, loading: false, success: false, error: true, message: payload, isCompanylocationsAdded: false };

        case deleteCompanyLocation.TRIGGER:
            return { ...state, loading: true, error: false, success: false, message: '' };
        case deleteCompanyLocation.SUCCESS:
            return { ...state, loading: false, error: false, success: true, message: payload.message, showLocations: payload.data };
        case deleteCompanyLocation.FAILURE:
            return { ...state, loading: false, success: false, error: true, message: payload, showLocations: [] };

        case showCompanyDetail.TRIGGER:
            return { ...state, companyInfo: payload };

        case addNewCompany.TRIGGER:
            return { ...state, message: '' };

        default:
            return { ...state }

    }
}