import React from 'react';
import { Container, Button } from 'shards-react';
import { connect } from 'react-redux';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { isEmpty } from 'lodash';
import {
    Row,
    Col
} from "reactstrap";
// core components

import RangePicker from "react-range-picker"
import { getTherapists } from '../../redux/therapist.redux';
import { getCompanies } from '../../redux/company.redux';
import { getRevenueReport } from '../../redux/dashboard.redux';
import { Field, reduxForm } from 'redux-form';
import { FormField } from '../../helper/formFields';
import PieChart from '../common/PieChart';
class Report extends React.Component {
    report_list = [
        { "name": "Revenue/Payments" },
        { "name": "Therapists" },
        { "name": "Corporate Bookings" }
    ]
    constructor(props) {
        super(props);
        this.state = {
            showRevenue: true,
            showTherapists: false,
            showCorporate: false,
            therapistSearchOptions: [],
            companiesList: [],
            startDate: "",
            endDate: " ",
            companyId: 0,
            therapistId: 0
        }
    }
    UNSAFE_componentWillMount() {
        this.props.getTherapists();
        this.props.getCompanies();
        const data = {
            therapist_id: 0,
            company_id: 0,
            start_date: "",
            end_date: ""
        }
        this.props.getRevenueReport(data);

    }
    componentDidUpdate(prevProps) {
        if (this.props !== prevProps) {
            if (this.props.therapists !== []) {
                var therapistArray = []
                this.props.therapists.forEach(element => {
                    therapistArray.push({ value: element.id, label: element.first_name })
                });
                this.setState({ therapistSearchOptions: therapistArray })
            }
        }
    }
    handleChange = date => {
        this.setState({
            startDate: date.toLocaleDateString()
        });
    };
    pickEndDate = (startDate, endDate) => {
        // var dateStr = moment.unix(startDate).format('YYYY-MM-DD');
        // debugger
        // let timeStr = (0 + ":" + 0),
        //   date = moment(dateStr).utc(false),
        //   time = moment(timeStr, 'HH:mm');

        //   date.set({
        //     hour: 0,
        //     minute: 0,
        //     second: 0
        //   });
        //   var d1 = date.add('1', 'd');
        //   var utcStart = (d1.utc() / 1000);

        // var d1 = new Date(startDate.toLocaleDateString());
        // d1 = d1.getTime();

        // var d2 = new Date(endDate.toLocaleDateString());
        // d2 = d2.getTime();

        var sdate = startDate.toLocaleDateString();
        var edate = endDate.toLocaleDateString();
        this.setState({
            startDate: sdate,
            endDate: edate
        });
    }
    showRevenueReport = () => {
        this.setState({
            showRevenue: true,
            showTherapists: false,
            showCorporate: false,
            companyId: 0,
            therapistId: 0
        })
    }
    showTherapistsReport = () => {
        this.setState({
            showRevenue: false,
            showTherapists: true,
            showCorporate: false
        })
    }
    showCorporateReport = () => {
        this.setState({
            showRevenue: false,
            showTherapists: false,
            showCorporate: true
        })
    }
    getTherapistRevenue = (data) => {
        this.setState({ companyId: 0, therapistId: data })
    }
    getCorporateRevenue = (data) => {
        this.setState({ companyId: data, therapistId: 0 })
    }
    getRevenueData = () => {
        const data = {
            therapist_id: this.state.therapistId,
            company_id: this.state.companyId,
            start_date: this.state.startDate,
            end_date: this.state.endDate
        }
        this.props.getRevenueReport(data);
    }

    render = () => {
        const { t, companies } = this.props;
        var companyList = [];
        if (!isEmpty(companies)) {
            companyList.push({ value: 0, label: t != null && t.translations.select_company })
        }
        companies.forEach(company => {
            companyList.push({ value: company.id, label: company.name })
        });


        return (
            <>
                <Container fluid className="personal-container user-container location-container dashboard report-container">
                    <Col className="right-container col-md-12">
                        <h2 className="pb-5 col-md-8 float-left title-header">
                            {t.translations.reports}
                        </h2>
                    </Col>
                    <Row gutter={40} className="row-container">
                        <Grid item lg="4" md="4" sm="4" xs="12" >
                            <Paper className={this.state.showRevenue ? "report-search-btn" : "disabled-report-search-btn"} onClick={this.showRevenueReport}>Revenue/Payments</Paper>
                        </Grid>
                        <Grid item lg="4" md="4" sm="4" xs="12">
                            <Paper className={this.state.showTherapists ? "report-search-btn" : "disabled-report-search-btn"} onClick={this.showTherapistsReport}>Therapists</Paper>
                        </Grid>
                        <Grid item lg="4" md="4" sm="4" xs="12">
                            <Paper className={this.state.showCorporate ? "report-search-btn" : "disabled-report-search-btn"} onClick={this.showCorporateReport}>Corporate Bookings</Paper>
                        </Grid>
                    </Row>
                    <Row gutter={40} className="row-container">

                        <Col lg="4" md="4" sm="4" xs="12" className="report-date-picker-container" >
                            <label className="Field-label">Select Date</label><br />
                            <RangePicker onDateSelected={this.handleChange} onClose={this.pickEndDate} />
                        </Col>
                        {(this.state.showTherapists && !isEmpty(this.state.therapistSearchOptions)) && <div className="col-md-4 col-xs-12 float-right therapist-search">
                            <Field
                                label={t != null ? t.translations.select_therapist : ''}
                                type="select"
                                ismulti={false}
                                name="category"
                                classes="Field-input"
                                options={this.state.therapistSearchOptions}
                                onChange={this.getTherapistRevenue}
                                component={FormField}
                                t={this.props.t}
                                // defaultValue={!isEmpty(treatmentInfo) ? { label: treatmentInfo.category, value: treatmentInfo.category } : ''}
                                placeholder={t != null ? t.translations.select_therapist : ''}
                            />
                        </div>
                        }
                        {(this.state.showCorporate && !isEmpty(companyList)) && <div className="col-md-4 col-xs-12 float-right therapist-search">
                            <Field
                                label={t != null ? t.translations.select_company : ''}
                                type="select"
                                name="company_id"
                                classes="Field-input menu-list"
                                options={companyList}
                                component={FormField}
                                t={this.props.t}
                                onChange={this.getCorporateRevenue}
                                placeholder={t != null ? t.translations.select_company : ''}
                            />
                        </div>
                        }
                        <Button className="btn-block float-right save-btn Field-input" onClick={this.getRevenueData}>{t != null && t.translations.search_enter_app}</Button>
                    </Row>

                    <Row className="row-container">
                        <Col md="12">
                            {!isEmpty(this.props.revenueResult) && <PieChart />}
                        </Col>
                    </Row>
                    {/* <div className="invoice-box mt-4">
                            <table cellpadding="0" cellspacing="0">
                                <tr className="top">
                                    <td colspan="2">
                                        <table>
                                            <tr>
                                                <td className="title">
                                                    {/* <img src="https://www.sparksuite.com/images/logo.png" style="width:100%; max-width:300px;" /> */}
                    {/* </td>
                                                <td>
                                                    Invoice #: 123<br />
                                                    Created: January 1, 2015<br />
                                                    Due: February 1, 2015
                                            </td>
                                            </tr>
                                        </table>
                                    </td>
                                </tr>

                                <tr className="information">
                                    <td colspan="2">
                                        <table>
                                            <tr>
                                                <td>
                                                    Sparksuite, Inc.<br />
                                                    12345 Sunny Road<br />
                                                    Sunnyville, CA 12345
                                            </td>

                                                <td>
                                                    Acme Corp.<br />
                                                    John Doe<br />
                                                    john@example.com
                                            </td>
                                            </tr>
                                        </table>
                                    </td>
                                </tr>
                                <tr className="heading">
                                    <td>
                                        Payment Method
                                </td>
                                    <td>
                                        Check #
                                </td>
                                </tr>
                                <tr className="details">
                                    <td>
                                        Check
                                </td>

                                    <td>
                                        1000
                                </td>
                                </tr>
                                <tr className="heading">
                                    <td>
                                        Item
                                </td>

                                    <td>
                                        Price
                                </td>
                                </tr>

                                <tr className="item">
                                    <td>
                                        Website design
                                </td>

                                    <td>
                                        $300.00
                                </td>
                                </tr>
                                <tr className="item">
                                    <td>
                                        Hosting (3 months)
                                </td>

                                    <td>
                                        $75.00
                                </td>
                                </tr>

                                <tr className="item last">
                                    <td>
                                        Domain name (1 year)
                                </td>

                                    <td>
                                        $10.00
                                </td>
                                </tr>

                                <tr className="total">
                                    <td></td>

                                    <td>
                                        Total: $385.00
                                </td>
                                </tr>
                            </table>
                        </div> */}

                </Container>
            </>
        );
    }
};

const mapStateToProps = state => {
    return {
        t: state.accountRedux.localeInfo.t,
        dashboardAnalytics: state.dashboardRedux.dashboardAnalytics,
        revenueResult: state.dashboardRedux.revenueResult,
        therapists: state.therapistRedux.therapists,
        companies: state.companyRedux.companies,

    };
};

const mapDispatchToProps = dispatch => {
    return {
        getTherapists: (data) => dispatch(getTherapists(data)),
        getCompanies: () => dispatch(getCompanies()),
        getRevenueReport: (data) => dispatch(getRevenueReport(data))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({
    form: 'ReportForm'
})(Report));