import React from "react";
import MainSidebar from '../MainSidebar/MainSidebar';
let openBar = false;
let testVar = 0
class NavbarToggle extends React.Component {
  constructor(props) {
    testVar += 1;
    console.log("test log in constructor" + testVar)
    console.log("test log openBar " + openBar)

    super(props);
    this.state = {
      opensidebar: false
    }


    console.log("test log opensidebar " + this.state.opensidebar)
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    this.setState({
      opensidebar: true
    })
    openBar = !openBar
  }

  render() {
    return (
      <nav className="nav">
        {openBar && <MainSidebar menuVisible />}
        {<a href="#" onClick={this.handleClick} className="nav-link nav-link-icon toggle-sidebar d-sm-inline d-md-none d-lg-none text-center">
          <i className="material-icons">&#xE5D2;</i>
        </a>}
      </nav>
    )
  }
}

export default NavbarToggle;
