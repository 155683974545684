import { createRoutine } from 'redux-saga-routines';
export const getDashboardAnalytics              = createRoutine('GET_DASHBOARD_ANALYTICS');
export const getDashboardAnalyticsForStack      = createRoutine('GET_DASHBOARD_ANALYTICS_FOR_STACK');
export const getDashboardRevenueReport          = createRoutine('GET_DASHBOARD_REVENUE_REPORT');
export const getRevenueReport                   = createRoutine('GET_REVENUE_REPORT');



const INITIAL_STATE = {
    error: false,
    success: false,
    message: '',
    loading: false,
    dashboardAnalytics: {},
    dashboardAnalyticsStack: {},
    revenueReport: [],
    revenueResult: 0
};
export default (state = INITIAL_STATE, { type, payload }) => {
    switch (type) {
        case getDashboardAnalytics.TRIGGER:
            return { ...state, loading: true, error: false, success: false, message: '', dashboardAnalytics: {} };
        case getDashboardAnalytics.SUCCESS:
            return { ...state, loading: false, error: false, success: true, dashboardAnalytics: payload };
        case getDashboardAnalytics.FAILURE:
            return { ...state, loading: false, success: false, error: true, message: payload };

        case getDashboardAnalyticsForStack.TRIGGER:
            return { ...state, loading: true, error: false, success: false, message: '', dashboardAnalyticsStack: {} };
        case getDashboardAnalyticsForStack.SUCCESS:
            return { ...state, loading: false, error: false, success: true, dashboardAnalyticsStack: payload };
        case getDashboardAnalyticsForStack.FAILURE:
            return { ...state, loading: false, success: false, error: true, message: payload };

                
        case getDashboardRevenueReport.TRIGGER:
            return { ...state, loading: true, error: false, success: true, message: '', revenueReport: [] };
        case getDashboardRevenueReport.SUCCESS:
            return { ...state, loading: false, error: false, success: true, revenueReport: payload };
        case getDashboardRevenueReport.FAILURE:
            return { ...state, loading: false, message: payload };

        case getRevenueReport.TRIGGER:
            return { ...state, loading: true, error: false, success: true, message: '', revenueResult: 0 };
        case getRevenueReport.SUCCESS:
            return { ...state, loading: false, error: false, success: true, revenueResult: payload };
        case getRevenueReport.FAILURE:
            return { ...state, loading: false, message: payload };

        default:
            return { ...state }

    }
}