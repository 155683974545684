import moment from 'moment';
import { isEmpty } from 'lodash';

export const required = value => value ? undefined :  'required_field_error_message';

export const isZeroOrNegative = value => value > 0 ? undefined : ' is never be zero or negative';

export const validEmail = value => /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value) ? undefined : 'is_not_valid_validation_message';

export const validPhone = value => /^\+[1-9]\d{1,14}$/i.test(value) ? undefined : 'phone_not_valid_validation_message';

export const passwordsMustMatch = (value = '', { password = '' } = {}) => !value || value === password ? undefined : 'repeat_password_must_match'

export const isOnlySpaces = (value = '') => !/\S/.test(value) ? ' contains only spaces' : undefined

export const isTime = value => moment(value, "hh:mm a").isValid() ? undefined : ' not valid';

export const matchDate = (value = '', { startDate = '' } = {}) => value > startDate ? ' should not be greater than event date' : null

export const isPositiveNumber = value => value >-1 || isEmpty(value) ? undefined : 'negative_error_message';

export const isTimeValid = value => isEmpty(value) || moment(value, "hh:mm a").isValid() ? undefined : 'is_not_valid_validation_message';

export const validPersonNumber = value => /^\d{6,8}[-|(\s)]{0,1}\d{4}$/i.test(value) ? undefined : 'is_not_valid_validation_message';

export const validOrgNum = value => /^(\d{1})(\d{5})[-]{0,1}(\d{4})$/i.test(value) ? undefined : 'is_not_valid_validation_message';

export const validVATNum=value=>/^(SE)?[0-9]{12}$/i.test(value)?undefined:'is_not_valid_validation_message';

export const percentageMatch=value=>/^(0*100{1,1}\.?((?<=\.)0*)?%?$)|(^0*\d{0,2}\.?((?<=\.)\d*)?%?)$/i.test(value)?undefined:'percentage_match_error_message';
