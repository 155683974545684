/* eslint eqeqeq: 0 */
import React, { Fragment } from 'react';
import MaterialTable from 'material-table';
import { Col } from 'shards-react';
import { isEmpty } from 'lodash'
import { PENDING, AUTHORIZED, CANCELED, COMPLETED, REFUNDED, PAYMENT_INITIATED, BOOKED_PAID, SWISH_REFUND, DISCARD } from '../../constants/urls';

var temp = "";
// const exportCsv = (columnList, initialData) => {
//     const columns = columnList.filter(columnDef => {
//         return !columnDef.hidden && columnDef.field && columnDef.export !== false;
//     });

//     const data = initialData.map(rowData =>
//         columns.map(columnDef => {
//             return columnDef.render ? columnDef.render(rowData) : rowData[columnDef.field];
//         })
//     );

//     const builder = new CsvBuilder('data' + '.csv');
//     builder
//         .setDelimeter(',')
//         .setColumns(columns.map(columnDef => columnDef.title))
//         .addRows(data)
//         .exportFile();
// };

class MaterialTableDemo extends React.Component {

    getColorForState(state) {


        switch (state) {
            case PENDING:
                return "orange";

            case PAYMENT_INITIATED:
                return "orange";

            case AUTHORIZED:
                return "orange";


            case BOOKED_PAID:
                return "orange";

            case SWISH_REFUND:
                return "blue";

            case CANCELED:
                return "red";

            case COMPLETED:
                return "green";

            case REFUNDED:
                return "blue";
            case DISCARD:
                return "gray";

            default:
                return "white";

        }
    }

    getTherapistTotal(data) {
        const totalHrs = data.reduce((prev, next) => prev + (next.revenue_6_percent + next.revenue_25_percent), 0);

        // render: rowData => <span>{ rowData != undefined ? ( (rowData.is_refunded ? "-" : "") +  (rowData.isMoreThanThreeHrs ? (rowData.revenue_6_percent + rowData.revenue_25_percent) * 0.70 :  (rowData.revenue_6_percent + rowData.revenue_25_percent) * 0.65 ).toFixed(2) + " SEK") : "" }</span>

        return (totalHrs).toFixed(2) + " SEK";
    }

    checkObj(obj) {
        var t = obj.filter(el => {
            // console.log("GroupRow3: " + JSON.stringify(el["tableData"]));
            var cond = (el["tableData"]["groupOrder"] != undefined);
            return cond;
        });

        console.log("checkObj: " + JSON.stringify(t));
        temp = t;
    }

    process(rowData) {
        const { t } = this.props;
        // var obj = temp;
        var data = rowData["groupData"];
        console.log("process: " + JSON.stringify(data));
        var title_ = temp[0]["title"];
        var name_ = data["value"];

        if (title_ == t.translations.company) {
            return `${title_}: ${name_} - 90 SEK`
            // return title_ + ": " + name_ + "  - " + "90 SEK";
        }
        return t.translations.staff + ": " + rowData.therapist_name + "  - " + this.getTherapistTotal(this.props.data);


        // debugger;
        // var str = "test";
        // if (t != null) {
        //     if (title_ == t.translations.company) {
        //         str = title_ + ": " + data["value"] + "  - " + "90 SEK";
        //     }
        //     else if (title_ == t.translations.company) {
        //         // str = title_ + ": " + rowData.therapist_name + "  - " + this.getTherapistTotal(this.props.data);
        //     }
        // }


        // return str;
    }

    render() {
        ////////debugger;
        const { t, loading, view, justShow, showExpand, selected_row_id } = this.props;
        return (
            <Fragment>
                <Col className="col-md-12 mt-5 data-table-container">
                    {loading && <i className="fa fa-spinner fa-spin"></i>}
                    {justShow == 1 && <MaterialTable
                        title=""
                        columns={this.props.columns}
                        data={!isEmpty(this.props.data) ? this.props.data : []}
                        actions={[{
                            icon: 'visibility',
                            tooltip: 'view',
                            // onClick: (event, rowData) => this.props.showDetail(rowData)
                        }]
                        }
                        localization={{
                            header: {
                                actions: this.props.showPayment ? t.translations.refund : t.translations.actions
                            },
                            body: {
                                emptyDataSourceMessage: t.translations.no_record_in_table_message
                            }
                        }}
                        onRowClick={(event, rowData, togglePanel) => this.props.showDetail(rowData)}
                        options={{
                            pageSize: 20,
                            actionsColumnIndex: -1
                        }}
                    />}
                    {justShow == 2 && <MaterialTable
                        title=""
                        columns={this.props.columns}
                        data={!isEmpty(this.props.data) ? this.props.data : []}
                        actions={[{
                            icon: 'delete',
                            tooltip: t.translations.delete,
                            onClick: (event, rowData) => this.props.deleteDetail(rowData),
                        }]
                        }
                        localization={{
                            header: {
                                actions: this.props.showPayment ? t.translations.refund : t.translations.actions
                            },
                            body: {
                                emptyDataSourceMessage: t.translations.no_record_in_table_message
                            }
                        }}
                        options={{
                            pageSize: 20,
                            actionsColumnIndex: -1
                        }}
                    />}
                    {justShow == 0 && (loading == false) && <MaterialTable
                        title=""
                        columns={this.props.columns}
                        data={!isEmpty(this.props.data) ? this.props.data : []}
                        actions={view ? [
                            {
                                icon: 'visibility',
                                tooltip: 'view',
                                onClick: (event, rowData) => this.props.showDetail(rowData)
                            },
                            // {
                            //     icon: 'edit',
                            //     tooltip: t.translations.edit,
                            //     onClick: (event, rowData) => this.props.showDetail(rowData)
                            // },
                            rowData => ({
                                icon: 'delete',
                                tooltip: t.translations.delete,
                                onClick: (event, rowData) => this.props.deleteDetail(rowData),
                            })
                        ] :
                            (!this.props.hideDelete && this.props.removeEditIcon !== true) && [
                                {
                                    icon: 'edit',
                                    tooltip: t.translations.edit,
                                    onClick: (event, rowData) => this.props.showDetail(rowData)
                                },
                                rowData => ({
                                    icon: 'delete',
                                    tooltip: t.translations.delete,
                                    onClick: (event, rowData) => this.props.deleteDetail(rowData),
                                })
                            ]
                            // :[ rowData => ({
                            //     icon: 'delete',
                            //     tooltip: t.translations.refund,
                            //     onClick: (event, rowData) => this.props.deleteDetail(rowData),
                            // })]
                        }
                        onRowClick={(event, rowData, togglePanel) => (view ? this.props.showDetail(rowData) : console.log('No row event triggered'))}
                        localization={{
                            header: {
                                actions: this.props.showPayment ? t.translations.refund : t.translations.actions
                            },
                            body: {
                                emptyDataSourceMessage: t.translations.no_record_in_table_message
                            }
                        }}
                        options={{
                            sorting: true,
                            exportButton: true,
                            filtering: true,
                            pageSize: 20,
                            actionsColumnIndex: -1,
                            rowStyle: rowData => ({
                                backgroundColor: (selected_row_id != -1 && rowData.raw == selected_row_id) ? 'red' : 'white'

                            })
                        }}
                    />}
                    {/* just show 4 is for booking panel */}
                    {justShow == 4 && <MaterialTable
                        title=""
                        columns={this.props.columns}
                        data={!isEmpty(this.props.data) ? this.props.data : []}
                        onRowClick={(event, rowData, togglePanel) => this.props.showDetail(rowData)}
                        localization={{
                            header: {
                                actions: this.props.showPayment ? t.translations.refund : t.translations.actions
                            },
                            body: {
                                emptyDataSourceMessage: t.translations.no_record_in_table_message
                            }
                        }}
                        options={{
                            sorting: true,
                            exportButton: true,
                            filtering: true,
                            pageSize: 20,
                            actionsColumnIndex: -1,
                            rowStyle: rowData => ({
                                backgroundColor: this.getColorForState(rowData.status)

                            })
                            // rowStyle: rowData => ({
                            //     backgroundColor: (rowData.status === "Pending") ? '#EEE' : '#FFF'
                            // })
                        }}
                    />}
                    {/* just show 14 is for booking panel */}
                    {justShow == 14 && <MaterialTable
                        title=""
                        columns={this.props.columns}
                        data={!isEmpty(this.props.data) ? this.props.data : []}
                        actions={[
                            // {
                            //     icon: 'room',
                            //     tooltip: t.translations.locations,
                            //     onClick: (event, rowData) => this.props.locationsDetail(rowData)
                            // },
                            // {
                            //     icon: 'spa',
                            //     tooltip: t.translations.treatment_list,
                            //     onClick: (event, rowData) => this.props.treatmentsDetail(rowData)
                            // },
                            {
                                icon: 'edit',
                                tooltip: t.translations.edit,
                                onClick: (event, rowData) => this.props.editDetail(rowData)
                            },
                            {
                                icon: 'delete',
                                tooltip: 'Disable',
                                onClick: (event, rowData) => this.props.deleteDetail(rowData)
                            },
                        ]}
                        // onRowClick={(event, rowData, togglePanel) => this.props.showDetail(rowData)}
                        localization={{
                            header: {
                                actions: this.props.showPayment ? t.translations.refund : t.translations.actions
                            },
                            body: {
                                emptyDataSourceMessage: t.translations.no_record_in_table_message
                            }
                        }}
                        options={{
                            sorting: true,
                            exportButton: true,
                            filtering: true,
                            pageSize: 20,
                            actionsColumnIndex: -1,
                            rowStyle: rowData => ({
                                backgroundColor: this.getColorForState(rowData.status)

                            })
                            // rowStyle: rowData => ({
                            //     backgroundColor: (rowData.status === "Pending") ? '#EEE' : '#FFF'
                            // })
                        }}
                    />}
                    {/* just show 6 is for therapist reports panel */}
                    {justShow == 6 && <MaterialTable
                        title=""
                        columns={this.props.columns}
                        data={!isEmpty(this.props.data) ? this.props.data : []}
                        onRowClick={(event, rowData, togglePanel) => this.props.showDetail(rowData)}
                        localization={{
                            header: {
                                actions: this.props.showPayment ? t.translations.refund : t.translations.actions
                            },
                            body: {
                                emptyDataSourceMessage: t.translations.no_record_in_table_message
                            }
                        }}
                        options={{
                            sorting: true,
                            exportButton: true,
                            filtering: true,
                            pageSize: 20,
                            actionsColumnIndex: -1,
                            // rowStyle: rowData => ({
                            //     backgroundColor: (rowData.tota_day_hrs > 180  ? '#90EE90' : 'white'),
                            // })
                            rowStyle: rowData => ({
                                backgroundColor: (rowData.is_refunded ? '#FFE5CC' : 'white')
                            })
                        }}
                        components={{
                            Toolbar: props => (
                                <div style={{ padding: 20 }}>
                                    <h3>{(t != null ? t.translations.my_revenue : 'Total Revenue') + " i " + this.props.month + ": " + this.props.month_revenue + " SEK"}</h3>
                                </div>
                            ),
                        }}
                    />}
                    {/* just show 5 is for user panel */}
                    {justShow == 5 && <MaterialTable
                        title=""
                        columns={this.props.columns}
                        data={!isEmpty(this.props.data) ? this.props.data : []}
                        actions={[
                            {
                                icon: 'edit',
                                tooltip: t.translations.edit,
                                onClick: (event, rowData) => this.props.showDetail(rowData)
                            },
                            {
                                icon: 'today',
                                tooltip: t.translations.bookings,
                                onClick: (event, rowData) => this.props.showBookings(rowData)
                            },
                            {
                                icon: 'home',
                                tooltip: t.translations.address,
                                onClick: (event, rowData) => this.props.pullAddress(rowData)
                            },
                            // {
                            //     icon: 'notifications',
                            //     tooltip: t.translations.notifications,
                            //     onClick: (event, rowData) => {
                            //         if (this.props.data.device_token === "") {
                            //             alert("NO token available");
                            //         }
                            //         else {
                            //             this.props.sendNotification(rowData);
                            //         }
                            //     },
                            //     disabled: false
                            // },
                            rowData => ({
                                icon: rowData.notifiable ? 'notifications' : 'gray-notifications',
                                tooltip: t.translations.notifications,
                                onClick: (event, rowData) => { this.props.sendNotification(rowData) },
                                disabled: rowData.notifiable, //(rowData.device_token === "device_token" || rowData.device_token === "sDeviceToken") ? true : false
                            })
                            // rowData => ({
                            //     icon: 'delete',
                            //     tooltip: t.translations.delete,
                            //     onClick: (event, rowData) => this.props.deleteDetail(rowData),
                            // })
                        ]}
                        // onRowClick={(event, rowData, togglePanel) => this.props.showDetail(rowData)}
                        localization={{
                            header: {
                                actions: t.translations.actions
                            },
                            body: {
                                emptyDataSourceMessage: t.translations.no_record_in_table_message
                            }
                        }}
                        options={{
                            sorting: true,
                            exportButton: true,
                            // filtering: true,
                            pageSize: 20,
                            actionsColumnIndex: -1
                        }}
                    />}
                    {/* just show 7 is for cron panel */}
                    {justShow == 7 && <MaterialTable
                        title=""
                        columns={this.props.columns}
                        data={!isEmpty(this.props.data) ? this.props.data : []}
                        // actions={[
                        //     {
                        //         icon: 'edit',
                        //         tooltip: t.translations.edit,
                        //         onClick: (event, rowData) => this.props.showDetail(rowData)
                        //     },
                        //     {
                        //         icon: 'today',
                        //         tooltip: t.translations.bookings,
                        //         onClick: (event, rowData) => this.props.showBookings(rowData)
                        //     },
                        //     // rowData => ({
                        //     //     icon: 'delete',
                        //     //     tooltip: t.translations.delete,
                        //     //     onClick: (event, rowData) => this.props.deleteDetail(rowData),
                        //     // })
                        // ]}
                        // onRowClick={(event, rowData, togglePanel) => this.props.showDetail(rowData)}
                        localization={{
                            header: {
                                actions: t.translations.actions
                            },
                            body: {
                                emptyDataSourceMessage: t.translations.no_record_in_table_message
                            }
                        }}
                        options={{
                            sorting: true,
                            exportButton: true,
                            filtering: true,
                            pageSize: 20,
                            actionsColumnIndex: -1
                        }}
                    />}
                    {(justShow == 8 && showExpand) && <MaterialTable
                        title=""
                        columns={this.props.columns}
                        data={!isEmpty(this.props.data) ? this.props.data : []}
                        onRowClick={(event, rowData, togglePanel) => this.props.showDetail(rowData)}
                        localization={{
                            header: {
                                actions: this.props.showPayment ? t.translations.refund : t.translations.actions
                            },
                            body: {
                                emptyDataSourceMessage: t.translations.no_record_in_table_message
                            }
                        }}
                        options={{
                            sorting: true,
                            exportButton: true,
                            exportAllData: true,
                            filtering: true,
                            pageSize: 20,
                            actionsColumnIndex: -1,
                            rowStyle: rowData => ({
                                backgroundColor: (rowData.isMoreThanThreeHrs ? (rowData.is_refunded ? '#FFE5CC' : '#90EE90') : (rowData.is_refunded ? '#FFE5CC' : 'white')),
                            })
                        }}
                        components={{
                            Toolbar: props => (
                                <div style={{ padding: 20 }}>
                                    <h3>{this.props.selected_month}</h3>
                                </div>
                            ),
                        }}
                        detailPanel={rowData => {
                            return (
                                <iframe
                                    title="youtube"
                                    width="100%"
                                    height="315"
                                    src="https://www.youtube.com/embed/C0DPdy98e4c"
                                    frameborder="0"
                                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                    allowfullscreen
                                />
                            )
                        }}
                    />}
                    {justShow == 8 && <MaterialTable
                        title=""
                        columns={this.props.columns}
                        data={!isEmpty(this.props.data) ? this.props.data : []}
                        // onRowClick={(event, rowData, togglePanel) => this.props.showDetail(rowData)}
                        localization={{
                            header: {
                                actions: this.props.showPayment ? t.translations.refund : t.translations.actions
                            },
                            body: {
                                emptyDataSourceMessage: t.translations.no_record_in_table_message
                            }
                        }}
                        options={{
                            search: false,
                            sorting: true,
                            grouping: true,
                            exportButton: true,
                            exportAllData: true,
                            // exportCsv: exportCsv_,
                            // selection: true,
                            filtering: true,
                            pageSize: 20,
                            actionsColumnIndex: -1,
                            rowStyle: rowData => ({
                                backgroundColor: (rowData.isMoreThanThreeHrs ? (rowData.is_refunded ? '#FFE5CC' : '#90EE90') : (rowData.is_refunded ? '#FFE5CC' : 'white')),
                            })
                        }}
                        components={{
                            // Groupbar: rowData => (
                            //     <div style={{ backgroundColor: 'red' }}>
                            //        {console.log("Groupbar: " + JSON.stringify(rowData))}
                            //        <p>{"Hello"}</p>
                            //     </div>
                            // ),
                            // GroupRow: rowData => (
                            //     <div style={{ padding: 0}}>
                            //         {console.log("GroupRow1: " + JSON.stringify(rowData))}
                            //         {/* {this.checkObj(rowData.columns)} */}
                            //         {/* {"Hello"} */}
                            //         <MTableGroupRow  {...rowData} />
                            //         {/* <p>{this.process(rowData)}</p> */}
                            //     </div>
                            // ),
                            // Toolbar: props => (
                            //   <div style={{ padding: 20}}>
                            //       <h3>{this.props.selected_month}</h3>
                            //   </div>
                            // ),
                        }}
                    />}
                    {justShow == 10 && <MaterialTable
                        title=""
                        columns={this.props.columns}
                        data={!isEmpty(this.props.data) ? this.props.data : []}
                        // onRowClick={(event, rowData, togglePanel) => this.props.showDetail(rowData)}
                        localization={{
                            header: {
                                actions: this.props.showPayment ? t.translations.refund : t.translations.actions
                            },
                            body: {
                                emptyDataSourceMessage: t.translations.no_record_in_table_message
                            }
                        }}
                        options={{
                            // sorting: true,
                            // search: true,
                            // grouping: true,
                            // filtering: true,
                            // exportButton: true,
                            // exportAllData: true,
                            // pageSize: 20,
                            // // actionsColumnIndex: -1,
                            // rowStyle: rowData => ({
                            // backgroundColor: (rowData.is_refunded ? '#FFE5CC' : 'white'),
                            //     // exportButton: true,
                            // })
                            sorting: true,
                            search: false,
                            exportButton: true,
                            exportAllData: true,
                            filtering: true,
                            // exportCsv: (columnList, initialData) => {
                            //   // alert('You should develop a code to export ' + initialData.length + ' rows');
                            //   const columns = columnList.filter(columnDef => {
                            //     return !columnDef.hidden && columnDef.field && columnDef.export !== false;
                            //   });

                            //   const data = initialData.map(rowData =>
                            //     columns.map(columnDef => {
                            //       return columnDef.render ? columnDef.render(rowData) : rowData[columnDef.field];
                            //     })
                            //   );

                            //   // CsvBuilder.default.
                            //   const builder = new CsvBuilder('data.csv');
                            //   builder.setDelimeter(',')
                            //     .setColumns(columns.map(columnDef => columnDef.title))
                            //     .addRows(data)
                            //     .exportFile();

                            //         // eslint-disable-next-line no-unused-vars
                            //     //   const builder = new CsvBuilder((this.props.exportFileName || this.props.title || 'data') + '.csv')
                            //     //   .setDelimeter(this.props.exportDelimiter)
                            //     //   .setColumns(columns.map(columnDef => columnDef.title))
                            //     //   .addRows(data)
                            //     //   .exportFile();

                            //     // this.setState({ exportButtonAnchorEl: null });
                            // },
                            pageSize: 20,
                            actionsColumnIndex: -1,
                            rowStyle: rowData => ({
                                backgroundColor: (rowData.is_refunded ? '#FFE5CC' : 'white'),

                            })
                        }}
                    // detailPanel= {
                    //     rowData => ({
                    //         // icon: rowData.id > 1000 ? 'visibility' : 'gray-visibility',
                    //         disabled: false, //(rowData.device_token === "device_token" || rowData.device_token === "sDeviceToken") ? true : false
                    //         // disabled: !(rowData.id > 1000) ,
                    //         icon: () => <UnfoldMore className={rowData.id > 1000 && classes.displayNone} />,
                    //         openIcon: UnfoldLess,
                    //         render: () => <div>{rowData.id}</div>,
                    //     })
                    // }
                    // components={{
                    //     Toolbar: props => (
                    //       <div style={{ padding: 20}}>
                    //           <h3>{this.props.selected_month}</h3>
                    //       </div>
                    //     ),
                    //   }}
                    />}
                    {justShow == 9 && <MaterialTable
                        title=""
                        columns={this.props.columns}
                        data={!isEmpty(this.props.data) ? this.props.data : []}
                        localization={{
                            header: {
                                actions: t.translations.refund_1
                            },
                            body: {
                                emptyDataSourceMessage: t.translations.no_record_in_table_message
                            }
                        }}
                        actions={[
                            // {
                            //     icon: 'visibility',
                            //     tooltip: t.translations.email_receipt_title,
                            //     onClick: (event, rowData) => this.props.showDetail(rowData)
                            // },
                            // {
                            //     icon: 'visibility',
                            //     tooltip: t.translations.email_refund_title,
                            //     onClick: (event, rowData) => this.props.showRefund(rowData)
                            // },
                            rowData => ({
                                icon: rowData.refund ? 'visibility' : 'gray-visibility',
                                tooltip: t.translations.email_refund_title,
                                onClick: (event, rowData) => { this.props.showRefund(rowData) },
                                disabled: false, //(rowData.device_token === "device_token" || rowData.device_token === "sDeviceToken") ? true : false
                            })
                        ]}
                        onRowClick={(event, rowData, togglePanel) => this.props.showDetail(rowData)}
                        options={{
                            actionsColumnIndex: 4,
                            sorting: true,
                            grouping: true,
                            exportButton: true,
                            // selection: true,
                            filtering: true,
                            pageSize: 20,
                            // rowStyle: rowData => ({
                            //     backgroundColor: (rowData.isMoreThanThreeHrs  ? '#90EE90' : 'white'),
                            // })
                        }}
                    // components={{
                    //     Toolbar: props => (
                    //       <div style={{ padding: 20}}>
                    //           <h3>{this.props.selected_month}</h3>
                    //       </div>
                    //     ),
                    //   }}
                    />}
                </Col>
            </Fragment>
        )
    }
}

export default MaterialTableDemo;