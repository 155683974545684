import {applyMiddleware, compose, createStore} from 'redux'
import {persistStore} from 'redux-persist'
import createSagaMiddleware from 'redux-saga';
import rootReducer from './redux'
import rootSaga from './sagas'
import {routerMiddleware} from 'react-router-redux';
import {createBrowserHistory} from 'history';

export const history = createBrowserHistory();
const sagaMiddleware = createSagaMiddleware();

const composeEnhancers = process.env.NODE_ENV === 'development' ? (window).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose : compose;

const store = createStore(
   rootReducer,
   composeEnhancers(applyMiddleware(sagaMiddleware, routerMiddleware(history)))
);

export const persistor = persistStore(store);

sagaMiddleware.run(rootSaga);

export default store;
