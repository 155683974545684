/* eslint eqeqeq: 0 */
import React, { Fragment } from 'react';
import { Col, FormInput, FormTextarea, FormGroup, FormFeedback } from "shards-react";
import * as moment from 'moment';
// import DatePicker from 'react-datepicker';
import Select from 'react-select';
import { isEmpty, undefined } from 'lodash';
import ImageDropZone from '../components/common/ImageDropZone';
import DatePicker from 'react-date-picker'
import TimePicker from 'react-time-picker';
import PhoneInput from 'react-phone-number-input';


const onSelect = (onChange, propChange) => (selectedValue) => {

  if (isEmpty(selectedValue)) {
    onChange(selectedValue);
    propChange && propChange(selectedValue);
  } else {

    onChange(selectedValue.value);
    propChange && propChange(selectedValue.value, selectedValue.name);
  };
}
const onSelect2 = (onChange, propChange) => (selectedValue) => {
  if (isEmpty(selectedValue)) {
    onChange(selectedValue);
    propChange && propChange(selectedValue);
  } else {
    propChange && propChange(selectedValue.value, selectedValue.name);
    onChange(selectedValue);
  };
}
const timeOptions = [];
new Array(24).fill().forEach((acc, index) => {
  let label = moment({ hour: index }).format('hh:mm A');
  timeOptions.push({ value: label, label });
  label = moment({ hour: index, minute: 30 }).format('hh:mm A');
  timeOptions.push({ value: label, label });
})

const onDateChange = onChange => value => {
  const utc = new Date(value.getFullYear(), value.getMonth(), value.getDate(), 0, 0, 0);
  onChange(utc);
}
const customStyles = {
  option: (provided, state) => ({
    ...provided,
    borderBottom: '1px dotted pink',
    fontSize: '18px',
    padding: 20
  }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = 'opacity 300ms';

    return { ...provided, opacity, transition };
  }
}

const InputType = (props) => {
  const fieldProps = { ...props.fieldProps };
  // console.log('defaultValue______: ' + JSON.stringify(fieldProps.defaultValue) + " => " + props.type);
  // console.log('DISABLED______: ' + JSON.stringify(fieldProps) + " = " + fieldProps.disableField);


  // console.log('OPTIONS123: ' + JSON.stringify(props.options) + " => " + (fieldProps.defaultValue === "None" ? props.options[0] : fieldProps.defaultValue));

  switch (props.type) {
    case 'mobile_phone':
      return <PhoneInput
        placeholder={fieldProps.placeholder}
        // value={fieldProps.defaultValue}
        value={JSON.stringify(fieldProps.defaultValue)}
        className="Field-input"
        length={12}
        onChange={fieldProps.onChange} />
    case 'multiSelect':
      return <Fragment>
        <Select
          isDisabled={fieldProps.disableField}
          styles={customStyles}
          isMulti={true}
          defaultValue={fieldProps.defaultValue}
          options={props.options}
          onChange={onSelect2(fieldProps.onChange, props.onSelect)}
          required={true}
          oninvalid={fieldProps.errorMessage}
          className={`form-control dropup basic-multi-select selectbox-container ${fieldProps.className} ${(!isEmpty(fieldProps.defaultValue) || !isEmpty(fieldProps.value)) ? '' : 'is-invalid'}`}
          placeholder={fieldProps.placeholder}
          noOptionsMessage={() => fieldProps.noOptionsMessage}
          {...props.pluginOptions}
        />
        <div class="invalid-feedback">{fieldProps.errorMessage}</div>
      </Fragment>
    case 'multiSelect1':
      return <Fragment>
        <Select
          isDisabled={fieldProps.disableField}
          styles={customStyles}
          isMulti={true}
          value={fieldProps.defaultValue}
          options={props.options}
          onChange={onSelect2(fieldProps.onChange, props.onSelect)}
          required={true}
          oninvalid={fieldProps.errorMessage}
          className={`form-control selectbox-container ${fieldProps.className} ${fieldProps.invalid ? 'is-invalid' : ''}`}
          placeholder={fieldProps.placeholder}
          noOptionsMessage={() => fieldProps.noOptionsMessage}
          {...props.pluginOptions}
        />
        <div class="invalid-feedback">{fieldProps.errorMessage}</div>
      </Fragment>
    // return <Fragment>
    //   <Select
    //     styles={customStyles}
    //     ismulti={fieldProps.ismulti}
    //     defaultValue={fieldProps.defaultValue}
    //     options={props.options}
    //     onChange={onSelect(fieldProps.onChange, props.onSelect)}
    //     required={true}
    //     oninvalid={fieldProps.errorMessage}
    //     className={`form-control dropup basic-multi-select selectbox-container select-box-cont ${fieldProps.className} ${(!isEmpty(fieldProps.defaultValue) || !isEmpty(fieldProps.value)) ? '' : 'is-invalid'}`}
    //     placeholder={fieldProps.placeholder}
    //     noOptionsMessage={() => fieldProps.noOptionsMessage}
    //     {...props.pluginOptions}
    //   />
    //   <div class="invalid-feedback">{fieldProps.errorMessage}</div>
    // </Fragment>
    case 'select':
      const selectStyles = {
        menuPortal: base => ({ ...base, zIndex: 9999 }),
        menu: provided => ({ ...provided, zIndex: "9999 !important" })
      };
      // debugger;
      return <Select
        isDisabled={fieldProps.disableField}
        styles={selectStyles}
        isMulti={false}
        // defaultValue={fieldProps.defaultValue}
        // value={fieldProps.defaultValue === "None" ? props.options[0] : fieldProps.defaultValue}
        value={fieldProps.defaultValue}
        options={props.options} //basic-multi-select
        onChange={onSelect(fieldProps.onChange, props.onSelect)}
        className={`form-control selectbox-container ${fieldProps.className} ${fieldProps.invalid ? 'is-invalid' : ''}`}
        placeholder={fieldProps.placeholder}
        noOptionsMessage={() => fieldProps.noOptionsMessage}
        {...props.pluginOptions}
      />
    case 'my-select':
      const selectStyles1 = {
        menuPortal: base => ({ ...base, zIndex: 9999 }),
        menu: provided => ({ ...provided, zIndex: "9999 !important" })
      };
      var val_res = props.options.find(op => {
        return op.value === fieldProps.selected
      });

      return <Select
        isDisabled={fieldProps.disableField}
        styles={selectStyles1}
        isMulti={false}
        // defaultValue={fieldProps.defaultValue}
        // value={fieldProps.defaultValue === "None" ? props.options[0] : fieldProps.defaultValue}
        // value={fieldProps.defaultValue}
        options={props.options}
        value={val_res}
        onChange={onSelect(fieldProps.onChange, props.onSelect)}
        className={`form-control selectbox-container ${fieldProps.className} ${fieldProps.invalid ? 'is-invalid' : ''}`}
        placeholder={fieldProps.placeholder}
        noOptionsMessage={() => fieldProps.noOptionsMessage}
        {...props.pluginOptions}
      />
    case 'textarea':
      const rows = props.pluginOptions.rows || 3;
      return <FormTextarea {...fieldProps} rows={rows} />

    case 'date':
      // delete fieldProps['value'];
      return <div className="form-control date-picker-control1">
        <DatePicker
          styles={customStyles}
          // value={fieldProps.defaultValue}
          // selected={this.state.startDate}
          selected={fieldProps.defaultValue || undefined}
          className={`my-datepicker`}
          onChange={onDateChange(fieldProps.onChange)}
          dateFormat="MM/dd/yyyy"
          placeholderText={fieldProps.placeholder}
          autoComplete="off"
          disabledKeyboardNavigation
          popperModifiers={{
            preventOverflow: {
              enabled: true,
              escapeWithReference: false, // force popper to stay in viewport (even when input is scrolled out of view)
              boundariesElement: 'viewport'
            }
          }}
        /></div>
    case 'time':
      delete fieldProps['value'];
      return <div className="form-control date-picker-control1">
        <DatePicker
          className={`my-datepicker`}
          defaultValue={fieldProps.defaultValue}
          // onChange={date => setStartDate(date)}
          showTimeSelect
          showTimeSelectOnly
          timeIntervals={15}
          timeCaption="Time"
          dateFormat="HH:mm"
        />
      </div>
    case 'file':
      return <ImageDropZone {...props.options} {...fieldProps} />
    case 'timeField':
      delete fieldProps['type'];
      return <FormInput {...fieldProps} type="number" min="0" max="2400" step="30" value={fieldProps.defaultValue} />

    case 'myTimePicker':
      return <div className="form-control picker1">
        <TimePicker
          disableClock={true}
          format={'HH:mm'}
          clearIcon={null}
          onChange={fieldProps.onChange}
          value={fieldProps.defaultValue}
        />
      </div>
    case 'pbTimePicker':
      return <div className="form-control picker1">
        <TimePicker
          // disableClock={true}
          format={'HH:mm'}
          clearIcon={null}
          onChange={fieldProps.onChange}
          value={fieldProps.defaultValue}
        />
      </div>
    case 'myDatePicker':
      return <div className="form-control picker1">
        <DatePicker
          disabled={fieldProps.disableField}
          // disableCalendar={true}
          format={'y-MM-dd'}
          clearIcon={null}
          onChange={fieldProps.onChange}
          value={fieldProps.defaultValue}
          selected={fieldProps.defaultValue || undefined}
        />
      </div>
    case 'pbDatePicker':
      return <div className="form-control picker1">
        <DatePicker
          disabled={fieldProps.disableField}
          // disableCalendar={true}
          format={'y-MM-dd'}
          showLeadingZeros={false}
          clearIcon={null}
          onChange={fieldProps.onChange}
          value={fieldProps.defaultValue}
        />
      </div>
    case 'vat':
      console.log('vat field');
      return <FormInput {...fieldProps} autoComplete="on" disabled={true} maxLength={fieldProps.maxLength} />
    case 'text1':
      console.log('text1 field');

      return <FormInput {...fieldProps} onChange={fieldProps.onChange} innerRef={fieldProps.innerRef} />
    default:
      return <FormInput {...fieldProps} autoComplete="on" disabled={fieldProps.disableField} maxLength={fieldProps.maxLength} />
  }
}

export const FormField = ({
  meta: { touched, error },
  input,
  type,
  id,
  label,
  placeholder,
  gridClasses,
  classes,
  options,
  selected,
  defaultValue,
  pluginOptions = {},
  disabled,
  onSelect,
  noOptionsMessage,
  ismulti,
  disableField,
  maxLength,
  t,
  errorMessage,
  innerRef
}) => {
  const fieldProps = {
    ...input,
    type,
    id,
    placeholder: placeholder,
    className: classes,
    noOptionsMessage: noOptionsMessage,
    invalid: !!(touched && error),
    defaultValue,
    selected,
    ismulti,
    disableField,
    maxLength,
    errorMessage,
    innerRef
  };
  var errorResponse = (placeholder != undefined ? placeholder : label) + "  " + t.translations[error];
  return <Col className={gridClasses}>
    <FormGroup>
      {!isEmpty(label) && <label className="Field-label">{label}</label>}
      <InputType type={type} fieldProps={fieldProps} options={options} placeholder={placeholder} pluginOptions={pluginOptions} disabled={disabled} onSelect={onSelect} />
      <FormFeedback>{touched && error ? (errorResponse) : undefined}</FormFeedback>
    </FormGroup>
  </Col>
}
