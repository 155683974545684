import React, { Component } from 'react';
import { Col, Button } from 'shards-react';
import { isEmpty } from 'lodash';
class Header extends Component {
    constructor(props) {
        super(props);
        this.goBack = this.goBack.bind(this);
    }
    goBack = () => (
        this.props.props.history.goBack()
    )
    render() {
        const { t, addNew, title, addNewBtnTitle, showCompanyCustomers, resetState, showCompanyLocations, companyDetail } = this.props; return (
            <Col className="right-container col-md-12">
                <h2 className="pb-5 col-md-8 float-left title-header"> {(showCompanyCustomers || showCompanyLocations) && <i className="fa fa-arrow-left back-btn" aria-hidden="true" onClick={resetState}></i>}
                    {isEmpty(addNewBtnTitle) && <i className="fa fa-arrow-left back-btn" aria-hidden="true" onClick={this.goBack} ></i>} {(t != null && (showCompanyLocations === true || showCompanyCustomers === true)) ? companyDetail.name : title}
                    {showCompanyLocations && <span> - {t != null && " " + t.translations.locations}</span>}
                    {showCompanyCustomers && <span> - {t != null && " " + t.translations.users}</span>}
                </h2>
                {((!isEmpty(addNewBtnTitle) && addNewBtnTitle !== "No") && !showCompanyCustomers) && <Button className="btn-block mt-2 float-right btn-login addUser-btn col-md-6" type="button" onClick={addNew}>{t != null && addNewBtnTitle}</Button>}
            </Col>
        )
    }
}

export default Header;
