/* eslint eqeqeq: 0 */
import React, { Fragment } from 'react';
import { Container, Form, Button, Col } from 'shards-react';
import { required } from '../../helper/validate';
import { FormField } from '../../helper/formFields';
import { reduxForm, Field } from 'redux-form';
import { connect } from 'react-redux';
import { getCompanies } from '../../redux/company.redux';
import { addUser, editUser } from '../../redux/user.redux';
import Alert from '../common/Alert';
import Header from '../common/header';
import { isEmpty } from 'lodash';
import PhoneInput from 'react-phone-number-input';
import { isEqual } from 'lodash';
const personnummer = require('personnummer');

var selected_company_id = 0;
class AddUser extends React.Component {
    personNumber = '';
    constructor(props) {
        super(props);
        this.state = {
            companiesNames: [],
            companiesList: [],
            mobile_phone: '',
            selected_company: "",
            t: null,
            showErrorMesage: '', showError: false
        }
    }
    componentDidMount() {
        var action_ = this.props.location.state.action;



        // debugger;
        this.props.getCompanies();
        let user = this.props.userInfo;
        this.setState({ t: this.props.t, mobile_phone: user.mobile_phone });
        if (user.zip === 0) {
            user.zip = ''
        }

        if (this.props.location.state.action === "edit") {
            selected_company_id = user.company_id;
        }


        if (action_ === "edit_address" && user !== "") {
            var data_ = this.props.location.state.address;
            user.city = data_.city;
            user.address = data_.street;
            user.zip = data_.zipcode;
            user.country = "Sweden";
            //    user.first_name = data_.firstname;
            //    user.last_name = data_.lastname;

            var phone_ = data_.phone_number != undefined && data_.phone_number.length > 0 ? data_.phone_number[0] : user.mobile_phone;
            user.mobile_phone = phone_;

            this.setState({ mobile_phone: phone_ });
        }

        if (this.props.userInfo.id > 0) {
            var person = this.maskStr(user.person_number);
            user.person_number = person;
            user.balance_amount = user.balance_amount + " SEK"
        }

        this.props.initialize({
            ...user,
        });
    }

    maskStr(cardnumber) {
        var first4 = cardnumber.substring(0, 8);
        var mask = cardnumber.substring(cardnumber.length - 4, 4).replace(/\d/g, "*");
        //first4 +
        return first4 + mask;
    }


    componentDidUpdate(prevProps) {
        if (!isEqual(prevProps.loading, this.props.loading)) {
            if (this.props.success) {
                //this.props.history.push('/user');
                this.props.history.goBack();
            }
        }
    }

    handleSubmit = (values, dispatch) => {
        var { id, first_name, last_name, city, zip, email, person_number, discount, address, terms_conditions, company_id } = values;
        if (isEmpty(zip)) {
            zip = 0
        }
        var validPersonNumber = personnummer.format(person_number, 'YYYYMMDDNNNN');
        if (id > 0) {
            const { mobile_phone } = this.state;
            //var personNum = person_number.replace(/[^0-9]/g, "").toString()
            let data = {
                id, first_name, last_name, email, discount, person_number: validPersonNumber, mobile_phone, city, zip, address, terms_conditions, company_id
            }

            console.log("COmpany params editUser: " + JSON.stringify(data));
            dispatch(editUser(data));
        }
        else {
            //var valid = this.validatePersonNumber(person_number);

            const { mobile_phone } = this.state;
            if (!isEmpty(validPersonNumber)) {

                let data = {
                    first_name, last_name, email, discount, person_number: validPersonNumber, mobile_phone, city, zip, address, terms_conditions, company_id
                }
                console.log("COmpany params addUser: " + JSON.stringify(data));

                dispatch(addUser(data));
            }
            else {
                this.setState({
                    showError: true,
                    showErrorMesage: "bad_day_person_number"

                })
            }
        }
    }

    validatePersonNumber = (pin) => {
        var val = pin.replace(/[^0-9]/g, "").toString();
        //Trim length to 10 chars, stripping preceding 19xx
        if (val.length > 10) {
            val = val.substring(val.length, val.length - 10);
        }
        //Check for date errors
        if (!val.match(/[0-9]{10}/)) {
            this.setState({
                showError: true,
                showErrorMesage: "is_not_valid_validation_message"
            })
            return false;
        }
        //  Year
        if (!val.match(/([0-9][0-9]).{8}/)) {
            this.setState({
                showError: true,
                showErrorMesage: "bad_year_person_number"
            })
            return false
        }
        //  Month
        if (!val.match(/.{2}(0[1-9]|1[0-2]).{6}/)) {
            this.setState({
                showError: true,
                showErrorMesage: "bad_month_person_number"
            })
            return false
        }
        //  Day
        if (!val.match(/.{4}(0[1-9]|[1-2][0-9]|3[0-1]).{4}/)) {
            this.setState({
                showError: true,
                showErrorMesage: "bad_day_person_number"
            })
            return false
        }
        var valArr = val.split(""),
            luhn = 0;
        luhn = (valArr[0] * 2).toString() +
            (valArr[1] * 1).toString() +
            (valArr[2] * 2).toString() +
            (valArr[3] * 1).toString() +
            (valArr[4] * 2).toString() +
            (valArr[5] * 1).toString() +
            (valArr[6] * 2).toString() +
            (valArr[7] * 1).toString() +
            (valArr[8] * 2).toString()
        var luhnArr = luhn.split("");
        var result = 0;
        luhnArr.forEach(luhnArr => {
            result += parseInt(luhnArr);
        });
        result = 10 - (result % 10);
        var controlNr = parseInt(val.substring(9, 10));
        if (controlNr === result) {
            var orgPersonNumber = val.replace(/(\d{3})(\d{3})(\d{4})/, "$1$2-$3");
            this.personNumber = orgPersonNumber;
            this.setState({
                showError: false,
                showErrorMesage: ''
            })
            return true;
        } else {
            this.setState({
                showError: true,
                showErrorMesage: "bad_checksum_person-number"
            })
            return false;
        }
    }

    setCompanyChange = (data) => {
        console.log("setCompanyChange: " + JSON.stringify(data));
        this.setState({ test: true });
        selected_company_id = data;
    }

    render = () => {
        this.data = this.state.companiesList;
        var companyList = [];
        const { companies, t, success, loading, message } = this.props;

        if (!isEmpty(companies)) {
            companyList.push({ value: 0, label: t != null && t.translations.select_company })
        }
        companies.forEach(company => {
            companyList.push({ value: company.id, label: company.name })
        });

        return (
            <Container fluid className="personal-container user-container">
                <Header t={this.props.t} title={t != null && t.translations.user} props={this.props} />
                <Col className="col-md-12 mt-5 data-table-container">
                    <Form onSubmit={this.props.handleSubmit(this.handleSubmit)}>
                        {/* {(showError == false && success!==false )&&<Alert success={this.props.success} message={this.props.message} t={this.props.t} />} */}
                        <Alert success={success} message={message} t={this.props.t} loading={loading} />
                        <div className="row">
                            <div className="col-md-6 col-xs-12 float-right">
                                <Field
                                    label={t != null ? t.translations.person_number : ''}
                                    type="text"
                                    name="person_number"
                                    classes="Field-input "
                                    validate={[required]}
                                    component={FormField}
                                    disableField={this.props.userInfo.id > 0 ? true : false}
                                    t={this.props.t}
                                />
                                {this.state.showError && <Fragment><br /><p className="person-number-validation">{this.props.t.translations[this.state.showErrorMesage]}</p></Fragment>}
                            </div>
                            <div className="col-md-6 col-xs-12 float-left">
                                <Field
                                    label={t != null ? t.translations.select_company : ''}
                                    type="my-select"
                                    selected={selected_company_id}
                                    name="company_id"
                                    classes="Field-input menu-list"
                                    options={companyList}
                                    onChange={this.setCompanyChange}
                                    component={FormField}
                                    t={this.props.t}
                                    placeholder={t != null ? t.translations.select_company : ''}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6 col-xs-12 float-left">
                                <Field
                                    label={t != null ? t.translations.first_name : ''}
                                    type="text"
                                    name="first_name"
                                    classes="Field-input"
                                    validate={[required]}
                                    component={FormField}
                                    t={this.props.t}
                                />
                            </div>
                            <div className="col-md-6 col-xs-12 float-right">
                                <Field
                                    label={t != null ? t.translations.last_name : ''}
                                    type="text"
                                    name="last_name"
                                    classes="Field-input"
                                    validate={[required]}
                                    component={FormField}
                                    t={this.props.t}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6 col-xs-12 float-left">
                                <Field
                                    label={t != null ? t.translations.email : ''}
                                    type="email"
                                    name="email"
                                    classes="Field-input"
                                    component={FormField}
                                    t={this.props.t}
                                />
                            </div>
                            <div className="col-md-6 col-xs-12 float-right">
                                <div className="ml-3">
                                    <label>{t != null ? t.translations.mobile : ''}</label>
                                    <PhoneInput
                                        defaultCountry={"SE"}
                                        placeholder={t != null ? t.translations.mobile : ''}
                                        value={!isEmpty(this.props.userInfo) ? this.props.userInfo.mobile_phone : ''}
                                        className="Field-input"
                                        length={12}
                                        onChange={mobile_phone => this.setState({ mobile_phone })} />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6 col-xs-12 float-left">
                                <Field
                                    label={t != null ? t.translations.street_address : ''}
                                    type="text"
                                    name="address"
                                    classes="Field-input"
                                    component={FormField}
                                    t={this.props.t}
                                />
                            </div>
                            <div className="col-md-6 col-xs-12 float-left">
                                <Field
                                    label={t != null ? t.translations.city : ''}
                                    type="text"
                                    name="city"
                                    classes="Field-input"
                                    component={FormField}
                                    t={this.props.t}
                                />
                            </div>
                            <div className="col-md-6 col-xs-12 float-right">
                                <Field
                                    label={t != null ? t.translations.postal_code : ''}
                                    type="number"
                                    name="zip"
                                    classes="Field-input"
                                    maxLength={5}
                                    component={FormField}
                                    t={this.props.t}
                                />
                            </div>
                            <div className="col-md-6 col-xs-12 float-right">
                                <Field
                                    label={t != null ? `${t.translations.discount} (%)` : ''}
                                    type="number"
                                    name="discount"
                                    disableField={true}
                                    classes="Field-input"
                                    component={FormField}
                                    t={this.props.t}
                                />
                            </div>
                        </div>
                        {this.props.userInfo.id > 0 &&
                            <div className="row">
                                <div className="col-md-6 col-xs-12 float-left">
                                    <Field
                                        label={t != null ? t.translations.benefit_type : ''}
                                        type="text"
                                        name="program_name"
                                        classes="Field-input "
                                        // validate={[required]}
                                        component={FormField}
                                        disableField={true}
                                        t={this.props.t}
                                    />
                                </div>
                                <div className="col-md-6 col-xs-12 float-right">
                                    <Field
                                        label={t != null ? t.translations.balance : ''}
                                        type="text"
                                        name="balance_amount"
                                        classes="Field-input "
                                        validate={[required]}
                                        component={FormField}
                                        disableField={true}
                                        t={this.props.t}
                                    />
                                </div>
                            </div>
                        }
                        <div className="row">
                            <div className="col-md-6 col-xs-12 float-left">
                                <Field
                                    label={t != null ? t.translations.allow_bank_id : ''}
                                    type="checkbox"
                                    name="terms_conditions"
                                    classes="subsidiary bank-id"
                                    component={FormField}
                                    t={this.props.t}
                                />
                            </div>
                        </div>
                        <Button className="btn-block float-right save-btn Field-input" type="submit">{t != null && t.translations.save_changes} {this.props.loading && <i className="fas fa-circle-notch fa-spin loader"></i>}</Button>
                    </Form>
                </Col>
            </Container >
        );
    }
};

const mapStateToProps = state => {
    return {
        companies: state.companyRedux.companies,
        success: state.userRedux.success,
        loading: state.userRedux.loading,
        message: state.userRedux.message,
        userInfo: state.userRedux.userInfo,
        t: state.accountRedux.localeInfo.t
    };
};
const mapDispatchToProps = dispatch => {
    return {
        getCompanies: () => dispatch(getCompanies()),
    }
};
export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({
    form: 'userForm',
    destroyOnUnmount: false
})(AddUser));
