/* eslint eqeqeq: 0 */
import React, { Fragment } from 'react';
import { Container, Form, Button, Col } from 'shards-react';
import { required } from '../../helper/validate';
import { FormField } from '../../helper/formFields';
import { reduxForm, Field } from 'redux-form';
import { connect } from 'react-redux';
import ConfirmationDialog from '../common/ConfirmationDialog';
import Alert from '../common/Alert';
import Header from '../common/header';
import { addCustomerCredit, getPreliminaryDetails } from '../../redux/user.redux';


var CryptoJS = require("crypto-js");

var customers_ = [];
var wallets = [{ label: 'ePassi', value: 1 }];

var selected_cust_id = 0;
var selected_cust_name = "";

var credit_amount = 0;
var reference_number = 0
var benefit_type = wallets[0]

var action_status = 0;

class AddCredit extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            sel_address: "",

            not_listed: false,
            showDialogue: false,

        }

    }

    resetValues() {
        selected_cust_id = 0;
        selected_cust_name = "";

    }

    loadBasicData() {

        const { user } = this.props;
        this.resetValues();

    }

    roundMinutes(date) {

        date.setHours(date.getHours() + Math.round(date.getMinutes() / 60));
        date.setMinutes(0, 0, 0); // Resets also seconds and milliseconds

        return date;
    }

    componentDidMount() {

        this.loadBasicData()
        this.resetValues();


        this.props.getPreliminaryDetails();
    }

    componentDidUpdate(prevProps) {
        console.log('componentDidUpdate');

        if (this.props != prevProps) {

            if (this.props.get_details != [] && this.props.get_details != '' && this.props.get_details.customers.length != 0) {
                console.log("componentDidUpdate - get_details: " + JSON.stringify(this.props.get_details));

            }

            if (action_status === 1) {

                if (this.props.success) {
                    // dispatchEvent
                    action_status = 0;
                    this.props.history.goBack();
                }
            }
        }
    }


    onCustomerChange = (cust) => {

        let filtered_ = customers_.filter(el => {
            return (el.value === cust)
        });


        selected_cust_name = filtered_[0].label;
        selected_cust_id = cust;

    }


    checkIfEmpty(obj) {
        if (obj === "" || obj === undefined || obj == 0) {
            return true;
        }
        return false;
    }

    showDetail = (event) => {

        if (this.checkIfEmpty(selected_cust_id)) {

            alert("All data fields are mandatory.");
            return
        }
        this.onSaveBtn()

    }

    onSaveBtn = () => {

        action_status = 1;

        let data = {
            cust_id: (selected_cust_id === "" || selected_cust_id === undefined ? customers_[0].value : selected_cust_id),
            credit_amount: credit_amount,
            reference: reference_number,
            benefit_type: benefit_type
        }
        this.props.addCustomerCredit(data)

    }
    onCreditChange(value) {
        credit_amount = value.currentTarget.value
    }

    onReferenceChange(value) {
        reference_number = value.currentTarget.value
    }
    onWalletChange(value) {
        benefit_type = value
    }

    render = () => {
        const { t, get_details } = this.props;

        if (get_details != "" && get_details != undefined) {

            if (customers_.length == 0) {
                customers_ = get_details.customers.map(person => ({ value: person.id, label: person.first_name + " " + person.last_name }));
                customers_.sort((a, b) => (a.label > b.label) ? 1 : ((b.label > a.label) ? -1 : 0));
            }
        }

        return (
            <Container fluid className="personal-container">


                <Header t={this.props.t} title={t != null && t.translations.employee_benefit_program} props={this.props} />
                <Col className="col-md-12 mt-5 data-table-container">
                    <Form autoComplete="off">
                        <Alert success={this.props.success} message={this.props.message} t={this.props.t} />
                        {/* <hr class="dotted"></hr> */}
                        <div className="row">
                            <div className="col-md-6 col-xs-12 float-left">
                                <Field
                                    label={t != null ? t.translations.customer_name : ''}
                                    options={customers_}
                                    selected={""}
                                    type="my-select"
                                    name="cust_id"
                                    classes="Field-input"
                                    validate={[required]}
                                    component={FormField}
                                    t={this.props.t}
                                    onChange={this.onCustomerChange}
                                    placeholder={t != null ? t.translations.select : ''}
                                    noOptionsMessage={t != null ? t.translations.no_options : ''}
                                />
                            </div>
                            <div className="col-md-6 col-xs-12 float-left">
                                <Field
                                    label={"Benefit"}
                                    options={wallets}
                                    selected={wallets[0]}
                                    type="my-select"
                                    name="wallet"
                                    classes="Field-input"
                                    validate={[required]}
                                    component={FormField}
                                    t={this.props.t}
                                    onChange={this.onWalletChange}
                                    placeholder={t != null ? t.translations.select : ''}
                                    noOptionsMessage={t != null ? t.translations.no_options : ''}
                                />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-6 col-xs-12 float-left">
                                <Field
                                    placeholder={"0 SEK"}
                                    type='number'
                                    name="credit"
                                    classes="Field-input"
                                    onChange={this.onCreditChange}
                                    validate={[required]}
                                    component={FormField}
                                    t={this.props.t}
                                />
                            </div>
                            <div className="col-md-6 col-xs-12 float-left">
                                <Field
                                    placeholder={"Reference number"}
                                    type="text"
                                    name="reference"
                                    classes="Field-input"
                                    onChange={this.onReferenceChange}
                                    component={FormField}
                                    t={this.props.t}
                                />
                            </div>
                        </div>
                        <hr class="dotted"></hr>
                        <Button onClick={this.showDetail} className="btn-block float-right save-btn Field-input" type="button">{t != null && (t.translations.save)} {this.props.loading && <i className="fas fa-circle-notch fa-spin loader"></i>}</Button>

                    </Form>
                </Col>
            </Container >
        );
    }
};

const mapStateToProps = state => {
    return {
        t: state.accountRedux.localeInfo.t,
        loading: state.userRedux.loading,
        success: state.userRedux.success,
        message: state.userRedux.message,
        addedCredit: state.userRedux.add_credit,
        get_details: state.userRedux.get_details,
        user: state.accountRedux.user,

    };
};

const mapDispatchToProps = dispatch => {
    return {
        getPreliminaryDetails: () => dispatch(getPreliminaryDetails()),

        addCustomerCredit: (data) => dispatch(addCustomerCredit(data))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({
    form: 'AddCreditForm'
})(AddCredit));
