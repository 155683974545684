import { createRoutine } from 'redux-saga-routines';
export const loginAdmin = createRoutine('LOGIN_ADMIN');
export const secretLogin = createRoutine('SECRET_LOGIN');

export const bankIdAuthentication = createRoutine('BANKID_AUTHENTICATION');
export const bankIdCollect = createRoutine('BANKID_Collect');
export const changePassword = createRoutine('CHANGE_PASSWORD');
export const exportLocalStorageData = createRoutine('EXPORT_LOCAL_STORAGE_DATA');
export const localeInformation = createRoutine('LOCALE_INFORMATION');
export const GET_CURRENT_USER = createRoutine('GET_USER');
export const getInvoices = createRoutine('GET_INVOICES');
export const getCompanyInvoices = createRoutine('GET_COMPANY_INVOICES');


const INITIAL_STATE = {
    error: false,
    success: false,
    message: '',
    loading: false,
    user: {},
    localeInfo: {},
    bankIdResponse: {},
    bankIdCollectResponse: {},
    bankIdCollectErrorResponse:{},
    invoices: [],
    company_invoices: [],
    secretUser:{}
};
export default (state = INITIAL_STATE, { type, payload }) => {
    switch (type) {


        case getInvoices.TRIGGER:
            return { ...state, loading: true, error: false, success: false ,invoices: []};
        case getInvoices.SUCCESS:
            return { ...state, loading: false, error: false, success: true, invoices: payload };
        case getInvoices.FAILURE:
            return { ...state, loading: false, success: false, error: true, message: payload };

        case getCompanyInvoices.TRIGGER:
            return { ...state, loading: true, error: false, success: false ,company_invoices: []};
        case getCompanyInvoices.SUCCESS:
            return { ...state, loading: false, error: false, success: true, company_invoices: payload };
        case getCompanyInvoices.FAILURE:
            return { ...state, loading: false, success: false, error: true, message: payload, company_invoices: [] };



        case loginAdmin.TRIGGER:
            return { ...state, loading: true, error: false, success: false, message: '' };
        case loginAdmin.SUCCESS:
            return { ...state, loading: false, error: false, success: true, user: payload };
        case loginAdmin.FAILURE:
            return { ...state, loading: false, success: false, error: true, message: payload, user: {} };

            case secretLogin.TRIGGER:
                return { ...state, loading: true, error: false, success: false, message: '' };
            case secretLogin.SUCCESS:
                return { ...state, loading: false, error: false, success: true, secretUser: payload };
            case secretLogin.FAILURE:
                return { ...state, loading: false, success: false, error: true, message: payload, secretUser: {} };
    
        case bankIdAuthentication.TRIGGER:
            return { ...state, loading: true, error: false, success: false, message: '' };
        case bankIdAuthentication.SUCCESS:
            return { ...state, loading: true, error: false, success: true, bankIdResponse: payload };
        case bankIdAuthentication.FAILURE:
            return { ...state, loading: false, success: false, error: true, bankIdResponse: {} };

        case bankIdCollect.TRIGGER:
            return { ...state, loading: true, error: false, success: false, message: '' };
        case bankIdCollect.SUCCESS:
            return { ...state, loading: false, error: false, success: true, bankIdCollectResponse: payload ,bankIdCollectErrorResponse: {}};
        case bankIdCollect.FAILURE:
            return { ...state, loading: false, success: false, error: true, bankIdCollectErrorResponse: payload };

        case GET_CURRENT_USER.TRIGGER:
            return { ...state, message: '', success: false };
        case GET_CURRENT_USER.SUCCESS:
            return { ...state, loading: false, user: { ...state.user, ...payload }, success: true, message: '' };

        case changePassword.TRIGGER:
            return { ...state, loading: true, error: false, success: false, message: '' };
        case changePassword.SUCCESS:
            return { ...state, loading: false, error: false, success: true, message: payload };
        case changePassword.FAILURE:
            return { ...state, loading: false, success: false, error: true, message: payload };

        case exportLocalStorageData.TRIGGER:
            return { ...state, loading: false, error: false, succes: true, user: payload }

        case localeInformation.TRIGGER:
            return { ...state, localeInfo: payload }

        default:
            return { ...state }

    }
}