import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import { connect } from 'react-redux';

class NotificationDialog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            t: null,
            setOpen:  false,
            open: true,
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props !== prevProps) {
            this.setState({
                t: this.props.t
            })
        }
    }
    componentDidMount() {
        this.setState({
            t: this.props.t
        })
    }

    handleClickOpen = () => {
      this.setState({ setOpen: true });
    };
  
    handleClose = () => {
        this.setState({ setOpen: false });
    };

    render() {
        const { t } = this.state;
        const { bodyText, title } = this.props;

        return (
            <Dialog 
                disableBackdropClick
                disableEscapeKeyDown
                maxWidth="xs"
                // onEntering={''}
                aria-labelledby="confirmation-dialog-title"
                open={true}
                >
            <DialogTitle id="form-dialog-title">{title}</DialogTitle>
            <DialogContent>
              <DialogContentText>{bodyText}</DialogContentText>
              <TextField
                autoFocus
                onChange={this.props.setTextValue}
                margin="dense"
                id="message"
                label=""
                type="text"
                fullWidth
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={this.props.handleCancel} color="primary">
              {t != null && t.translations.cancel_forgot}
              </Button>
              <Button onClick={this.props.handleOk} color="primary">
              {t != null && t.translations.send}
              </Button>
            </DialogActions>
          </Dialog>
        );
    }
}
const mapStateToProps = state => {
    return {
        t: state.accountRedux.localeInfo.t
    };
};
const mapDispatchToProps = dispatch => {
    return {
    }
};
export default connect(mapStateToProps, mapDispatchToProps)(NotificationDialog);