import { createRoutine } from 'redux-saga-routines';
export const getAllPayments     = createRoutine('GET_ALL_PAYMENT');
export const getAllLogs         = createRoutine('GET_ALL_LOGS');
export const getSystemLogs      = createRoutine('GET_SYSTEM_LOGS');
export const getReceipts        = createRoutine('GET_RECEIPTS');

export const capture = createRoutine('CAPTURE');


const INITIAL_STATE = {
    error: false,
    success: false,
    message: '',
    loading: false,
    payments: [],
    data: [],
    logs: [],
    receipts: [],

};
export default (state = INITIAL_STATE, { type, payload }) => {
    switch (type) {

        case getAllPayments.TRIGGER:
            return { ...state, loading: true, error: false, success: false, message: '' };
        case getAllPayments.SUCCESS:
            return { ...state, loading: false, error: false, success: true, payments: payload };
        case getAllPayments.FAILURE:
            return { ...state, loading: false, success: false, error: true, message: payload };

        case getAllLogs.TRIGGER:
            return { ...state, loading: true, error: false, success: false, message: '' };
        case getAllLogs.SUCCESS:
            return { ...state, loading: false, error: false, success: true, data: payload };
        case getAllLogs.FAILURE:
            return { ...state, loading: false, error: true, success: false, message: payload };
    
        case getSystemLogs.TRIGGER:
            return { ...state, loading: true, error: false, success: false, logs: [], message: '' };
        case getSystemLogs.SUCCESS:
            return { ...state, loading: false, error: false, success: true, logs: payload };
        case getSystemLogs.FAILURE:
            return { ...state, loading: false, error: true, success: false, logs: [], message: payload };
    
        case getReceipts.TRIGGER:
            return { ...state, loading: true, error: false, success: false, receipts: [], message: '' };
        case getReceipts.SUCCESS:
            return { ...state, loading: false, error: false, success: true, receipts: payload };
        case getReceipts.FAILURE:
            return { ...state, loading: false, error: true, success: false, receipts: [], message: payload };
    
        default:
            return { ...state }

    }
}