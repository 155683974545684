import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Container, Row } from 'shards-react';
import Header from '../common/header';
import { getNotifAdmin } from '../../redux/user.redux';
import MaterialTable from '../common/materialTable';
import * as moment from 'moment';
import ConfirmationDialog from '../common/ConfirmationDialog';

class Notification extends Component {
    constructor(props) {
        super(props);
        this.state = {
            t: null,
            filterState: "auth",
            send_notification: false,
            filtered_customers: [],
            filtered_types: [],

        }
    }
    componentDidMount() {
        this.setState({ t: this.props.t });
        this.props.getNotifAdmin();
    }

    componentDidUpdate(prevProps) {

    }
    showDetail = (event) => {

    }

    segmentControlTapped(val) {
        console.log('segmentControlTapped: ' + val);
        this.setState({ filterState: val });
    }

    filterBookings(val) {
        const { payments } = this.props;

        ////////debugger;

        if (payments.length > 0) {
            if (val === "auth") {
                let resultant = payments.filter(item => {
                    return item.payment_status === 'success' || item.payment_status === 'Success';
                })


                // date and time sorting
                resultant.sort((a, b) => {
                    var c = moment(new Date(0).setUTCSeconds(a.date)).format('YYYY-MM-DD');
                    var d = moment(new Date(0).setUTCSeconds(b.date)).format('YYYY-MM-DD');

                    var cT = moment(new Date(0).setUTCSeconds(a.date)).format('HH:MM');
                    var dT = moment(new Date(0).setUTCSeconds(b.date)).format('HH:MM');

                    return c.localeCompare(d) || cT.localeCompare(dT);
                });

                //reverse arr
                let rev = resultant.reverse();
                return rev;
            }
            else if (val === "completed") {
                let resultant = payments.filter(item => {
                    return item.payment_status === 'captured' || item.payment_status === 'Captured';
                })


                // date and time sorting
                resultant.sort((a, b) => {
                    var c = moment(new Date(0).setUTCSeconds(a.date)).format('YYYY-MM-DD');
                    var d = moment(new Date(0).setUTCSeconds(b.date)).format('YYYY-MM-DD');

                    var cT = moment(new Date(0).setUTCSeconds(a.date)).format('HH:MM');
                    var dT = moment(new Date(0).setUTCSeconds(b.date)).format('HH:MM');

                    return c.localeCompare(d) || cT.localeCompare(dT);
                });

                //reverse arr
                let rev = resultant.reverse();

                return rev;
            }
            else if (val === "failed") {
                let resultant = payments.filter(item => {
                    return item.payment_status === 'captured_failed';
                });

                // date and time sorting
                resultant.sort((a, b) => {
                    var c = moment(new Date(0).setUTCSeconds(a.date)).format('YYYY-MM-DD');
                    var d = moment(new Date(0).setUTCSeconds(b.date)).format('YYYY-MM-DD');

                    var cT = moment(new Date(0).setUTCSeconds(a.date)).format('HH:MM');
                    var dT = moment(new Date(0).setUTCSeconds(b.date)).format('HH:MM');

                    return c.localeCompare(d) || cT.localeCompare(dT);
                });

                //reverse arr
                let rev = resultant.reverse();

                return rev;
            }
            else if (val === "cancelled") {
                let resultant = payments.filter(item => {
                    return item.payment_status === 'annulled';
                });

                // date and time sorting
                resultant.sort((a, b) => {
                    var c = moment(new Date(0).setUTCSeconds(a.date)).format('YYYY-MM-DD');
                    var d = moment(new Date(0).setUTCSeconds(b.date)).format('YYYY-MM-DD');

                    var cT = moment(new Date(0).setUTCSeconds(a.date)).format('HH:MM');
                    var dT = moment(new Date(0).setUTCSeconds(b.date)).format('HH:MM');

                    return c.localeCompare(d) || cT.localeCompare(dT);
                });

                //reverse arr
                let rev = resultant.reverse();

                return rev;
            }
        }
        return payments;
    }

    sendNotification = () => {
        // this.setState({ send_notification: true});
        return (this.props.history.push('/addnotification'))
    }

    sendNotificationAction = () => {
        // this.setState({ send_notification: true});
    }

    selectCustomer = (event) => {
        this.setState({ selected_therapist: event });
    }

    selectNotificationType = (event) => {
        this.setState({ selected_therapist: event });
    }

    handleSubmit = (values, dispatch) => {

        // this.props.getBookingPriceInformation(data);
    }

    minTwoDigits(n) {
        return (n < 10 ? '0' : '') + n;
    }

    generateColumns(t) {
        var bookingColumns = [];

        if (t != null) {
            bookingColumns = [
                {
                    title: t.translations.booking_id,
                    field: 'booking_id',
                },
                {
                    title: t.translations.customer_id,
                    field: 'cust_id'
                },
                {
                    title: t.translations.device_type,
                    field: 'platform',
                },
                // {
                //     title: t.translations.confirmBooking_type_app,
                //     field: 'action_type'
                // },
                // {
                //     title: t.translations.actions,
                //     field: 'action_by'
                // },
                {
                    title: t.translations.message,
                    field: 'message'
                },
                {
                    title: t.translations.status,
                    field: 'status',
                },
                // {
                //     title: "t.translations.device_token",
                //     field: 'device_token',
                // },
                {
                    title: t.translations.created_at,
                    field: 'created_time',
                    render: rowData => <span>{moment(new Date(0).setUTCSeconds(rowData.created_time)).tz('Europe/Stockholm').format('YYYY-MM-DD HH:mm')}</span>
                    // field: 'created_at',
                    // render: rowData => <span>{moment(rowData.created_at).format('YYYY-MM-DD HH:mm')}</span>

                }
            ];
        }

        return bookingColumns;
    }

    render() {
        const { loading, notifications } = this.props;
        const { t } = this.state;
        var columns = this.generateColumns(t);

        // var filtered_ = this.filterBookings(this.state.filterState);

        return (
            <Fragment>
                <Container fluid className="personal-container user-container">
                    {this.state.send_notification &&
                        <ConfirmationDialog
                            title={this.state.t != null ? this.state.t.translations.booking_details : ''}
                            bookingCancelBtn={true}
                            bodyText={
                                <Fragment>
                                    <h5>{t.translations.confirmBooking_type_app}</h5>
                                    <div>
                                        <select id="lang" onChange={this.change} value={this.state.value}>
                                            <option value="general">General</option>
                                            <option value="profile">Profile Update</option>
                                            <option value="booking">Booking</option>
                                            <option value="promo_code">Promo Code</option>
                                        </select>
                                    </div>
                                    <h5>{t.translations.customers}</h5>
                                    <div>
                                        <select id="lang" onChange={this.change} value={this.state.value}>
                                            <option value="general">General</option>
                                            <option value="profile">Profile Update</option>
                                            <option value="booking">Booking</option>
                                            <option value="promo_code">Promo Code</option>
                                        </select>
                                    </div>
                                    <br /> <br />
                                    <h5>{t.translations.notif_title_lbl}</h5>
                                    <div>
                                    </div>
                                    <br /> <br />
                                    <h5>{t.translations.notif_msg_lbl}</h5>
                                    <div>
                                    </div>
                                </Fragment>
                            }
                            handleCancel={() => this.setState({ send_notification: false })}
                            handleOk={() => this.sendNotification()} />}
                    <Row className="">
                        <Header t={this.props.t} addNew={this.sendNotification} title={t != null && t.translations.notifications} addNewBtnTitle={t != null && t.translations.send_notification} />
                    </Row>
                    <Row>
                        <MaterialTable t={this.props.t}
                            justShow={4}
                            columns={columns}
                            data={notifications}
                            // showDetail={this.showDetail}
                            loading={loading}
                            // deleteDetail={this._renderDialog}
                            view={true} />
                    </Row>
                </Container >
            </Fragment>
        )
    }
}

const mapStateToProps = state => {
    return {
        t: state.accountRedux.localeInfo.t,
        loading: state.userRedux.loading,
        success: state.userRedux.success,
        message: state.userRedux.message,
        notifications: state.userRedux.notifications,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getNotifAdmin: () => dispatch(getNotifAdmin())
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Notification);
