import {combineReducers} from 'redux';
import {routerReducer} from 'react-router-redux';
import { reducer as formReducer } from 'redux-form'
import companyRedux from './company.redux';
import accountRedux from './account.redux';
import userRedux from './user.redux';
import staffRedux from './staff.redux';
import locationRedux from './location.redux';
import personalRedux from './personal.redux';
import treatmentRedux from './treatment.redux';
import therapistRedux from './therapist.redux';
import paymentRedux from './payment.redux';
import bookingRedux from './booking.redux';
import dashboardRedux from './dashboard.redux';
// const PERSIST_CONFIG = {
//    key: 'auth',
//    version: 1,
//    whitelist: ['authToken', 'userObj']
// };
const rootReducers = combineReducers({
    routing: routerReducer,
    form: formReducer,
    companyRedux,
    dashboardRedux,
    accountRedux,
    userRedux,
    staffRedux,
    locationRedux,
    personalRedux,
    treatmentRedux,
    therapistRedux,
    paymentRedux,
    bookingRedux
});
export default rootReducers;
