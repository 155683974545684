/* eslint eqeqeq: 0 */

import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import store from './store';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import * as serviceWorker from './serviceWorker';
import '../node_modules/font-awesome/css/font-awesome.min.css';
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";

const Root = () => (
   <Provider store={store}>
      <BrowserRouter>
         <I18nextProvider i18n={i18n}>
            <App i18n={i18n} />
         </I18nextProvider>
      </BrowserRouter>
   </Provider>
);

ReactDOM.render(<Root />, document.getElementById('root'));
serviceWorker.unregister();

// import React from 'react';
// import ReactDOM from 'react-dom';
// import './index.css';
// import App from './App';
// import * as serviceWorker from './serviceWorker';

// ReactDOM.render(<App />, document.getElementById('root'));

// // If you want your app to work offline and load faster, you can change
// // unregister() to register() below. Note this comes with some pitfalls.
// // Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
