/* eslint eqeqeq: 0 */
import React from 'react';
import { Container, Form, Button, Col } from 'shards-react';
import { required } from '../../helper/validate';
import { FormField } from '../../helper/formFields';
import { reduxForm, Field } from 'redux-form';
import { connect } from 'react-redux';
import Alert from '../common/Alert';
import Header from '../common/header';
import { getNotificationUsers, sendNotifAdmin } from '../../redux/user.redux';

var goBack = false;
class AddNotification extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            country: '',
            t: null,
            showErrorMesage: '',
            showError: false,
            treatment_ids: [],
            selected_treatment_ids: [],
            is_treatment_empty: false,
            file: null,
            newFile: {},
            fileSizeErrorMessage: '',
            types: [
                { value: "general", label: "General" },
                // { value: "profile_update", label: "Profile" },
                // { value: "booking", label: "Booking" },
                // { value: "promo_code", label: "Promo Code" }
            ],
            customers: []
        }
    }

    componentDidMount() {
        this.setState({ t: this.props.t });
        this.props.getNotificationUsers();
    }

    componentDidUpdate(prevProps) {
        console.log("componentDidUpdate: " + JSON.stringify(this.props.notif_users));

        // debugger;
        if (this.props !== prevProps) {
            if (this.props.notif_users !== [] && this.props.notif_users != undefined) {
                var therapistArray = []
                this.props.notif_users.forEach(element => {
                    therapistArray.push({ value: element.id, label: element.first_name + " " + element.last_name })
                });
                this.setState({ customers: therapistArray })
            }
            if (this.props.send_notif_data !== [] && this.props.send_notif_data != undefined && this.props.send_notif_data !== "") {
                console.log("SEND NOTIF RESPONSE: " + JSON.stringify(this.props.send_notif_data));
                if (goBack) {
                    goBack = false;
                    this.props.history.goBack();
                }
            }


        }
    }


    handleSubmit = (values, dispatch) => {
        console.log("handleSubmit: " + JSON.stringify(values));
        var { notif_type, title, message, customer_name } = values;
        var customer_names = []
        customer_name.forEach(element => {
            customer_names.push(element.value);
        });

        var customer_ids = customer_names.join();

        goBack = true;
        var data = {
            booking_id: -1,
            cust_ids: customer_ids,
            type: notif_type,
            title: title,
            message: message
        }

        this.props.sendNotifAdmin(data);
    }

    selectedCustomers = (cust) => {

    }

    render = () => {
        this.data = this.state.companiesList;
        const { t } = this.state;
        var treatmentList = [];
        const { treatments, therapistInfo, success, message, loading } = this.props;

        console.log("render: therapistInfo : " + JSON.stringify(therapistInfo));
        treatments.forEach(treatment => {
            treatmentList.push({ value: treatment.id, label: treatment.name });
        });

        return (
            <Container fluid className="personal-container user-container">
                <Header t={this.props.t} title={t != null && t.translations.notifications} props={this.props} />
                <Col className="col-md-12 mt-5 data-table-container">
                    <Form onSubmit={this.props.handleSubmit(this.handleSubmit)}>
                        <Alert success={success} message={message} t={this.props.t} loading={loading} />
                        <div className="row">
                            <div className="col-md-9 col-xs-12 float-left">
                                <Field
                                    label={t != null && t.translations.confirmBooking_type_app}
                                    type="select"
                                    name="notif_type"
                                    options={this.state.types}
                                    classes="Field-input"
                                    validate={[required]}
                                    component={FormField}
                                    t={this.props.t}
                                />
                                <br />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-9 col-xs-12 float-left">
                                <Field
                                    label={t != null ? t.translations.notif_title_lbl : ''}
                                    type="text"
                                    name="title"
                                    classes="Field-input"
                                    disabled={true}
                                    defaultValue={"Healness"}
                                    validate={[required]}
                                    component={FormField}
                                    t={this.props.t}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-9 col-xs-12 float-left">
                                <Field
                                    label={t != null ? t.translations.notif_msg_lbl : ''}
                                    type="text"
                                    name="message"
                                    classes="Field-input"
                                    validate={[required]}
                                    component={FormField}
                                    t={this.props.t}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-9 col-xs-12 float-left">
                                <Field
                                    label={t != null && t.translations.customer_name}
                                    type="multiSelect"
                                    options={this.state.customers}
                                    ismulti
                                    name="customer_name"
                                    classes="Field-input"
                                    component={FormField}
                                    onChange={this.selectedCustomers}
                                    // defaultValue={this.state.types}
                                    t={this.props.t}
                                />
                            </div>
                        </div>
                        <div className="col-md-6 col-xs-12 float-right">
                            <Button className="btn-block float-right save-btn Field-input" type="submit">{t != null && t.translations.send} {this.props.loading && <i className="fas fa-circle-notch fa-spin loader"></i>}</Button>
                        </div>
                    </Form>
                </Col>
            </Container >
        );
    }
};

const mapStateToProps = state => {
    return {
        success: state.userRedux.success,
        loading: state.userRedux.loading,
        message: state.userRedux.message,
        therapistInfo: state.therapistRedux.therapistInfo,
        t: state.accountRedux.localeInfo.t,
        treatments: state.treatmentRedux.treatments,
        notif_users: state.userRedux.notif_users,
        send_notif_data: state.userRedux.notif_users,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        getNotificationUsers: () => dispatch(getNotificationUsers()),
        sendNotifAdmin: (data) => dispatch(sendNotifAdmin(data)),
    }
};
export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({
    form: 'therapistForm',
    destroyOnUnmount: false
})(AddNotification));