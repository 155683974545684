import React, { Fragment } from "react";
import { Nav, NavItem, NavLink } from "shards-react";
import { connect } from 'react-redux';
import SidebarNavItem from "./SidebarNavItem";
import { items, DefaultStaffPermissions, RoleTypes, DefaultAdminPermissions } from "../../../constants";
import logo from '../../../images/logo.png';
import ConfirmationDialog from '../../common/ConfirmationDialog';
import { APP_VERSION } from '../../../constants/urls';
import Clock from 'react-live-clock';

class SidebarNavItems extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openDialog: false,
      t: null
    }
    this.onChange = this.onChange.bind(this);
  }
  componentDidMount() {
    console.log("SideNavbar ")
    this.setState({
      t: this.props.t
    })
  }
  componentDidUpdate(prevProps) {
    if (this.props !== prevProps) {
      this.setState({
        t: this.props.t
      })
    }
  }
  openDialogBox = () => {
    this.setState({
      openDialog: true
    })
  }
  onChange() {

  }
  handleCancel = () => {
    this.setState({ openDialog: false })
  }

  handleOk = () => {
    var base_url = window.location.origin;
    window.location.href = base_url;
    localStorage.setItem('localInfo', null);
  }

  render() {
    var Permissions = [];
    const { role_type, first_name, last_name } = this.props.user;
    console.log('USERMENU: ' + JSON.stringify(this.props.user));
    //!isEmpty(this.props.bankIdResponse) && 
    if (role_type === parseInt(RoleTypes.STAFF)) {
      Permissions.push(DefaultStaffPermissions.StaffDashboard);
      Permissions.push(DefaultStaffPermissions.Schedule);
      Permissions.push(DefaultAdminPermissions.Booking);
      Permissions.push(DefaultStaffPermissions.Locations);
      Permissions.push(DefaultStaffPermissions.Personal);
      Permissions.push(DefaultStaffPermissions.Contact);
      Permissions.push(DefaultStaffPermissions.Account);
      Permissions.push(DefaultStaffPermissions.About);
      Permissions.push(DefaultStaffPermissions.Logout);
    }
    if ((role_type === (parseInt(RoleTypes.ADMIN)) || role_type === parseInt(RoleTypes.SUPERADMIN))) {

      Permissions.push(DefaultAdminPermissions.Dashboard);
      Permissions.push(DefaultStaffPermissions.Schedule);
      Permissions.push(DefaultAdminPermissions.Booking);
      Permissions.push(DefaultAdminPermissions.EmployeeBenefitProgram);
      Permissions.push(DefaultAdminPermissions.Transaction);
      Permissions.push(DefaultAdminPermissions.Receipt);
      Permissions.push(DefaultAdminPermissions.Promotion);
      Permissions.push(DefaultAdminPermissions.Customers);
      Permissions.push(DefaultAdminPermissions.Companies);
      Permissions.push(DefaultAdminPermissions.Users);
      Permissions.push(DefaultStaffPermissions.Locations); //Added by Sana
      Permissions.push(DefaultAdminPermissions.Treatment);
      Permissions.push(DefaultAdminPermissions.Invoice);
      Permissions.push(DefaultAdminPermissions.CompanyInvoice);

      Permissions.push(DefaultAdminPermissions.VAT);
      Permissions.push(DefaultAdminPermissions.Notification);

      Permissions.push(DefaultAdminPermissions.Scheduler);
      Permissions.push(DefaultAdminPermissions.Events);
      Permissions.push(DefaultAdminPermissions.Account);
      Permissions.push(DefaultAdminPermissions.About);
      Permissions.push(DefaultAdminPermissions.Logout);

      Permissions.push(DefaultAdminPermissions.Personal);
      Permissions.push(DefaultAdminPermissions.Staff);

      // Permissions.push(DefaultAdminPermissions.Hotel);
      Permissions.push(DefaultAdminPermissions.Therapist);
      // Permissions.push(DefaultAdminPermissions.Reports);



    }
    return (
      <div className="nav-wrapper">
        {this.state.openDialog && <ConfirmationDialog handleCancel={this.handleCancel} handleOk={this.handleOk} title={this.state.t != null ? this.state.t.translations.logout : ''} bodyText={this.state.t != null && this.state.t.translations.confirm_logout} />}
        <img src={logo} className="mt-5 ml-3 mb-5 logo" alt="logo" />
        <Nav className="nav--no-borders flex-column">
          <div className="logged-user1">
            <p className="text-center mt-3 version" style={{ 'color': '#efd75d' }}>{first_name + " " + last_name}</p>
            <p className="text-center mt-3 version" style={{ 'color': 'white' }}>{APP_VERSION}</p>
            <p className="text-center mt-3 version" style={{ 'color': 'white' }}><Clock format={'HH:mm:ss'} ticking={true} />{" (SYS)"}</p>
            <p className="text-center mt-3 version" style={{ 'color': 'white' }}><Clock format={'HH:mm:ss'} ticking={true} timezone={'Europe/Stockholm'} />{" (SE)"}</p>
            <p></p>
          </div>
          {items.map((item, idx) => (
            <Fragment key={idx}>
              {Permissions.includes(item.title) ? <SidebarNavItem item={item} t={this.state.t} titleKey={item.title} /> : null}
            </Fragment>))}
          <NavItem>
            <NavLink>
              <span className="sidebar-title" onClick={this.openDialogBox}>{this.state.t != null && this.state.t.translations.logout}</span><br />
            </NavLink>
          </NavItem>
        </Nav>
      </div>
    )
  }
}

// className="logout-container"
const mapStateToProps = state => {
  return {
    user: state.accountRedux.user,
    bankIdResponse: state.accountRedux.bankIdResponse,
    t: state.accountRedux.localeInfo.t
  };
};

export default connect(mapStateToProps)(SidebarNavItems);
