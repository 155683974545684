import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Container, Row, Col } from 'shards-react';
import MaterialTable from '../common/materialTable';
import { getUsers, showUserDetail } from '../../redux/user.redux';

class Hotel extends Component {
    constructor(props) {
        super(props);
        this.state = {
            t: null
        }
    }
    componentDidMount() {
        this.setState({ t: this.props.t });
        this.props.getUsers();
    }
    componentDidUpdate(prevProps) {

    }
    showDetail = (event) => {

    }

    onTableUpdate = ({ sortColumn, sortDirection }) => {

    }
    addNewCompany = () => {

    }
    showDetail = (event) => {
        this.props.showDetail(event);
        this.props.history.push('/addcustomer');
    }
    render() {
        const { t } = this.props;
        var columns = null;
        if (t !== null) {
            columns = [
                {
                    title: t.translations.person_number,
                    field: 'person_number'
                },
                {
                    title: t.translations.first_name,
                    field: 'first_name'
                },
                {
                    title: t.translations.last_name,
                    field: 'last_name',
                },
                {
                    title: t.translations.email,
                    field: 'email',
                }
            ];
        }
        return (
            <Fragment>
                <Container fluid className="personal-container user-container">
                    <Row className="">
                        {/* <Header t={this.props.t} addNew={this.addNewCompany} title={t != null && t.translations.hotel_list} addNewBtnTitle={t != null && t.translations.add_new_hotel} /> */}
                        <Col className="col-md-12 mt-5 data-table-container">
                            <h3 className="ml-5 under-construction">Under Construction</h3>
                            {this.props.users && <MaterialTable t={this.props.t} columns={columns} data={this.props.users} showDetail={this.showDetail} />}
                        </Col>
                    </Row>
                </Container >
            </Fragment>
        )
    }
}

const mapStateToProps = state => {
    return {
        t: state.accountRedux.localeInfo.t,
        users: state.userRedux.usersList,
        loading: state.companyRedux.loading,
        companies: state.companyRedux.companies,
        Customers: state.companyRedux.showCustomers
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getUsers: (data) => dispatch(getUsers(data)),
        showDetail: (data) => dispatch(showUserDetail(data))

    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Hotel);
