import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Container, Row, Col } from 'shards-react';
import { getAllLogs } from '../../redux/payment.redux';
import MaterialTable from '../common/materialTable';
import * as moment from 'moment';
import { SegmentedControl } from 'segmented-control'

class Scheduler extends Component {
    constructor(props) {
        super(props);
        this.state = {
            t: null,
            filterState: "all"
        }
    }
    componentDidMount() {
        this.setState({ t: this.props.t });
        this.props.getAllLogs();
    }
    componentDidUpdate(prevProps) {

    }
    showDetail = (event) => {

    }

    segmentControlTapped(val) {
        console.log('segmentControlTapped: ' + val);
        this.setState({ filterState: val });
    }

    generateColumns(t, state_) {
        var columns = null;
        if (t !== null) {
            if (state_ === "start") {
                columns = [
                    {
                        title: "ID", //t.translations.status,
                        field: 'id',
                    },
                    {
                        title: t.translations.status,
                        field: 'status',
                    },
                    {
                        title: t.translations.created_at,
                        // field: 'created_at',
                        // render: rowData => <span>{moment(rowData.created_at).format('YYYY-MM-DD HH:mm')}</span>
                        field: 'created_time',
                        render: rowData => <span>{moment(new Date(0).setUTCSeconds(rowData.created_time)).tz('Europe/Stockholm').format('YYYY-MM-DD HH:mm')}</span>

                    }
                ];
            }
            else if (state_ === "old") {
                columns = [
                    {
                        title: "ID", //t.translations.status,
                        field: 'id',
                    },
                    {
                        title: t.translations.message,
                        field: 'message',
                    },
                    {
                        title: t.translations.created_at,
                        // field: 'created_at',
                        // render: rowData => <span>{moment(rowData.created_at).format('YYYY-MM-DD HH:mm')}</span>
                        field: 'created_time',
                        render: rowData => <span>{moment(new Date(0).setUTCSeconds(rowData.created_time)).tz('Europe/Stockholm').format('YYYY-MM-DD HH:mm')}</span>
                    }
                ];
            }
            else if (state_ === "failed") {
                columns = [
                    {
                        title: "ID", //t.translations.status,
                        field: 'id',
                    },
                    {
                        title: t.translations.booking_id,
                        field: 'booking_id',
                    },
                    {
                        title: t.translations.status,
                        field: 'status',
                    },
                    {
                        title: t.translations.message,
                        field: 'message',
                    },
                    {
                        title: t.translations.db_updated,
                        field: 'db_updated',
                    },
                    {
                        title: t.translations.created_at,
                        field: 'created_time',
                        render: rowData => <span>{moment(new Date(0).setUTCSeconds(rowData.created_time)).tz('Europe/Stockholm').format('YYYY-MM-DD HH:mm')}</span>
                        // field: 'created_at',
                        // render: rowData => <span>{moment(rowData.created_at).format('YYYY-MM-DD HH:mm')}</span>
                    }
                ];
            }
            else if (state_ === "ignored" || state_ === "old") {
                columns = [
                    {
                        title: "ID", //t.translations.status,
                        field: 'id',
                    },
                    {
                        title: t.translations.booking_id,
                        field: 'booking_id',
                    },
                    {
                        title: t.translations.status,
                        field: 'status',
                    },
                    {
                        title: t.translations.created_at,
                        field: 'created_time',
                        render: rowData => <span>{moment(new Date(0).setUTCSeconds(rowData.created_time)).tz('Europe/Stockholm').format('YYYY-MM-DD HH:mm')}</span>
                        // field: 'created_at',
                        // render: rowData => <span>{moment(rowData.created_at).format('YYYY-MM-DD HH:mm')}</span>
                    }
                ];
            }
            else if (state_ === "cancel" || state_ === "change_date" || state_ === "change_therapist") {
                columns = [
                    {
                        title: "ID", //t.translations.status,
                        field: 'id',
                    },
                    {
                        title: t.translations.booking_id,
                        field: 'booking_id',
                    },
                    {
                        title: t.translations.message,
                        field: 'message',
                    },
                    {
                        title: t.translations.created_at,
                        field: 'created_time',
                        render: rowData => <span>{moment(new Date(0).setUTCSeconds(rowData.created_time)).tz('Europe/Stockholm').format('YYYY-MM-DD HH:mm')}</span>
                        // field: 'created_at',
                        // render: rowData => <span>{moment(rowData.created_at).format('YYYY-MM-DD HH:mm')}</span>
                    }
                ];
            }
            else if (state_ === "refund") {
                columns = [
                    {
                        title: "ID", //t.translations.status,
                        field: 'id',
                    },
                    {
                        title: t.translations.booking_id,
                        field: 'booking_id',
                    },
                    {
                        title: t.translations.status,
                        field: 'status',
                    },
                    {
                        title: t.translations.message,
                        field: 'message',
                    },
                    {
                        title: t.translations.customer_sms,
                        field: 'customer_sms',
                    },
                    {
                        title: t.translations.therapist_sms,
                        field: 'therapist_sms',
                    },
                    {
                        title: t.translations.receipt_email,
                        field: 'receipt_email',
                    },
                    {
                        title: t.translations.db_updated,
                        field: 'db_updated',
                    },
                    {
                        title: t.translations.created_at,
                        field: 'created_time',
                        render: rowData => <span>{moment(new Date(0).setUTCSeconds(rowData.created_time)).tz('Europe/Stockholm').format('YYYY-MM-DD HH:mm')}</span>
                        // field: 'created_at',
                        // render: rowData => <span>{moment(rowData.created_at).format('YYYY-MM-DD HH:mm')}</span>
                    }

                ];
            }
            else if (state_ === "all") {
                columns = [
                    {
                        title: "ID", //t.translations.status,
                        field: 'id',
                    },
                    {
                        title: "Type", //t.translations.status,
                        field: 'type',
                    },
                    {
                        title: t.translations.booking_id,
                        field: 'booking_id',
                    },
                    {
                        title: t.translations.status,
                        field: 'status',
                    },
                    {
                        title: t.translations.customer_sms,
                        field: 'customer_sms',
                    },
                    {
                        title: t.translations.therapist_sms,
                        field: 'therapist_sms',
                    },
                    {
                        title: t.translations.receipt_email,
                        field: 'receipt_email',
                    },
                    {
                        title: t.translations.db_updated,
                        field: 'db_updated',
                    },
                    {
                        title: t.translations.created_at,
                        field: 'created_time',
                        render: rowData => <span>{moment(new Date(0).setUTCSeconds(rowData.created_time)).tz('Europe/Stockholm').format('YYYY-MM-DD HH:mm')}</span>
                        // field: 'created_at',
                        // render: rowData => <span>{moment(rowData.created_at).format('YYYY-MM-DD HH:mm')}</span>
                    }

                ];
            }
            else {
                columns = [
                    {
                        title: "ID", //t.translations.status,
                        field: 'id',
                    },
                    {
                        title: t.translations.booking_id,
                        field: 'booking_id',
                    },
                    {
                        title: t.translations.status,
                        field: 'status',
                    },
                    {
                        title: t.translations.customer_sms,
                        field: 'customer_sms',
                    },
                    {
                        title: t.translations.therapist_sms,
                        field: 'therapist_sms',
                    },
                    {
                        title: t.translations.receipt_email,
                        field: 'receipt_email',
                    },
                    {
                        title: t.translations.db_updated,
                        field: 'db_updated',
                    },
                    {
                        title: t.translations.created_at,
                        field: 'created_time',
                        render: rowData => <span>{moment(new Date(0).setUTCSeconds(rowData.created_time)).tz('Europe/Stockholm').format('YYYY-MM-DD HH:mm')}</span>
                        // field: 'created_at',
                        // render: rowData => <span>{moment(rowData.created_at).format('YYYY-MM-DD HH:mm')}</span>
                    }

                ];
            }

        }

        return columns;
    }

    filterBookings(val) {
        const { payments } = this.props;

        ////////debugger;

        if (payments.length > 0) {
            if (val === "start") {
                let resultant = payments.filter(item => {
                    // return item.status === 'START';
                    return item.status === 'START' && item.type === "CAPTURE";
                })


                // date and time sorting
                resultant.sort((a, b) => {
                    var c = moment(new Date(0).setUTCSeconds(a.date)).format('YYYY-MM-DD');
                    var d = moment(new Date(0).setUTCSeconds(b.date)).format('YYYY-MM-DD');

                    var cT = moment(new Date(0).setUTCSeconds(a.date)).format('HH:MM');
                    var dT = moment(new Date(0).setUTCSeconds(b.date)).format('HH:MM');

                    return c.localeCompare(d) || cT.localeCompare(dT);
                });

                //reverse arr
                // let rev = resultant.reverse();
                return resultant;
            }
            else if (val === "query") {
                let resultant = payments.filter(item => {
                    // return item.status === 'START';
                    return item.type === "QUERY";
                })


                // date and time sorting
                resultant.sort((a, b) => {
                    var c = moment(new Date(0).setUTCSeconds(a.date)).format('YYYY-MM-DD');
                    var d = moment(new Date(0).setUTCSeconds(b.date)).format('YYYY-MM-DD');

                    var cT = moment(new Date(0).setUTCSeconds(a.date)).format('HH:MM');
                    var dT = moment(new Date(0).setUTCSeconds(b.date)).format('HH:MM');

                    return c.localeCompare(d) || cT.localeCompare(dT);
                });

                //reverse arr
                // let rev = resultant.reverse();
                return resultant;
            }
            else if (val === "failed") {
                let resultant = payments.filter(item => {
                    return item.status === 'FAILED' && item.type === "CAPTURE";
                })


                // date and time sorting
                resultant.sort((a, b) => {
                    var c = moment(new Date(0).setUTCSeconds(a.date)).format('YYYY-MM-DD');
                    var d = moment(new Date(0).setUTCSeconds(b.date)).format('YYYY-MM-DD');

                    var cT = moment(new Date(0).setUTCSeconds(a.date)).format('HH:MM');
                    var dT = moment(new Date(0).setUTCSeconds(b.date)).format('HH:MM');

                    return c.localeCompare(d) || cT.localeCompare(dT);
                });

                //reverse arr
                // let rev = resultant.reverse();

                return resultant;
            }
            else if (val === "success") {
                let resultant = payments.filter(item => {
                    return item.status === 'SUCCESS' && (item.type === "CAPTURE" || item.type === "SWISH_CAPTURE");
                })


                // date and time sorting
                resultant.sort((a, b) => {
                    var c = moment(new Date(0).setUTCSeconds(a.date)).format('YYYY-MM-DD');
                    var d = moment(new Date(0).setUTCSeconds(b.date)).format('YYYY-MM-DD');

                    var cT = moment(new Date(0).setUTCSeconds(a.date)).format('HH:MM');
                    var dT = moment(new Date(0).setUTCSeconds(b.date)).format('HH:MM');

                    return c.localeCompare(d) || cT.localeCompare(dT);
                });

                //reverse arr
                // let rev = resultant.reverse();

                return resultant;
            }
            else if (val === "refund") {
                let resultant = payments.filter(item => {
                    return item.status === 'SUCCESS' && item.type === "REFUND";
                })


                // date and time sorting
                resultant.sort((a, b) => {
                    var c = moment(new Date(0).setUTCSeconds(a.date)).format('YYYY-MM-DD');
                    var d = moment(new Date(0).setUTCSeconds(b.date)).format('YYYY-MM-DD');

                    var cT = moment(new Date(0).setUTCSeconds(a.date)).format('HH:MM');
                    var dT = moment(new Date(0).setUTCSeconds(b.date)).format('HH:MM');

                    return c.localeCompare(d) || cT.localeCompare(dT);
                });

                //reverse arr
                // let rev = resultant.reverse();

                return resultant;
            }
            else if (val === "ignored") {
                let resultant = payments.filter(item => {
                    return item.status === 'IGNORED' && item.type === "CAPTURE";
                });

                // date and time sorting
                resultant.sort((a, b) => {
                    var c = moment(new Date(0).setUTCSeconds(a.date)).format('YYYY-MM-DD');
                    var d = moment(new Date(0).setUTCSeconds(b.date)).format('YYYY-MM-DD');

                    var cT = moment(new Date(0).setUTCSeconds(a.date)).format('HH:MM');
                    var dT = moment(new Date(0).setUTCSeconds(b.date)).format('HH:MM');

                    return c.localeCompare(d) || cT.localeCompare(dT);
                });

                //reverse arr
                // let rev = resultant.reverse();

                return resultant;
            }
            else if (val === "old") {
                let resultant = payments.filter(item => {
                    return item.status === '' && (item.type === "CAPTURE" || item.type === "DELETED");
                });

                // date and time sorting
                // resultant.sort((a, b) => {
                //     var c = moment(new Date(0).setUTCSeconds(a.date)).format('YYYY-MM-DD');
                //     var d = moment(new Date(0).setUTCSeconds(b.date)).format('YYYY-MM-DD');

                //     var cT = moment(new Date(0).setUTCSeconds(a.date)).format('HH:MM');
                //     var dT = moment(new Date(0).setUTCSeconds(b.date)).format('HH:MM');

                //     return c.localeCompare(d) || cT.localeCompare(dT);
                // });

                //reverse arr
                // let rev = resultant.reverse();

                return resultant;
            }
            else if (val === "cancel") {
                let resultant = payments.filter(item => {
                    return item.type === "CANCEL_BOOKING_3";
                });

                // date and time sorting
                resultant.sort((a, b) => {
                    var c = moment(new Date(0).setUTCSeconds(a.date)).format('YYYY-MM-DD');
                    var d = moment(new Date(0).setUTCSeconds(b.date)).format('YYYY-MM-DD');

                    var cT = moment(new Date(0).setUTCSeconds(a.date)).format('HH:MM');
                    var dT = moment(new Date(0).setUTCSeconds(b.date)).format('HH:MM');

                    return c.localeCompare(d) || cT.localeCompare(dT);
                });

                //reverse arr
                let rev = resultant.reverse();

                return rev;
            }
            else if (val === "change_date") {
                let resultant = payments.filter(item => {
                    return item.status === 'DONE' && item.type === "CHANGE_DATE";
                });

                // date and time sorting
                resultant.sort((a, b) => {
                    var c = moment(new Date(0).setUTCSeconds(a.date)).format('YYYY-MM-DD');
                    var d = moment(new Date(0).setUTCSeconds(b.date)).format('YYYY-MM-DD');

                    var cT = moment(new Date(0).setUTCSeconds(a.date)).format('HH:MM');
                    var dT = moment(new Date(0).setUTCSeconds(b.date)).format('HH:MM');

                    return c.localeCompare(d) || cT.localeCompare(dT);
                });

                //reverse arr
                // let rev = resultant.reverse();

                return resultant;
            }
            else if (val === "change_therapist") {
                let resultant = payments.filter(item => {
                    return item.status === 'DONE' && item.type === "CHANGE_THERAPIST";
                });

                // date and time sorting
                resultant.sort((a, b) => {
                    var c = moment(new Date(0).setUTCSeconds(a.date)).format('YYYY-MM-DD');
                    var d = moment(new Date(0).setUTCSeconds(b.date)).format('YYYY-MM-DD');

                    var cT = moment(new Date(0).setUTCSeconds(a.date)).format('HH:MM');
                    var dT = moment(new Date(0).setUTCSeconds(b.date)).format('HH:MM');

                    return c.localeCompare(d) || cT.localeCompare(dT);
                });

                //reverse arr
                // let rev = resultant.reverse();

                return resultant;
            }
        }
        return payments;
    }

    render() {
        const { loading, } = this.props;

        //////debugger;
        // const { t } = this.state;
        const { t } = this.props;
        var columns = this.generateColumns(t, this.state.filterState);

        var filtered_ = this.filterBookings(this.state.filterState);

        return (
            <Fragment>
                <Container fluid className="personal-container user-container">
                    <Row>
                        <Col className="right-container col-md-12">
                            <h2 className="pb-5 col-md-8 float-left title-header">
                                {this.props.t.translations.scheduler}
                            </h2>
                        </Col>
                    </Row>
                    <Row>
                        <SegmentedControl
                            name="filterBooking"
                            options={[
                                // { label: "All", value: "all", default: true },
                                { label: t.translations.status_failed, value: "failed" },
                                { label: t.translations.status_success, value: "success", default: true },
                                { label: t.translations.status_start, value: "start" },
                                { label: t.translations.status_refund, value: "refund" },
                                { label: t.translations.status_canceled, value: "cancel" },
                                { label: t.translations.status_change_date, value: "change_date" },
                                { label: t.translations.status_change_therapist, value: "change_therapist" },
                                { label: t.translations.status_ignored, value: "ignored" },
                                { label: t.translations.status_old, value: "old" },
                            ]}
                            setValue={newValue => this.segmentControlTapped(newValue)}
                            style={{ width: '90%', height: 40, color: 'black' }} // purple400
                        />
                    </Row>
                    <Row className="">
                        {(columns !== null && t != null) &&
                            <MaterialTable t={this.props.t}
                                justShow={7}
                                columns={columns}
                                data={filtered_}
                                showPayment={true}
                                hideDelete={true}
                                showDetail={this.showDetail}
                                loading={loading} />}

                        {/* <Col className="col-md-12 mt-5 data-table-container">
                            <h3 className="ml-5 under-construction">Under Construction</h3>
                        </Col> */}
                    </Row>
                </Container >
            </Fragment>
        )
    }
}

const mapStateToProps = state => {
    return {
        t: state.accountRedux.localeInfo.t,
        loading: state.paymentRedux.loading,
        success: state.paymentRedux.success,
        message: state.paymentRedux.message,
        payments: state.paymentRedux.data,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getAllLogs: (data) => dispatch(getAllLogs(data))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Scheduler);
