/* eslint eqeqeq: 0 */
import React, { Fragment } from 'react'
import { Container, Col, Row } from 'shards-react';
import { required, } from '../../helper/validate';
import { FormField } from '../../helper/formFields';
import { reduxForm, Field } from 'redux-form';
import { connect } from 'react-redux';
import MaterialTable from '../common/materialTable';
import { getInvoices } from '../../redux/account.redux';
import { isEqual } from 'lodash';
import * as moment from 'moment';

var selected_month = "Jan";
var selected_month_id = 0;
var selected_month_index = 0;

// var total_vat = 0;
// var total_revenue = 0;
// var total_paid = 0;

// var total_vat_earned = 0;
// var total_vat_refunded = 0;


var total_vat_6 = 0;
var total_vat_25 = 0;
var total_rev_6 = 0;
var total_rev_25 = 0;

// var total_vat_6             = 0;
// var total_vat_25            = 0;




class VATReport extends React.Component {
    slot_values = [];
    addedSlots = false;

    getMonth(idx) {
        // console.log("mn: " + JSON.stringify(this.props.localeInformation ))
        var objDate = new Date();
        objDate.setDate(1);
        objDate.setMonth(idx - 1);

        var locale = "sv";
        var month = objDate.toLocaleString(locale, { month: "short" });

        return month;
    }

    constructor(props) {
        super(props);
        this.state = {
            slot_ids: [],
            // selected_month: "Apr",
            selected_month_id: (new Date()).getMonth(),
            selected_month_index: 0,
            total_vat: 0,
            total_paid: 0,
            total_revenue: 0,

            total_vat_25: 0,
            total_vat_6: 0,
            total_rev_6: 0,
            total_rev_25: 0,

            total_vat_refunded: 0,
            total_vat_earned: 0,

            filterState: "date",
            months: [
                { value: '0', label: this.getMonth(1) },
                { value: '1', label: this.getMonth(2) },
                { value: '2', label: this.getMonth(3) },
                { value: '3', label: this.getMonth(4) },
                { value: '4', label: this.getMonth(5) },
                { value: '5', label: this.getMonth(6) },
                { value: '6', label: this.getMonth(7) },
                { value: '7', label: this.getMonth(8) },
                { value: '8', label: this.getMonth(9) },
                { value: '9', label: this.getMonth(10) },
                { value: '10', label: this.getMonth(11) },
                { value: '11', label: this.getMonth(12) }
            ],
            hasTreatChange: false,
            default_treatment_category: 'Healness',
            price_per_unit_ex_vat: '662.94',
        }
    }

    componentDidMount() {
        // var data = { "month" : parseInt(this.state.selected_month_id) };
        // console.log("Param: " + data);

        var d = new Date();
        var n = d.getMonth();
        console.log("Month1: " + n);

        var temp = [];
        moment.locale("sv");

        for (let index = 0; index < 12; index++) {
            // d.setMonth(d.getMonth() - index);
            // var str = moment(d).format('MMM, YY');
            var dte = moment(d).subtract(index, 'months');
            var str = dte.format('MMM YY');

            console.log('Iteration: ' + str);
            console.log('month: ' + dte.month());
            console.log('year: ' + dte.year());

            var ident = ((dte.month() + 1) * 10000) + dte.year();
            if (index === 0) {
                selected_month_id = ident;
                selected_month = str;
            }
            var dict = { value: ident, label: str }
            temp.push(dict);
        }
        this.setState({ months: temp });
        console.log("temp: " + JSON.stringify(temp));
        moment.locale("en");


        var month_ = Math.round(selected_month_id / 10000);
        var year_ = selected_month_id % 10000;

        const data = {
            month: parseInt(month_),
            year: parseInt(year_)
        }
        this.props.getInvoices(data);
    }

    minTwoDigits(n) {
        return (n < 10 ? '0' : '') + n;
    }

    componentDidUpdate(prevProps) {
        if (!isEqual(prevProps.loading, this.props.loading)) {
            if (this.props.success) {
                // this.props.history.push('/treatment');
            }
        }
    }


    onChangeMonth = (month) => {
        console.log('onChangeMonth: ' + month);
        this.setState({ selected_month_id: month });
        var t = this.state.months.filter(element => {
            return element.value == month;
        })

        selected_month_id = month;
        selected_month = t[0].label;
        console.log('selected_month: ' + JSON.stringify(selected_month));



        // if (month == 0) {
        //     selected_month = this.getMonth(1);
        // }
        // if (month == 1) {
        //     selected_month = this.getMonth(2);
        // }
        // if (month == 2) {
        //     selected_month = this.getMonth(3);
        // }
        // if (month == 3) {
        //     selected_month = this.getMonth(4);
        // }
        // if (month == 4) {
        //     selected_month = this.getMonth(5);
        // }
        // if (month == 5) {
        //     selected_month = this.getMonth(6);
        // }
        // if (month == 6) {
        //     selected_month = this.getMonth(7);
        // }
        // if (month == 7) {
        //     selected_month = this.getMonth(8);
        // }
        // if (month == 8) {
        //     selected_month = this.getMonth(9);
        // }
        // if (month == 9) {
        //     selected_month = this.getMonth(10);
        // }
        // if (month == 10) {
        //     selected_month = this.getMonth(11);
        // }
        // if (month == 11) {
        //     selected_month = this.getMonth(12);
        // }

        let index = this.state.months.findIndex(x => x.value === month);

        this.setState({ selected_month_index: index });
        selected_month_index = index;


        var month_ = Math.round(month / 10000);
        var year_ = month % 10000;

        console.log("Month: " + month_);
        console.log("Year: " + year_);

        // total_vat = 0;
        // total_vat_refunded = 0;
        // total_vat_earned = 0;

        const data = {
            month: parseInt(month_),
            year: parseInt(year_)
        }
        this.props.getInvoices(data);
    }

    processStats(invoices) {
        // var filter = t.filter(function(el) {
        //     return el.location_type == data;
        //   });
        var temp = [];

        if (invoices.length > 0) {

            var temp_data = [];

            // total_vat = 0;
            // total_revenue = 0;
            // total_paid = 0;

            // total_vat_refunded = 0;
            // total_vat_earned = 0;

            total_vat_25 = 0;
            total_vat_6 = 0;
            total_rev_25 = 0;
            total_rev_6 = 0;



            for (var i = 0, len = invoices.length; i < len; i++) {
                var main = { ...invoices[i] };
                var obj = { ...invoices[i] };
                var obj1 = { ...invoices[i] };



                obj1["date_time"] = obj1.date + " " + obj1.time;
                obj1["total_revenue_6"] = (obj1.revenue_6_percent).toFixed(2);
                obj1["total_revenue_25"] = (obj1.revenue_25_percent).toFixed(2);
                obj1["total_vat_6"] = (obj1.vat_6_percent).toFixed(2);
                obj1["total_vat_25"] = (obj1.vat_25_percent).toFixed(2);
                obj1["is_refunded"] = false;
                temp_data.push(obj1);

                if (main.refund) {
                    obj["id"] += 1000;

                    obj["date_time"] = obj.date + " " + obj.time;
                    obj["total_revenue_6"] = "-" + (obj.revenue_6_percent).toFixed(2);
                    obj["total_revenue_25"] = "-" + (obj.revenue_25_percent).toFixed(2);
                    obj["total_vat_6"] = "-" + (obj.vat_6_percent).toFixed(2);
                    obj["total_vat_25"] = "-" + (obj.vat_25_percent).toFixed(2);
                    obj["is_refunded"] = true;
                    temp_data.push(obj);


                    total_vat_6 -= obj1["vat_6_percent"];
                    total_vat_25 -= obj1["vat_25_percent"];

                    total_rev_25 -= obj1["revenue_25_percent"];
                    total_rev_6 -= obj1["revenue_6_percent"];

                    // total_vat -= (obj1["vat_6_percent"] + obj1["vat_25_percent"]);

                    // total_revenue -= (obj1["revenue_6_percent"] + obj1["revenue_25_percent"]);
                    // total_paid -= obj1["total_payable"];

                    // total_vat_refunded += obj["vat_6_percent"] + obj["vat_25_percent"];

                }
                else {

                    total_vat_6 += obj1["vat_6_percent"];
                    total_vat_25 += obj1["vat_25_percent"];

                    total_rev_25 += obj1["revenue_25_percent"];
                    total_rev_6 += obj1["revenue_6_percent"];

                    // total_vat += (obj1["vat_6_percent"] + obj1["vat_25_percent"]);

                    // total_revenue += (obj1["revenue_6_percent"] + obj1["revenue_25_percent"]);
                    // total_paid += obj1["total_payable"];

                    // total_vat_earned += obj["vat_6_percent"] + obj["vat_25_percent"];
                }
            }

            // total_vat = total_temp;;

            temp = temp_data;
            console.log("Total: " + temp.length);
            console.log("Total JSON: " + JSON.stringify(temp));


            var data1_ = temp.filter(function (el) {
                return el.is_refunded == true;
            });
            console.log("Data1: " + data1_.length);

        }

        return temp;
    }

    segmentControlTapped(val) {
        console.log('segmentControlTapped: ' + val);
        this.setState({ filterState: val });
    }

    generateColumns(t) {
        var bookingColumns = null;
        // console.log("generateColumns - total_rev_25: " + total_rev_25);
        // console.log("generateColumns - total_rev_6: " + total_rev_6);
        // console.log("generateColumns - total_vat_25: " + total_vat_25);
        // console.log("generateColumns - total_vat_6: " + total_vat_6);

        if (t != null) {
            bookingColumns = [
                {
                    title: t.translations.booking_id,
                    field: 'booking_id',
                },
                {
                    title: t.translations.payment_method,
                    field: 'payment_method'
                },
                {
                    title: t.translations.schedule_date,
                    field: 'date_time'
                },
                {
                    title: t.translations.customer_name,
                    field: 'customer_name'
                },
                {
                    title: t.translations.revenue_6_percent,
                    field: 'total_revenue_6',
                    // render: rowData => <span>{(rowData != undefined ?  (rowData.is_refunded ? "-" : "") + (rowData.revenue_6_percent).toFixed(2) : (total_rev_6).toFixed(2) + " SEK" )}</span>
                },
                {
                    title: t.translations.revenue_25_percent,
                    field: 'total_revenue_25',
                    // render: rowData => <span>{(rowData != undefined ? (rowData.is_refunded ? "-" : "") + (rowData.revenue_25_percent).toFixed(2) : (total_rev_25).toFixed(2) + " SEK" )}</span>
                },
                {
                    title: t.translations.vat_6_percent,
                    field: 'total_vat_6',
                    // render: rowData => <span>{(rowData != undefined ? (rowData.is_refunded ? "-" : "") + (rowData.vat_6_percent).toFixed(2) : (total_vat_6).toFixed(2) + " SEK")}</span>
                },
                {
                    title: t.translations.vat_25_percent,
                    field: 'total_vat_25',
                    // render: rowData => <span>{(rowData != undefined ? (rowData.is_refunded ? "-" : "") + (rowData.vat_25_percent).toFixed(2) : (total_vat_25).toFixed(2) + " SEK" )}</span>
                },
                // {
                //     title: t.translations.refund,
                //     field: 'refund',
                //     render: rowData => <span>{(rowData != undefined ?((rowData.id > 1000) ? "true" : "false") : "")}</span>

                // },
                // {
                //     title: t.translations.total_vat,
                //     field: 'total_paid',
                // render: rowData => <span>{(rowData != undefined ?((rowData.id > 1000) ? "-" : "") + (rowData.vat_6_percent + rowData.vat_25_percent).toFixed(2) + " SEK" : "0.00 SEK" )}</span>
                // },
            ];
        }

        return bookingColumns;
    }

    filterInvoices(val) {
        const { invoices } = this.props;
        var invoices_ = invoices;

        var invoices_temp = [];
        //////debugger;

        if (invoices_.length > 0) {
            if (val === "date") {
                const unique_therapists = [...new Set(invoices_.map(item => item.therapist_id))];
                var temp = [];
                unique_therapists.forEach(element => {
                    var filter_ = invoices_.filter(function (el) {
                        return el.therapist_id == element;
                    });
                    // const unique_dates = [...new Set(filter_.map(item => item.date))];

                    let map = filter_.reduce((prev, next) => {
                        if (next.date in prev) {
                            prev[next.date].vat_25_percent += next.vat_25_percent;
                            prev[next.date].vat_6_percent += next.vat_6_percent;
                            prev[next.date].slot_time += next.slot_time;
                        }
                        else {
                            prev[next.date] = next;
                        }
                        return prev;
                    }, {});

                    const values = Object.values(map);

                    temp += values;
                });

                invoices_temp = temp;//[invoices_[0]];

            }
            else {
                invoices_temp = invoices_;
            }
        }


        return invoices_temp;
    }

    showMonthReport = () => {
        // if (this.props.user.role_type === 3) {
        //     this.setState({ showLocationDropDown: true });
        // const data1 = { therapist_id: -1 }
        // this.props.getALLLocationsForTherapist(data1)
        // }
        // else {
        //     this.props.newLocation();
        //     this.props.history.push('/addlocation')
        // }
    }

    showVATReport = (event) => {
        this.props.history.push('/vat');
    }

    render = () => {
        const { t, invoices, loading, } = this.props;

        var processed = this.processStats(invoices);
        var bookingColumns = this.generateColumns(t);
        // var show_selected_month = selected_month;
        var show_selected_month = { label: selected_month.label, value: selected_month_id.value };

        console.log("Month4: " + JSON.stringify(show_selected_month));

        // var ffdate              = new Date(2020, selected_month_id, 1);  // 2009-11-10
        // const month             = ffdate.toLocaleString('default', { month: 'long' });

        var month_ = Math.round(selected_month_id / 10000);
        var year_ = selected_month_id % 10000;
        var ffdate = new Date(year_, month_, 1);  // 2009-11-10
        var dte = moment(ffdate);
        var month = dte.format('MMM YY');

        processed.forEach(ele => {
            // console.log("Obj: " + JSON.stringify(ele.id) + " =: " + ele.is_refunded  + " = " + (ele.id > 1000));
            console.log("Obj: " + JSON.stringify(ele.id) + " = " + (ele.id > 1000));

        });
        //////debugger;

        return (
            <Fragment>
                <Container fluid className="personal-container user-container">
                    <Row>
                        <Col className="invoice-right-container col-md-12">
                            <h2 className="pb-5 col-md-8 float-left title-header">
                                {this.props.t.translations.vat_report}
                            </h2>
                        </Col>
                    </Row>
                    <Row className="">
                        <Col className="col-md-12 mt-5">
                            {/* <div className="row"> */}
                            {/* <div className="col-md-12 col-xs-12 float-center"> */}
                            <Field
                                label={t != null ? t.translations.select_month : ''}
                                type="select"
                                name="month"
                                classes="Field-input"
                                options={this.state.months}
                                validate={[required]}
                                component={FormField}
                                onChange={this.onChangeMonth}
                                t={this.props.t}
                                defaultValue={this.state.months[selected_month_index]}
                                placeholder={t != null ? t.translations.month : ''}
                            />
                            {/* </div> */}
                            {/* </div> */}
                        </Col>
                    </Row>
                    <Row>
                        <Col className="col-md-12">
                            <div className="row">
                                <div className="col-md-12 col-xs-12 float-center">
                                    {/* <h6 align="left">{"             Total Paid = (Total Revenue + Total VAT)        =>      " + total_paid.toFixed(2) + " = (" +  total_revenue.toFixed(2) + " + " + total_vat.toFixed(2) + ")" }</h6> */}
                                    <h6 align="center">{t.translations.revenue_6_percent + ": " + total_rev_6.toFixed(2) + " SEK"}</h6>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12 col-xs-12 float-center">
                                    {/* <h6 align="left">{"             Total Paid = (Total Revenue + Total VAT)        =>      " + total_paid.toFixed(2) + " = (" +  total_revenue.toFixed(2) + " + " + total_vat.toFixed(2) + ")" }</h6> */}
                                    <h6 align="center">{t.translations.revenue_25_percent + ": " + total_rev_25.toFixed(2) + " SEK"}</h6>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12 col-xs-12 float-center">
                                    {/* <h6 align="left">{"             Total Revenue = (6% Revenue + 25% Revenue)      =>      " + total_revenue.toFixed(2) + " = (" +  total_rev_6.toFixed(2) + " + " + total_rev_25.toFixed(2) + ")"}</h6> */}
                                    <h6 align="center">{t.translations.vat_6_percent + ": " + total_vat_6.toFixed(2) + " SEK"}</h6>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12 col-xs-12 float-center">
                                    {/* <h6 align="left">{"             Total VAT = (6% VAT + 25% VAT)      =>      " + total_vat.toFixed(2) + " = (" +  total_vat_6.toFixed(2) + " + " + total_vat_25.toFixed(2) + ")"}</h6> */}
                                    <h6 align="center">{t.translations.vat_25_percent + ": " + total_vat_25.toFixed(2) + " SEK"}</h6>
                                </div>
                            </div>
                            {/* <div className="row">
                            <div className="col-md-12 col-xs-12 float-left">
                                <h6 align="left">{"             Total VAT Earned = (Total VAT - Total Refunded VAT)      =>      " + total_vat.toFixed(2) + " = (" +  total_vat_earned.toFixed(2) + " - " + total_vat_refunded.toFixed(2) + ")"}</h6>
                            </div>
                        </div> */}
                        </Col>
                    </Row>
                    <Row>
                        <Col className="col-md-12">
                            {/* <Row className=""> */}
                            <MaterialTable t={this.props.t}
                                justShow={10}
                                columns={bookingColumns}
                                data={processed}
                                // showDetail={this.showDetail}
                                loading={loading}
                                // deleteDetail={this._renderDialog}
                                selected_month={month}
                                view={true} />
                            {/* </Row> */}
                        </Col>
                    </Row>
                </Container >
            </Fragment>
        );
    }
};

const mapStateToProps = state => {
    return {
        t: state.accountRedux.localeInfo.t,
        // loading: state.accountRedux.loading,
        // success: state.accountRedux.success,
        // message: state.accountRedux.message,
        invoices: state.accountRedux.invoices
    };
};
const mapDispatchToProps = dispatch => {
    return {
        getInvoices: (data) => dispatch(getInvoices(data)),
    }
};
export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({
    form: 'treatmentForm',
    destroyOnUnmount: false
})(VATReport));
