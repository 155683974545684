/* eslint eqeqeq: 0 */
import React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import routes from "./routes";
import withTracker from "./withTracker";
import "bootstrap/dist/css/bootstrap.min.css";
// import 'bootstrap/dist/js/bootstrap.min.js';

import "./styles/shards-dashboards.1.1.0.css";
import "./styles/index.scss"
import { localeInformation } from './redux/account.redux';
import { connect } from 'react-redux';
import { DragDropContext as dragDropContext } from "react-dnd";
import HTML5Backend from "react-dnd-html5-backend";
import StaffLogin from './components/authentication/staff/staffLogin/index';
import SecretLogin from './components/authentication/secret/secretLogin/index';
import { S_LOGIN } from './constants/urls'
// import '../node_modules/react-agenda/build/index.js';
// import '../node_modules/react-datetime/css/react-datetime.css';
import 'react-agenda/build/styles.css';
import 'react-datetime/css/react-datetime.css';
import { decryptToken } from "./constants";


class App extends React.Component {

  state = {
    value: (window.navigator.userLanguage || window.navigator.language) == 'en' ? 'en' : 'sv'
  };

  saveState() {
    ////////debugger;
    console.log('saveState');

  }

  componentDidMount() {
    window.addEventListener('onbeforeunload', this.saveState)


  }

  componentWillUnmount() {
    window.removeEventListener('beforeunload', this.saveState)
  }

  handleChange = event => {
    let newlang = event.target.value;
    this.setState(prevState => ({ value: newlang }));
    this.props.i18n.changeLanguage(newlang);
  };

  render() {
    const { i18n, user, } = this.props;
    var fun = this.handleChange;
    var data = Object.keys(i18n.store.data).find(key => key === this.state.value);
    var payloadData = {
      value: this.state.value,
      t: i18n.store.data[data],
      i18n: i18n,
      handleChange: fun
    }
    this.props.localeInformation(payloadData);
    const { token } = user;
    var url = window.location.href
    url = url.split("/")
    url = url[url.length - 1]

    console.log(decryptToken(S_LOGIN))

    return (
      < Router basename={process.env.REACT_APP_BASENAME || ""} >
        <div>

          {
            (Object.entries(this.props.secretUser).length != 0 && token == null)
              ?
              <StaffLogin t={i18n.store.data[data]} handleChange={this.handleChange} value={this.state.value} />
              :
              token == null ?
                (
                  url == decryptToken(S_LOGIN) ?
                    <SecretLogin t={i18n.store.data[data]} handleChange={this.handleChange} value={this.state.value} />
                    :
                    <StaffLogin t={i18n.store.data[data]} handleChange={this.handleChange} value={this.state.value} />
                )
                :
                // <Route path="/" component={StaffLogin} t={i18n.store.data[data]} handleChange={this.handleChange} value={this.state.value}/>
                routes.map((route, index) => {
                  return (
                    <Route
                      key={index}
                      path={route.path}
                      exact={route.exact}
                      component={withTracker(props => {
                        return (
                          <route.layout {...props} value={this.state.value} t={i18n.store.data[data]} i18n={i18n} handleChange={this.handleChange}>
                            <route.component {...props} />
                          </route.layout>
                        );
                      })}
                    />
                  );
                })
          }
        </div>
      </Router >
    )
  }

}
const mapStateToProps = state => {
  return {
    user: state.accountRedux.user,
    bankIdResponse: state.accountRedux.bankIdResponse,
    secretUser: state.accountRedux.secretUser
  };
};
const mapDispatchToProps = dispatch => {
  return {
    localeInformation: (data) => dispatch(localeInformation(data)),
  }
};

// export default App;

export default connect(mapStateToProps, mapDispatchToProps)(dragDropContext(HTML5Backend)(App));
