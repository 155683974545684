import { call, put, takeLatest } from "redux-saga/effects";
import Api from "../services/ApiCaller";
import {
   getBookings,
   editBooking,
   deleteBooking,
   getBookingPriceInformation,
   getRefundedBookings
} from "../redux/booking.redux";

function* getBookingsRequest(api, { payload = {} }) {
   try {
      console.log('Base URL: ' + Api.callServer);
      console.log('API URL: ' + api);
      console.log('Params: ' + JSON.stringify(payload));


      const { res = {} } = yield call(Api.callServer, api, payload);
      const { data, message, success } = res;

      if (success) {
         yield put(getBookings.success(data));
      } else {
         yield put(getBookings.failure(message));
      }
   } catch (e) {
      yield put(getBookings.failure(e.result));
   }
}
function* getRefundedBookingsRequest(api, { payload = {} }) {
   try {
      console.log('Base URL: ' + Api.callServer);
      console.log('API URL: ' + api);
      console.log('Params: ' + JSON.stringify(payload));


      const { res = {} } = yield call(Api.callServer, api, payload);
      const { data, message, success } = res;

      if (success) {
         yield put(getRefundedBookings.success(data));
      } else {
         yield put(getRefundedBookings.failure(message));
      }
   } catch (e) {
      yield put(getRefundedBookings.failure(e.result));
   }
}

function* editBookingRequest(api, { payload = {} }) {
   try {
      console.log('Base URL: ' + Api.callServer);
      console.log('API URL: ' + api);
      console.log('Params: ' + JSON.stringify(payload));

      let formData = new FormData();    //formdata object

      formData.append('booking_id', payload.booking_id);
      formData.append('notes', payload.notes);
      formData.append('payment_status', "update_note");

      const { res = {} } = yield call(Api.callServer, api, formData);

      const { data, message, success } = res;

      if (success) {
         yield put(editBooking.success(data));
      } else {
         yield put(editBooking.failure(message));
      }
   } catch (e) {
      yield put(editBooking.failure(e.result));
   }
}


function* deleteBookingRequest(api, { payload = {} }) {
   try {
      const { res = {} } = yield call(Api.callServer, api, payload);
      const { message, success } = res;
      if (success) {
         yield put(deleteBooking.success(message));
      }
      else {
         yield put(deleteBooking.failure(message));
      }
   } catch (e) {
      yield put(deleteBooking.failure(e.result));
   }
}

function* getBookingPriceInformationRequest(api, { payload = {} }) {
   try {
      const { res = {} } = yield call(Api.callServer, api, payload);
      const { data, message, success } = res;
      if (success) {
         yield put(getBookingPriceInformation.success(data));
      } else {
         yield put(getBookingPriceInformation.failure(message));
      }
   } catch (e) {
      yield put(getBookingPriceInformation.failure(e.result));
   }
}

export default (api) => {
   const editBookingApi = (data) => api.put("v1/updateBooking", data);
   const getBookingApi = (data) => api.get("v1/GetAllBookingForAdmin", data); //GetAllBookingForAdmin or /GetAllBooking
   const getRefundedBookingApi = (data) => api.get("v1/GetAllRefundedSwishBooking", data);
   const deleteBookingApi = (data) => api.delete("v1/cancelBooking", data);
   const getBookingPriceApi = (data) => api.get("v1/getPriceForBooking", data); // Garbage


   return [
      takeLatest(getBookings.TRIGGER, getBookingsRequest, getBookingApi),
      takeLatest(editBooking.TRIGGER, editBookingRequest, editBookingApi),
      takeLatest(deleteBooking.TRIGGER, deleteBookingRequest, deleteBookingApi),
      takeLatest(getBookingPriceInformation.TRIGGER, getBookingPriceInformationRequest, getBookingPriceApi),
   ];
};
