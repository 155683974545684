import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Col } from "shards-react";
import { connect } from 'react-redux';
import SidebarNavItems from "./SidebarNavItems";
import MainFooter from '../MainFooter';
import { isEmpty } from 'lodash';
import { exportLocalStorageData } from '../../../redux/account.redux';

class MainSidebar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      menuVisible: props.menuVisible
    };

    this.onChange = this.onChange.bind(this);
  }

  componentDidMount() {
    var user = this.props.user;
    if (isEmpty(user)) {
      var userData = localStorage.getItem('localInfo');
      this.props.exportLocalStorageData(JSON.parse(userData));
      user = JSON.parse(userData);
    }
  }

  componentWillUnmount() {
  }

  onChange() {

  }

  render() {
    const classes = classNames(
      "main-sidebar",
      "px-0",
      "col-12",
      this.state.menuVisible && "nav-drawer-mobile",

    );

    return (
      <Col
        tag="aside"
        className={classes}
        lg={{ size: 2 }}
        md={{ size: 3 }}
      >
        {/* <SidebarMainNavbar hideLogoText={this.props.hideLogoText} /> */}
        <SidebarNavItems history={this.props.history} />
        <MainFooter />
      </Col>
    );
  }
}

MainSidebar.propTypes = {
  /**
   * Whether to hide the logo text, or not.
   */
  hideLogoText: PropTypes.bool
};

MainSidebar.defaultProps = {
  hideLogoText: false
};
const mapStateToProps = state => {
  return {
    user: state.accountRedux.user
  };
};
const mapDispatchToProps = dispatch => {
  return {
    exportLocalStorageData: (data) => dispatch(exportLocalStorageData(data)),
  }
};
export default connect(mapStateToProps, mapDispatchToProps)(MainSidebar);
