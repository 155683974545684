/* eslint eqeqeq: 0 */
import React, { Fragment } from 'react';
import { Container, Row, Col } from 'shards-react';
import { reduxForm, } from 'redux-form';
import { connect } from 'react-redux';
import 'react-big-scheduler/lib/css/style.css';
import MaterialTable from '../common/materialTable';

import moment from 'moment';
// import { getBookings, showBookingDetail, deleteBooking, getBookingPriceInformation } from '../../redux/booking.redux';
import { statsTherapist } from '../../redux/therapist.redux';
import { showBookingDetail, } from '../../redux/booking.redux';

import ConfirmationDialog from '../common/ConfirmationDialog';
import { SegmentedControl } from 'segmented-control'

var my_revenue_total = 0.0;
var bookingInfo = [];

class Schedule extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            test: false,
            filterState: "this_month",
            data: null,
            showDialogue: false,
            bookingData: [],
            filtered_bookings: [],
            t: null,
            gettherapistStart: false,
            showTherapistTreatments: false,
            selectedTherapist: '',
            therapistWorkingHours: [],
            currentShowOnCalendar: [],
            showSchedule: false
        }

    }
    UNSAFE_componentWillMount() {
        this.setState({ t: this.props.t });
        var user_id = this.props.user.id;
        console.log('MY THERAPIST ID: ' + user_id);

        const data = {
            "therapist_id": user_id,
            "epoch_date": "1585650576"
        };
        this.props.statsTherapist(data);
    }

    generateColumns(t) {
        var bookingColumns = null;

        if (t != null) {
            bookingColumns = [
                {
                    title: t.translations.booking_id,
                    field: 'booking_id'
                    // render: rowData => <span>{Buffer.from(rowData.id, 'base64').toString('ascii')}</span>
                },
                {
                    title: t.translations.date_time,
                    field: 'date',
                    render: rowData => <span>{rowData.date + " " + rowData.time}</span>
                },
                {
                    title: t.translations.customer_name,
                    field: 'customer_name',
                },
                {
                    title: t.translations.treatment_type,
                    field: 'treatment_type',
                    render: rowData => <span>{rowData.treatment_name + " (" + rowData.treatment_category + ")"}</span>
                },
                {
                    title: t.translations.select_slot,
                    field: 'select_slot',
                    render: rowData => <span>{rowData.slot_time}</span>
                },
                {
                    title: t.translations.total_revenue,
                    field: 'total_revenue',
                    render: rowData => <span>{(rowData.is_refunded ? "-" : "") + (rowData.revenue_6_percent + rowData.revenue_25_percent).toFixed(2) + " SEK"}</span>
                },
                {
                    title: t.translations.my_revenue,
                    field: 'my_revenue',
                    render: rowData => <span>{(rowData.is_refunded ? "-" : "") + (rowData.my_revenue).toFixed(2) + " SEK"}</span>
                },


                // {
                //     title: "revenue_6_percent",
                //     field: 'revenue_6_percent',
                // },
                // {
                //     title: "revenue_25_percent",
                //     field: 'revenue_25_percent',
                // },
                // {
                //     title: t.translations.total_vat,
                //     field: 'total_vat',
                //     render: rowData => <span>{(rowData.vat_6_percent + rowData.vat_25_percent).toFixed(2) + " SEK"}</span>
                // },
                // {
                //     title: "vat_6_percent",
                //     field: 'vat_6_percent',
                // },
                // {
                //     title: "vat_25_percent",
                //     field: 'vat_25_percent',
                // },
                // {
                //     title: t.translations.total_paid,
                //     field: 'total_payable',
                //     render: rowData => <span>{(rowData.total_payable).toFixed(2) + " SEK"}</span>
                // },
                {
                    title: t.translations.location,
                    field: 'location'
                },
                {
                    title: t.translations.company,
                    field: 'company_name'
                },

                // ,
                // {
                //     title: t.translations.authorization_id,
                //     field: 'authorization_id'
                // }
            ];
        }

        return bookingColumns;
    }


    showDetail = (event) => {
        // this.props.showBookingDetail(event);
        bookingInfo = event;
        this.setState({
            showDialogue: true
        })
    }

    calculateSums() {
        const { stats } = this.props;

        if (stats !== "") {
            // const unique = [...new Set(stats.map(item => item.date))];

            // unique.forEach(element => {

            // });
        }

    }

    filterBookings(val) {
        const { stats } = this.props;
        var bookings = stats;



        if (bookings != undefined && bookings != null && bookings != []) {

            var temp_arr = [];
            var current_arr = [];
            var past_arr = [];

            const today = moment();
            const from_date = today.startOf('month').format("YYYY-MM-DD");
            const to_date = today.endOf('month').format("YYYY-MM-DD");

            var last_month = moment().add(-6, "month");
            const from_date_last = last_month.startOf('month').format("YYYY-MM-DD");

            for (let index = 0; index < bookings.length; index++) {
                const element = bookings[index];
                var key = element[0]["date"];
                var formatted_date = moment(new Date(key)).format('YYYY-MM-DD');

                var count_ = element.length;
                var isArray = Array.isArray(element)

                if (isArray && count_ > 0) {
                    // const totalRev = element.reduce((prev, next) => prev + next.revenue_25_percent + next.revenue_6_percent, 0);
                    const totalHrs = element.reduce((prev, next) => prev + next.slot_time, 0);

                    // var my_rev_ = (totalHrs >= 180 ? totalRev * 0.70 : totalRev * 0.65);

                    // var data = {
                    //     "count": count_,
                    //     "data": element,
                    //     "my_revenue": my_rev_.toFixed(2),
                    //     "sum_revenue": totalRev.toFixed(2),
                    //     "sum_hrs": totalHrs
                    // }

                    // var final = {
                    //     key: data
                    // }
                    // temp_arr.push(final);

                    const map1 = element.map(x => {
                        x["tota_day_hrs"] = totalHrs;
                        x["my_revenue"] = (totalHrs >= 180 ? (x.revenue_25_percent + x.revenue_6_percent) * 0.70 : (x.revenue_25_percent + x.revenue_6_percent) * 0.65);
                        return x;
                    });

                    if (formatted_date >= from_date && formatted_date <= to_date) {
                        current_arr.push.apply(current_arr, map1);
                    }
                    else if (formatted_date >= from_date_last && formatted_date <= to_date) {
                        past_arr.push.apply(past_arr, map1);
                    }
                }
            }

            // date and time sorting
            past_arr.sort((a, b) => {
                var c = moment(new Date(a.date)).format('YYYY-MM-DD');
                var d = moment(new Date(b.date)).format('YYYY-MM-DD');

                var cT = moment(new Date(a.date)).format('HH:MM');
                var dT = moment(new Date(b.date)).format('HH:MM');

                return d.localeCompare(c) || dT.localeCompare(cT);
            });

            current_arr.sort((a, b) => {
                var c = moment(new Date(a.date)).format('YYYY-MM-DD');
                var d = moment(new Date(b.date)).format('YYYY-MM-DD');

                var cT = moment(new Date(a.date)).format('HH:MM');
                var dT = moment(new Date(b.date)).format('HH:MM');

                return d.localeCompare(c) || dT.localeCompare(cT);
            });

            //reverse arr
            let rev_past = past_arr.reverse();
            let rev_current = current_arr.reverse();

            if (val === "this_month") {
                const totalRev = rev_current.reduce((prev, next) => prev + next.my_revenue, 0);
                my_revenue_total = totalRev;
                return rev_current;
            }
            else if (val === "last_month") {
                const totalRev = rev_past.reduce((prev, next) => prev + next.my_revenue, 0);
                my_revenue_total = totalRev;
                return rev_past;
            }
        }
        else {
            bookings = [];
        }
        return bookings;
    }

    segmentControlTapped(val) {
        console.log('segmentControlTapped: ' + val);
        this.setState({ filterState: val });
    }

    processStats(invoices) {
        // var filter = t.filter(function(el) {
        //     return el.location_type == data;
        //   });
        var temp_data = [];


        if (invoices.length > 0) {
            // var total_rev_6_ = invoices.reduce((prev,next) => prev + next.revenue_6_percent,0);
            // var total_rev_25_ = invoices.reduce((prev,next) => prev + next.revenue_25_percent,0);
            // var total_vat_6_ = invoices.reduce((prev,next) => prev + next.vat_6_percent,0);
            // var total_vat_25_ = invoices.reduce((prev,next) => prev + next.vat_25_percent,0);
            // var total_paid_ = invoices.reduce((prev,next) => prev + next.total_payable,0);

            // var total = (total_rev_6_ + total_rev_25_) ;
            // var total_vat_ = (total_vat_6_ + total_vat_25_) ;

            // debugger;
            // // if (this.state.total_revenue != total) {
            //     total_paid      = total_paid_;
            //     total_vat       = total_vat_;
            //     total_revenue   = total ;

            //     total_rev_6     = total_rev_6_ ;
            //     total_rev_25    = total_rev_25_ ;
            //     total_vat_6     = total_vat_6_ ;
            //     total_vat_25    = total_vat_25_;
            // // }

            // console.log("Total Receipts: " + JSON.stringify(invoices.length));
            // const unique_therapists = [...new Set(invoices.map(item => item.therapist_id))];
            // console.log("unique_therapists: " + JSON.stringify(unique_therapists.length));

            // unique_therapists.forEach(element => {
            //     // therapist based bookings
            //     var filter_ = invoices.filter(function(el) {
            //         return el.therapist_id == element;
            //     });
            //     console.log("therapist based bookings: " + JSON.stringify(filter_.length));

            //     //unique dates
            //     const unique_dates = [...new Set(filter_.map(item => item.date))];
            //     console.log("Unique Dates: " + JSON.stringify(unique_dates));
            //     unique_dates.forEach(date => {

            //         var sum = 0;
            //         //date wise check
            //         var filter_b = filter_.filter(function(el) {
            //             var cond = (el.date == date && el.therapist_id == element);
            //             if (cond) {
            //                 sum += el.slot_time;
            //             }
            //             return cond;
            //         });

            //         console.log("filter_b: " + JSON.stringify(filter_b));


            //         //////debugger;
            //         var result = [];
            //         if (sum >= 180) { // 3 or 2.75 
            //             result = filter_b.map((el) => {
            //                 el["isMoreThanThreeHrs"] = true;
            //                 return el;
            //             })
            //             // result = filter_b.map(function(el) {
            //             //     el["isMoreThanThreeHrs"] = true;
            //             //     return el;
            //             // });
            //         }
            //         else {
            //             result = filter_b.map((el) => {
            //                 el["isMoreThanThreeHrs"] = false;
            //                 return el;
            //             })
            //         }
            //         temp = temp.concat(result);

            //         // temp.push(result);
            //     });

            // });

            for (var i = 0, len = invoices.length; i < len; i++) {
                var main = { ...invoices[i] };
                var obj = { ...invoices[i] };
                var obj1 = { ...invoices[i] };

                obj1["is_refunded"] = false;
                temp_data.push(obj1);

                if (main.refund) {
                    obj["is_refunded"] = true;
                    temp_data.push(obj);
                }

            }

        }

        return temp_data;

    }

    getMonth(idx) {
        // console.log("mn: " + JSON.stringify(this.props.localeInformation ))
        var objDate = new Date();
        objDate.setDate(1);
        objDate.setMonth(idx - 1);

        var locale = "sv";
        var month = objDate.toLocaleString(locale, { month: "short" });

        return month;
    }

    getAllMonths() {
        return [
            this.getMonth(1),
            this.getMonth(2),
            this.getMonth(3),
            this.getMonth(4),
            this.getMonth(5),
            this.getMonth(6),
            this.getMonth(7),
            this.getMonth(8),
            this.getMonth(9),
            this.getMonth(10),
            this.getMonth(11),
            this.getMonth(12)
        ]
    }

    render = () => {
        const { t } = this.state;
        const { loading, } = this.props;
        var bookingColumns = this.generateColumns(t);
        // var unique = [...new Set(stats.map(item => item.date))];
        // var ages = stats.map(function(obj) { return obj.date; });
        // ages = ages.filter(function(v,i) { return ages.indexOf(v) == i; });
        // console.log('Unique: ' + ages);


        let rev_bookings = this.filterBookings(this.state.filterState);   //bookings.reverse();
        let rev_bookings_temp = this.processStats(rev_bookings);   //bookings.reverse();

        return (
            <Fragment>
                <Container fluid className="personal-container user-container">
                    {this.state.showDialogue &&
                        <ConfirmationDialog
                            title={this.state.t != null ? this.state.t.translations.booking_details : ''}
                            bookingCancelBtn={true}
                            bodyText={
                                <Fragment>
                                    <h5>{t.translations.booking_id}</h5>
                                    <p>{bookingInfo.booking_id}</p>
                                    <h5>{t.translations.date_time}</h5>
                                    <p>{bookingInfo.date + " " + bookingInfo.time}</p>
                                    <h5>{t.translations.customer_name}</h5>
                                    <p>{bookingInfo.customer_name}</p>
                                    <h5>{t.translations.treatment_type}</h5>
                                    <p>{bookingInfo.treatment_type}</p>
                                    <h5>{t.translations.slot_time}</h5>
                                    <p>{bookingInfo.slot_time}</p>
                                    <h5>{t.translations.total_revenue}</h5>
                                    <p>{(bookingInfo.is_refunded ? "-" : "") + (bookingInfo.revenue_6_percent + bookingInfo.revenue_25_percent).toFixed(2) + " SEK"}</p>
                                    <h5>{t.translations.my_revenue}</h5>
                                    <p>{(bookingInfo.is_refunded ? "-" : "") + bookingInfo.my_revenue.toFixed(2) + " SEK"}</p>
                                    <h5>{t.translations.location}</h5>
                                    <p>{bookingInfo.location}</p>
                                    <h5>{t.translations.company}</h5>
                                    <p>{bookingInfo.company_name}</p>

                                    {/* <h5>{t.translations.authorization_id}</h5>
                                    <p>{bookingInfo.authorization_id}</p> */}
                                </Fragment>
                            }
                            handleCancel={() => this.setState({ showDialogue: false })}
                            handleOk={null} />}
                    <Row>
                        <Col className="invoice-right-container col-md-12">
                            <h2 className="pb-5 col-md-8 float-left title-header">
                                {this.props.t.translations.staffdashboard}
                            </h2>
                        </Col>
                    </Row>
                    <Row>
                        <SegmentedControl
                            name="filterBooking"
                            options={[
                                // { label: "This Week", value: "week" },
                                // { label: "This Month", value: "month" },
                                { label: t != null ? t.translations.last_month : "Previous Month", value: "last_month" },
                                { label: t != null ? t.translations.this_month : "This Month", value: "this_month", default: true },
                            ]}
                            setValue={newValue => this.segmentControlTapped(newValue)}
                            style={{ width: 400, height: 45, color: 'black' }} // purple400
                        />
                    </Row>
                    {/* <Row>
                    <label>Pick A Month</label>
                    <input type="text" class="form-control" id="datepicker-example"></input>
                </Row> */}
                    <Row className="">
                        <MaterialTable t={this.props.t}
                            justShow={6}
                            month={this.state.filterState === "this_month" ? moment().locale('se').format('MMM, YYYY') : moment().subtract(1, 'months').locale('se').format('MMM, YYYY')}
                            month_revenue={my_revenue_total.toFixed(2)}
                            columns={bookingColumns}
                            data={rev_bookings_temp}
                            showDetail={this.showDetail}
                            loading={loading}
                            // deleteDetail={this._renderDialog}
                            view={true} />
                    </Row>
                </Container >
            </Fragment>
        );
    }

};

const mapStateToProps = state => {
    return {
        t: state.accountRedux.localeInfo.t,
        success: state.therapistRedux.success,
        loading: state.therapistRedux.loading,
        message: state.therapistRedux.message,
        stats: state.therapistRedux.stats,
        user: state.accountRedux.user,
        bookingInfo: state.bookingRedux.bookingInfo,
        bookingPriceInfo: state.bookingRedux.bookingPriceInfo
    };
};
const mapDispatchToProps = dispatch => {
    return {
        showBookingDetail: (data) => dispatch(showBookingDetail(data)),
        statsTherapist: (data) => dispatch(statsTherapist(data)),
    }
};
export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({
    form: 'ScheduleForm'
})(Schedule));


