/* eslint eqeqeq: 0 */
import React from 'react';
import { Container, Form, Button, Col } from 'shards-react';
import { required, validEmail } from '../../helper/validate';
import { FormField } from '../../helper/formFields';
import { reduxForm, Field } from 'redux-form';
import { connect } from 'react-redux';
import { getCompanies } from '../../redux/company.redux';
import Alert from '../common/Alert';
import Header from '../common/header';
import { getTreatments } from '../../redux/treatment.redux';
import { addTherapist, updateTherapist } from '../../redux/therapist.redux';
import { getLocations, getALLLocationsForTherapist } from '../../redux/location.redux';

import PhoneInput from 'react-phone-number-input';
import { isEmpty, isEqual } from 'lodash'; // isString, isNull
import { RNS3 } from 'react-native-s3-upload-new';
import defaultImage from '../../images/defaultProfile.png'
const Compress = require('compress.js')
const personnummer = require('personnummer');
const compress = new Compress()

const options = {
    keyPrefix: "images/",
    bucket: "healness-images-s3",
    region: "eu-north-1",
    accessKey: "AKIATNGOCS26HXH67AKW",
    secretKey: "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",
    successActionStatus: 201
}

class AddTherapist extends React.Component {
    personNumber = '';
    without_treatment = false;
    without_location = false;

    _file = {};
    constructor(props) {
        super(props);
        this.state = {
            country: '',
            t: null,
            showErrorMesage: '',
            showError: false,
            treatment_ids: [],
            selected_treatment_ids: [],
            selected_location_ids: [],
            is_treatment_empty: false,
            is_location_empty: false,
            file: null,
            newFile: {},
            fileSizeErrorMessage: ''
        }
    }

    UNSAFE_componentWillMount() {
        var treatmentIds = [];
        var selectedTreatments = [];
        this.props.treatments.forEach(treatment => {
            treatmentIds.push(treatment.id);
        });

        if (!isEmpty(this.props.therapistInfo)) {
            this.props.therapistInfo.treatments.forEach(treatment => {
                selectedTreatments.push(treatment.id);
            });
            this.setState({ selected_treatment_ids: selectedTreatments });
        }

        if (!isEmpty(treatmentIds)) {
            if (isEmpty(this.state.treatment_ids)) {
                this.setState({ treatment_ids: treatmentIds })
            }
        }
    }

    componentDidMount() {
        this.props.getTreatments();
        this.setState({ t: this.props.t, mobile_phone: this.props.therapistInfo.mobile_phone });
        var therapist = this.props.therapistInfo;
        if (therapist.zip === 0) {
            therapist.zip = ''
        }
        // if (!isEmpty(therapist)) {
        //     therapist.person_number = therapist.person_number.substring(therapist.person_number.length, therapist.person_number.length - 10).replace(/(\d{3})(\d{3})(\d{4})/, "$1$2-$3");
        // }
        if (!isEmpty(therapist)) {
            if (therapist.city === null) {
                therapist.city = '';
            }

        }

        const data1 = { therapist_id: -1 }
        this.props.getALLLocationsForTherapist(data1);
        // this.props.getLocations();

        ////debugger;
        if (this.props.therapistInfo.id > 0) {
            var person = this.maskStr(therapist.person_number);
            therapist.person_number = person;
        }
        this.props.initialize({
            ...therapist
        });
    }
    componentDidUpdate(prevProps) {
        if (!isEqual(prevProps.loading, this.props.loading)) {
            console.log("componentDidUpdate: " + this.props.message);
            if (this.props.success && !isEmpty(this.state.treatment_ids)) {
                this.props.history.push('/therapist');
            }
            else if (this.props.success && this.props.message === "therapist_add_success_message") {
                this.props.history.push('/therapist');
            }
        }
    }
    compressImage = (file) => {
        var files = [];
        files.push(file)
        compress.compress(files, {
            size: 4, // the max size in MB, defaults to 2MB
            quality: .75, // the quality of the image, max is 1,
            maxWidth: 1920, // the max width of the output image, defaults to 1920px
            maxHeight: 1920, // the max height of the output image, defaults to 1920px
            resize: true, // defaults to true, set false if you do not want to resize the image width and height
        }).then((data) => {
            this.setState({ file: data[0] })
            console.log(data)
            // returns an array of compressed images
        })
    }

    handleSubmit = (values, dispatch) => {
        var { id, person_number, first_name, last_name, email, zip, city, description, street_address, post_address } = values; //profileImage
        // var pp = { profileImage, size: 1000 }
        console.log("PN1: " + person_number);

        var { therapistInfo } = this.props;
        if (therapistInfo.person_number != undefined) {
            person_number = therapistInfo.person_number
        }
        console.log("PN2: " + person_number);

        var treatment_ids = [];
        therapistInfo.treatments != null && therapistInfo.treatments.forEach(treatment => {
            treatment_ids.push(treatment.value);
        });
        this.setState({ selected_treatment_ids: treatment_ids })
        var { mobile_phone, selected_treatment_ids, selected_location_ids, file } = { ...this.state };
        //this.compressImage(file);

        var mno = therapistInfo.locations;
        if (selected_location_ids.length === 0) {
            var treatment_ids_ = [];
            mno != null && mno.forEach(treatment => {
                treatment_ids_.push(treatment.value);
            });
            selected_location_ids = treatment_ids_;
        }


        console.log("PN3: " + JSON.stringify(personnummer));
        var validPersonNumber = personnummer.format(person_number, 'YYYYMMDDNNNN');
        console.log("Valid: " + validPersonNumber);
        var profile_image = "";
        if (!isEmpty(selected_treatment_ids)) {
            if (file !== null && file != "image_removed") {
                var files = [];
                files.push(file);
                compress.compress(files, {
                    size: 4, // the max size in MB, defaults to 2MB
                    quality: .75, // the quality of the image, max is 1,
                    maxWidth: 1920, // the max width of the output image, defaults to 1920px
                    maxHeight: 1920, // the max height of the output image, defaults to 1920px
                    resize: true, // defaults to true, set false if you do not want to resize the image width and height
                }).then((results) => {
                    // Example mimes:
                    // image/png, image/jpeg, image/jpg, image/gif, image/bmp, image/tiff, image/x-icon,  image/svg+xml, image/webp, image/xxx, image/png, image/jpeg, image/webp
                    // If mime is not provided, it will default to image/jpeg
                    const img1 = results[0]
                    const base64str = img1.data
                    const imgExt = img1.ext
                    file = Compress.convertBase64ToFile(base64str, imgExt);
                    file["name"] = '_' + Math.random().toString(36).substr(2, 9);;
                    // -> Blob {size: 457012, type: "image/png"}

                    RNS3.put(file, options)
                        .then(data => {

                            profile_image = data.body.postResponse.location;
                            if (id > 0) {
                                if (selected_treatment_ids === undefined || selected_treatment_ids[0] === undefined) {
                                    const data = { id, description, person_number, profile_image, first_name, last_name, email, city, zip, mobile_phone, treatment_ids: treatment_ids, location_ids: selected_location_ids };
                                    dispatch(updateTherapist(data));
                                }
                                else {
                                    const data = { id, description, person_number, profile_image, first_name, last_name, email, city, zip, mobile_phone, treatment_ids: selected_treatment_ids, location_ids: selected_location_ids };
                                    dispatch(updateTherapist(data));
                                }
                            }
                            else {
                                if (!isEmpty(validPersonNumber)) {
                                    const data = {
                                        person_number: validPersonNumber, description, profile_image, first_name, last_name, email, city, zip, mobile_phone, treatment_ids: selected_treatment_ids, location_ids: selected_location_ids
                                    };
                                    dispatch(addTherapist(data));
                                }
                                else {
                                    console.log('Error1');
                                    this.setState({
                                        showError: true,
                                        showErrorMesage: "bad_day_person_number"
                                    })
                                }
                            }
                        })
                        .catch(err => console.error(err));
                })

            }
            else {

                if (id > 0) {

                    const img = file == "image_removed" ? file : this.props.therapistInfo.profile_image;
                    if (selected_treatment_ids === undefined || selected_treatment_ids[0] === undefined) {
                        const data = { id, description, person_number, profile_image: img, first_name, last_name, email, city, zip, mobile_phone, treatment_ids: treatment_ids, street_address, post_address, location_ids: selected_location_ids };
                        dispatch(updateTherapist(data));
                    }
                    else {
                        const data = { id, description, person_number, profile_image: img, first_name, last_name, email, city, zip, mobile_phone, treatment_ids: selected_treatment_ids, street_address, post_address, location_ids: selected_location_ids };
                        dispatch(updateTherapist(data));
                    }

                }
                else {
                    if (!isEmpty(validPersonNumber)) {
                        const data = {
                            person_number: validPersonNumber,
                            description,
                            profile_image: this.props.therapistInfo.profile_image,
                            first_name, last_name, email, city, zip, mobile_phone, street_address, post_address,
                            treatment_ids: selected_treatment_ids
                        };
                        dispatch(addTherapist(data));
                    }
                    else {
                        console.log('Error2');
                        this.setState({
                            showError: true,
                            showErrorMesage: "bad_day_person_number"

                        })
                    }
                }
            }


        }
    }

    getTreatment = (treatments) => {
        var treatment_ids = [];
        treatments != null && treatments.forEach(treatment => {
            treatment_ids.push(treatment.value);
        });
        this.setState({ selected_treatment_ids: treatment_ids })
        if (treatments === null) {
            this.setState({ is_treatment_empty: true });
            this.without_treatment = true;
            // this.props.onChange('therapistInfo.treatments',[]);
        }
    }

    getLocation = (treatments) => {
        var treatment_ids = [];
        treatments != null && treatments.forEach(treatment => {
            treatment_ids.push(treatment.value);
        });
        this.setState({ selected_location_ids: treatment_ids })
        if (treatments === null) {
            this.setState({ is_location_empty: true });
            this.without_location = true;
            // this.props.onChange('therapistInfo.treatments',[]);
        }
    }

    imagePicked = ({ file }) => {
        var max_size = 5 * 1000000;
        if (file) {
            if (file.size > max_size) {
                this.setState({ fileSizeErrorMessage: 'please upload file less then 5 MB' })
            }
            else {
                this.setState({
                    file,
                    fileSizeErrorMessage: ''
                });
            }
        }
        else {
            this.setState({
                file: "image_removed",
                fileSizeErrorMessage: ''
            });
        }
    }

    maskStr(cardnumber) {
        var first4 = cardnumber.substring(0, 8);
        var mask = cardnumber.substring(cardnumber.length - 4, 4).replace(/\d/g, "*");
        //first4 +
        return first4 + mask;
    }


    render = () => {
        this.data = this.state.companiesList;
        const { t } = this.state;
        var treatmentList = [];
        var locList = [];
        var allLocList = [];
        var selectedLocs = [];
        const { treatments, therapistInfo, success, message, loading, locations, AllLocations } = this.props;

        //// debugger;
        console.log("render: therapistInfo : " + JSON.stringify(therapistInfo));
        console.log("render: locations : " + locations.length);
        console.log("render: AllLocations : " + AllLocations.length);

        treatments.forEach(treatment => {
            treatmentList.push({ value: treatment.id, label: treatment.name });
        });

        locations.forEach(treatment => {
            locList.push({ label: treatment.name, value: treatment.id });
            selectedLocs.push(treatment.id);
        });

        // this.setState({ selected_location_ids: selectedLocs });

        AllLocations.forEach(treatment => {
            if (treatment.location_type !== "1") {
                allLocList.push({ value: treatment.id, label: treatment.name });
            }
        });

        console.log("render: therapistInfo -> treatments : " + JSON.stringify(therapistInfo.treatments));
        console.log("render: therapistInfo -> treatments : " + JSON.stringify(locList));


        console.log("render: AllLocations (without private) : " + allLocList.length);

        return (
            <Container fluid className="personal-container user-container">
                <Header t={this.props.t} title={t != null && t.translations.staff} props={this.props} />
                <Col className="col-md-12 mt-5 data-table-container">
                    <Form onSubmit={this.props.handleSubmit(this.handleSubmit)}>
                        <Alert success={success} message={message} t={this.props.t} loading={loading} />
                        <div className="row">
                            <div className="col-md-6 col-xs-12 float-right">
                                <Field
                                    label={t != null ? t.translations.person_number : ''}
                                    type="text"
                                    name="person_number"
                                    classes="Field-input "
                                    validate={[required]}
                                    disableField={this.props.therapistInfo.id > 0 ? true : false}
                                    component={FormField}
                                    t={this.props.t}
                                />
                                <br />
                                {this.state.showError && <p className="person-number-validation">{this.props.t.translations[this.state.showErrorMesage]}</p>}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6 col-xs-12 float-right">
                                <Field
                                    label={t != null ? t.translations.first_name : ''}
                                    type="text"
                                    name="first_name"
                                    classes="Field-input "
                                    validate={[required]}
                                    component={FormField}
                                    t={this.props.t}
                                />
                            </div>
                            <div className="col-md-6 col-xs-12 float-left">
                                <Field
                                    label={t != null ? t.translations.last_name : ''}
                                    type="text"
                                    name="last_name"
                                    classes="Field-input"
                                    validate={[required]}
                                    component={FormField}
                                    t={this.props.t}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6 col-xs-12 float-right">
                                <Field
                                    label={t != null ? t.translations.email : ''}
                                    type="email"
                                    name="email"
                                    classes="Field-input"
                                    validate={[validEmail]}
                                    component={FormField}
                                    t={this.props.t}
                                />
                            </div>
                            <div className="col-md-6 col-xs-12 float-left therapist react-phone-number">
                                <label>{t != null ? t.translations.mobile : ''}</label>
                                <PhoneInput
                                    placeholder={t != null ? t.translations.mobile : ''}
                                    value={!isEmpty(this.props.therapistInfo) ? this.props.therapistInfo.mobile_phone : ''}
                                    className="Field-input"
                                    length={12}
                                    onChange={mobile_phone => this.setState({ mobile_phone })} />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6 col-xs-12 float-left">
                                <Field
                                    label={t != null ? t.translations.street_address : ''}
                                    type="text"
                                    name="street_address"
                                    classes="Field-input"
                                    component={FormField}
                                    t={this.props.t}
                                />
                            </div>
                            <div className="col-md-6 col-xs-12 float-left">
                                <Field
                                    label={t != null ? t.translations.city : ''}
                                    type="text"
                                    name="city"
                                    classes="Field-input"
                                    component={FormField}
                                    t={this.props.t}
                                />
                            </div>
                            {/* <div className="col-md-6 col-xs-12 float-right">
                                <Field
                                    label={t != null ? t.translations.post_address : ''}
                                    type="text"
                                    name="post_address"
                                    classes="Field-input"
                                    component={FormField}
                                    t={this.props.t}
                                />
                            </div> */}
                        </div>
                        <div className="row">
                            <div className="col-md-6 col-xs-12 float-right">
                                <Field
                                    label={t != null ? t.translations.postal_code : ''}
                                    type="number"
                                    name="zip"
                                    classes="Field-input"
                                    maxLength={5}
                                    component={FormField}
                                    t={this.props.t}
                                />
                            </div>
                            <div className="col-md-6 col-xs-12 float-right">
                                <Field
                                    label={t != null ? t.translations.description : ''}
                                    type="text"
                                    name="description"
                                    classes="Field-input"
                                    component={FormField}
                                    t={this.props.t}
                                />
                            </div>
                        </div>

                        <div className="row">
                        </div>
                        <div className="row">
                            <div className="col-md-6 col-xs-12 float-left">
                                <Field
                                    label={t != null ? t.translations.select_location : ''}
                                    type="multiSelect"
                                    ismulti
                                    onChange={this.getLocation}
                                    name="location_id"
                                    classes="Field-input-loc"
                                    options={allLocList}
                                    component={FormField}
                                    t={this.props.t}
                                    // defaultValue={locList}
                                    defaultValue={(!isEmpty(therapistInfo) && !this.state.is_location_empty) ? therapistInfo.locations : []}
                                    placeholder={t != null ? t.translations.select_location : ''}
                                />
                            </div>
                            <div className="col-md-6 col-xs-12 float-left">
                                <Field
                                    label={t != null ? t.translations.select_treatment : ''}
                                    type="multiSelect"
                                    ismulti
                                    onChange={this.getTreatment}
                                    name="treatment_id"
                                    classes="Field-input-loc"
                                    options={treatmentList}
                                    component={FormField}
                                    t={this.props.t}
                                    defaultValue={(!isEmpty(therapistInfo) && !this.state.is_treatment_empty) ? therapistInfo.treatments : []}
                                    placeholder={t != null ? t.translations.select_treatment : ''}
                                />
                            </div>
                        </div>
                        <div className="row">

                            <div className="col-md-6 col-xs-12 float-right user-profile-image">
                                <Field
                                    label={t != null ? t.translations.select_image : ''}
                                    type="file"
                                    name="profileImage"
                                    className=""
                                    t={this.props.t}
                                    options={{
                                        height: 112,
                                        width: 112,
                                        initValue: therapistInfo.profile_image ? therapistInfo.profile_image : defaultImage,
                                        imagePicked: this.imagePicked
                                    }}
                                    component={FormField}
                                />
                                {this.state.fileSizeErrorMessage !== '' && <span className="image-error-message">{this.state.fileSizeErrorMessage}</span>}
                            </div>

                        </div>
                        <div className="col-md-6 col-xs-12 float-right">
                            <Button className="btn-block float-right save-btn Field-input" type="submit">{t != null && t.translations.save_changes} {this.props.loading && <i className="fas fa-circle-notch fa-spin loader"></i>}</Button>
                        </div>
                    </Form>
                </Col>
            </Container >
        );
    }
};

const mapStateToProps = state => {
    return {
        success: state.therapistRedux.success,
        loading: state.therapistRedux.loading,
        message: state.therapistRedux.message,
        therapistInfo: state.therapistRedux.therapistInfo,
        t: state.accountRedux.localeInfo.t,
        treatments: state.treatmentRedux.treatments,
        locations: state.locationRedux.locations,
        AllLocations: state.locationRedux.AllLocations
    };
};
const mapDispatchToProps = dispatch => {
    return {
        getALLLocationsForTherapist: (data) => dispatch(getALLLocationsForTherapist(data)),
        getLocations: (data) => dispatch(getLocations(data)),
        getCompanies: () => dispatch(getCompanies()),
        getTreatments: () => dispatch(getTreatments()),
    }
};
export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({
    form: 'therapistForm',
    destroyOnUnmount: false
})(AddTherapist));
