import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Container, Row, Col, Button } from 'shards-react';
import MaterialTable from '../common/materialTable';
import { getBookings, showBookingDetail, deleteBooking, getBookingPriceInformation, editBooking, getRefundedBookings } from '../../redux/booking.redux';
import { changeTherapist, getAvailableTherapist, refundBooking, sendReceipt } from '../../redux/user.redux';

import * as moment from 'moment';
import ConfirmationDialog from '../common/ConfirmationDialog';
import Alert from '../common/Alert';
import { FormField } from '../../helper/formFields';
import { required } from '../../helper/validate';
import { Field, reduxForm } from 'redux-form';
import { SegmentedControl } from 'segmented-control'
import { AUTHORIZED, CANCELED, INCOMPLETE, COMPLETED, REFUNDED, PENDING, PAYMENT_INITIATED, BOOKED_PAID } from '../../constants/urls';

var reload_ = false;

class Booking extends Component {
    constructor(props) {
        super(props);
        this.state = {
            t: null,
            available_therapists: [],
            show_available_therapists: false,
            show_available_therapists_error: false,
            show_receipt_error: false,
            show_receipt_dialog: false,
            show_receipt_success: false,
            receipt_data: "",
            available_therapists_error: "",
            filterState: "today",
            change_cust_email: "",
            showDialogue: false,
            filtered_bookings: [],
            deleteItem: {},
            showDeleteBookingDialogue: false,
            showRefundDialogue: false,
            bEditNotes: false,
            sNotes: this.props.bookingInfo.notes
        }
    }
    componentDidMount() {
        this.setState({ t: this.props.t });
        var user_id = this.props.user.id;
        var role_type = this.props.user.role_type;


        if (role_type == 3) {
            var data = { "therapist_id": user_id }
            this.props.getBookings(data);
        }
        else {
            this.props.getBookings();
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props !== prevProps) {
            //debugger;

            if (this.props.bookings !== null && this.props.bookings != undefined) {
                console.log("NEW PROPS " + JSON.stringify(this.props.refundedBookings))

                if (this.props.user.role_type == 3) {
                    var user_id = this.props.user.id;
                    let recvd_bookings = this.props.bookings;
                    // // //////////debugger;
                    console.log('MY THERAPIST ID: ' + user_id);
                    let filtered_ = recvd_bookings.filter(el => {
                        // var status = (el.status === 'Pending');
                        // // status = true;
                        console.log('Filtered: ' + el.therapist_id + " = " + user_id);

                        return (el.therapist_id === user_id)
                    });
                    this.setState({ filtered_bookings: filtered_ });
                }
                else {
                    let recvd_bookings = this.props.bookings;
                    this.setState({ filtered_bookings: recvd_bookings });
                    if (!(this.state.sNotes && this.state.sNotes != this.props.bookingInfo.notes))
                        this.state.sNotes = this.props.bookingInfo.notes

                }
                this.setState({ sNotes: this.props.bookingInfo.notes })
            }

            if (this.props.avail_therapists != null && this.props.avail_therapists != undefined && this.props.avail_therapists !== "") {
                // debugger;
                if (this.props.avail_therapists.allocated_therapist != undefined &&
                    this.props.avail_therapists.allocated_therapist != null &&
                    this.props.avail_therapists.allocated_therapist !== "") {

                    if (this.props.avail_therapists.allocated_therapist.length == 0) {
                        this.setState({ show_available_therapists_error: true });
                        this.setState({ available_therapists_error: "No therapist available right now." });
                    }
                    else {
                        this.setState({ show_available_therapists: true });
                        this.setState({ available_therapists: this.props.avail_therapists.allocated_therapist });
                    }

                }


            }

            if (this.props.send_receipt != null && this.props.send_receipt != undefined && this.props.send_receipt !== "") {
                if (this.state.receipt_data != "" && this.state.receipt_data != undefined) {
                    this.setState({
                        show_receipt_error: false,
                        receipt_data: ""
                    });
                    if (this.props.send_receipt === "sent") {
                        this.setState({ show_receipt_success: true });
                    }
                    else {
                        this.setState({ show_receipt_success: false });
                    }

                }
            }

            if (this.props.update_therapist != null && this.props.update_therapist != undefined && this.props.update_therapist !== "") {
                if (reload_) {
                    reload_ = false;
                    this.props.getBookings();
                }
            }

            if (this.props.refund_data != null && this.props.refund_data != undefined && this.props.refund_data !== "") {
                if (reload_) {
                    reload_ = false;
                    this.props.getBookings();
                }
            }
        }
    }

    showDetail = (event) => {
        this.props.showBookingDetail(event);

        this.setState({ show_available_therapists: false });
        this.setState({ showDialogue: true });
    }

    addNewBooking = () => {
        // this.props.history.push('/addbooking');
        this.props.history.push('/addbooking', { action: "add", data: null, t: this.state.t })
    }

    addOtherBooking = () => {
        this.props.history.push('/addotherbooking', { action: "add", data: null, t: this.state.t })
    }
    downloadSwishPayment = () => {
        this.props.getRefundedBookings();
    }

    _renderDialog = (event) => {
        this.setState({ showDialogue: false });
        this.setState({ showDeleteBookingDialogue: true, deleteItem: event })
    }

    sendReceipt = (event) => {
        const { change_booking_id } = this.state;
        const data = {
            "booking_id": change_booking_id
        }
        // debugger;
        this.props.sendReceipt(data);
        this.setState({ show_receipt_dialog: false });
    }

    deleteBooking = (event) => {
        const { deleteItem } = this.state;
        const data = { booking_id: deleteItem.id }
        this.props.deleteBooking(data);
        this.setState({ showDeleteBookingDialogue: false });
        setTimeout(() => {
            this.props.getBookings();
        }, 2000);
    }

    handleSubmit = (values, dispatch) => {
        const { booking_id } = values;
        const data = {
            booking_id
        }
        this.props.getBookingPriceInformation(data);
    }

    minTwoDigits(n) {
        return (n < 10 ? '0' : '') + n;
    }

    segmentControlTapped(val) {
        console.log('segmentControlTapped: ' + val);
        this.setState({ schedule_view: val });
        this.setState({ filterState: val });

        // if (val === 'set_schedule'){
        //     this._openModal();
        // }

    }

    mapStatusStr(status) {

        var temp = "";
        if (status === "Auth" || status == AUTHORIZED) {
            temp = "Booked";
        }
        else if (status === "Cancelled" || status == CANCELED) {
            temp = "Cancelled";
        }
        else if (status === "Completed" || status == COMPLETED) {
            temp = "Confirmed";
        }
        else if (status === "Incompleted" || status == INCOMPLETE) {
            temp = "Failed";
        }
        else if (status === "Refunded" || status == REFUNDED) {
            temp = "Refunded";
        }
        else temp = status

        return temp;
    }

    filterBookings(val) {
        // const { bookings } = this.props;
        var bookings = this.state.filtered_bookings;
        var date_ = "";

        ////debugger;

        if (bookings.length > 0) {
            if (val === "past") {

                date_ = moment().format("YYYY-MM-DD");
                let resultant = bookings.filter(item => {
                    var formatted_ = moment(new Date(0).setUTCSeconds(item.date)).format('YYYY-MM-DD');
                    return formatted_ < date_ && (item.status !== "PENDING" && item.status !== "PAYMENT_INITIATED" && item.status !== "EXPIRED" && item.status !== "DISCARD" && item.status !== "REFUNDED" && item.status !== "SWISH_REFUND" && item.status !== "Cancelled" && item.status !== CANCELED);
                })


                // date and time sorting
                resultant.sort((a, b) => {
                    var c = moment(new Date(0).setUTCSeconds(a.date)).format('YYYY-MM-DD');
                    var d = moment(new Date(0).setUTCSeconds(b.date)).format('YYYY-MM-DD');

                    var cT = moment(new Date(0).setUTCSeconds(a.date)).format('HH:MM');
                    var dT = moment(new Date(0).setUTCSeconds(b.date)).format('HH:MM');

                    return c.localeCompare(d) || cT.localeCompare(dT);
                });

                //reverse arr
                let rev = resultant.reverse();

                return rev;

            }
            else if (val === "today") {
                date_ = moment().format("YYYY-MM-DD");
                let resultant = bookings.filter(item => {
                    // var formatted_ = moment.utc(item.date).format("YYYY-MM-DD");
                    var formatted_ = moment(new Date(0).setUTCSeconds(item.date)).format('YYYY-MM-DD');
                    return formatted_ === date_ && (item.status !== "PENDING" && item.status !== "PAYMENT_INITIATED" && item.status !== "EXPIRED" && item.status !== "DISCARD" && item.status !== "REFUNDED" && item.status !== "SWISH_REFUND" && item.status !== "Cancelled" && item.status !== CANCELED);
                })


                // date and time sorting
                resultant.sort((a, b) => {
                    var c = moment(new Date(0).setUTCSeconds(a.date)).format('YYYY-MM-DD');
                    var d = moment(new Date(0).setUTCSeconds(b.date)).format('YYYY-MM-DD');

                    var cT = moment(new Date(0).setUTCSeconds(a.date)).format('HH:MM');
                    var dT = moment(new Date(0).setUTCSeconds(b.date)).format('HH:MM');

                    return c.localeCompare(d) || cT.localeCompare(dT);
                });

                //reverse arr
                let rev = resultant.reverse();

                return rev;
            }
            else if (val === "tomorrow") {
                date_ = moment().add(1, "days").format("YYYY-MM-DD");
                let resultant = bookings.filter(item => {
                    // var formatted_ = moment.utc(item.date).format("YYYY-MM-DD");
                    var formatted_ = moment(new Date(0).setUTCSeconds(item.date)).format('YYYY-MM-DD');
                    return formatted_ === date_ && (item.status !== "PENDING" && item.status !== "PAYMENT_INITIATED" && item.status !== "EXPIRED" && item.status !== "DISCARD" && item.status !== "REFUNDED" && item.status !== "SWISH_REFUND" && item.status !== "Cancelled" && item.status !== CANCELED);
                })


                // date and time sorting
                resultant.sort((a, b) => {
                    var c = moment(new Date(0).setUTCSeconds(a.date)).format('YYYY-MM-DD');
                    var d = moment(new Date(0).setUTCSeconds(b.date)).format('YYYY-MM-DD');

                    var cT = moment(new Date(0).setUTCSeconds(a.date)).format('HH:MM');
                    var dT = moment(new Date(0).setUTCSeconds(b.date)).format('HH:MM');

                    return c.localeCompare(d) || cT.localeCompare(dT);
                });

                //reverse arr
                let rev = resultant.reverse();

                return rev;
            }
            else if (val === "week") {
                const today = moment();
                const from_date = today.startOf('week').format("YYYY-MM-DD");
                const to_date = today.endOf('week').format("YYYY-MM-DD");

                ////debugger;
                let resultant = bookings.filter(item => {
                    // var formatted_ = moment.utc(item.date).format("YYYY-MM-DD");
                    var formatted_ = moment(new Date(0).setUTCSeconds(item.date)).format('YYYY-MM-DD');
                    return (formatted_ >= from_date && formatted_ <= to_date) && (item.status !== "PENDING" && item.status !== "PAYMENT_INITIATED" && item.status !== "EXPIRED" && item.status !== "DISCARD" && item.status !== "REFUNDED" && item.status !== "SWISH_REFUND" && item.status !== "Cancelled" && item.status !== CANCELED);
                });

                // date and time sorting
                resultant.sort((a, b) => {
                    var c = moment(new Date(0).setUTCSeconds(a.date)).format('YYYY-MM-DD');
                    var d = moment(new Date(0).setUTCSeconds(b.date)).format('YYYY-MM-DD');

                    var cT = moment(new Date(0).setUTCSeconds(a.date)).format('HH:MM');
                    var dT = moment(new Date(0).setUTCSeconds(b.date)).format('HH:MM');

                    return c.localeCompare(d) || cT.localeCompare(dT);
                });

                //reverse arr
                let rev = resultant.reverse();

                return rev;
            }
            else if (val === "month") {
                const today = moment(new Date());
                const from_date = today.startOf('month').format("YYYY-MM-DD");
                const to_date = today.endOf('month').format("YYYY-MM-DD");

                let resultant = bookings.filter(item => {
                    // var formatted_ = moment.utc(item.date).format("YYYY-MM-DD");
                    var formatted_ = moment(new Date(0).setUTCSeconds(item.date)).format('YYYY-MM-DD');
                    return (formatted_ >= from_date && formatted_ <= to_date) && (item.status !== "PENDING" && item.status !== "PAYMENT_INITIATED" && item.status !== "EXPIRED" && item.status !== "DISCARD" && item.status !== "REFUNDED" && item.status !== "SWISH_REFUND" && item.status !== "Cancelled" && item.status !== CANCELED);
                });

                // date and time sorting
                resultant.sort((a, b) => {
                    var c = moment(new Date(0).setUTCSeconds(a.date)).format('YYYY-MM-DD');
                    var d = moment(new Date(0).setUTCSeconds(b.date)).format('YYYY-MM-DD');

                    var cT = moment(new Date(0).setUTCSeconds(a.date)).format('HH:MM');
                    var dT = moment(new Date(0).setUTCSeconds(b.date)).format('HH:MM');

                    return c.localeCompare(d) || cT.localeCompare(dT);
                });

                //reverse arr
                let rev = resultant.reverse();

                return rev;
            }
            else if (val === "future") {
                date_ = moment().add(1, "days").format("YYYY-MM-DD");


                let resultant = bookings.filter(item => {
                    // var formatted_ = moment.utc(item.date).format("YYYY-MM-DD");
                    var formatted_ = moment(new Date(0).setUTCSeconds(item.date)).format('YYYY-MM-DD');
                    return formatted_ > date_ && (item.status !== "PENDING" && item.status !== "PAYMENT_INITIATED" && item.status !== "EXPIRED" && item.status !== "DISCARD" && item.status !== "REFUNDED" && item.status !== "SWISH_REFUND" && item.status !== "CANCELLED" && item.status !== CANCELED);
                });


                // date and time sorting
                resultant.sort((a, b) => {
                    var c = moment(new Date(0).setUTCSeconds(a.date)).format('YYYY-MM-DD');
                    var d = moment(new Date(0).setUTCSeconds(b.date)).format('YYYY-MM-DD');

                    var cT = moment(new Date(0).setUTCSeconds(a.date)).format('HH:MM');
                    var dT = moment(new Date(0).setUTCSeconds(b.date)).format('HH:MM');

                    return d.localeCompare(c) || dT.localeCompare(cT);
                });

                //reverse arr
                let rev = resultant.reverse();

                return rev;
            }
            else if (val === "preliminary") {
                let resultant = bookings.filter(item => {

                    return item.preliminary == true && (item.status === "PENDING" || item.status == PAYMENT_INITIATED);
                });


                // date and time sorting
                resultant.sort((a, b) => {
                    var c = moment(new Date(0).setUTCSeconds(a.date)).format('YYYY-MM-DD');
                    var d = moment(new Date(0).setUTCSeconds(b.date)).format('YYYY-MM-DD');

                    var cT = moment(new Date(0).setUTCSeconds(a.date)).format('HH:MM');
                    var dT = moment(new Date(0).setUTCSeconds(b.date)).format('HH:MM');

                    return d.localeCompare(c) || dT.localeCompare(cT);
                });

                //reverse arr
                let rev = resultant.reverse();

                return rev;
            }
            else {
                let resultant = bookings.filter(item => {

                    return item.status === "PENDING" || item.status == PAYMENT_INITIATED || item.status == "EXPIRED" || item.status == "DISCARD" || item.status == "REFUNDED" || item.status == "SWISH_REFUND" || item.status == "Cancelled" || item.status == CANCELED
                });


                // date and time sorting
                resultant.sort((a, b) => {
                    var c = moment(new Date(0).setUTCSeconds(a.date)).format('YYYY-MM-DD');
                    var d = moment(new Date(0).setUTCSeconds(b.date)).format('YYYY-MM-DD');

                    var cT = moment(new Date(0).setUTCSeconds(a.date)).format('HH:MM');
                    var dT = moment(new Date(0).setUTCSeconds(b.date)).format('HH:MM');

                    return d.localeCompare(c) || dT.localeCompare(cT);
                });

                //reverse arr
                let rev = resultant.reverse();

                return rev;
            }

        }
        return bookings;
    }

    generateColumns(t) {
        var bookingColumns = null;
        if (t != null) {
            if (this.state.filterState === "preliminary") {
                bookingColumns = [
                    {
                        title: t.translations.booking_id,
                        field: 'raw'
                        // render: rowData => <span>{Buffer.from(rowData.id, 'base64').toString('ascii')}</span>
                    },
                    {
                        title: t.translations.customer_name,
                        field: 'customer.first_name',
                        render: rowData => <span>{rowData.customer.first_name + " " + rowData.customer.last_name}</span>
                    },

                    {
                        title: t.translations.location,
                        field: 'location.name',
                        render: rowData => <span>{rowData.location.name}</span>

                    },
                    {
                        title: t.translations.treatment_category,
                        field: 'category'
                    },
                    {
                        title: t.translations.confirmBooking_treatment_app,
                        field: 'treatment_name'
                    },
                    {
                        title: t.translations.staff,
                        field: 'therapist_name'
                    },
                    {
                        title: t.translations.select_slot,
                        field: 'slot_time',
                    },
                    // {
                    //     title: t.translations.companyBooking_price_app,
                    //     field: 'price',
                    //     render: rowData => <span>{rowData.price.toFixed(2) + " SEK"}</span>
                    // },

                    {
                        title: t.translations.schedule_date,
                        field: 'backend_scheduled_date',
                        render: rowData => {
                            rowData.backend_scheduled_date = moment(new Date(0).setUTCSeconds(rowData.date)).format('YYYY-MM-DD') + " " + this.minTwoDigits(Math.trunc(rowData.reserved_slot / 100)) + ":" + this.minTwoDigits(rowData.reserved_slot % 100);
                            return (<span>{rowData.backend_scheduled_date}</span>)
                        }
                        // render: rowData => <span>{rowData.reserved_slot >= 2200 ? rowData.reserved_date : moment(new Date(0).setUTCSeconds(rowData.date)).format('YYYY-MM-DD') + " " + this.minTwoDigits(Math.trunc(rowData.reserved_slot / 100)) + ":" + this.minTwoDigits(rowData.reserved_slot % 100)}</span>
                        // render: rowData => <span>{"Test1:" + moment(new Date(0).setUTCSeconds(rowData.date - 7200)).format('YYYY-MM-DD') + " " + this.minTwoDigits(Math.trunc(rowData.reserved_slot/100)) + ":" + this.minTwoDigits(rowData.reserved_slot%100)}</span>
                    },
                ];
            }
            else {
                bookingColumns = [
                    {
                        title: t.translations.booking_id,
                        field: 'raw'
                        // render: rowData => <span>{Buffer.from(rowData.id, 'base64').toString('ascii')}</span>
                    },
                    {
                        title: t.translations.customer_name,
                        field: 'customer.first_name',
                        render: rowData => <span>{rowData.customer.first_name + " " + rowData.customer.last_name}</span>
                    },
                    {
                        title: t.translations.location,
                        field: 'location.name',
                        render: rowData => <span>{rowData.location.name}</span>

                    },
                    {
                        title: t.translations.payment_method,
                        field: 'payment_method',
                        render: rowData => {
                            return (
                                <span>{rowData.payment_method}</span>
                            )
                        }
                    },
                    {
                        title: t.translations.treatment_category,
                        field: 'category'
                    },
                    {
                        title: t.translations.confirmBooking_treatment_app,
                        field: 'treatment_name'
                    },
                    {
                        title: t.translations.staff,
                        field: 'therapist_name'
                    },
                    {
                        title: t.translations.select_slot,
                        field: 'slot_time',
                    },
                    {
                        title: t.translations.companyBooking_price_app,
                        field: 'price',
                        render: rowData => <span>{rowData.price.toFixed(2) + " SEK"}</span>
                    },
                    {
                        title: t.translations.schedule_date,
                        field: 'backend_scheduled_date',
                        render: rowData => {
                            // if (rowData.raw == 2957)
                            //     debugger
                            const formattedDate = moment(new Date(0).setUTCSeconds(rowData.date)).format('YYYY-MM-DD') + " " + this.minTwoDigits(Math.trunc(rowData.reserved_slot / 100)) + ":" + this.minTwoDigits(rowData.reserved_slot % 100);
                            //shown date is different from real date causes problem in search & filter, adding new entity to datasource to match search
                            rowData.backend_scheduled_date = rowData.reserved_slot >= 2200 ? (rowData.reserved_date ? rowData.reserved_date : formattedDate) : formattedDate
                            return (<span>{rowData.backend_scheduled_date}</span>)
                        }

                    },

                    {
                        title: t.translations.status,
                        field: 'backend_status',
                        render: rowData => {
                            //shown status is different from real date causes problem in search & filter, adding new entity to datasource to match search
                            rowData.backend_status = this.mapStatusStr(rowData.status)
                            return (
                                <span>{rowData.backend_status}</span>
                            )
                        }
                    }
                    // ,
                    // {
                    //     title: t.translations.authorization_id,
                    //     field: 'authorization_id'
                    // }
                ];
            }

        }

        return bookingColumns;
    }
    capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    changeTherapistMethod(event) {
        // = (event) =>
        console.log("changeTherapistMethod: " + JSON.stringify(event));
        this.setState({ showDialogue: false });
        this.setState({ change_booking_id: event.raw });

        ////debugger;
        var data = {
            "user_id": event.customer.id,
            "treatment_type": event.category,
            "treatment_id": event.treatment_id,
            "location_id": event.location.id,
            "slot_id": event.slot_id,
            "reserved_slot": event.reserved_slot,
            "schedule_date": event.date,
        }
        this.props.getAvailableTherapist(data);
    }

    updatePreliminaryBooking(event) {
        // = (event) =>
        console.log("updatePreliminaryBooking: " + JSON.stringify(event));
        this.setState({ showDialogue: false });
        this.props.history.push('/editbooking', { action: "edit", data: event, t: this.state.t })
    }

    deletePreliminaryBooking(event) {
        // = (event) =>
        console.log("deletePreliminaryBooking: " + JSON.stringify(event));
        this.setState({ showDialogue: false });

    }

    sendReceiptMethod(event) {
        console.log("sendReceiptMethod: " + JSON.stringify(event));
        this.setState({ receipt_data: event.raw })
        this.setState({ showDialogue: false });
        this.setState({ change_booking_id: event.raw });
        this.setState({ change_cust_email: event.customer.email });

        this.setState({ show_receipt_dialog: true });

        ////debugger;
        // var data = {
        //     "booking_id" : event.raw,
        // }
        // var data = {
        //     booking_id: event.raw
        // }
        // this.props.sendReceipt(data);
    }

    showPayments(event) {
        console.log("showPayments: " + JSON.stringify(event));
        this.props.history.push('/transaction', { booking_info: event });
        // this.setState({ showDialogue: false });
        // this.setState({ change_booking_id: event.raw });
        // this.setState({ change_cust_email: event.customer.email });

        // this.setState({ show_receipt_dialog: true });

        ////debugger;
        // var data = {
        //     "booking_id" : event.raw,
        // }
        // var data = {
        //     booking_id: event.raw
        // }
        // this.props.sendReceipt(data);
    }

    updateTherapistMethod(event) {
        console.log('updateTherapistMethod: ' + JSON.stringify(event));
        this.setState({ show_available_therapists: false });

        ////debugger;
        var { selected_therapist } = this.state;
        if (selected_therapist != undefined && selected_therapist !== "") {
            var data = {
                "booking_id": this.state.change_booking_id,
                "new_therapist_id": selected_therapist
            }

            reload_ = true;

            console.log("Update Booking: " + JSON.stringify(data));
            this.props.changeTherapist(data);
        }
    }

    refundBookingMethod(event) {
        console.log("refundBookingMethod: " + JSON.stringify(event));
        this.setState({ refund_booking_id: event.raw });
        this.setState({ showDialogue: false });
        this.setState({ showRefundDialogue: true });
    }

    refundBooking = (event) => {
        console.log("refundBooking: ");
        this.setState({ showDialogue: false });
        this.setState({ showRefundDialogue: false });


        if (this.state.refund_booking_id != undefined && this.state.refund_booking_id != null && this.state.refund_booking_id !== "") {
            var data = {
                "booking_id": this.state.refund_booking_id
            }
            reload_ = true;
            this.props.refundBooking(data);
        }
    }

    selectNewTherapist = (event) => {
        this.setState({ selected_therapist: event });
    }

    cancelAvailableTherapistPopup(event) {
        this.setState({ show_available_therapists: false });
    }
    renderNotesIcon(role_type) {

        if (role_type != 3)

            return (<div
                onClick={() => this.setState({ bEditNotes: true })}
                style={{ height: 30, width: 50, }}>
                <img
                    alt={this.props.t ? this.props.t.edit : "Edit"}
                    style={{ height: 12, width: 12, marginLeft: 15 }} src={require('../../assets/edit.png')} />
            </div>
            )
    }

    render() {


        var filtered_therapists = this.state.available_therapists.map(person => ({ value: person.id, label: person.name }));

        const { t, loading, bookingInfo, success, message } = this.props;

        let rev_bookings = this.filterBookings(this.state.filterState);   //bookings.reverse();
        var bookingColumns = this.generateColumns(t);

        return (
            <Fragment>
                <Container fluid className="personal-container user-container scheduled-container">

                    {this.state.showDeleteBookingDialogue &&
                        <ConfirmationDialog
                            title={this.state.t != null ? this.state.t.translations.delete_confirmation : ''}
                            bodyText={this.state.t != null && this.state.t.translations.are_you_sure_to_delete}
                            handleCancel={() => this.setState({ showDeleteBookingDialogue: false })}
                            handleOk={this.deleteBooking} />}

                    {this.state.show_receipt_dialog && <ConfirmationDialog
                        bodyText={this.state.t != null ? this.state.t.translations.receipt_confirmation + " " + this.state.change_cust_email + " ?" : ''}
                        title={''}
                        handleCancel={() => this.setState({ show_receipt_dialog: false })}
                        handleOk={this.sendReceipt} />}
                    {this.state.showRefundDialogue &&
                        <ConfirmationDialog
                            bodyText={this.state.t != null && (bookingInfo.payment_status === 'captured' ? this.state.t.translations.are_you_sure_to_refund : this.state.t.translations.are_you_sure_to_cancel_booking)}
                            // bodyText={this.state.t != null && this.state.t.translations.are_you_sure_to_refund}
                            handleCancel={() => this.setState({ showRefundDialogue: false })}
                            handleOk={this.refundBooking} />}

                    {this.state.show_available_therapists_error &&
                        <ConfirmationDialog
                            title={""}
                            bodyText={this.state.available_therapists_error}
                            handleCancel={() => this.setState({ show_available_therapists_error: false })}
                            handleOk={null} />}

                    {this.state.show_receipt_success &&
                        <ConfirmationDialog
                            title={""}
                            hideCancelBtn
                            bodyText={this.state.t != null && this.state.t.translations.receipt_success_message}
                            handleOk={() => this.setState({ show_receipt_success: false })}
                            handleCancel={null} />}
                    {this.state.show_receipt_error &&
                        <ConfirmationDialog
                            title={""}
                            bodyText={this.state.available_therapists_error}
                            handleCancel={() => this.setState({ show_receipt_error: false })}
                            handleOk={null} />}

                    {this.state.showDialogue &&
                        <ConfirmationDialog
                            title={this.state.t != null ? this.state.t.translations.booking_details : ''}
                            bookingCancelBtn={true}
                            bodyText={
                                <Fragment>
                                    <h5>{t.translations.customer_name}</h5>
                                    <p>{bookingInfo.customer.first_name + " " + bookingInfo.customer.last_name}</p>
                                    <h5>{t.translations.treatment_category}</h5>
                                    <p>{bookingInfo.category}</p>
                                    <h5>{t.translations.confirmBooking_treatment_app}</h5>
                                    <p>{bookingInfo.treatment_name}</p>
                                    <h5>{t.translations.staff}</h5>
                                    <p>{bookingInfo.therapist_name}</p>
                                    <div style={{
                                        display: "flex",
                                        justifyContent: 'space-between',
                                        flexDirection: "row",
                                        marginBottom: this.state.sNotes ? 0 : 20,
                                        // backgroundColor: 'green'

                                    }}>
                                        <div style={{
                                            display: "flex",
                                            justifyContent: 'space-between',
                                            flexDirection: "column",
                                            // backgroundColor: 'yellow',
                                            width: '100%'


                                        }}>
                                            <h5>{t.translations.note_to_therapist}</h5>

                                            {
                                                this.state.bEditNotes ?
                                                    <div style={{ height: 50, display: 'flex', flexDirection: 'row', width: '100%' }}>
                                                        <input
                                                            style={{
                                                                // alignSelf: 'flex-end',
                                                                outline: 0,
                                                                marginTop: 5,

                                                                borderWidth: "0 0 2px",
                                                                // borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, borderBottomWidth: 2,
                                                                height: 30, borderColor: '#D3D3D3',
                                                                paddingInline: "10px 20px",
                                                                // borderRadius: 5,
                                                                width: '95%'
                                                            }}
                                                            type="text"
                                                            value={this.state.sNotes}
                                                            onChange={(event) => {

                                                                this.setState({ sNotes: event.target.value })
                                                            }}
                                                            onBlur={() => { this.setState({ bEditNotes: false }) }}

                                                        />
                                                        <button
                                                            style={{ backgroundColor: '#006FE6', width: 100, height: 40, borderColor: '#006FE6', marginLeft: 15 }}
                                                            onClick={() => {
                                                                this.setState({ bEditNotes: false })
                                                                this.props.editBooking({ booking_id: bookingInfo.id, notes: this.state.sNotes })

                                                                this.props.getBookings();
                                                            }}>
                                                            {t != null && t.translations.save}
                                                        </button>


                                                    </div>
                                                    :
                                                    this.state.sNotes &&
                                                    <p>{this.state.sNotes}</p>

                                            }
                                        </div>
                                        {!this.state.bEditNotes && this.renderNotesIcon(this.props.user.role_type)}
                                    </div>
                                    <h5>{t.translations.select_slot}</h5>
                                    <p>{bookingInfo.slot_time}</p>
                                    <h5>{t.translations.confirmBooking_location_app}</h5>
                                    <p>{bookingInfo.location != null && (bookingInfo.location.location_type === "1" ? bookingInfo.location.address : bookingInfo.location.name)}</p>
                                    <h5>{t.translations.schedule_date}</h5>
                                    <p>{moment(new Date(0).setUTCSeconds(bookingInfo.date)).format('YYYY-MM-DD') + " " + this.minTwoDigits(Math.trunc(bookingInfo.reserved_slot / 100)) + ":" + this.minTwoDigits(bookingInfo.reserved_slot % 100)}</p>
                                    <h5>{t.translations.companyBooking_price_app}</h5>
                                    <p>{bookingInfo.price === 0 ? "-" : bookingInfo.price.toFixed(2) + " SEK"}</p>
                                    <h5>{t.translations.transaction_id}</h5>
                                    <p>{bookingInfo.transaction_id === "" ? "-" : bookingInfo.transaction_id}</p>
                                    <h5>{t.translations.status}</h5>
                                    <p>{(bookingInfo.status == PENDING || bookingInfo.status == PAYMENT_INITIATED) ? bookingInfo.status : bookingInfo.status + " (" + bookingInfo.payment_status + ")"}</p>
                                    {(bookingInfo.status != REFUNDED && bookingInfo.status != CANCELED) &&
                                        <table>
                                            <tr>
                                                <div>
                                                    {(bookingInfo.status == AUTHORIZED || bookingInfo.status == COMPLETED || bookingInfo.status == BOOKED_PAID) &&
                                                        <td>
                                                            <Button
                                                                onClick={() => {
                                                                    this.refundBookingMethod(bookingInfo);
                                                                }}
                                                                className="btn-block float-right save-btn Field-input"
                                                                type="submit">
                                                                {t != null && ((bookingInfo.status == AUTHORIZED || bookingInfo.status == BOOKED_PAID) ? t.translations.cancel_booking : t.translations.refund)}
                                                            </Button>
                                                        </td>}
                                                    {(bookingInfo.status != INCOMPLETE && bookingInfo.status != CANCELED && bookingInfo.status != REFUNDED && (bookingInfo.status != PENDING && bookingInfo.preliminary == true)) &&
                                                        <td>
                                                            <Button
                                                                onClick={() => {
                                                                    this.changeTherapistMethod(bookingInfo);
                                                                }}
                                                                className="btn-block float-right save-btn Field-input"
                                                                type="submit">
                                                                {t != null && t.translations.change_therapist}
                                                            </Button>
                                                        </td>}
                                                    {(bookingInfo.status == PENDING && bookingInfo.preliminary == true) &&
                                                        <td>
                                                            <Button
                                                                onClick={() => {
                                                                    this.updatePreliminaryBooking(bookingInfo);
                                                                }}
                                                                className="btn-block float-right save-btn Field-input"
                                                                type="submit">
                                                                {t != null && t.translations.edit}
                                                            </Button>
                                                        </td>}
                                                    {(bookingInfo.status == PENDING && bookingInfo.preliminary == true) &&
                                                        <td>
                                                            <Button
                                                                onClick={() => {
                                                                    this._renderDialog(bookingInfo);
                                                                }}
                                                                className="btn-block float-right save-btn Field-input"
                                                                type="submit">
                                                                {t != null && t.translations.delete}
                                                            </Button>
                                                        </td>}
                                                </div>
                                            </tr>
                                            <tr>
                                                <div>
                                                    {(bookingInfo.status != INCOMPLETE && bookingInfo.status != CANCELED && bookingInfo.status != PENDING && bookingInfo.status != BOOKED_PAID) &&
                                                        <td>
                                                            <Button
                                                                onClick={() => {
                                                                    this.sendReceiptMethod(bookingInfo);
                                                                }}
                                                                className="btn-block float-right save-btn Field-input"
                                                                type="submit">
                                                                {t != null && t.translations.share_receipt}
                                                            </Button>
                                                        </td>}
                                                    {(bookingInfo.status == COMPLETED || bookingInfo.status == AUTHORIZED) &&
                                                        <td>
                                                            <Button
                                                                onClick={() => {
                                                                    this.showPayments(bookingInfo);
                                                                }}
                                                                className="btn-block float-right save-btn Field-input"
                                                                type="submit">
                                                                {t != null && t.translations.title_payment}
                                                            </Button>
                                                        </td>}
                                                </div>
                                            </tr>
                                        </table>
                                    }
                                    {/* <h5>{t.translations.authorization_id}</h5>
                                    <p>{bookingInfo.authorization_id}</p> */}
                                </Fragment>
                            }
                            handleCancel={() => this.setState({ showDialogue: false })}
                            handleOk={null} />}

                    {this.state.show_available_therapists &&
                        <ConfirmationDialog
                            title={this.state.t != null ? this.state.t.translations.booking_details : ''}
                            bookingCancelBtn={true}
                            bodyText={
                                <Fragment>
                                    <h5>{t.translations.schedule_date}</h5>
                                    <p>{moment(new Date(0).setUTCSeconds(bookingInfo.date)).format('YYYY-MM-DD') + " " + this.minTwoDigits(Math.trunc(bookingInfo.reserved_slot / 100)) + ":" + this.minTwoDigits(bookingInfo.reserved_slot % 100)}</p>

                                    <div>
                                        <Field
                                            label={t != null ? t.translations.select_therapist : ''}
                                            type="select"
                                            name="therapist"
                                            classes="Field-input"
                                            validate={[required]}
                                            component={FormField}
                                            t={this.props.t}
                                            onSelect={this.selectNewTherapist}
                                            options={filtered_therapists}
                                            placeholder={t != null ? t.translations.select_therapist : ''}
                                            noOptionsMessage={t != null && t.translations.no_options}
                                        />
                                    </div>
                                </Fragment>
                            }
                            handleCancel={() => this.cancelAvailableTherapistPopup(bookingInfo)}
                            handleOk={() => this.updateTherapistMethod(bookingInfo)} />}

                    <Row>
                        <Col className="col-md-6">
                            <h3 className="pb-5 title-header">{t != null && t.translations.bookings}</h3>
                        </Col>
                        {(this.props.user.role_type == 3 || this.props.user.role_type == 2) &&
                            <Col className="col-md-6">
                                {/* <button id="myBtn" onClick={this.onOpenModal}>Open Modal</button> */}
                                <Button className="btn-block mt-2 float-right btn-login addUser-btn addCompanySchedule" type="button" onClick={this.addNewBooking}>{t != null ? t.translations.add_pre_booking : ''}</Button>
                                {this.props.user.role_type != 3 && <Button
                                    className="btn-block mt-2 float-right btn-login addUser-btn addCompanySchedule"
                                    type="button"
                                    onClick={this.addOtherBooking}>{t != null ? t.translations.add_invoice_booking : ''}
                                </Button>
                                }

                            </Col>
                        }

                        <Col className="col-md-6">
                        </Col>
                    </Row>
                    <Row>
                        {/* {this.props.user.role_type != 3 && <Header t={this.props.t}
                            addNew={this.addNewBooking}
                            title={t != null && t.translations.bookings}
                            addNewBtnTitle={(t != null && t.translations.add_pre_booking)} />} */}
                        {/* {this.props.user.role_type != 3 && <Header t={this.props.t} addNew={this.addNewBooking} title={t != null && t.translations.bookings} addNewBtnTitle={t != null && t.translations.add_new_booking} /> } */}
                        {/* {this.props.user.role_type == 3 && <Header t={this.props.t}
                            title={t != null && t.translations.bookings}
                            props={this.props}
                            addNew={this.addNewBooking}
                            addNewBtnTitle={(t != null && t.translations.add_pre_booking)} />
                        } */}


                        <Alert success={success} message={message} t={this.props.t} loading={loading} />

                    </Row>
                    {/* <Row>
                        {this.props.user.role_type != 3 &&  <Header t={this.props.t}
                            title={"Test"}
                            props={this.props}
                            addNew={this.addOtherBooking}
                            addNewBtnTitle={(t != null && t.translations.add_invoice_booking)}
                            />
                        }
                    </Row> */}
                    <Row>
                        <br />
                        <SegmentedControl
                            name="filterBooking"
                            options={[
                                { label: t.translations.abandoned, value: "abandoned" },
                                { label: t.translations.past, value: "past" },
                                { label: this.capitalizeFirstLetter(t.translations.today), value: "today", default: true },
                                { label: this.capitalizeFirstLetter(t.translations.tomorrow), value: "tomorrow" },
                                // { label: "This Week", value: "week" },
                                // { label: "This Month", value: "month" },
                                { label: t.translations.future, value: "future" },
                                { label: t.translations.preliminary, value: "preliminary" },
                            ]}
                            setValue={newValue => this.segmentControlTapped(newValue)}
                            style={{ width: 600, height: 40, color: 'black', marginTop: 70 }} // purple400
                        />
                        <br /> <br />
                    </Row>
                    <Row className="">
                        <MaterialTable t={this.props.t}
                            justShow={4}
                            columns={bookingColumns}
                            data={rev_bookings}
                            showDetail={this.showDetail}
                            loading={loading}
                            deleteDetail={this._renderDialog}
                            view={true} />
                    </Row>
                </Container >
            </Fragment>
        )
    }
}

const mapStateToProps = state => {
    return {
        t: state.accountRedux.localeInfo.t,
        loading: state.bookingRedux.loading,
        success: state.bookingRedux.success,
        message: state.bookingRedux.message,
        bookings: state.bookingRedux.bookings,
        user: state.accountRedux.user,
        bookingInfo: state.bookingRedux.bookingInfo,
        bookingPriceInfo: state.bookingRedux.bookingPriceInfo,
        refundedBookings: state.bookingRedux.refundedBookings,
        refund_data: state.userRedux.refund_data,
        update_therapist: state.userRedux.update_therapist,
        avail_therapists: state.userRedux.avail_therapists,
        send_receipt: state.userRedux.send_receipt,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getAvailableTherapist: (data) => dispatch(getAvailableTherapist(data)),
        sendReceipt: (data) => dispatch(sendReceipt(data)),

        refundBooking: (data) => dispatch(refundBooking(data)),
        changeTherapist: (data) => dispatch(changeTherapist(data)),
        getRefundedBookings: (data) => dispatch(getRefundedBookings(data)),
        editBooking: (data) => dispatch(editBooking(data)),
        getBookings: (data) => dispatch(getBookings(data)),
        showBookingDetail: (data) => dispatch(showBookingDetail(data)),
        deleteBooking: (data) => dispatch(deleteBooking(data)),
        getBookingPriceInformation: (data) => dispatch(getBookingPriceInformation(data))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({
    form: 'BookingForm',
    destroyOnUnmount: false
})(Booking));