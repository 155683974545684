/* eslint eqeqeq: 0 */
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Container, Row } from 'shards-react';
import Header from '../common/header';
import { getTherapists, showTherapistDetail, deleteTherapist, addNewTherapist } from '../../redux/therapist.redux';
import MaterialTable from '../common/materialTable';
import Alert from '../common/Alert';
import ConfirmationDialog from '../common/ConfirmationDialog';
import defaultImage from '../../images/defaultProfile.png'

class Therapist extends Component {
    constructor(props) {
        super(props);
        this.state = {
            t: null, openConfirmationBox: false,
            deleteItem: {}
        }
    }
    componentDidMount() {
        this.setState({ t: this.props.t });
        this.props.getTherapists();
        setTimeout(() => {
            this.props.addNewTherapist()
        }, 3000);

    }
    componentDidUpdate() {
        if (this.props.deleted) {
            this.props.getTherapists();
        }
    }
    showDetail = (event) => {
        this.props.showTherapistDetail(event);
        this.props.history.push('/edittherapist');
    }
    deleteTherapist = (event) => {
        const { deleteItem } = this.state;
        const data = { therapistId: deleteItem.id }
        this.props.deleteTherapist(data);
        this.setState({ openConfirmationBox: false })
    }

    maskStr(cardnumber) {
        var first4 = cardnumber.substring(0, 8);
        var mask = cardnumber.substring(cardnumber.length - 4, 4).replace(/\d/g, "*");
        //first4 + 
        return first4 + mask;
    }

    addNewTherapist = () => {
        return (
            this.props.history.push('/addtherapist')
        )
    }
    _renderDialog = (event) => {
        this.setState({ openConfirmationBox: true, deleteItem: event })
    }

    render() {
        const { t } = this.state;
        var columns = null;
        const { loading, therapists, success, message, deleted } = this.props;
        if (t !== null) {
            columns = [
                {
                    title: t.translations.person_number,
                    field: 'person_number',
                    render: rowData => <span>{this.maskStr(rowData.person_number)}</span>
                    // render: rowData => <span>{
                    //     rowData.person_number.substring(rowData.person_number.length, rowData.person_number.length - 10).replace(/(\d{3})(\d{3})(\d{4})/, "$1$2-$3")}
                    // </span>
                },
                {
                    title: t.translations.first_name,
                    field: 'first_name'
                },
                {
                    title: t.translations.last_name,
                    field: 'last_name',
                },
                {
                    title: t.translations.email,
                    field: 'email',
                },
                {
                    title: 'Profile Image',
                    field: 'profile_image',
                    render: rowData => <span className="profile-image"><img alt={"profileImage"} src={rowData.profile_image ? rowData.profile_image : defaultImage} /></span>
                }
            ];
        }
        return (
            <Fragment>
                <Container fluid className="personal-container user-container">
                    <Row className="">
                        <Header t={this.props.t} addNew={this.addNewTherapist} title={t != null && t.translations.therapist_list} addNewBtnTitle={t != null && t.translations.add_new_therapist} />
                        {deleted == false && <Alert success={success} message={message} t={this.props.t} loading={loading} />}
                        {this.state.openConfirmationBox &&
                            <ConfirmationDialog
                                title={this.state.t != null ? this.state.t.translations.delete_confirmation : ''}
                                bodyText={this.state.t != null && this.state.t.translations.are_you_sure_to_delete}
                                handleCancel={() => this.setState({ openConfirmationBox: false })}
                                handleOk={this.deleteTherapist} />}

                        {(columns !== null && t != null) &&
                            <MaterialTable
                                t={this.props.t}
                                selected_row_id={-1}
                                columns={columns}
                                justShow={0}
                                data={therapists}
                                deleteDetail={this._renderDialog}
                                showDetail={this.showDetail}
                                loading={loading} />}
                    </Row>
                </Container >
            </Fragment>
        )
    }
}

const mapStateToProps = state => {
    return {
        t: state.accountRedux.localeInfo.t,
        loading: state.therapistRedux.loading,
        success: state.therapistRedux.success,
        message: state.therapistRedux.message,
        therapists: state.therapistRedux.therapists,
        deleted: state.therapistRedux.deleted
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getTherapists: () => dispatch(getTherapists()),
        showTherapistDetail: (data) => dispatch(showTherapistDetail(data)),
        deleteTherapist: (data) => dispatch(deleteTherapist(data)),
        addNewTherapist: () => dispatch(addNewTherapist())
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Therapist);
