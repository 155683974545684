/* eslint eqeqeq: 0 */
import React from 'react';
import { Container, Form, Button, Col } from 'shards-react';
import { addLocation, updateLocation } from '../../redux/location.redux';
import {
    getLocationsByTherapistId,
    getTherapists,
    getTherapistScheduleSlots,
    addTherapistScheduleSlots,
    deleteTherapistScheduleSlots,
} from '../../redux/therapist.redux';
import { checkScheduleSlots } from '../../redux/therapist.redux';
import { getLocations } from '../../redux/location.redux';
import { required } from '../../helper/validate';
import { FormField } from '../../helper/formFields';
import { reduxForm, Field } from 'redux-form';
import { connect } from 'react-redux';
import Alert1 from '../common/Alert';
import { Alert } from "shards-react";

import { locationTypesOptionN } from '../../constants';
import ConfirmationDialog from '../common/ConfirmationDialog';

import { isEmpty } from 'lodash';
import Header from '../common/header';

var currentLocationTypeValue = {}
var currentLocationTypeValueTotal = []
var start_d = new Date();
var end_d = new Date().setTime(1582736400);
var bCount = 0;

var action_status = 0;

class AddSchedule extends React.Component {
    without_treatment = false;

    constructor(props) {
        super(props);
        this.state = {
            t: null,
            showAPIError: false,
            showDuplicateError: false,
            open: false,
            locationType: '',
            locationTypeValue: '',
            country: '',
            treatment_ids: [],
            open_date: start_d,
            close_date: end_d,
            start_time: "0800",
            end_time: "1700",
            // stime: moment().hour("08").minute("00"),
            stime: '08:00',
            etime: '17:00',
            showDeleteBookingDialogue: false,
            showDBookingDialogue: false,

            showError: false,
            locations: [],
            therapists: [],
            category_type: [],

            date: new Date(),

            locationsOptionsMain: [],
            locationsOptions: [],
            therapistsOptions: [],
            typeOptions: [],

            selected_loc: "",
            selected_type: "",
            selected_therapist: "",

            therapistSearchOptions: [],
            location_treatment_ids: [],
            is_treatment_empty: false,
            currentLocationTypeValue: {}
        }
        this.toggle = this.toggle.bind(this);
    }

    capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    loadBasicData() {

        const { user } = this.props;
        console.log('loadBasicData User_id: ' + user.id);
        console.log('loadBasicData User_id: action' + this.props.location.state.action);


        if (this.props.location.state.action === "add") {
            this.setState({ typeOptions: ["Healness", "Company", "Other"] })
            this.setState({ selected_type: locationTypesOptionN[0] })

            //FOR THERAPIST PORTAL


            if (user.role_type === 3) {
                this.setState({ therapistsOptions: [user] })
                this.setState({ selected_therapist: { value: user.id, label: user.first_name + " " + user.last_name } })
                currentLocationTypeValue = { value: user.id, label: user.first_name + " " + user.last_name }
                currentLocationTypeValueTotal = [
                    currentLocationTypeValue
                    // { value: 3, label: "Sana" },
                    // { value: 4, label: "Sahar"}
                ]

                this.setState({ selected_therapist: currentLocationTypeValueTotal[0] });

                const data = {
                    therapist_id: user.id
                }
                //APICALL
                this.props.getLocations(data);

                this.setState({ therapistsOptions: [currentLocationTypeValue] });
                this.setState({ selected_therapist: currentLocationTypeValue });

            }
            else {

                console.log('APICALL: getTherapists');
                this.props.getTherapists();
            }
        }
        else {

            //////debugger;

            let data_ = JSON.parse(this.props.location.state.data);
            if (data_.name != undefined && data_.name !== null) {
                var capitalized = this.capitalizeFirstLetter(data_.name);
                var therapist_id_ = data_.therapist_id;

                var loc_obj = { value: data_.loc_id, label: data_.loc_name };

                this.setState({ selected_loc: loc_obj });

                console.log('locationTypesOptionN: ' + JSON.stringify(locationTypesOptionN));

                var selected_typeN = locationTypesOptionN.filter(function (el) {
                    if (capitalized === "Healness_locations") {
                        var check = el.label === "Healness" || el.label === "healness";
                        return check;
                    }
                    return el.label === capitalized;
                });


                this.setState({ selected_type: selected_typeN[0] })


                // var startDateTime_ = new Date(data_.startDateTime);
                // var endDateTime_ = new Date(data_.endDateTime);

                var startDateTime_ = new Date(0); // The 0 there is the key, which sets the date to the epoch
                startDateTime_.setUTCSeconds(data_.start_time);
                var offset_ = startDateTime_.getTimezoneOffset() * 60000;
                var diff_ = startDateTime_.getTime() + offset_;
                startDateTime_.setTime(diff_);

                var endDateTime_ = new Date(0); // The 0 there is the key, which sets the date to the epoch
                endDateTime_.setUTCSeconds(data_.end_time);
                var offset_1 = endDateTime_.getTimezoneOffset() * 60000;
                var diff_1 = endDateTime_.getTime() + offset_1;
                endDateTime_.setTime(diff_1);

                // var startDateTime_ = new Date(data_.start_time * 1000);
                // var endDateTime_ = new Date(data_.end_time * 1000);

                var stime_ = startDateTime_.getHours().toString() + ":" + startDateTime_.getMinutes().toString();
                var etime_ = endDateTime_.getHours().toString() + ":" + endDateTime_.getMinutes().toString();

                this.setState({ stime: stime_ })
                this.setState({ etime: etime_ })
                this.setState({ date: startDateTime_ })


                if (user.role_type === 3) {
                    this.setState({ therapistsOptions: [user] })
                    this.setState({ selected_therapist: { value: user.id, label: user.first_name + " " + user.last_name } })
                    currentLocationTypeValue = { value: user.id, label: user.first_name + " " + user.last_name }
                    currentLocationTypeValueTotal = [
                        currentLocationTypeValue
                    ]

                    const data = {
                        therapist_id: user.id
                    }

                    //APICALL
                    this.props.getLocations(data);

                    var selected_therapist_ = currentLocationTypeValueTotal.filter(function (el) {
                        return el.value === therapist_id_;
                    });

                    this.setState({ therapistsOptions: [currentLocationTypeValue] });
                    this.setState({ selected_therapist: selected_therapist_[0] });

                }
                else {

                    console.log('APICALL: getTherapists');
                    this.props.getTherapists();

                    // console.log('APICALL: getLocations');
                    // this.props.getLocations(data);
                }

            }

        }
    }

    UNSAFE_componentWillMount() {
        // componentDidMount() {
        var state_ = this.props.location.state.action;
        let data_ = this.props.location.state.data;
        var t_ = this.props.location.state.t;
        this.setState({ t: t_ });

        this.setState({ action_state: state_ });


        const { user } = this.props;
        console.log('State Action: ' + state_);
        console.log('State Data: ' + JSON.stringify(data_));
        console.log('componentDidMount User_id: ' + user.id);

        this.props.initialize({
            ...user,
        });

        if (data_ !== null) {
            this.props.initialize({
                ...data_,
            });
        }

        this.loadBasicData();
        // locationTypesOptionN.sort();        // First sort the elements of fruits
        // locationTypesOptionN.reverse();


    }


    componentDidUpdate(prevProps) {
        console.log('componentDidUpdate123 : ' + this.props.del_success + " = " + this.props.del_message + " => " + this.props.del_payload);

        if (this.props !== prevProps) {
            if (this.props.check_therapist_data !== [] && this.props.check_therapist_data !== '') {
                var count = this.props.check_therapist_data.data;
                bCount = count;

                if (this.state.showDeleteBookingDialogue == true && bCount > 0) {
                    this.setState({ showDeleteBookingDialogue: false })
                    this.setState({ showDBookingDialogue: true })
                }
                else if (this.state.showDeleteBookingDialogue == true && bCount == 0) {
                    this.setState({ showDeleteBookingDialogue: false })
                    let data_ = JSON.parse(this.props.location.state.data);
                    action_status = 1;
                    var param = { "slot_id": data_.id }
                    console.log('Delete Params: ' + JSON.stringify(param))
                    this.props.deleteTherapistScheduleSlots(param);
                }

                console.log("check_therapist_data: " + count);
            }

            if (this.props.locations !== [] && this.props.locations !== '' && this.props.locations.length != 0) {
                if ((this.state.locationsOptionsMain.length === 0) || JSON.stringify(this.state.locations) != JSON.stringify(this.props.locations)) {

                    this.setState({ locations: this.props.locations });
                    let locArray = []
                    let allArray = []

                    this.props.locations.forEach(element => {
                        allArray.push({ value: element.id, label: element.name + "" + (element.room === null ? "" : " " + element.room) })
                    });
                    this.setState({ locationsOptionsMain: allArray });

                    if (this.props.location.state.action === 'add') {
                        let healness_locations_ = this.props.locations.filter(function (el) {
                            return el.location_type === "4" || el.location_type === 4;
                        });
                        console.log('Filtered Locs: ' + JSON.stringify(healness_locations_));

                        healness_locations_.forEach(element => {
                            locArray.push({ value: element.id, label: element.name + "" + (element.room == null ? "" : " " + element.room) })
                        });


                        if (locArray.length > 0) {
                            this.setState({ selected_loc: locArray[0] });
                        }
                        this.setState({ locationsOptions: locArray });
                    }
                    else {
                        if (this.state.selected_type.value != undefined && this.state != undefined) {
                            let val_ = this.state.selected_type.value;

                            let healness_locations_ = this.props.locations.filter(function (el) {
                                return el.location_type === val_;
                            });
                            console.log('Filtered Locs: ' + JSON.stringify(healness_locations_));

                            healness_locations_.forEach(element => {
                                locArray.push({ value: element.id, label: element.name + "" + (element.room === null ? "" : " " + element.room) })
                            });


                            let data_ = JSON.parse(this.props.location.state.data);
                            let loc_id_ = data_.loc_id;

                            let selected_locs_ = locArray.filter(function (el) {
                                return el.value === loc_id_;
                            });

                            let temp1_ = selected_locs_[0];

                            this.setState({ selected_loc: temp1_ });
                            this.setState({ locationsOptions: locArray });
                        }

                    }

                }
            }

            if (this.props.therapistLocations !== [] && this.props.therapistLocations !== '' && this.props.therapistLocations.length != 0) {
                if (this.state.locationsOptionsMain.length === 0) {
                    this.setState({ locations: this.props.locations });
                    let locArray = []
                    let allArray = []

                    this.props.therapistLocations.forEach(element => {
                        allArray.push({ value: element.id, label: element.name + "" + (element.room === null ? "" : " " + element.room) })
                    });
                    this.setState({ locationsOptionsMain: allArray });

                    if (this.props.location.state.action === 'add') {
                        let healness_locations_ = this.props.locations.filter(function (el) {
                            return el.location_type === "4" || el.location_type === 4;
                        });
                        console.log('Filtered Locs: ' + JSON.stringify(healness_locations_));

                        healness_locations_.forEach(element => {
                            locArray.push({ value: element.id, label: element.name + "" + (element.room === null ? "" : " " + element.room) })
                        });


                        if (locArray.length > 0) {
                            this.setState({ selected_loc: locArray[0] });
                        }
                        this.setState({ locationsOptions: locArray });
                    }
                    else {
                        if (this.state.selected_type.value != undefined && this.state != undefined) {
                            let val_ = this.state.selected_type.value;

                            let healness_locations_ = this.props.therapistLocations.filter(function (el) {
                                return el.location_type === val_;
                            });
                            console.log('Filtered Locs: ' + JSON.stringify(healness_locations_));

                            healness_locations_.forEach(element => {
                                locArray.push({ value: element.id, label: element.name + "" + (element.room === null ? "" : " " + element.room) })
                            });


                            let data_ = JSON.parse(this.props.location.state.data);
                            let loc_id_ = data_.loc_id;

                            let selected_locs_ = locArray.filter(function (el) {
                                return el.value === loc_id_;
                            });

                            let temp1_ = selected_locs_[0];

                            this.setState({ selected_loc: temp1_ });
                            this.setState({ locationsOptions: locArray });
                        }

                    }
                }
            }

            if (this.props.therapists !== [] && this.props.therapists !== '' && this.props.therapists.length != 0) {
                if (this.state.therapistsOptions.length === 0) {
                    var therapistArray = []
                    currentLocationTypeValueTotal = []
                    this.setState({ therapists: this.props.therapists });

                    ////////debugger;
                    this.props.therapists.forEach(element => {
                        therapistArray.push({ value: element.id, label: element.first_name + " " + element.last_name })
                    });

                    if (therapistArray.length > 0 && this.props.user.role_type !== 3) {
                        if (this.props.location.state.action === "add") {
                            this.setState({ selected_therapist: therapistArray[0] });
                        }
                        else {
                            let data_ = JSON.parse(this.props.location.state.data);

                            var type_1 = therapistArray.filter(function (number) {
                                return number.value == data_.therapist_id;
                            });
                            this.setState({ selected_therapist: type_1[0] });

                        }
                    }

                    const { user } = this.props;

                    if (user.role_type == 2) {
                        const data = {
                            therapist_id: therapistArray[0].value
                        }

                        this.props.getLocations(data);
                    }

                    currentLocationTypeValueTotal = therapistArray;
                    this.setState({ therapistsOptions: therapistArray })
                }
            }

            if (this.props.add_therapist_data !== [] && this.props.add_therapist_data !== '') {


                let msg = this.props.add_therapist_data.message;

                if (this.props.add_therapist_data === "therapist_schedule_overlap" && !this.state.showDuplicateError) {
                    //working here
                    action_status = 0;
                    if (this.state.showDuplicateError === false) {
                        this.setState({ showDuplicateError: true });
                    }
                }
                else if (this.props.success && msg === "therapist_schedule_add_success_message") {
                    if (action_status === 1) {
                        action_status = 0;
                        this.props.history.push('/schedule');
                    }

                    // this.props.history.goBack();
                    console.log("Teste");

                }
                else {
                    // if (action_status === 1) {
                    //     var msg = this.props.message;
                    //     if (this.props.success) {
                    //         // dispatchEvent
                    //         this.setState({ showDBookingDialogue: false })
                    //         this.setState({ showDeleteBookingDialogue: false })
                    //         action_status = 0;
                    //         this.props.history.goBack();
                    //     }
                    //     else if (msg === "therapist_schedule_overlap") {
                    //         action_status = 0;
                    //         this.setState({ showAPIError: true });
                    //         // alert("Error in entries, try again with different values.");
                    //     }
                    // }
                }

            }
            if (this.props.delete_therapist_data !== [] && this.props.delete_therapist_data !== '') {
                if (action_status === 1) {
                    let msg = this.props.message;
                    if (this.props.success) {
                        // dispatchEvent
                        this.setState({ showDBookingDialogue: false })
                        this.setState({ showDeleteBookingDialogue: false })
                        action_status = 0;
                        this.props.history.goBack();
                    }
                    else if (msg === "therapist_schedule_overlap") {
                        action_status = 0;
                        this.setState({ showAPIError: true });
                        // alert("Error in entries, try again with different values.");
                    }
                }

            }


            // if (this.props.therapistSlots != null && this.props.therapistSlots !== '') {
            //     if (this.props.therapistSlots.therapistSlots != null && this.props.therapistSlots.therapistSlots !== '' && this.props.therapistSlots.therapistSlots != undefined) {
            //         console.log('Added successfully: ' + JSON.stringify(this.props.therapistSlots.therapistSlots));
            //         if (this.props.therapistSlots.therapistSlots.length > 0) {
            //         }
            //     }
            // this.props.history.push('/location');
            // }
        }
    }

    toggle() {
        this.setState(prevState => {
            return { open: !prevState.open };
        });
    }

    handleSubmit = (values, dispatch) => {
        const { role_type, } = this.props.user;
        const { t, location_treatment_ids, mobile_phone } = this.state;
        var country = t.translations.default_country;
        var { name, address, floor, room, apartment_num, zip, id, treatment_id, notes, address_lat, address_lng, city, location_type } = values;

        if (!isEmpty(location_treatment_ids)) {
            if (isEmpty(zip)) {
                if (zip > 0) {
                }
                else {
                    zip = 0;
                }
            }
            if (id > 0) {
                var data = {
                    id, treatment_id, mobile_phone, city, address_lat, address_lng, treatment_ids: location_treatment_ids, name, address, floor, room, apartment_num, notes, country, zip, location_type: location_type, therapist_id: role_type === 3 ? this.props.user.id : 0, company_id: this.props.company.id
                }
                dispatch(updateLocation(data))
            }
            else {
                let data = {
                    name, address_lng, address_lat, city, treatment_id, mobile_phone, treatment_ids: location_treatment_ids, address, floor, room, apartment_num, notes, country, zip, location_type: location_type, therapist_id: role_type === 3 ? id : 0, company_id: this.props.company.id
                }
                dispatch(addLocation(data))
            }
        }
    }

    selectCountry(val) {
        this.setState({ country: val });
    }

    getTreatment = (treatments) => {
        var treatment_ids = [];
        treatments != null && treatments.forEach(treatment => {
            treatment_ids.push(treatment.value);
        });
        this.setState({ treatment_ids, location_treatment_ids: treatment_ids })
        if (treatments === null) {
            this.setState({ is_treatment_empty: true });
            this.without_treatment = true;
            // this.props.onChange('therapistInfo.treatments',[]);
        }
    }


    loadTherapistsValues() {
        var therapists = [{ "id": 15, "name": "Test User" }]
        return therapists
    }

    loadTypeValues() {
        return (
            <select>
                <option selected value="healness">Healness</option>
                <option value="company">Company</option>
                <option value="other">Other</option>
            </select>
        );
    }

    loadLocValues() {
        console.log('loadLocValues: ' + JSON.stringify(this.props.locations));

        var type_1 = this.props.locations.filter(function (number) {
            return number.location_type == 1;
        });
        var type_2 = this.props.locations.filter(function (number) {
            return number.location_type == 2;
        });
        var type_3 = this.props.locations.filter(function (number) {
            return number.location_type == 3;
        });
        var type_4 = this.props.locations.filter(function (number) {
            return number.location_type == 4;
        });
        //.map(person => ({ id: person.id, name: person.name, room: person.room }));

        console.log('loadLocValues: type_4' + JSON.stringify(type_4));

        // export const locationTypes = {
        //     Private: 1,
        //     Office: 2,
        //     Hotels: 3,
        //     Healness_Locations: 4
        //   }

        var temp = {
            "private": type_1,
            "office": type_2,
            "hotels": type_3,
            "healness_locations": type_4
        }

        return temp;
        // return (
        //     <select>
        //     <option value="grapefruit">Grapefruit</option>
        //     <option value="lime">Lime</option>
        //     <option defaultValue value="coconut">Coconut</option>
        //     <option value="mango">Mango</option>
        //   </select>
        // );
    }

    handleTimeChange = event => {
        console.log('handleTimeChange: ' + event);
    }

    getTypeDropdown = (data) => {
        console.log('getTypeDropdown: ' + data);
        var val = locationTypesOptionN.filter(function (number) {
            return number.value == data;
        });
        console.log('getTypeDropdown: ' + val);

        this.setState({ selected_type: val[0] });

        ////////debugger;

        ////////////debugger;
        var temp1 = this.loadLocValues();
        var te1 = temp1["office"];
        var te2 = temp1["hotels"];
        var comb = te1.concat(te2);

        ////////debugger;

        if (data != 1) {
            //not private - //val.label === 'healness_locations' ||

            //            var t = (val.label === "Healness") ?  temp1["healness_locations"] : ( val.label === 'private' ?  temp1["private"] : ( comb ) );

            var t;


            if (data === "4" || data === 4) { //val.label === "Healness" || d
                t = temp1["healness_locations"];
            }
            else if (data === "1" || data === 1) { //val.label === 'Private' ||
                t = temp1["private"];
            }
            else {
                t = comb;
            }

            var filter = t.filter(function (el) {
                return el.location_type == data;
            });

            console.log('Filtered Locs: ' + JSON.stringify(filter));

            let locArray = []
            filter.forEach(element => {
                locArray.push({ value: element.id, label: element.name + "" + (element.room === null ? "" : " " + element.room) })
            });

            if (locArray.length > 0) {
                this.setState({ selected_loc: locArray[0] });
            }
            this.setState({ locationsOptions: locArray })
        }


    }

    getLocDropdown = (data) => {

        ////////debugger;
        var val = this.state.locationsOptions.filter(function (number) {
            return number.value == data;
        });

        this.setState({ selected_loc: val[0] });
    }

    getTherapistDropdown = (data) => {
        console.log('getTherapistDropdown: ' + data);
        var val = this.state.therapistsOptions.filter(function (number) {
            return number.value == data;
        });
        console.log('getTherapistDropdown: ' + val);

        const { user } = this.props;

        if (user.role_type === 2) {
            const params = {
                therapist_id: val[0].value
            }

            this.props.getLocations(params);


        }

        this.setState({ selected_therapist: val[0] });

    }

    dateChanged = (data) => {
        console.log('dateChanged: ' + data);
        var date_ = data.toLocaleDateString("en-US");
        console.log('dateChanged1 : ' + date_);
        this.setState({ date: date_ });
    }

    onSaveBtn = (data) => {

        const { user } = this.props;

        action_status = 1;



        var ssT = this.state.stime;

        // var formattedS = ssT.format("HH:mm")
        var resS = ssT.split(":");
        var sHr = parseInt(resS[0]);
        var sMin = parseInt(resS[1]);

        // var sTime = parseInt(ssT);
        // var sHr  = sTime/100;
        // var sMin = sTime%100;

        var eeT = this.state.etime;

        var resE = eeT.split(":");
        var eHr = parseInt(resE[0]);
        var eMin = parseInt(resE[1]);

        // var eTime = parseInt(eeT);
        // var eHr  = eTime/100;
        // var eMin = eTime%100;

        var aDate = this.state.date;

        var userTimezoneOffset = aDate.getTimezoneOffset() * 60000;

        var sd = new Date();
        // var sMD = moment.tz(sd, "Europe/Stockholm");
        sd.setDate(aDate.getDate());
        sd.setMonth(aDate.getMonth());
        sd.setFullYear(aDate.getFullYear());
        // sd.setHours(sHr > 1 ? sHr : sTime);
        // sd.setMinutes(sHr > 1 ? sMin : 0);
        sd.setHours(sHr);
        sd.setMinutes(sMin);
        sd.setSeconds(0);
        var t1 = sd.getTime();
        var sEpoch = t1 - userTimezoneOffset;
        var sRes = sEpoch.toString().substr(0, 10);


        var ed = new Date();
        // var eMD = moment.tz(ed, "Europe/Stockholm");
        ed.setDate(aDate.getDate());
        ed.setMonth(aDate.getMonth());
        ed.setFullYear(aDate.getFullYear());
        // ed.setHours(eHr > 1 ? eHr : eTime);
        // ed.setMinutes(eHr > 1 ? eMin : 0);
        ed.setHours(eHr);
        ed.setMinutes(eMin);
        ed.setSeconds(0);
        var t2 = ed.getTime();
        var eEpoch = t2 - userTimezoneOffset //sd.getUTCMilliseconds();
        var eRes = eEpoch.toString().substr(0, 10);

        var type_ = this.state.selected_type.label;

        console.log("sd: " + sd);
        console.log("ed: " + ed);
        console.log("cond: " + (ed > sd));

        console.log("COMPARISON123: " + eHr + " - " + sHr);
        if (ed > sd) {
            action_status = 1;

            if (this.state.action_state !== "add") {
                //update
                console.log('onSaveBtn - update')

                let data_ = JSON.parse(this.props.location.state.data);
                var slot_id_ = data_.id;
                let name_ = (type_ === 'Healness' ? "healness_locations" : ((type_ != null && type_ != undefined) ? type_.toLowerCase() : type_));

                const locId = (type_ === 'Private') ? '0' : (this.state.selected_loc ? this.state.selected_loc.value : data_.loc_id)
                const locName = (type_ === 'Private') ? 'Private' : (this.state.selected_loc ? this.state.selected_loc.label : data_.loc_name)

                let params = {
                    id: slot_id_,
                    name: name_,
                    loc_id: locId,
                    loc_name: locName,
                    start_time: sRes, //Math.floor(i.startDateTime.getTime() / 1000) ,
                    end_time: eRes
                }

                // this.setState({ data:params });

                console.log('APICALL: therapistWorkingHours: ' + JSON.stringify(params));

                let data = {
                    therapist_id: user.role_type === 3 ? user.id : this.state.selected_therapist.value,
                    therapistSlots: [params]
                }

                console.log('APICALL: addTherapistScheduleSlots Params: ' + JSON.stringify(data));

                //APICALL
                this.props.addTherapistScheduleSlots(data);
            }
            else {
                //add

                let name_ = (type_ === 'Healness' ? "healness_locations" : ((type_ != null && type_ != undefined) ? type_.toLowerCase() : type_));

                let params = {
                    id: -1,
                    name: name_,
                    loc_id: type_ === 'Private' ? '0' : this.state.selected_loc.value,
                    loc_name: type_ === 'Private' ? 'Private' : this.state.selected_loc.label,
                    start_time: sRes, //Math.floor(i.startDateTime.getTime() / 1000) ,
                    end_time: eRes
                }

                // this.setState({ data:params });

                console.log('APICALL: therapistWorkingHours: ' + JSON.stringify(params));

                let data = {
                    therapist_id: user.role_type === 3 ? user.id : this.state.selected_therapist.value,
                    therapistSlots: [params]
                }

                console.log('APICALL: addTherapistScheduleSlots Params: ' + JSON.stringify(data));

                //APICALL
                this.props.addTherapistScheduleSlots(data);
            }
        }
        else {
            //show error
            this.setState({ showError: true })
        }

    }

    deleteSchedule = () => {
        console.log('deleteSchedule : ');

        let data_ = JSON.parse(this.props.location.state.data);
        var param = { "slot_id": data_.id }
        this.props.checkScheduleSlots(param);
    }

    deleteBookingSchedule = () => {
        let data_ = JSON.parse(this.props.location.state.data);

        action_status = 1;
        var param = { "slot_id": data_.id }
        console.log('Params: ' + JSON.stringify(param))
        this.props.deleteTherapistScheduleSlots(param);
    }

    onDeleteBtn = (data) => {
        console.log('onDeleteBtn - delete : ');
        this.setState({ showDeleteBookingDialogue: true })
    }

    onChange = date => this.setState({ date })
    onSTimeChange = stime => this.setState({ stime })
    onETimeChange = etime => this.setState({ etime })

    dismiss = () => {
        this.setState({ showAPIError: false });
    }

    render = () => {

        const { t, } = this.state;
        const { selected_type, locationsOptions, } = this.state;


        return (
            <Container fluid className="location-container">
                <Header t={this.props.t} title={t != null && t.translations.schedule} props={this.props} />
                {this.state.showAPIError && <Alert theme="success" dismissible={this.dismiss} open={this.state.showAPIError}>
                    {"Error in entries, try again with different values."}
                </Alert>
                }
                {this.state.showError &&
                    <ConfirmationDialog
                        // title={this.state.t != null ? this.state.t.translations.delete_confirmation : ''}
                        bodyText={'End date should be greater than start date.'}
                        // handleCancel={() => this.setState({ showError: false })}
                        handleOk={() => this.setState({ showError: false })} />}
                {this.state.showDuplicateError &&
                    <ConfirmationDialog
                        // title={this.state.t != null ? this.state.t.translations.delete_confirmation : ''}
                        bodyText={this.state.t != null && this.state.t.translations.therapist_schedule_overlap}
                        handleCancel={() => {
                            this.setState({ showDuplicateError: false })

                        }}
                        handleOk={null} />}

                {this.state.showDeleteBookingDialogue &&
                    <ConfirmationDialog
                        // title={this.state.t != null ? this.state.t.translations.delete_confirmation : ''}
                        bodyText={this.state.t != null && this.state.t.translations.are_you_sure_to_delete}
                        handleCancel={() => this.setState({ showDeleteBookingDialogue: false })}
                        handleOk={this.deleteSchedule} />}
                {this.state.showDBookingDialogue &&
                    <ConfirmationDialog
                        // title={this.state.t != null ? this.state.t.translations.delete_confirmation : ''}
                        bodyText={this.state.t != null && this.state.t.translations.booking_in_schedule}
                        handleCancel={() => this.setState({ showDBookingDialogue: false })}
                        handleOk={this.deleteBookingSchedule} />}
                <Col className="mt-5 ml-3 col-md-12 data-table-container">
                    <Form onSubmit={this.props.handleSubmit(this.handleSubmit)}>
                        <Alert1 success={this.props.success} message={this.props.message} t={this.props.t} />
                        <div className="row">
                            <div className="col-md-6 col-xs-12 float-left">
                                <Field
                                    label={t != null ? t.translations.schedule_select_type : 'Välj platstyp'}
                                    options={locationTypesOptionN}
                                    type="select"
                                    name="category_type"
                                    disableField={false}
                                    classes="Field-input"
                                    validate={[required]}
                                    component={FormField}
                                    t={this.props.t}
                                    onChange={this.getTypeDropdown}
                                    defaultValue={selected_type}
                                    //defaultValue={(!isEmpty(locationInfo.treatments)) && this.state.currentLocationTypeValue}
                                    placeholder={t != null ? t.translations.schedule_select_type : 'Välj'}
                                    noOptionsMessage={t != null ? t.translations.no_options : 'Inga alternativ'}
                                />
                            </div>
                            <div className="col-md-6 col-xs-12 float-right">
                                {/* {this.state.selected_type.label !== 'Private' && */}
                                <Field
                                    label={t != null ? t.translations.schedule_select_loc : 'Plats'}
                                    options={locationsOptions}
                                    type="select"
                                    name="location_type"
                                    disableField={(this.state.selected_type != undefined ? this.state.selected_type.label === 'Private' : false)}
                                    classes="Field-input"
                                    validate={[required]}
                                    component={FormField}
                                    t={this.props.t}
                                    onChange={this.getLocDropdown}
                                    // defaultValue={this.props.location.state.action === 'add' ? this.state.selected_loc : "None"}
                                    defaultValue={this.state.selected_loc}
                                    //defaultValue={(!isEmpty(locationInfo.treatments)) && this.state.currentLocationTypeValue}
                                    placeholder={t != null ? t.translations.schedule_select_loc : 'Välj'}
                                    noOptionsMessage={t != null ? t.translations.no_options : 'Inga alternativ'}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6 col-xs-12 float-left"
                                style={{ display: "flex", flexDirection: 'column', paddingLeft: '2%' }}>

                                {/* <label className="Field-label">
                                    {t != null ? t.translations.schedule_start_date : 'Shift start time'}
                                </label>
                                <div >
                                    <TimePicker
                                        allowEmpty={false}
                                        label={t != null ? t.translations.schedule_start_date : 'Shift start time'}
                                        type="myTimePicker"
                                        name="start_time"

                                        showSecond={false}
                                        defaultValue={this.state.stime}
                                        popupStyle={{ width: '100%' }}
                                        style={{
                                            width: '100%',

                                            padding: 0.4375
                                            // height: 200
                                        }}
                                        // classes="form-control"

                                        onChange={this.onSTimeChange}
                                        validate={[required]}
                                        t={this.props.t}
                                    />
                                </div> */}
                                {/* <InputType type={type} fieldProps={fieldProps} options={options} placeholder={placeholder} pluginOptions={pluginOptions} disabled={disabled} onSelect={onSelect} /> */}


                                <Field
                                    label={t != null ? t.translations.schedule_start_date : 'Shift start time'}
                                    type="myTimePicker"
                                    name="start_time"
                                    classes="Field-input"
                                    defaultValue={this.state.stime}
                                    onChange={this.onSTimeChange}
                                    validate={[required]}
                                    component={FormField}
                                    t={this.props.t}
                                />
                            </div>
                            <div className="col-md-6 col-xs-12 float-right">
                                <Field
                                    label={t != null ? t.translations.schedule_end_date : 'Shift end time'}
                                    type="myTimePicker"
                                    name="end_time"
                                    defaultValue={this.state.etime}
                                    onChange={this.onETimeChange}
                                    classes="Field-input"
                                    validate={[required]}
                                    component={FormField}
                                    t={this.props.t}
                                />
                                {/* <Field
                                    label={"Closing Time"}
                                    type="time"
                                    name="close_date"
                                    classes="Field-input"
                                    component={FormField}
                                    t={this.props.t}
                                    // defaultValue={end_d}
                                    onChange={this.handleTimeChange} /> */}
                            </div>

                        </div>
                        <div className="row">
                            <div className="col-md-6 col-xs-12 float-left">
                                <Field
                                    label={t != null ? t.translations.schedule_select_therapist : 'Välj behandlare'}
                                    options={currentLocationTypeValueTotal}
                                    type="select"
                                    name="location_type"
                                    disableField={false}
                                    classes="Field-input"
                                    validate={[required]}
                                    component={FormField}
                                    t={this.props.t}
                                    onChange={this.getTherapistDropdown}
                                    defaultValue={this.state.selected_therapist}
                                    //defaultValue={(!isEmpty(locationInfo.treatments)) && this.state.currentLocationTypeValue}
                                    placeholder={t != null ? t.translations.schedule_select_therapist : 'Välj'}
                                    noOptionsMessage={t != null ? t.translations.no_options : 'Inga alternativ'}
                                />
                            </div>

                            <div className="col-md-6 col-xs-12 float-right">
                                <Field
                                    label={t != null ? "Select Date" : ''}
                                    type="myDatePicker"
                                    name="date"
                                    // classes="Field-input"
                                    component={FormField}
                                    onChange={this.onChange}
                                    defaultValue={this.state.date}
                                    t={this.props.t}
                                />
                            </div>
                        </div>
                        {this.state.action_state !== "add" && <Button onClick={this.onDeleteBtn} className="btn-block float-right delete-btn" type="button">{"Radera"} {this.props.loading && <i className="fas fa-circle-notch fa-spin loader"></i>}</Button>}
                        <Button onClick={this.onSaveBtn} className="btn-block float-right save-btn Field-input" type="button">{this.state.action_state === "add" ? "Spara" : "Uppdatera"} {this.props.loading && <i className="fas fa-circle-notch fa-spin loader"></i>}</Button>

                    </Form>
                </Col>
            </Container >
        );
    }
};

const mapStateToProps = state => {
    return {
        t: state.accountRedux.localeInfo.t,
        loading: state.therapistRedux.loading,
        success: state.therapistRedux.success,
        message: state.therapistRedux.message,
        del_success: state.therapistRedux.del_success,
        del_message: state.therapistRedux.del_message,
        del_payload: state.therapistRedux.del_payload,

        therapists: state.therapistRedux.therapists,
        treatments: state.treatmentRedux.treatments,
        therapistLocations: state.therapistRedux.therapistLocations,
        schedule: state.therapistRedux.schedule,
        user: state.accountRedux.user,
        therapistTreaments: state.therapistRedux.therapistTreaments,
        therapistSlots: state.therapistRedux.therapistSlots,
        therapist_list: state.therapistRedux.allTherapist,
        showTherapistData: state.therapistRedux.showTherapistData,
        locations: state.locationRedux.locations,
        delete_therapist: state.therapistRedux.delete_therapist,
        delete_therapist_data: state.therapistRedux.delete_therapist_data,
        check_therapist_data: state.therapistRedux.check_therapist_data,
        add_therapist_data: state.therapistRedux.add_therapist_data
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getTherapists: (data) => dispatch(getTherapists(data)),
        getLocations: (data) => dispatch(getLocations(data)),
        getLocationsByTherapistId: (data) => dispatch(getLocationsByTherapistId(data)),
        addTherapistScheduleSlots: (data) => dispatch(addTherapistScheduleSlots(data)),
        getTherapistScheduleSlots: (data) => dispatch(getTherapistScheduleSlots(data)),
        deleteTherapistScheduleSlots: (data) => dispatch(deleteTherapistScheduleSlots(data)),
        checkScheduleSlots: (data) => dispatch(checkScheduleSlots(data))


    }
};

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({
    form: 'AddScheduleForm'
})(AddSchedule));