/* eslint eqeqeq: 0 */
import React from 'react';
import { Container, Row, Form, Col } from 'shards-react';
import { required, } from '../../../../helper/validate';
import { FormField } from '../../../../helper/formFields';
import { reduxForm, Field } from 'redux-form';
import { connect } from 'react-redux';
import CompanyInfo from '../../companyInfo';
import { loginAdmin, bankIdCollect } from '../../../../redux/account.redux';
import Alert from '../../../common/Alert';
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { isEqual, isEmpty } from 'lodash';
import { Redirect } from "react-router-dom";
import { capture } from '../../../../redux/payment.redux';
import { APP_VERSION, PENDING } from '../../../../constants/urls';

import axios from 'axios';
const personnummer = require('personnummer');
const localIpUrl = require('local-ip-url');

class StaffLogin extends React.Component {
    personNumber = '';
    callcollect = false;
    constructor(props) {
        super(props);
        console.log("StaffLogin - constructor");

        this.state = {
            personalNumber: "",
            orderRef: "",
            startCollect: false,
            baseurlhandled: false,
            showErrorMesage: '',
            showError: false,
            validateBankid: false,
            isBankIdError: false,
            bankIdErrorMessage: ''
        }
    }


    saveState() {
        ////////debugger;
        console.log('saveState');
    }

    componentDidMount() {
        window.addEventListener('onbeforeunload', this.saveState)
        // this.handleSubmit({ person_number: 199604046764 })
        // this.handleSubmit({ person_number: 198908081816 })
    }

    componentWillUnmount() {
        window.removeEventListener('beforeunload', this.saveState)
    }

    UNSAFE_componentWillReceiveProps(nextProps) {

        if (!isEmpty(nextProps.user)) {
            console.log("user staffLogin: " + JSON.stringify(this.props.user));
        }

        if (!isEmpty(nextProps.bankIdCollectResponse)) {
            console.log("bankIdCollectResponse: " + JSON.stringify(nextProps.bankIdCollectResponse));
            const { data } = nextProps.bankIdCollectResponse;

            setTimeout(() => {
                if (data === null || data === undefined) {
                    console.log("bankIdCollectResponse: startCollect");
                    this.loadData(this.state.orderRef);
                }
                else {
                    const { orderRef, status, completionData } = data;
                    if (status !== PENDING) {
                        console.log("userSign: " + JSON.stringify(completionData));

                    }
                    else {
                        console.log("bankIdCollectResponse: startCollect");
                        this.loadData(orderRef);
                    }
                };
            }, 5500);



            //bankIdCollectResponse: {"data":{"orderRef":"6cb93f09-eaaf-42ff-92c5-39292d53d6f3","status":"pending","hintCode":"outstandingTransaction","completionData":null},"message":""}
        }
        else if (!isEmpty(nextProps.bankIdResponse)) {
            const { orderRef } = nextProps.bankIdResponse;
            console.log("bankIdResponse: " + nextProps.bankIdResponse.orderRef);
            const { data } = nextProps.bankIdCollectResponse;

            if (orderRef !== undefined) {

                if (this.state.startCollect === false) {
                    console.log("bankIdCollectResponse: startCollect");
                    this.setState({ orderRef: orderRef });

                    this.loadData(orderRef);

                }
                this.setState({ startCollect: true });
            }
            else {
                this.setState({ startCollect: false });
            }

            if (data !== undefined && data !== null) {
                console.log("bankIdCollectResponse: data" + data);
            }

        }

    }

    async loadData(orderRef) {
        console.log('loadData1')

        try {
            var url = `https://api.healness.se/api/Collect?orderRef=${orderRef}`;
            // var url = "";
            const response = await axios.get(url);
            if (response != undefined) {
                const { data } = response.data;
                console.log(data)
                if (data === null || data === undefined) {
                    this.loadData(this.state.orderRef);
                }
                else {
                    console.log('loginAdmin: ' + data)

                    const { orderRef, status, completionData } = data;
                    if (status !== PENDING) {
                        var personalNumber = completionData.user.personalNumber;
                        const data = {
                            person_number: personalNumber
                        }
                        this.setState({ personalNumber })

                        this.props.loginAdmin(data);
                    }
                    else {
                        this.loadData(orderRef);
                    }
                };
            }
        } catch (error) {
            console.error("Axios Error : " + error);
        }
        //}
    }

    handleSubmit = (values, dispatch) => {
        const { person_number } = values;
        var ipaddress = localIpUrl();

        if (/^\d{6,8}[-|(\s)]{0,1}\d{4}$/i.test(person_number)) {
            //var valid = personnummer.valid(person_number);

            var validPersonNumber = personnummer.format(person_number, 'YYYYMMDDNNNN');
            // var valid = this.validatePersonNumber(person_number);
            //var valid = true;

            //////////debugger;
            if (!isEmpty(validPersonNumber)) {
                if (this.state.startCollect) {
                    // debugger;
                    // var personalNumber = this.state.personNumber != undefined ? this.state.personNumber : person_number;
                    // const data = {
                    //     person_number: personalNumber
                    // }
                    // this.props.loginAdmin(data);
                }
                else {
                    const data = {
                        ip: ipaddress,
                        person_number: validPersonNumber,//'199604046764',
                        // userVisibleData: 'Healness Admin',
                        // userNonVisibleData: 'Testing',
                    }
                    // dispatch(bankIdAuthentication(data));

                    // const data1 = {
                    //     person_number: validPersonNumber
                    // }
                    this.props.loginAdmin(data);
                }

            }
            else {
                this.setState({
                    showError: true,
                    showErrorMesage: "bad_day_person_number"

                })
            }
        }
        else {
            if (person_number.includes("#")) {

                var hashIndex = person_number.indexOf("#");
                var valid = person_number.substr(0, hashIndex);

                const data1 = {
                    person_number: valid
                }
                this.props.loginAdmin(data1);

            }
            else {
                alert(this.props.t.translations.login_placeholder + this.props.t.translations.is_not_valid_validation_message);
            }
        }

    }
    render = () => {
        console.log('LOGGED IN USER: ' + JSON.stringify(this.props.user));

        var errorMessage = ''; //, redirectToSchedule = false;
        if (!isEmpty(this.props.bankIdCollectErrorResponse)) {
            const { message } = this.props.bankIdCollectErrorResponse;
            errorMessage = message;
        }
        // if (!isEmpty(this.props.bankIdCollect)) {
        //     const { data } = this.props.bankIdCollect;
        //     if (data.completionData != null && !isEmpty(this.props.user)) {
        //         redirectToSchedule = true
        //     }
        // }
        return (
            <Container fluid className="login-container login-error-message-container">
                {!isEmpty(this.props.user) && <Redirect to={'/schedule'} />}
                <Row className="login-content">
                    <CompanyInfo props={this.props} />
                    <Col className=" col-md-6 login-data login-background" >
                        <Form onSubmit={this.props.handleSubmit(this.handleSubmit)}>
                            {!isEmpty(this.props.bankIdCollectErrorResponse) && <Alert success={this.props.success} message={errorMessage} t={this.props.t} />}
                            {!this.props.success && <Alert success={this.props.success} loading={this.props.loading} message={this.props.message} t={this.props.t} />}
                            <Row className="my-2">
                                <Field
                                    placeholder={this.props.t.translations.login_placeholder}
                                    type="text"
                                    name="person_number"
                                    classes="login-input"
                                    validate={[required]} // validPersonNumber
                                    component={FormField}
                                    t={this.props.t}
                                />
                            </Row>
                            {this.state.showError && <p className="person-number-invalid">{this.props.t.translations[this.state.showErrorMesage]}</p>}

                            <button class="btn-login2">
                                <span class="bankid-icon"></span>
                                Logga in med Mobilt BankID
                                {this.props.loading && <i className="fas fa-circle-notch fa-spin loader"></i>}
                            </button>
                            {/* <Button className="btn-block btn-login1 max-width login-input" type="submit">{this.props.t.translations.login_btn_app} {this.props.loading && <i className="fas fa-circle-notch fa-spin loader"></i>}</Button> */}
                            <RadioGroup
                                aria-label="Language"
                                name="lang"
                                value={this.props.value}
                                onChange={this.props.handleChange}
                                className="col-md-12 mt-5"
                            >
                                <FormControlLabel
                                    value="en"
                                    control={<Radio />}
                                    checked={isEqual(this.props.value, 'en') && true}
                                    label={this.props.t.translations.english}
                                    className="float-left col-md-5"
                                />
                                <FormControlLabel
                                    value="sv"
                                    control={<Radio />}
                                    checked={isEqual(this.props.value, 'sv') && true}
                                    label={this.props.t.translations.swedish}
                                    className="col-md-5"
                                />
                            </RadioGroup>
                            <p className="text-center mt-3" style={{ 'color': 'white' }}>{APP_VERSION}</p>
                        </Form>
                    </Col>
                </Row>
            </Container >
        );
    }
};

const mapStateToProps = state => {
    return {
        message: state.accountRedux.message,
        success: state.accountRedux.success,
        error: state.accountRedux.error,
        user: state.accountRedux.user,
        loading: state.accountRedux.loading,
        bankIdResponse: state.accountRedux.bankIdResponse,
        bankIdCollectResponse: state.accountRedux.bankIdCollectResponse,
        bankIdCollectErrorResponse: state.accountRedux.bankIdCollectErrorResponse
    };
};
const mapDispatchToProps = dispatch => {
    return {
        bankIdCollect: (data) => dispatch(bankIdCollect(data)),
        loginAdmin: (data) => dispatch(loginAdmin(data)),
        capture: (data) => dispatch(capture(data))
    }
};
export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({
    form: 'staffLoginForm',
    destroyOnUnmount: false
})(StaffLogin));