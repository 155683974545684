// Layout Types
import { DefaultLayout } from "./layouts";

// Route Views
import StaffLogin from "./components/authentication/staff/staffLogin/index";
import SecretLogin from "./components/authentication/secret/secretLogin/index";
import Signup from './components/authentication/staff/signUp/index';
import ForgotPassword from './components/authentication/staff/forgotPassword/index';
// import Personal from './components/account/personal';
import Contact from './components/contact';
import Schedule from './components/schedule';
import Location from './components/location';
import AddLocation from './components/location/addLocation';
import Dashboard from './components/dashboard';
import AddSchedule from './components/schedule/addSchedule';
import EditSchedule from './components/schedule/addSchedule';
import EmployeeBenefitProgram from './components/employeebenefit'
 import addCredit from "./components/employeebenefit/addCredit";

import About from './components/about';

import StaffDashboard from './components/staffDashboard';
import Hotel from './components/hotel';
import User from './components/user';
import Therapist from './components/therapist';
import AddTherapist from './components/therapist/addTherapist';
import Treatment from './components/treatment';
import AddTreatment from './components/treatment/addTreatment';
import AddUser from './components/user/addUser';
import Company from './components/company';
import Booking from './components/booking';
import AddBooking from './components/booking/addBooking';
import EditBooking from './components/booking/addBooking';

import AddOtherBooking from './components/booking/addOtherBooking';


import Transaction from './components/transaction';
import Scheduler from './components/jobs';
import Events from './components/events';

import Invoice from './components/invoice';
import CompanyInvoice from './components/invoice/index_company';

import AddCompany from './components/company/addCompany';
import AdminLogin from './components/authentication/admin/adminLogin';
import Report from './components/report';
import VATReport from './components/invoice/VATReport';
import Receipts from './components/receipts';

import ShowBookings from './components/user/showBookings';

import Notification from './components/notification';
import AddNotification from './components/notification/addNotification';

import Promotion from './components/promotion';
import AddPromotion from './components/promotion/addPromotion';

import { S_LOGIN } from './constants/urls'
import { decryptToken } from "./constants";
export default [
  // {
  //   path: "/bank",
  //   exact: true,
  //   layout: AdminLogin,
  //   component: BankId
  // },
  {
    path: "/promotion",
    exact: true,
    layout: DefaultLayout,
    component: Promotion
  },
  {
    path: "/addnotification",
    exact: true,
    layout: DefaultLayout,
    component: AddNotification
  },
  {
    path: "/notifications",
    exact: true,
    layout: DefaultLayout,
    component: Notification
  },
  {
    path: "/admin",
    exact: true,
    layout: AdminLogin,
    component: AdminLogin
  },
  {
    path: "/signup",
    exact: true,
    layout: Signup,
    component: Signup
  },
  {
    path: "/forgot-password",
    exact: true,
    layout: ForgotPassword,
    component: ForgotPassword
  },
  {
    path: "/dashboard",
    exact: true,
    layout: DefaultLayout,
    component: Dashboard
  },
  {
    path: "/addschedule",
    exact: true,
    layout: DefaultLayout,
    component: AddSchedule
  },
  {
    path: "/editschedule",
    exact: true,
    layout: DefaultLayout,
    component: EditSchedule
  },
  {
    path: "/staffdashboard",
    exact: true,
    layout: DefaultLayout,
    component: StaffDashboard
  },
  // {
  //   path: "/personal",
  //   layout: DefaultLayout,
  //   component: Personal
  // },
  {
    path: "/contact",
    layout: DefaultLayout,
    component: Contact
  },
  {
    path: "/about_system",
    layout: DefaultLayout,
    component: About
  },
  {
    path: "/location",
    layout: DefaultLayout,
    component: Location
  },
  {
    path: "/addlocation",
    layout: DefaultLayout,
    component: AddLocation
  },
  {
    path: "/editlocation",
    layout: DefaultLayout,
    component: AddLocation
  },
  {
    path: "/schedule",
    layout: DefaultLayout,
    component: Schedule
  },
  {
    path: "/therapist",
    layout: DefaultLayout,
    component: Therapist
  },
  {
    path: "/addtherapist",
    layout: DefaultLayout,
    component: AddTherapist
  },
  {
    path: "/edittherapist",
    layout: DefaultLayout,
    component: AddTherapist
  },
  {
    path: "/treatment",
    layout: DefaultLayout,
    component: Treatment
  },
  {
    path: "/addtreatment",
    layout: DefaultLayout,
    component: AddTreatment
  },
  {
    path: "/edittreatment",
    layout: DefaultLayout,
    component: AddTreatment
  },
  {
    path: "/booking",
    layout: DefaultLayout,
    component: Booking
  },
  {
    path: "/addbooking",
    layout: DefaultLayout,
    component: AddBooking
  },
  {
    path: "/editbooking",
    exact: true,
    layout: DefaultLayout,
    component: EditBooking
  },
  {
    path: "/addotherbooking",
    exact: true,
    layout: DefaultLayout,
    component: AddOtherBooking
  },
  {
    path: "/employeebenefitprogram",
    layout: DefaultLayout,
    component: EmployeeBenefitProgram
  },
   {
     path: "/addCredit",
     layout: DefaultLayout,
     component: addCredit
	   },
  {
    path: "/hotel",
    layout: DefaultLayout,
    component: Hotel
  },
  {
    path: "/transaction",
    layout: DefaultLayout,
    component: Transaction
  },
  {
    path: "/scheduled_events",
    layout: DefaultLayout,
    component: Scheduler
  },
  {
    path: "/events",
    layout: DefaultLayout,
    component: Events
  },
  {
    path: "/invoice",
    layout: DefaultLayout,
    component: Invoice
  },
  {
    path: "/company_invoice",
    layout: DefaultLayout,
    component: CompanyInvoice
  },
  {
    path: "/vat",
    layout: DefaultLayout,
    component: VATReport
  },
  {
    path: "/receipts",
    layout: DefaultLayout,
    component: Receipts
  },
  {
    path: "/customers",
    layout: DefaultLayout,
    component: User
  },
  // {
  //   path: "/adduser",
  //   layout: DefaultLayout,
  //   component: AddUser
  // },
  // {
  //   path: "/edituser",
  //   layout: DefaultLayout,
  //   component: AddUser
  // },
  {
    path: "/addcustomer",
    layout: DefaultLayout,
    component: AddUser
  },
  {
    path: "/editcustomer",
    layout: DefaultLayout,
    component: AddUser
  },
  {
    path: "/company",
    layout: DefaultLayout,
    component: Company
  },
  {
    path: "/addcompany",
    layout: DefaultLayout,
    component: AddCompany
  },
  {
    path: "/addpromotion",
    layout: DefaultLayout,
    component: AddPromotion
  },
  {
    path: "/editpromotion",
    layout: DefaultLayout,
    component: AddPromotion
  },
  {
    path: "/editcompany",
    layout: DefaultLayout,
    component: AddCompany
  },
  {
    path: "/report",
    layout: DefaultLayout,
    component: Report
  },
  {
    path: "/showbookings",
    layout: DefaultLayout,
    component: ShowBookings
  },
  {
    path: "/",
    exact: true,
    layout: StaffLogin,
    component: StaffLogin,
    value: 'en'
  },
  {
    path: "/" + decryptToken(S_LOGIN),
    exact: true,
    layout: SecretLogin,
    component: SecretLogin,
    value: 'en'
  }
];


// export const publicRoutes= [
//   {
//     path: "/",
//     exact: true,
//     layout: 'login',
//     component: Login
//   },
//   {
//     path: "/admin",
//     exact: true,
//     layout: 'login',
//     component: AdminLogin
//   },
//   {
//     path: "/signup",
//     exact: true,
//     layout: 'login',
//     component: Signup
//   },
//   {
//     path: "/forgot-password",
//     exact: true,
//     layout: 'login',
//     component: ForgotPassword
//   }
// ];
