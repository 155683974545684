import { createRoutine } from 'redux-saga-routines';
export const registerStaff = createRoutine('REGISTER_STAFF');
export const updateStaff = createRoutine('UPDATE_STAFF');
export const updateContact = createRoutine('UPDATE_CONTACT');

const INITIAL_STATE = {
    error: false,
    success: false,
    message: '',
    data: '',
    loading: false,
    user:{}
};
export default (state = INITIAL_STATE, { type, payload }) => {
    switch (type) {
        case registerStaff.TRIGGER:
            return { ...state, loading: true, error: false, success: false, message: '' };
        case registerStaff.SUCCESS:
            return { ...state, loading: false, error: false, success: true, message: payload };
        case registerStaff.FAILURE:
            return { ...state, loading: false, success: false, error: true, message: payload };

        case updateStaff.TRIGGER:
            return { ...state, loading: true, error: false, success: false, message: '' };
        case updateStaff.SUCCESS:
            return { ...state, loading: false, error: false, success: true, message:payload };
        case updateStaff.FAILURE:
            return { ...state, loading: false, success: false, error: true, message: payload };

        case updateContact.TRIGGER:
            return { ...state, loading: true, error: false, success: false, data: '' };
        case updateContact.SUCCESS:
            return { ...state, loading: false, error: false, success: true, data:payload };
        case updateContact.FAILURE:
            return { ...state, loading: false, success: false, error: true, data: payload };

        default:
            return { ...state }

    }
}