import { call, put, takeLatest } from "redux-saga/effects";
import Api from "../services/ApiCaller";
import {
  addTreatment,getTreatments,updateTreatment,deleteTreatment
} from "../redux/treatment.redux";

function* addTreatmentRequest(api, { payload = {} }) {
   try {
      const {res={}}= yield call(Api.callServer, api, payload);
      const { message,success } = res;
      if (success) {
         yield put(addTreatment.success(message));
      } else {
         yield put(addTreatment.failure(message));
      }
   } catch (e) {
      yield put(addTreatment.failure(e.result));
   }
}
function* updateTreatmentRequest(api, { payload = {} }) {
   try {
      const {res={}}= yield call(Api.callServer, api, payload);
      const { message,success } = res;
      if (success) {
         yield put(updateTreatment.success(message));
      } else {
         yield put(updateTreatment.failure(message));
      }
   } catch (e) {
      yield put(updateTreatment.failure(e.result));
   }
}
function* getTreatmentsRequest(api, { payload = {} }) {
   try {
      const {res={}}= yield call(Api.callServer, api, payload);
      const { message,data,success } = res;
      if (success) {
         yield put(getTreatments.success(data));
      } else {
         yield put(getTreatments.failure(message));
      }
   } catch (e) {
      yield put(getTreatments.failure(e.result));
   }
}
function* deleteTreatmentRequest(api, { payload = {} }) {
   try {
      const {res={}}= yield call(Api.callServer, api, payload);
      const { message,success } = res;
      if (success) {
         yield put(deleteTreatment.success(message));
      } else {
         yield put(deleteTreatment.failure(message));
      }
   } catch (e) {
      yield put(deleteTreatment.failure(e.result));
   }
}

export default (api) => {
   const addTreatmentApi = (data) => api.post("v1/addTreatment", data); // garbage not used
   const updateTreatmentApi = (data) => api.put("v1/updateTreatment", data); // garbage not used
   const deleteTreatmentApi = (data) => api.delete("v1/deleteTreatment", data);
   const getTreatmentsApi = (data) => api.get("/getAllTreatmentsForAdmin", data);
   return [
      takeLatest(addTreatment.TRIGGER, addTreatmentRequest, addTreatmentApi),
      takeLatest(updateTreatment.TRIGGER, updateTreatmentRequest, updateTreatmentApi),
      takeLatest(deleteTreatment.TRIGGER, deleteTreatmentRequest, deleteTreatmentApi),
      takeLatest(getTreatments.TRIGGER, getTreatmentsRequest, getTreatmentsApi),
   ];
};