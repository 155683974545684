/* eslint eqeqeq: 0 */
import React, { Fragment } from 'react';
import { Container, Form, Button, Col } from 'shards-react';
import { required } from '../../helper/validate';
import { FormField } from '../../helper/formFields';
import { reduxForm, Field } from 'redux-form';
import { connect } from 'react-redux';
import ConfirmationDialog from '../common/ConfirmationDialog';
import Alert from '../common/Alert';
import Header from '../common/header';
import { getPreliminaryDetails, createPreliminaryDetails, updatePreliminaryDetails } from '../../redux/user.redux';
import { getLocations } from '../../redux/location.redux';
import * as moment from 'moment';

var CryptoJS = require("crypto-js");

var customers_ = [];
var locations_ = [];
var treatments_ = [];
var locations_main = [];

var therapist_ = [];

var slots_ = [
    { value: 1, label: "15" },
    { value: 2, label: "30" },
    { value: 3, label: "45" },
    { value: 4, label: "60" },
    { value: 5, label: "90" },
    { value: 6, label: "120" }];

var location_types = [
    { value: '4', label: 'Healness' },
    { value: '1', label: 'Private - Home' }
]

var selected_cust_id = 0;
var selected_cust_name = "";

var selected_therapist_id = 0;
var selected_therapist_name = "";

var selected_treatment_id = 0;
var selected_treatment_name = "";

var selected_slot_id = 0;
var selected_slot_name = "";

var selected_loc_type_t = 0;
var selected_loc_id = 0;
var selected_loc_name = "";

var address_ = "Hello";
var rt_ = "";



var action_status = 0;

class AddBooking extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            sel_address: "",

            not_listed: false,
            showDialogue: false,
            loc_not_listed: false,
            scheduled_date: new Date(),
            scheduled_time: this.roundMinutes(new Date()),

            // companiesList: [], mobile_phone: '',
            // t: null,
            // showErrorMesage: '', showError: false
        }

        // this.handleSubmit = this.handleSubmit.bind(this)

    }

    resetValues() {
        // //1 - customer
        // sel_cust                = "";
        // selected_cust_name      = "";
        // selected_cust_id_t      = "";

        // //2 - therapist
        // sel_therapist           = "";
        // selected_therapist_name = "";

        // //3 - loc type
        // sel_type                = "";
        // selected_loc_type_t     = "";

        // //4 - treatment
        // sel_treatment           = "";
        // selected_treatment_name = "";

        // //5 - slot
        // sel_slot                = "";
        // selected_slot_name      = "";

        // //6 - loc
        // sel_loc                 = "";
        // address_                = "";


        ///==============
        selected_cust_id = 0;
        selected_cust_name = "";

        selected_therapist_id = 0;
        selected_therapist_name = "";

        selected_treatment_id = 0;
        selected_treatment_name = "";

        selected_slot_id = 0;
        selected_slot_name = "";

        selected_loc_type_t = 0;
        selected_loc_id = 0;
        selected_loc_name = "";

    }

    loadBasicData() {

        const { user } = this.props;
        console.log('user_id: ' + user.id);
        console.log('action: ' + this.props.location.state.action);
        console.log('data: ' + JSON.stringify(this.props.location.state.data));


        if (this.props.location.state.action == "edit") {
            // debugger;
            let data_ = this.props.location.state.data;
            // var scheduled_date_ = new Date(0).setUTCSeconds(data_.date);
            var scheduled_date_ = moment(new Date(0).setUTCSeconds(data_.date)).toDate();
            console.log("ABCD: " + scheduled_date_);

            var hrs = data_.reserved_slot / 100;
            var mins = data_.reserved_slot % 100;

            scheduled_date_.setHours(hrs);
            scheduled_date_.setMinutes(mins);

            var parsed_ = parseInt(data_.date);
            var dt = new Date(parsed_);
            dt.setHours(hrs);
            dt.setMinutes(mins);
            var scheduled_date_val = dt.getTime() / 1000;
            console.log("Edit scheduled_date_val: " + scheduled_date_val);

            var temp = moment(scheduled_date_).add(hrs, 'hours').add(mins, 'minutes').toDate();
            console.log("Edit scheduled_time: " + temp);
            console.log("Edit scheduled_time roundMinutes: " + this.roundMinutes(temp));

            this.setState({ scheduled_date: scheduled_date_ });
            this.setState({ scheduled_time: scheduled_date_ });


            // var time = temp.format("HH:mm");

            selected_cust_id = data_.customer.id;
            selected_cust_name = data_.customer.first_name + " " + data_.customer.last_name;

            selected_therapist_id = data_.therapist_id;
            selected_therapist_name = data_.therapist_name;
            if (this.props.get_details)
                this.getTherapistChange(selected_therapist_id);

            selected_treatment_id = data_.treatment_id;
            selected_treatment_name = data_.treatment_name;

            selected_slot_id = data_.slot_id;
            selected_slot_name = data_.slot_time;

            selected_loc_type_t = data_.location.location_type == "1" ? location_types[1].value : location_types[0].value;
            selected_loc_id = data_.location.id;
            selected_loc_name = data_.location.location_type == "1" ? data_.location.address : data_.location.name;

            address_ = data_.location.location_type == "1" ? data_.location.address : data_.location.name;
            var t1 = [];
            t1["loc_address"] = address_;
            t1["scheduled_date"] = scheduled_date_;
            t1["scheduled_time"] = temp;

            this.loadSlots(selected_cust_id);


            this.props.initialize({
                ...t1,
            });


            // this.setState({ selected_cust_id: data_.customer.id });
            // this.setState({ selected_therapist_id: data_.therapist_id });
            // this.setState({ selected_therapist_name: data_.therapist_name });
            // this.setState({ selected_slot_id: data_.slot_id });
            // this.setState({ selected_treatment_id: data_.treatment_id });

            // this.setState({ selected_loc_type: selected_loc_type_t});
            // this.setState({ selected_loc_id: data_.location.id });
            // this.setState({ selected_loc_name: data_.location.location_type === "1" ? data_.location.address : data_.location.name });


            // //1 - customer
            // sel_cust                = data_.customer.id;
            // selected_cust_name      = data_.customer.first_name + " " + data_.customer.last_name;
            // selected_cust_id_t      = data_.customer.id;

            // //2 - therapist
            // sel_therapist           = data_.therapist_id;
            // selected_therapist_name = data_.therapist_name;

            // //3 - loc type
            // sel_type                = data_.location.location_type === "1" ? location_types[1].value :location_types[0].value ;
            // selected_loc_type_t     = data_.location.location_type === "1" ? location_types[1].value : location_types[0].value;

            // //4 - treatment
            // sel_treatment           = data_.treatment_id;
            // selected_treatment_name = data_.treatment_name;

            // //5 - slot
            // sel_slot                = data_.slot_id;
            // selected_slot_name      = data_.slot_time;

            // //6 - loc
            // sel_loc                 = data_.location.id;
            // address_                = data_.location.location_type === "1" ? data_.location.address : data_.location.name;

        }
        else {
            this.resetValues();
        }
    }

    roundMinutes(date) {

        date.setHours(date.getHours() + Math.round(date.getMinutes() / 60));
        date.setMinutes(0, 0, 0); // Resets also seconds and milliseconds

        return date;
    }

    componentDidMount() {
        // this.props.initialize({
        //     // ...treatment,
        // });

        console.log("componentDidMount scheduled_time roundMinutes: " + this.state.scheduled_time);

        this.loadBasicData()
        this.resetValues();

        const { id } = this.props.user;
        let data;
        if (id == 22) {
            data = { therapist_id: id }
            this.props.getLocations(data);
        }

        // this.props.getBookingPriceInformation(data);

        //debugger;
        this.props.getPreliminaryDetails();
    }

    componentDidUpdate(prevProps) {
        console.log('componentDidUpdate');

        if (this.props != prevProps) {
            if (this.props.get_details != [] && this.props.get_details != '' && this.props.get_details.customers.length != 0) {
                console.log("componentDidUpdate - get_details: " + JSON.stringify(this.props.get_details));
                if (this.props.location.state.action == "edit") {
                    if (selected_cust_id == 0) {
                        this.loadBasicData();
                    }
                }
            }

            if (action_status === 1) {

                if (this.props.success) {
                    // dispatchEvent
                    action_status = 0;
                    this.props.history.goBack();
                }
            }
        }
    }

    DecryptData(encryptedData) {
        var decryptedText = null;
        try {
            //Creating the Vector Key
            var iv = CryptoJS.enc.Hex.parse('526564614865616c');                //Encoding the Password in from UTF8 to byte array
            var Pass = CryptoJS.enc.Utf8.parse('Crypto');                //Encoding the Salt in from UTF8 to byte array
            var Salt = CryptoJS.enc.Utf8.parse("cryptography123example");               //Creating the key in PBKDF2 format to be used during the decryption
            var key128Bits1000Iterations = CryptoJS.PBKDF2(Pass.toString(CryptoJS.enc.Utf8), Salt, { keySize: 128 / 32, iterations: 1000 });
            //Enclosing the test to be decrypted in a CipherParams object as supported by the CryptoJS libarary
            var cipherParams = CryptoJS.lib.CipherParams.create({
                ciphertext: CryptoJS.enc.Base64.parse(encryptedData)
            });

            //Decrypting the string contained in cipherParams using the PBKDF2 key
            var decrypted = CryptoJS.AES.decrypt(cipherParams, key128Bits1000Iterations, { mode: CryptoJS.mode.CBC, iv: iv, padding: CryptoJS.pad.Pkcs7 });
            decryptedText = decrypted.toString(CryptoJS.enc.Utf8);
            return decryptedText;
        }
        //Malformed UTF Data due to incorrect password
        catch (err) {
            return "";
        }
    }

    submitMethod(values) {
        console.log("submitMethod");

    }

    handleSubmit = (values, dispatch) => {
        console.log("handleSubmit");

        // const { cust_id, loc_type, loc_id, treatment_id, loc_name, slot_id, therapist_id, promocode, notes  } = values;
        const { selected_cust_id, selected_loc_id, selected_treatment_id, selected_slot_id } = this.state;
        selected_therapist_id = this.props.user.id;
        const { scheduled_time, scheduled_date } = this.state;

        //debugger;
        let data = {
            cust_id: selected_cust_id,
            therapist_id: selected_therapist_id,
            treatment_id: selected_treatment_id,
            loc_id: selected_loc_id,
            loc_name: "selected_therapist_id",
            slot_id: selected_slot_id,
            scheduled_date: scheduled_date,
            reserved_slot: scheduled_time,
            promo_code: "",
            notes: "",
        }

        console.log("params: " + JSON.stringify(data));

        // dispatch(createPreliminaryDetails(data));
    }

    onCustomerChange = (cust) => {
        console.log('onCustomerChange: ' + cust);

        let filtered_ = customers_.filter(el => {
            return (el.value === cust)
        });

        // var loc_obj = { value: filtered_[0].value, label: filtered_[0].label };
        // sel_cust = loc_obj;
        // sel_cust = filtered_[0].value;

        selected_cust_name = filtered_[0].label;
        selected_cust_id = cust;

        this.setState({ test: 1 });
    }

    getTherapistChange = (data) => {
        console.log("getTherapistChange: " + JSON.stringify(data));
        const { get_details } = this.props;
        // debugger;

        let filtered_t = get_details.therapists.filter(el => {
            if (el.id === data) {
                //set therapist name
                selected_therapist_name = el.first_name + " " + el.last_name;
            }
            return (el.id === data)
        });
        if (!filtered_t.length)
            return;
        let therapist_treatments = filtered_t[0].therapist_treatment.map(x => x.treatment_id);
        var treatments = therapist_treatments.join();
        let filtered_treatments = get_details.treatments.filter(el => {
            return treatments.includes(el.id);
        });

        //reload treatments as per therapist
        treatments_ = filtered_treatments.map(person => ({ value: person.id, label: person.name }));

        let therapist_locs = filtered_t[0].therapist_location.map(x => x.loc_id);
        var locations = therapist_locs.join();
        let filtered_locations = get_details.locations.filter(el => {
            return locations.includes(el.id);
        });

        //reload locations as per therapist
        locations_ = filtered_locations.map(person => ({ value: person.id, label: person.name }));

        //set therapist id
        selected_therapist_id = data;

        // reset location and treatments
        selected_treatment_id = 0;
        selected_treatment_name = "";

        selected_loc_id = 0;
        selected_loc_name = "";

        selected_slot_id = 0;
        selected_slot_name = "";

        selected_loc_type_t = 0;

        this.setState({ test: 2 });

    }

    loadSlots(cust) {
        // const { get_details } = this.props;
        // console.log("get_details.treatments: " + JSON.stringify(get_details));
        // if (get_details.treatments != undefined) {
        //   let filtered_cust = get_details.treatments.filter( el => {
        //       if (el.id === cust) {
        //           //set treatment name
        //           selected_treatment_name = el.name;
        //       }
        //       return cust === el.id;
        //   });

        //   //load slots on basis of treatments
        //   if (filtered_cust != undefined && filtered_cust.length > 0) {
        //       var details = filtered_cust[0].slot_details;
        //       slots_ = details.map(person => ({ value: person.id, label: person.slot_time }));
        //   }
        //   else {
        //       slots_ = [];
        //   }
        // }

    }

    onTreatmentChange = (cust) => {
        console.log('onTreatmentChange: ' + cust);
        const { get_details } = this.props;

        let filtered_cust = get_details.treatments.filter(el => {
            if (el.id === cust) {
                //set treatment name
                selected_treatment_name = el.name;
            }
            return cust === el.id;
        });

        //load slots on basis of treatments
        if (filtered_cust != undefined && filtered_cust.length > 0) {
            var details = filtered_cust[0].slot_details;
            slots_ = details.map(person => ({ value: person.id, label: person.slot_time }));
        }
        else {
            slots_ = [];
        }

        //set treatment id
        selected_treatment_id = cust;

        selected_slot_id = 0;
        selected_slot_name = "";

        this.setState({ test: 3 });
    }

    onSlotChange = (cust) => {
        console.log('onSlotChange: ' + cust);

        let filtered_ = slots_.filter(el => {
            return (el.value === cust)
        });

        //set slot id/name
        selected_slot_name = filtered_[0].label;
        selected_slot_id = cust;

        this.setState({ test: 4 });
    }

    onLocTypeChange = (cust) => {
        console.log('onLocTypeChange: ' + cust);
        const { get_details } = this.props;

        if (locations_main == []) {
            locations_main = locations_;
        }

        let filtered_cust = [];
        if (cust != "1") {
            filtered_cust = get_details.locations.filter(el => {
                return cust === el.address_type;
            });
        }
        else {
            filtered_cust = get_details.customers.filter(el => {
                return selected_cust_id == el.id;
            });
        }


        if (filtered_cust != undefined && filtered_cust.length > 0) {
            if (cust != "1") {
                locations_ = filtered_cust.map(person => ({ value: person.id, label: person.name }));
            }
            else {
                var add = "no_home_address";
                if (filtered_cust[0].address != null) {
                    add = filtered_cust[0].address + ", " + (filtered_cust[0].zip == null || filtered_cust[0].zip == 0 ? "" : (filtered_cust[0].zip + " ")) + filtered_cust[0].city;
                }
                address_ = add;

                var t1 = [];
                t1["loc_address"] = add;
                this.props.initialize({
                    ...t1,
                });

                console.log("onLocTypeChange private address: " + add);

                //set locations
                selected_loc_name = add;
                selected_loc_id = -1;

                var result = get_details.locations.find(location => {
                    return location.address == add
                });
                locations_ = [{ value: result.id ? result.id : -1, label: add }];
            }

        }
        else {
            locations_ = [];
        }

        selected_loc_type_t = cust;

        selected_loc_id = 0;
        selected_loc_name = "";

        this.setState({ test: 5 });
    }

    onLocChange = (cust) => {
        console.log('onLocChange: ' + cust);

        this.setState({ selected_loc_id: cust });

        let filtered_ = locations_.filter(el => {
            return (el.value === cust)
        });

        address_ = filtered_[0].label;
        // sel_loc = filtered_[0].value;

        selected_loc_id = filtered_[0].value;
        selected_loc_name = filtered_[0].label;

        this.setState({ test: 6 });
    }

    onScheduledDateChange = scheduled_date => this.setState({ scheduled_date })

    onScheduledTimeChange = scheduled_time => this.setState({ scheduled_time })

    // onScheduledTimeChange(scheduled_time) {
    //   this.setState({ scheduled_time });
    // }

    checkIfEmpty(obj) {
        if (obj === "" || obj === undefined || obj == 0) {
            return true;
        }
        return false;
    }

    showDetail = (event) => {
        const { scheduled_time } = this.state;
        console.log("showDetail - scheduled_time: " + scheduled_time);
        console.log("scheduled_time: " + typeof scheduled_time);
        console.log(typeof scheduled_time);

        const { user } = this.props;
        if (user.role_type == 3) {
            selected_therapist_id = user.id;
        }


        if (selected_loc_type_t != "1") {
            if (this.checkIfEmpty(selected_cust_id) || this.checkIfEmpty(selected_therapist_id) ||
                this.checkIfEmpty(selected_treatment_id) || this.checkIfEmpty(selected_loc_id) ||
                this.checkIfEmpty(selected_loc_name) || this.checkIfEmpty(selected_slot_id)) {

                alert("All data fields are mandatory.");
            }
            else {
                if (scheduled_time.toString().includes(":") && !scheduled_time.toString().includes("GMT") && !scheduled_time.toString().includes("+")) {
                    rt_ = scheduled_time;
                }
                else {
                    var t = moment(scheduled_time);

                    if (t === null || t === undefined) {
                        rt_ = scheduled_time;
                    }
                    else {
                        rt_ = t.format('HH:mm');
                    }
                }

                // rt_ = moment(scheduled_time).format('HH:mm');

                this.setState({ showDialogue: true });
            }
        }
        else {
            if (this.checkIfEmpty(selected_cust_id) ||
                this.checkIfEmpty(selected_therapist_id) ||
                this.checkIfEmpty(selected_treatment_id) ||
                this.checkIfEmpty(address_) ||
                this.checkIfEmpty(selected_slot_id)) {

                alert("All data fields are mandatory.");
            }
            else if (address_ === "no_home_address") {
                alert("No home address available, try with another type of location");
            }
            else {
                if (scheduled_time.toString().includes(":") && !scheduled_time.toString().includes("GMT") && !scheduled_time.toString().includes("+")) {
                    rt_ = scheduled_time;
                }
                else {
                    let t = moment(scheduled_time);
                    if (t === null || t === undefined) {
                        rt_ = scheduled_time;
                    }
                    else {
                        rt_ = t.format('HH:mm');
                    }
                }


                this.setState({ showDialogue: true });
            }
        }


    }

    onSaveBtn = (data) => {
        console.log("onSaveBtn: " + this.props.get_details);
        action_status = 1;

        // selected_therapist_id = this.props.user.id;
        const { scheduled_date } = this.state;



        var dt = new Date(scheduled_date);

        // rt_ = moment(scheduled_time).format('HH:mm');
        // console.log("scheduled_time: " + scheduled_time);
        // console.log("rt_: " + rt_);

        var time_ = rt_.split(":");
        dt.setHours(time_[0]);
        dt.setMinutes(time_[1]);
        var scheduled_date_val = Math.floor(dt.getTime() / 1000);

        // var rt = new Date(scheduled_time);
        // var reserved = moment(scheduled_time).format('HH:mm'); // 08/20/2014 3:30 pm
        var reserved_ = rt_.toString().replace(":", "");


        if (this.props.location.state.action === "edit") {
            let data_ = this.props.location.state.data;
            let data = {
                booking_id: data_.raw,
                cust_id: (selected_cust_id === "" || selected_cust_id === undefined ? customers_[0].value : selected_cust_id),
                therapist_id: (selected_therapist_id === "" || selected_therapist_id === undefined ? therapist_[0].value : selected_therapist_id),
                treatment_id: (selected_treatment_id === "" || selected_treatment_id === undefined ? treatments_[0].value : selected_treatment_id),
                loc_id: ((selected_loc_id === "" || selected_loc_id === undefined || selected_loc_id == 0) ? locations_[0].value : selected_loc_id),
                loc_name: (selected_loc_name === "" || selected_loc_name === undefined ? locations_[0].label : selected_loc_name),
                slot_id: (selected_slot_id === "" || selected_slot_id === undefined ? slots_[0].value : selected_slot_id),
                scheduled_date: scheduled_date_val.toString(),
                reserved_slot: reserved_.toString()
            }
            console.log("params: " + JSON.stringify(data));


            this.props.updatePreliminaryDetails(data);

        }
        else {
            let data = {
                cust_id: (selected_cust_id === "" || selected_cust_id === undefined ? customers_[0].value : selected_cust_id),
                therapist_id: (selected_therapist_id === "" || selected_therapist_id === undefined ? therapist_[0].value : selected_therapist_id),
                treatment_id: (selected_treatment_id === "" || selected_treatment_id === undefined ? treatments_[0].value : selected_treatment_id),
                loc_id: ((selected_loc_id === "" || selected_loc_id === undefined || selected_loc_id == 0) ? locations_[0].value : selected_loc_id),
                loc_name: (selected_loc_name === "" || selected_loc_name === undefined ? locations_[0].label : selected_loc_name),
                slot_id: (selected_slot_id === "" || selected_slot_id === undefined ? slots_[0].value : selected_slot_id),
                scheduled_date: scheduled_date_val.toString(),
                reserved_slot: reserved_.toString()
            }
            console.log("params: " + JSON.stringify(data));

            this.props.createPreliminaryDetails(data);
        }
    }

    render = () => {
        const { t, get_details, locations } = this.props;
        console.log("get_details Render: " + this.props.get_details);

        if (this.props.user != undefined) {
            if (this.props.user.role_type == 3) {
                therapist_ = [{ value: this.props.user.id, label: this.props.user.first_name + " " + this.props.user.last_name }]
            }
            if (this.props.user.role_type == 3) {
                selected_therapist_name = this.props.user.first_name + " " + this.props.user.last_name
            }
            // this.setState({ selected_therapist: therapist_[0] });
        }

        if (locations != "" && get_details != undefined) {
            // locations_ = get_details.locations.map(person => ({ value: person.id, label: person.address_type == 1 ? person.address : person.name }));
            locations_ = locations.map(person => ({ value: person.id, label: person.address_type == 1 ? person.address : person.name }));
        }
        if (get_details != "" && get_details != undefined) {

            if (therapist_.length == 0) {
                therapist_ = get_details.therapists.map(person => ({ value: person.id, label: person.first_name + " " + person.last_name }));
            }
            if (customers_.length == 0) {
                customers_ = get_details.customers.map(person => ({ value: person.id, label: person.first_name + " " + person.last_name }));
                customers_.sort((a, b) => (a.label > b.label) ? 1 : ((b.label > a.label) ? -1 : 0));
            }

            if (treatments_.length == 0) {
                if (this.props.user.id != 1)
                    this.getTherapistChange(this.props.user.id);
                // treatments_ = get_details.treatments.map(person => ({ value: person.id, label: person.name }));
                // console.log("Treatments updated 1");
            }

            if (locations_.length == 0) {
                locations_ = get_details.locations.map(person => ({ value: person.id, label: person.name }));
            }

        }

        console.log("TREATMENTS ===== : " + JSON.stringify(treatments_));
        console.log("selected_slot_id: " + JSON.stringify(selected_slot_id));

        return (
            <Container fluid className="personal-container">
                {this.state.showDialogue &&
                    <ConfirmationDialog
                        title={t != null ? t.translations.booking_details : ''}
                        bookingCancelBtn={true}
                        bodyText={
                            <Fragment>
                                <h5>{t.translations.customer_name}</h5>
                                <p>{selected_cust_name}</p>
                                <h5>{t.translations.confirmBooking_treatment_app}</h5>
                                <p>{selected_treatment_name}</p>
                                <h5>{t.translations.staff}</h5>
                                <p>{selected_therapist_name}</p>
                                <h5>{t.translations.select_slot}</h5>
                                <p>{selected_slot_name}</p>
                                <h5>{t.translations.confirmBooking_location_app}</h5>
                                <p>{address_}</p>
                                <h5>{t.translations.schedule_date}</h5>
                                <p>{moment(new Date(this.state.scheduled_date)).format('YYYY-MM-DD') + " " + rt_.toString()}</p>
                            </Fragment>
                        }
                        okBtnTxt={(this.props.location.state.action === "edit" ? t.translations.update : t.translations.add_new_booking)}
                        handleCancel={() => this.setState({ showDialogue: false })}
                        handleOk={this.onSaveBtn} />}

                <Header t={this.props.t} title={t != null && t.translations.menu_booking} props={this.props} />
                <Col className="col-md-12 mt-5 data-table-container">
                    <Form autoComplete="off">
                        <Alert success={this.props.success} message={this.props.message} t={this.props.t} />
                        <hr class="dotted"></hr>
                        <div className="row">
                            <div className="col-md-6 col-xs-12 float-left">
                                <Field
                                    label={t != null ? t.translations.customer_name : ''}
                                    options={customers_}
                                    selected={this.props.location.state.action === "edit" ? selected_cust_id : ""}
                                    type="my-select"
                                    name="cust_id"
                                    classes="Field-input"
                                    validate={[required]}
                                    component={FormField}
                                    t={this.props.t}
                                    onChange={this.onCustomerChange}
                                    placeholder={t != null ? t.translations.select : ''}
                                    noOptionsMessage={t != null ? t.translations.no_options : ''}
                                />
                            </div>
                        </div>
                        {this.props.user.role_type != 3 && <div>
                            <hr class="dotted"></hr>
                            <div className="row">
                                <div className="col-md-6 col-xs-12 float-left">
                                    <Field
                                        label={t != null ? t.translations.staff : ''}
                                        type="my-select"
                                        name="therapist_id"
                                        classes="Field-input"
                                        options={therapist_}
                                        onChange={this.getTherapistChange}
                                        selected={this.props.location.state.action === "edit" ? selected_therapist_id : ""}
                                        component={FormField}
                                        t={this.props.t}
                                        placeholder={t != null ? t.translations.select : ''}
                                        noOptionsMessage={t != null && t.translations.no_options}
                                    />
                                </div>
                            </div>
                        </div>}
                        <hr class="dotted"></hr>
                        <div className="row">
                            <div className="col-md-6 col-xs-12 float-right">
                                <Field
                                    label={t != null ? t.translations.menu_treatment : ''}
                                    type="my-select"
                                    name="treatment_id"
                                    selected={this.props.location.state.action === "edit" ? selected_treatment_id : ""}
                                    classes="Field-input"
                                    options={treatments_}
                                    validate={[required]}
                                    component={FormField}
                                    t={this.props.t}
                                    onChange={this.onTreatmentChange}
                                    placeholder={t != null ? t.translations.select : ''}
                                    noOptionsMessage={t != null && t.translations.no_options}
                                />
                            </div>
                            <div className="col-md-6 col-xs-12 float-left">
                                <Field
                                    label={t != null ? t.translations.select_slot : ''}
                                    type="my-select"
                                    name="slot_id"
                                    classes="Field-input"
                                    options={slots_}
                                    selected={this.props.location.state.action === "edit" ? selected_slot_id : ""}
                                    // validate={[required]}
                                    component={FormField}
                                    t={this.props.t}
                                    onChange={this.onSlotChange}
                                    placeholder={t != null ? t.translations.select : ''}
                                    noOptionsMessage={t != null && t.translations.no_options}
                                />
                            </div>
                        </div>
                        <hr class="dotted"></hr>
                        <div className="row">
                            <div className="col-md-6 col-xs-12 float-center">
                                <Field
                                    label={t != null ? t.translations.location_type : ''}
                                    type="my-select"
                                    name="loc_type"
                                    selected={this.props.location.state.action === "edit" ? selected_loc_type_t : ""}
                                    classes="Field-input"
                                    validate={[required]}
                                    component={FormField}
                                    t={this.props.t}
                                    options={location_types}
                                    onChange={this.onLocTypeChange}
                                    placeholder={t != null ? t.translations.select : ''}
                                    noOptionsMessage={t != null && t.translations.no_options}
                                />
                            </div>
                        </div>
                        <div className="row">

                            {selected_loc_type_t != "1" &&
                                <div className="col-md-6 col-xs-12 float-left">
                                    <Field
                                        label={t != null ? t.translations.confirmBooking_location_app : ''}
                                        type="my-select"
                                        selected={this.props.location.state.action === "edit" ? selected_loc_id : ""}
                                        name="loc_id"
                                        classes="Field-input"
                                        validate={[required]}
                                        component={FormField}
                                        t={this.props.t}
                                        options={selected_loc_type_t == 0 ? [] : locations_}
                                        onChange={this.onLocChange}
                                        placeholder={t != null ? t.translations.select : ''}
                                        noOptionsMessage={t != null && t.translations.no_options}
                                    />
                                </div>
                            }

                            {selected_loc_type_t === "1" &&
                                <div className="col-md-6 col-xs-12 float-left">
                                    <Field
                                        label={t != null ? t.translations.confirmBooking_location_app : ''}
                                        type="vat"
                                        disabled
                                        name="loc_address"
                                        classes="Field-input"
                                        component={FormField}
                                        t={this.props.t}
                                    />
                                </div>
                            }
                        </div>
                        {this.state.loc_not_listed && <div>
                            <div className="row">
                                <div className="col-md-6 col-xs-12 float-right">
                                    {/* <Map
                                    google={this.props.google}
                                    center={{lat: 18.5204, lng: 73.8567}}
                                    height='300px'
                                    zoom={15}
                                    /> */}
                                    <Field
                                        label={t != null ? t.translations.location_name : ''}
                                        type="text"
                                        name="loc_name"
                                        classes="Field-input"
                                        validate={[required]}
                                        component={FormField}
                                        t={this.props.t}
                                    />
                                </div>
                            </div>
                        </div>}
                        <br />
                        <br />
                        <hr class="dotted"></hr>
                        <div className="row">
                            <div className="col-md-6 col-xs-12 float-left">
                                <Field
                                    label={t != null ? t.translations.schedule_date : ''}
                                    type="pbDatePicker"
                                    name="scheduled_date"
                                    classes="Field-input"
                                    defaultValue={this.state.scheduled_date}
                                    onChange={this.onScheduledDateChange}
                                    validate={[required]}
                                    component={FormField}
                                    t={this.props.t}
                                />
                            </div>
                            <div className="col-md-6 col-xs-12 float-right">
                                <Field
                                    label={t != null ? t.translations.schedule_time : ''}
                                    type="pbTimePicker"
                                    name="scheduled_time"
                                    defaultValue={this.state.scheduled_time}
                                    onChange={this.onScheduledTimeChange}
                                    classes="Field-input"
                                    validate={[required]}
                                    component={FormField}
                                    t={this.props.t}
                                />
                            </div>
                        </div>
                        {/* <div className="row">
                            <div className="col-md-6 col-xs-12 float-left">
                                <Field
                                    label={t != null ? t.translations.schedule_time : ''}
                                    type="pbDateTimePicker"
                                    name="scheduled_time"
                                    // defaultValue={this.state.scheduled_time}
                                    // onChange={this.onScheduledTimeChange}
                                    classes="Field-input"
                                    validate={[required]}
                                    component={FormField}
                                    t={this.props.t}
                                />
                            </div>
                        </div> */}
                        <Button onClick={this.showDetail} className="btn-block float-right save-btn Field-input" type="button">{t != null && (this.props.location.state.action === "edit" ? t.translations.update : t.translations.save)} {this.props.loading && <i className="fas fa-circle-notch fa-spin loader"></i>}</Button>

                    </Form>
                </Col>
            </Container >
        );
    }
};

const mapStateToProps = state => {
    return {
        t: state.accountRedux.localeInfo.t,
        loading: state.userRedux.loading,
        success: state.userRedux.success,
        message: state.userRedux.message,
        get_details: state.userRedux.get_details,
        user: state.accountRedux.user,
        locations: state.locationRedux.locations,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getPreliminaryDetails: () => dispatch(getPreliminaryDetails()),
        getLocations: (data) => dispatch(getLocations(data)),
        updatePreliminaryDetails: (data) => dispatch(updatePreliminaryDetails(data)),
        createPreliminaryDetails: (data) => dispatch(createPreliminaryDetails(data))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({
    form: 'AddBookingForm'
})(AddBooking));
