import { createRoutine } from 'redux-saga-routines';
import { groupBy } from '../constants';
export const addTherapist = createRoutine('ADD_THERAPIST');
export const addNewTherapist = createRoutine('ADD_NEW_THERAPIST');
export const updateTherapist = createRoutine('UPDATE_THERAPIST');
export const statsTherapist = createRoutine('GET_THERAPIST_STATS');

export const deleteTherapist = createRoutine('DELETE_THERAPIST');
export const getTherapists = createRoutine('GET_THERAPISTS');
export const getLocationsByTherapistId = createRoutine('GET_LOCATIONS_BY_THERAPIST_ID');
export const getTherapistsByTreatmentId = createRoutine('GET_THERAPISTS_BY_TREATMENT_ID');
export const showTherapistDetail = createRoutine('SHOW_THERAPIST_DETAIL');
export const getSchedule = createRoutine('GET_SCHEDULE');
export const getTreatmentsByTherapistId = createRoutine('GET_TREATMENTS_BY_THERAPIST_ID');
export const addTherapistScheduleSlots = createRoutine('ADD_THERAPIST_SCHEDULE_SLOTS');
export const getTherapistScheduleSlots = createRoutine('GET_THERAPIST_SCHEDULE_SLOTS');
export const deleteTherapistScheduleSlots = createRoutine('DELETE_THERAPIST_SCHEDULE_SLOTS');
export const getAllSchedule = createRoutine('GET_ALL_SCHEDULE');
export const checkScheduleSlots = createRoutine('CHECK_SCHEDULE_SLOTS');

const INITIAL_STATE = {
    error: false,
    success: false,
    message: '',
    del_success: false,
    del_message: '',
    loading: false,
    therapistInfo: {},
    therapists: [],
    allTherapist: [],
    therapistLocations: [],
    deleted: false,
    schedule: [],
    all_schedule: [],
    stats: [],
    therapistTreaments: [],
    therapistSlots: [],
    showTherapistData: false,
    delete_therapist: false,
    add_therapist_data: '',
    delete_therapist_data: '',
    check_therapist_data: '',
};
export default (state = INITIAL_STATE, { type, payload }) => {
    switch (type) {
        case addTherapist.TRIGGER:
            return { ...state, loading: true, error: false, success: false, message: '', deleted: false };
        case addTherapist.SUCCESS:
            return { ...state, loading: false, error: false, success: true, message: payload, deleted: false };
        case addTherapist.FAILURE:
            return { ...state, loading: false, success: false, error: true, message: payload, deleted: false };

        case updateTherapist.TRIGGER:
            return { ...state, loading: true, error: false, success: false, message: '', deleted: false };
        case updateTherapist.SUCCESS:
            return { ...state, loading: false, error: false, success: true, message: payload, deleted: false };
        case updateTherapist.FAILURE:
            return { ...state, loading: false, success: false, error: true, message: payload, deleted: false };

        case statsTherapist.TRIGGER:
            return { ...state, loading: true, error: false, success: false, stats: '', deleted: false };
        case statsTherapist.SUCCESS:
            return { ...state, loading: false, error: false, success: true, stats: payload, deleted: false };
        case statsTherapist.FAILURE:
            return { ...state, loading: false, success: false, error: true, stats: payload, deleted: false };


        case deleteTherapist.TRIGGER:
            return { ...state, loading: true, error: false, success: false, message: '', deleted: false };
        case deleteTherapist.SUCCESS:
            return { ...state, loading: false, error: false, success: true, message: payload, deleted: true };
        case deleteTherapist.FAILURE:
            return { ...state, loading: false, success: false, error: true, message: payload, deleted: false };

        case getTherapists.TRIGGER:
            return { ...state, loading: true, error: false, success: false, therapistInfo: '', deleted: false, allTherapist: [], showTherapistData: false };
        case getTherapists.SUCCESS:
            let therapist_list = [];
            const grouped1 = groupBy(payload, therapist => therapist.id);
            therapist_list = [];
            grouped1.forEach(element => {
                therapist_list.push(element[0])
            });
            return { ...state, loading: false, error: false, success: true, therapists: therapist_list, deleted: false, showTherapistData: true };
        case getTherapists.FAILURE:
            return { ...state, loading: false, success: false, error: true, message: payload, deleted: false, showTherapistData: false };

        case getTreatmentsByTherapistId.TRIGGER:
            return { ...state, loading: true, error: false, success: false, therapistTreaments: [] };
        case getTreatmentsByTherapistId.SUCCESS:
            return { ...state, loading: false, error: false, success: true, therapistTreaments: payload };
        case getTreatmentsByTherapistId.FAILURE:
            return { ...state, loading: false, success: false, error: true, message: payload };

        case getTherapistsByTreatmentId.TRIGGER:
            return { ...state, loading: true, error: false, success: false, therapistInfo: '', allTherapist: [] };
        case getTherapistsByTreatmentId.SUCCESS:
            // var therapist_list = [];
            // payload.forEach(schedule => {
            //     therapist_list.push(schedule.therapist);
            // });
            // const grouped = groupBy(therapist_list, therapist => therapist.id);
            // therapist_list = [];
            // grouped.forEach(element => {
            //     therapist_list.push(element[0])
            // });
            return { ...state, loading: false, error: false, success: true, allTherapist: payload };
        case getTherapistsByTreatmentId.FAILURE:
            return { ...state, loading: false, success: false, error: true, message: payload };

        case getLocationsByTherapistId.TRIGGER:
            return { ...state, loading: true, error: false, success: false, therapistLocations: [] };
        case getLocationsByTherapistId.SUCCESS:
            return { ...state, loading: false, error: false, success: true, therapistLocations: payload };
        case getLocationsByTherapistId.FAILURE:
            return { ...state, loading: false, success: false, error: true, message: payload };

        case getSchedule.TRIGGER:
            return { ...state, loading: true, error: false, success: false, schedule: [] };
        case getSchedule.SUCCESS:
            therapist_list = [];
            payload.forEach(schedule => {
                therapist_list.push(schedule.therapist);
            });
            const grouped = groupBy(therapist_list, therapist => therapist.id);
            therapist_list = [];
            grouped.forEach(element => {
                therapist_list.push(element[0])
            });
            return { ...state, loading: false, error: false, success: true, schedule: payload, therapists: therapist_list };
        case getSchedule.FAILURE:
            return { ...state, loading: false, success: false, error: true, message: payload };

        case getAllSchedule.TRIGGER:
            return { ...state, loading: true, error: false, success: false, all_schedule: [] };
        case getAllSchedule.SUCCESS:
            return { ...state, loading: false, error: false, success: true, all_schedule: payload };
        case getAllSchedule.FAILURE:
            return { ...state, loading: false, success: false, error: true, message: payload };

        case showTherapistDetail.TRIGGER:
            return { ...state, therapistInfo: payload };

        case addTherapistScheduleSlots.TRIGGER:
            return { ...state, loading: true, error: false, success: false, add_therapist_data: '' };
        case addTherapistScheduleSlots.SUCCESS:
            const { data } = payload;
            return { ...state, loading: false, error: false, success: true, add_therapist_data: payload, therapistSlots: data.therapistSlots };
        case addTherapistScheduleSlots.FAILURE:
            return { ...state, loading: false, success: false, error: true, add_therapist_data: payload, deleted: false };


        case deleteTherapistScheduleSlots.TRIGGER:
            return { ...state, loading: true, error: false, success: false, delete_therapist_data: '', deleted: false, delete_therapist: false };
        case deleteTherapistScheduleSlots.SUCCESS:
            return { ...state, loading: false, error: false, success: true, delete_therapist_data: payload, deleted: true, delete_therapist: true };
        case deleteTherapistScheduleSlots.FAILURE:
            return { ...state, loading: false, error: true, success: false, delete_therapist_data: '', deleted: false, delete_therapist: false };

        case checkScheduleSlots.TRIGGER:
            return { ...state, loading: true, error: false, success: false, check_therapist_data: '' };
        case checkScheduleSlots.SUCCESS:
            return { ...state, loading: false, error: false, success: true, check_therapist_data: payload };
        case checkScheduleSlots.FAILURE:
            return { ...state, loading: false, error: true, success: false, check_therapist_data: '' };


        case getTherapistScheduleSlots.TRIGGER:
            return { ...state, loading: true, error: false, success: false, therapistSlots: [], deleted: false };
        case getTherapistScheduleSlots.SUCCESS:
            return { ...state, loading: false, error: false, success: true, therapistSlots: payload, deleted: false };
        case getTherapistScheduleSlots.FAILURE:
            return { ...state, loading: false, success: false, error: true, message: payload, deleted: false };

        case addNewTherapist.TRIGGER:
            return { ...state, message: '' };

        default:
            return { ...state }

    }
}